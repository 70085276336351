import { Promotion } from "../promotion.model";
import { TicketLevel } from "../ticket-level.model";
import { TicketPrice } from "../ticket-price.model";
import { CartItemTypes } from "./cart-item-types.enum";
import { ReservedSeat } from "../reserved/seat.model";
import { CartItemCollection } from "./cart-item-collection.model";
import { CartItemTicketPriceSummary } from "./cart-item-tickettype-summary.model";
import * as _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { PromotionCode } from "../promotion-code.model";
import { ConsumerGatePassHolder } from "../passes/consumer-gate-pass.model";
import { EventStoreChannel } from "../events/event-store-channel.model";

export interface CartItemProduct {
    id: number;
}

export abstract class CartItem {

    public uuid: string;
    public itemType: CartItemTypes;
    public product: CartItemProduct;
    public ticketPrice: TicketPrice;
    public seat: ReservedSeat = null;
    public price: number;

    public fees: number = 0;
    public discount: number = 0;

    // used for family passes
    public selectedQty: number = 0;
    public members: Array<ConsumerGatePassHolder> = new Array<ConsumerGatePassHolder>();

    public promotion: Promotion = new Promotion();
    public channel: EventStoreChannel;

    constructor() {
        this.uuid = uuid();
    }

    get totalPrice(): number {
        return (this.price + this.fees) - this.discount;
    }

    public hasPromotion(): boolean {
        if (this.promotion === null) {
            return false;
        } else {
            return this.promotion.id > 0
        }
    }

    get isReserved(): boolean {
        if (this.seat === null) {
            return false;
        }
        return true;
    }

    public serialize(): any {

        let channel = {id: null};
        let channelCode = null;

        if (this.isReserved) {

            if (this.channel) {
                channel = { id: this.channel.id };
                channelCode =  this.channel.channelCode ? this.channel.channelCode.code : null;
            }

            return {
                uuid: this.uuid,
                itemType: this.itemType,
                ticketPrice: {id: this.ticketPrice.id},
                product: {id: this.product.id, type: this.itemType},
                seat: this.seat.serialize() || null,
                price: this.price,
                channel: channel,
                channelCode: channelCode
            }
        } else {

            if (this.channel) {
                channel = { id: this.channel.id };
                channelCode =  this.channel.channelCode ? this.channel.channelCode.code : null;
            }

            return {
                uuid: this.uuid,
                itemType: this.itemType,
                ticketPrice: {id: this.ticketPrice.id},
                product: {id: this.product.id, type: this.itemType},
                price: this.price,
                selectedQty: this.selectedQty,
                members: this.members,
                channel: channel,
                channelCode: channelCode
            }
        }

    }
}

export abstract class CartItemSummary {

    public items: CartItemCollection = new CartItemCollection();

    public product: CartItemProduct;
    public ticketPrices: CartItemTicketPriceSummary[];
    public totalFees: number;
    public totalAmount: number;
    public promotion: Promotion = new Promotion();

    constructor(product: CartItemProduct, items: CartItem[]) {
        this.items._addItems(items);
        // this.items.items = items;
        this.product = product;
        this.ticketPrices = _.chain(items.filter((item) => item.ticketPrice && !item.ticketPrice.isReservedPrice()))
            .groupBy((value) => value.ticketPrice.id)
            .map((value) =>  new CartItemTicketPriceSummary(new TicketPrice().deserialize(value[0].ticketPrice), value.length))
            .orderBy(['ticketPrice.ticketType.rank'])
            .value();

        let promotionItem = this.items.items.find((item) => {
            return item.hasPromotion();
        });

        if (promotionItem) {
            this.promotion = promotionItem.promotion;
        }
    }

    get seats(): CartItem[] {
        return this.items.items.filter((item) => item.seat)
    }

    public hasPromotion(): boolean {
        if (this.promotion === null) {
            return false;
        } else {
            return this.promotion.id > 0
        }
    }

}
