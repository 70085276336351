import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable()
export class SpinnerService {

    public loading: Subject<boolean> = new Subject<boolean>();
    public message: Subject<string> = new Subject<string>();

    constructor(private spinner: NgxSpinnerService) { }

    show() {
        this.spinner.show();
        this.loading.next(true);
    }

    hide() {
        this.spinner.hide().then(() => {
            this.setMessage("Loading...");
        });

        of(this.loading).pipe(
            delay(0),
            tap(() => this.loading.next(false))
        ).subscribe();
    }

    setMessage(message: string) {
        this.message.next(message);
    }
}
