import { Component, Input } from '@angular/core';
import { Event } from '@app/data/models/event.model';

@Component({
    selector: 'ts-event-header-list-item',
    templateUrl: './event-header-list-item.component.html',
    styleUrls: ['./event-header-list-item.component.scss']
})

export class EventHeaderListItemComponent {
    @Input('event') event: Event;
    @Input('logo') logo: string;
    @Input('ticketView') ticketView: boolean;
    @Input('parentSchoolView') parentSchoolView: boolean;
 
    constructor() { }

}
