<div class="modal-body p-0 pb-4 mb-3">
    <button type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <ts-ticket-header [agency]="admission.event.agency" class="fallback-gradient"></ts-ticket-header>
    <h5 class="border-0 text-center mt-4 pt-3 pb-0 mb-0">
        {{ admission.event.agency.name }}
    </h5>
    <ts-ticket-event-header [event]="admission.event"></ts-ticket-event-header>
    <div>
        <h5 class="h5 border-top border-bottom text-center py-2 my-1 text-uppercase">
            <strong>Select ticket Quantity</strong>
        </h5>
        <div class="row align-items-center m-0 schedule py-2" *ngFor="let level of punchPass.levels">
            <div class="col-8 text-left">
                <div class="price title">{{level.ticketType.title}}</div>
            </div>
            <div class="col-4 text-right">
                <div class="text-muted">{{level.remainingUses}} remaining</div>
            </div>
            <div class="col-12 pt-2">
                <div class="quantity-selector">
                    <div class="row align-items-center text-center m-0">
                        <div class="col-4 p-0">
                            <button mdbRippleRadius type="button" class="btn btn-light quantity-decrease border-0" style="width:100%;height:100%;" (click)="level.updateQty(-1)" [disabled]="level.selectedQty === 0">-</button>
                        </div>
                        <div class="col-4 border-right border-left">
                            <div class="quantity">{{ level.selectedQty }}</div>
                        </div>
                        <div class="col-4 p-0">
                            <button mdbRippleRadius type="button" class="btn btn-light quantity-increase border-0" style="width:100%;height:100%;" (click)="level.updateQty(1)" [disabled]="level.isMaxedOut()">+</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-3">
            <div class="check-in-all text-center">
                <button type="button" class="btn btn-success btn-block text-uppercase" (click)="claimTickets()" [disabled]="isLoading || punchPass.getTotalSelectedQty() === 0">
                    <div *ngIf="!isLoading"><i class="fa fa-check-circle mr-2"></i>CLAIM TICKETS</div>
                    <div *ngIf="isLoading"><i class="fa fa-spin fa-spinner mr-2"></i>Claiming Tickets...</div>
                </button>
            </div>
            <div class="alert alert-info font-sm text-center my-2" style="border-radius:0;">
                <strong>Please note:</strong> Using your pass to claim tickets will decrease the number of uses you have remaining on your punch pass.  Claimed tickets are non-refundable.
            </div>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
    <button type="button" class="btn btn-light" aria-label="Close" style="min-width:150px;" (click)="close()">Close</button>
</div>
