import { Component, Input, OnInit } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';

@Component({
  selector: 'ts-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})

export class CallToActionComponent implements OnInit {
  @Input('version') version: string;
  @Input('agency') agency: Agency;

  constructor() {}

  ngOnInit() {
      this.agency.currentYear.name
  }


}
