import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AgencyComponent } from './agency.component';
import { AgencyHomeComponent } from '@app/agency/home/home.component';
import { AgencyEventsComponent } from '@app/agency/events/listing.component';
import { AgencyPassesComponent } from '@app/agency/passes/listing.component';
import { AgencyTicketRenewalComponent } from '@app/agency/ticket-renewal/ticket-renewal.component';
import { AgencyEventComponent } from '@app/agency/events/event.component';
import { AgencyPassComponent } from '@app/agency/passes/pass.component';
import { AgencyResolver } from '@app/data/agency.resolver';
import { AgencySeasonReservedComponent } from './passes/season-reserved/season-reserved.component';
import { AgencySeasonTicketRenewalComponent } from './season-ticket-renewal/season-ticket-renewal.component';
import { AuthenticationGuard } from '@app/core';
import { SeasonTicketExchangeComponent } from './season-ticket-exchange/season-ticket-exchange.component';
import { AgencyBundleComponent } from './bundles/pass.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts    
    { path: ':agency-id', component: AgencyComponent, data: {showSpinner: true}, resolve: { agency: AgencyResolver },
        children: [ 
            { path: '', component: AgencyHomeComponent, data: {showSpinner: true}},
            { path: 'events', component: AgencyEventsComponent, data: {showSpinner: true}},
            { path: 'events/:event-id', component: AgencyEventComponent, data: {showSpinner: true}},
            { path: 'passes', component: AgencyPassesComponent, data: {showSpinner: true}},
            { path: 'passes/:pass-id', component: AgencyPassComponent, data: {showSpinner: true}},
            { path: 'bundle/:pass-id', component: AgencyBundleComponent, data: {showSpinner: true}},
            { path: 'season/:season-id', component: AgencySeasonReservedComponent, data: {showSpinner: true}},
            { path: 'renewal/:pass-id', component: AgencySeasonTicketRenewalComponent, data: {showSpinner: false}},
            { path: 'renewal/:pass-id/:access-key', component: AgencySeasonTicketRenewalComponent, data: {showSpinner: false}},
            { path: 'exchange/:pass-id', component: SeasonTicketExchangeComponent, canActivate: [AuthenticationGuard], data: {showSpinner: false}},
        ],
        runGuardsAndResolvers: 'paramsChange'
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AgencyRoutingModule { }
