import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset/reset-password.component';

import { RegisterComponent } from '@app/auth/register/register.component';
import { AuthComponent } from '@app/auth/auth.component';
import { SharedModule } from '@app/shared';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailComponent } from './shared/email/email.component';
import { UserComponent } from './shared/user/user.component';
import { PasswordComponent } from './shared/password/password.component';
import { VerificationCodeComponent } from './shared/verification-code/verification-code.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        NgbModule,
        AuthRoutingModule,
    ],
    declarations: [
        AuthComponent, 
        LoginComponent, 
        ResetPasswordComponent, 
        RegisterComponent,
        ChangePasswordComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        EmailComponent,
        UserComponent,
        PasswordComponent,
        VerificationCodeComponent,
    ],
    providers: [
    ]
})
export class AuthModule {}
