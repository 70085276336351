<form (ngSubmit)="postEmail()" [formGroup]="forgotPasswordForm" novalidate autocomplete="on">
    <div class="form-group">
        <div class="py-2">
            <p class="m-0 p-0 pb-1 text-center">
                Forgot your password? Enter your email below:
            </p>
        </div>
        <label class="d-block my-3">
            <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'" />
            <span hidden >Email</span>
            <div [hidden]="forgotPasswordForm.get('email').untouched" *ngIf="forgotPasswordForm.get('email').invalid" class="text-danger" style="font-size: .8em">
                <div *ngIf="forgotPasswordForm.get('email').errors?.required">Email is required</div>
                <div *ngIf="forgotPasswordForm.get('email').errors?.email">Email must be a valid email format</div>
            </div>
        </label>
    </div>
    <button mdbRippleRadius class="btn btn-primary w-100 mx-0 mt-3" type="submit" [disabled]="forgotPasswordForm.pristine || forgotPasswordForm.invalid || isLoading">
        <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
        <h6 class="m-0" [hidden]="isLoading">Forgot Password</h6>
    </button>
    <button mdbRippleRadius class="btn btn-outline-primary w-100 mx-0 my-4" [routerLink]="['/auth', 'login']">
        <h6 class="m-0">Back To Login</h6>
    </button>
</form>