<div class="modal-body p-0 mb-3">
    <button type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="activeModal.dismiss(false)">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <ts-ticket-header [agency]="admission.event.agency" class="fallback-gradient"></ts-ticket-header>
    <h5 class="border-0 text-center mt-4 pt-3 pb-0 mb-0">
        {{ admission.event.agency.name }}
    </h5>
    <ts-ticket-event-header [event]="admission.event"></ts-ticket-event-header>
    <div class="p-3 text-left" style="overflow:hidden;" *ngIf="step === 1">
        <div *ngIf="admission.getTransferableTickets().length > 0">
            <h5 class="border-top border-bottom text-center py-2 text-uppercase mx-4">
                Transfer Tickets
            </h5>
            <div class="alert alert-warning font-sm text-center mb-4">
                <strong>Please note:</strong> The person who you are transferring the tickets to will receive an email.  Once they register, the tickets will be available in their account.
            </div>
            <h6 class="text-center py-2 text-uppercase">
                Select Tickets to be Transferred
            </h6>
            <mat-list style="background:transparent;">
                <mat-list-item class="border rounded mb-2" [ngClass]="{'border-success': isTicketSelected(ticket.uuid)}" style="background:white;height:47px;cursor:pointer;" *ngFor="let ticket of admission.getTransferableTickets()" (click)="toggleTicket(ticket.uuid)">
                    <i mat-list-icon class="mt-1 mr-3 fa fa-lg fa-fw" [ngClass]="{'fa-check-square text-success': isTicketSelected(ticket.uuid), 'fa-square-o': !isTicketSelected(ticket.uuid) } "></i>
                    <p class="h5" mat-line *ngIf="ticket.level"><strong>{{ ticket.level.value }}</strong> <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                    <p class="h5" mat-line *ngIf="!ticket.level"><strong>Section:</strong> {{ ticket.seat.section }} <strong>Seat:</strong> {{ ticket.seat.row }}-{{ ticket.seat.number }} - <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div *ngIf="step === 2"  class="p-3 mx-auto my-0 mb-2">
        <h5 class="border-top border-bottom text-center py-2 text-uppercase mx-4">
            Transfer Tickets To
        </h5>
        <div *ngIf="users.length > 0">
            <h6 class="text-center text-uppercase pt-2">
                Select a User
            </h6>
            <mat-list style="background:transparent;">
                <mat-list-item class="border rounded mb-2" [ngClass]="{'border-success': isUserSelected(user)}" style="background:white;height:47px;cursor:pointer;"  *ngFor="let user of users" (click)="toggleUser(user)">
                    <i mat-list-icon class="mt-1 mr-3 fa fa-lg fa-fw" [ngClass]="{'fa-check-square text-success': isUserSelected(user), 'fa-square-o': !isUserSelected(user)} "></i>
                    <p class="h5" mat-line><strong>{{ user.name }} - {{user.email}}</strong></p>
                </mat-list-item>
            </mat-list>
            <div class="text-center row my-2 font-sm align-items-center">
                <div class="col-5"><hr /></div>
                <div class="col-2">OR</div>
                <div class="col-5"><hr /></div>
            </div>
        </div>
        <!-- <div class="text-center my-2 text-uppercase">
            <strong>Enter an Email Address</strong>
        </div> -->
        <form (ngSubmit)="transferTickets()" [formGroup]="transferForm" novalidate>
            <div class="form-group">
                <label class="d-block">
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="email-addon"><i class="fa fa-envelope"></i></span>
                        </div>
                        <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Enter Email Address'"  aria-label="Email Address" aria-describedby="email-addon" email/>
                    </div>
                    <span hidden>Email</span>
                    <small [hidden]="transferForm.controls.email.valid || transferForm.controls.email.untouched" class="text-danger">
                    Email is required
                    </small>
                </label>
            </div>
        </form>
        <div class="mt-2 mb-3" [hidden]="step==1">
            <button class="btn btn-secondary m-0 btn-block mb-3 text-uppercase" type="submit" [disabled]="transferForm.invalid || isLoading" (click)="transferTickets()">
                <span [hidden]="!isLoading">
                    <i class="fa fa-cog fa-spin mr-3 d-inline-block"></i>
                    Sending Tickets...
                </span>
                <span [hidden]="isLoading"><i class="fa fa-paper-plane mr-3"></i>Transfer Tickets</span>
            </button>
        </div>
        <h6 class="text-center mt-4 py-2 text-uppercase">
            Tickets being Transferred
        </h6>
        <mat-list style="background:transparent;">
            <mat-list-item class="border rounded mb-2" style="background:white;height:47px;cursor:pointer;" *ngFor="let ticket of selectedTickets">
                <i mat-list-icon class="mt-1 mr-1 fas fa-lg fa-fw fa-ticket-alt"></i>
                <p class="h5" mat-line *ngIf="ticket.level"><strong>{{ ticket.level.value }}</strong> <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                <p class="h5" mat-line *ngIf="!ticket.level"><strong>Section:</strong> {{ ticket.seat.section }} <strong>Seat:</strong> {{ ticket.seat.row }}-{{ ticket.seat.number }} - <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="p-3">
        <div [hidden]="step==2">
            <button type="button" class="btn btn-secondary btn-block text-uppercase" (click)="updateStep(2)" [disabled]="selectedTickets.length==0">
                Next<i class="fa fa-arrow-right ml-2"></i>
            </button>
        </div>
        <div [hidden]="step==1">
            <button class="btn btn-block btn-secondary m-0 mr-auto text-uppercase" type="submit" (click)="step = 1">
                <i class="fa fa-arrow-left mr-3"></i>Select Tickets
            </button>
        </div>
    </div>
</div>

<div class="text-center modal-close-button">
    <button type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="activeModal.dismiss(false)">Cancel</button>
</div>
