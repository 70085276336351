<div class="home-container">
    <div class="container-fluid pb-3">
        <div class="text-center">
            <div class="container px-0 px-sm-3" style="z-index:4;">
                <div class="card col-md-6 mt-3 mx-auto">
                    <div class="card-body">
                        <h4 class="card-title text-center m-0" style="border:none;">
                            <div class="row">
                                <div class="col-10 col-offset-1 col-sm-8 col-sm-offset-2 text-center mr-auto ml-auto mt-5">
                                    <img alt="Ticket Spicket" routerLink="/home" style="cursor:pointer;" src="https://s3.amazonaws.com/com.ticketspicket.app/logos/ticket_spicket_hometown_logo_black_and_white_v1.svg" class="img-fluid flex-center mb-3">
                                    <a routerLink="/home" style="cursor:pointer;" class="font-sm d-block text-center mt-2 mb-3"><i class="fa fa-arrow-left mr-2"></i>Back to Search</a>
                                </div>
                            </div>
                        </h4>
                        <div class="alert alert-danger error-message" [hidden]="!error || isLoading" >
                            {{error}}
                        </div>
                        <div class="progress mb-3" style="height: 0.5rem; border-radius: 0">
                            <div class="progress-bar bg-agency-primary" role="progressbar" [ngStyle]="{ width: getWidth() }" aria-label="Progress Bar"></div>
                        </div>
                        <mat-tab-group mat-stretch-tabs [selectedIndex]="selected">
                            <mat-tab>
                                <app-auth-email
                                    (updateSelected)="updateSelected($event)"
                                    (generateVerificationCode)="generateVerificationCode($event)"
                                    [isLoading]="isLoading"
                                >
                                </app-auth-email>
                            </mat-tab>
                            <mat-tab>
                                <app-auth-verification-code
                                    (updateSelected)="updateSelected($event)"
                                    (sendVerificationCode)="sendVerificationCode($event)"
                                    (resendCode)="resendCode()"
                                    [email]="forgotPasswordContext.email"
                                    [isLoading]="isLoading"
                                >
                                </app-auth-verification-code>
                            </mat-tab>
                            <mat-tab>
                                <app-auth-password
                                    (updateSelected)="updateSelected($event)"
                                    (updatePassword)="updatePassword($event)"
                                    [isLoading]="isLoading"
                                >
                                </app-auth-password>
                            </mat-tab>
                            <mat-tab>
                                <div class="alert alert-success text-center my-2">
                                    Your password has been reset successfully. Please login with your new password.
                                </div>
                                <button type="button" mdbRippleRadius class="btn btn-outline-primary w-100 mx-0 my-4" [routerLink]="['/auth', 'login']">
                                    <h6 class="m-0">Login</h6>
                                </button>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
