import { Component, Inject, OnInit } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { MessageService } from "../../message.service";
import { TicketService } from "@app/data/services/ticket.service";
import { TicketModalComponent } from "@app/tickets/ticket/ticket-modal.component";
import { TicketTransferModalComponent } from "@app/tickets/transfers/transfer-modal.component";
import { EventAdmission } from "@app/data/models/tickets/event-admission.model";
import * as _ from 'lodash';
import { AdmissionActions } from "@app/data/models/tickets/admission.interface";
import { ConsumerPassClaimModalComponent } from "@app/passes/claim/pass-claim-modal.component";
import { TicketCancelTransferModalComponent } from '@app/tickets/transfers/cancel-transfer-modal.component';
import { ConsumerPassPunchModalComponent } from "@app/passes/punch/pass-punch-modal.component";

@Component({
    selector: 'ts-ticket-bottom-sheet',
    templateUrl: './ticket-bottom-sheet.component.html'
})
export class TicketBottomSheet implements OnInit {

    private _modalOptions: any = {
        centered: true,
        backdrop: 'static', 
        keyboard: false
    }

    public actions: any = AdmissionActions;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<TicketBottomSheet>,
        @Inject(Router) private _router: Router,
        @Inject(NgbModal) private _modalService: NgbModal,
        @Inject(MessageService) private _messageService: MessageService,
        @Inject(TicketService) private _ticketService: TicketService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: EventAdmission
    ) {
    }

    ngOnInit() {
    }

    public doAction (action: string) {
        
        this.bottomSheetRef.dismiss(action);

        switch (action) {
            case AdmissionActions.buy:
                this.buyTickets()
                break;
            case AdmissionActions.view:
                this.viewTickets()
                break;
            case AdmissionActions.transfer:
                this.initiateTransfer()
                break;
            case AdmissionActions.print:
                this.printTickets()
                break;
            case AdmissionActions.claim:
                this.claimTickets()
                break;
            case AdmissionActions.punch:
                this.usePunchPass()
                break;
            case AdmissionActions.claimAll:
                this.claimAllTickets()
                break;
            case AdmissionActions.accept:
                this.acceptTransfer()
                break;
            case AdmissionActions.decline:
                this.declineTransfer()
                break;
            case AdmissionActions.cancel:
                this.cancelTransfer()
                break;
            default: break;
        }
    }

    public buyTickets() {
        this._router.navigate(['agency', this.data.event.agency.uuid, 'events', this.data.event.uuid]);
    }

    public viewTickets() {
        const modalRef = this._modalService.open(TicketModalComponent, this._modalOptions);
        modalRef.componentInstance.eventTicket = this.data;
    }

    public initiateTransfer() {
        const modalRef = this._modalService.open(TicketTransferModalComponent, this._modalOptions);
        modalRef.componentInstance.admission = this.data;
    }

    public printTickets() {
        this._messageService.ConfirmYesNo("Would you prefer to have your tickets sent to you as a .pdf via email and made available for download?", "Email and Download Your Tickets?").then(() => {
            this._ticketService.emailTickets(this.data.event.uuid).subscribe(() => {
                this._messageService.toast.success("Your tickets are on the way!", "Tickets Sent!");
            })
        });
    }

    public claimAllTickets() {
        this._messageService.ConfirmYesNo("Are you sure you would like to claim all of your tickets to this event?", "Claim All Your Tickets?").then(() => {
            // this._ticketService.emailTickets(this.data.event.uuid).subscribe(() => {
                this._messageService.toast.success("Your tickets are on the way!", "Tickets Sent!");
            // })
        });
    }

    public claimTickets() {
        this.bottomSheetRef.dismiss('claim');
        const modalRef = this._modalService.open(ConsumerPassClaimModalComponent, this._modalOptions);
        modalRef.componentInstance.admission = this.data;
    }

    public usePunchPass() {
        this.bottomSheetRef.dismiss('punch');
        const modalRef = this._modalService.open(ConsumerPassPunchModalComponent, this._modalOptions);
        modalRef.componentInstance.admission = this.data;
    }

    public acceptTransfer() {
        this.bottomSheetRef.dismiss('accept');
    }

    public declineTransfer() {
        this.bottomSheetRef.dismiss('decline');
    }

    public cancelTransfer() {
        const modalRef = this._modalService.open(TicketCancelTransferModalComponent, this._modalOptions);
        modalRef.componentInstance.admission = this.data;
    }

}
