<div class="calendar-header text-center mb-3">
    <div class="btn-group d-flex" role="group" aria-label="Date Range Picker">
        <div class="d-flex">
            <button class="btn btn-light p-2 m-0 text-center text-uppercase" type="button" (click)="addDay(-7)">
                <i class="fa fa-chevron-left"></i>
                <div style="font-weight:600;" class="font-sm d-none d-sm-block mt-2">Prev Week</div>
            </button>
        </div>
        <div class="flex-grow-1">
            <button type="button" (click)="picker.open()" class="btn btn-light p-2 text-center text-uppercase w-100">
                <div class="description">Showing Events For:</div>
                <h5 class="mb-0 pb-0 pt-1">{{ filter.startDate | date: 'MMM dd'}} - {{ filter.endDate | date: 'dd, yyyy'}}</h5>
            </button>
        </div>
        <div class="d-flex">
            <button class="btn btn-light p-2 m-0 text-center text-uppercase" type="button" (click)="addDay(7)">
                <i class="fa fa-chevron-right"></i><div style="font-weight:600;" class="font-sm d-none d-sm-block mt-2">Next Week</div>
            </button>
        </div>
    </div>
    <input [matDatepicker]="picker" type="hidden" (dateChange)="onDateChange($event)">
    <mat-datepicker touchUi #picker [dateClass]="applySpecialDateCSS" [startAt]="filter.startDate"></mat-datepicker>
    <div class="px-3 py-1 text-center bg-light text-muted border-top border-bottom mb-3 text-uppercase" style="font-size:0.6rem;">
        Tap above to change the date range for your tickets.
    </div>
</div>
<div class="px-3">
    <ts-section-header>
        Tickets
        <div class="actions">
            <div class="float-right">
                <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm" [ngClass]="{'active': filter.hasActivityFilter() || filter.hasAgencyFilter()}" (click)="showFilter()">
                    Filter Tickets
                    <i class="fa fa-filter"></i>
                    <i class="fa fa-xs pt-1 fa-check" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasActivityFilter() || filter.hasAgencyFilter()"></i>
                </button>
            </div>
        </div>
    </ts-section-header>
    <ts-ticket-legend *ngIf="(filteredEvents$ | async).length > 0"></ts-ticket-legend>
    <ts-loading message="Loading Tickets" [show]="isLoading"></ts-loading>
    <div *ngIf="!isLoading">
        <ts-no-results *ngIf="(filteredEvents$ | async).length == 0 && !filter.hasFilter()" [message]="noResultsMessage"></ts-no-results>
        <ts-ticket-list-item [ticket]="ticket" *ngFor="let ticket of filteredEvents$ | async"></ts-ticket-list-item>
        <div class="text-center p-3" style="color:lightslategrey;" *ngIf="filter.hasFilter() && (filteredEvents$ | async).length == 0">
            <h4 class="h4-responsive">There are no results based on the applied filter</h4>
            <div class="mt-1">
                <button mdbRippleRadius type="button" class="btn btn-secondary mr-2 active" (click)="filter.clearFilters()">
                    <span class="d-none d-sm-inline">Remove Filter</span>
                    <i class="fa fa-filter ml-1"></i>
                    <i class="fa fa-xs pt-1 fa-times" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasFilter()"></i>
                </button>
            </div>
        </div>
    </div>
</div>
