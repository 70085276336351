<div class="background-color">
    <div class="container bg-white pb-4 pt-3">
        <ts-section-header class="h5-responsive">My Teams</ts-section-header>
        <div *ngIf="following.length === 0" class="text-center">
            <ts-no-results message="teams you are following"></ts-no-results>
            <button class="btn btn-secondary" routerLink="/home">Find A Team</button>
        </div>
        <div class="row pt-3" *ngIf="following.length > 0">
            <div class="col-12 col-sm-12 col-lg-4 mb-4" *ngFor="let agency of following" [routerLink]="['/agency', agency.uuid]">
                <ts-agency-card [agency]="agency"></ts-agency-card>
            </div>
        </div>
    </div>
</div>