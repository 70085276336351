import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { NgbPaginationModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketSpicketModule } from '@app/shared/ticket-spicket/ticket-spicket.module';
import { TicketSpicketMaterialModule } from '@app/shared/material.module';


@NgModule({
  imports: [
    CommonModule,
    NgbPaginationModule,
    NgbCollapseModule,
  
    TicketSpicketModule,
    TicketSpicketMaterialModule    
  ],
  declarations: [
    LoaderComponent
  ],
  exports: [
    TicketSpicketModule,
    TicketSpicketMaterialModule,    
    NgbPaginationModule,
    NgbCollapseModule,
    LoaderComponent
  ]
})
export class SharedModule { }
