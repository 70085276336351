<div class="mb-5">
    <ts-section-header>Transfer Activity History</ts-section-header>
    <ts-no-results message="historical transfers" *ngIf="transferService.history.length == 0"></ts-no-results>
    <div *ngIf="transferService.history.length > 0">
        <div class="row mb-5">
            <div class="col-sm-12 col-md-4" *ngFor="let transfer of transferService.history.slice(pagination.startItem(), pagination.endItem())">
                <mat-card class="my-2 p-1">
                    <mat-card-header>
                        <mat-card-title class="m-1"><i class="fa fa-fw fa-lg mr-2" [ngClass]="{'fa-inbox': transfer.isReceiver, 'fa-send': transfer.isSender}"></i>{{transfer.dateStatus | date: 'MM/dd/yyyy hh:mm a'}}</mat-card-title>
                        <mat-card-subtitle class="m-1">{{transfer.getNarrative()}}</mat-card-subtitle>
                    </mat-card-header>
                </mat-card>
            </div>
        </div>
        <ts-pagination [collectionSize]="transferService.history.length" [pagination]="pagination"></ts-pagination>
    </div>
</div>