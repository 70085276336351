export interface ITypeProduct {
    id: number;
    value: string;
}

export class ITypeActivity {
    id: number;
    iconClassName: string;
    value: string;
}

export class ITypeGender {
    id: number;
    value: string;
}

export class ITypeSeason {

    id: number;
    value: string;
    iconClassName: string;
    rank: number;
}

export class ITypeEvent {

    id?: number;
    code: string;
    name?: string;
    iconClassName?: string;
}