<form (ngSubmit)="postPassword()" [formGroup]="passwordForm" novalidate autocomplete="on">
    <div class="text-center font-weight-bold h5 text-uppercase">Set Your Password</div>
    <div class="form-group">
        <label class="d-block my-3">
            <input type="password" class="form-control" formControlName="password" autocomplete="new-password" [placeholder]="'Password'"/>                                    
            <span hidden >Password</span>
            <div [hidden]="passwordForm.get('password').untouched" *ngIf="passwordForm.get('password').invalid" class="text-danger" style="font-size: .8em">
                <div *ngIf="passwordForm.get('password').errors?.required">Password is required</div>
                <div *ngIf="passwordForm.get('password').errors?.strong">Password is not strong enough</div>
            </div>
        </label>
        <div class="py-1 font-sm text-left">
            <p class="m-0 p-0 pb-1 text-center"><strong>In order to maintain a secure account, your password must contain the following:</strong></p>
            <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.get('password').errors?.['strong']?.length ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least 6 characters</div>
            <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.get('password').errors?.['strong']?.alpha ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one letter</div>                                    
            <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.get('password').errors?.['strong']?.number ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one number</div>
        </div>
        <label class="d-block my-3">
            <input type="password" class="form-control" formControlName="passwordConfirm" autocomplete="off" [placeholder]="'Confirm Password'"/>
            <span hidden >Confirm Password</span>
            <small [hidden]="passwordForm.get('passwordConfirm').valid || passwordForm.get('passwordConfirm').untouched" class="text-danger" >
                Confirm Password is required
            </small>
            <div [hidden]="passwordForm.controls.passwordConfirm.untouched" *ngIf="passwordForm.get('passwordConfirm').errors" class="text-danger" style="font-size: .8em">
                Confirm Password does not match Password
            </div>
        </label>
    </div>
    <button mdbRippleRadius class="btn btn-primary w-100 m-0 mb-4" type="submit" [disabled]="passwordForm.invalid || isLoading">
        <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
        <h6 class="m-0" [hidden]="isLoading">Save Password</h6>
    </button>
</form>