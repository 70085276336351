import { Component, Input } from '@angular/core';

@Component({
    selector: 'ts-svg',
    templateUrl: './svg.component.html'
})
export class SvgComponent {

    constructor() { }

}
