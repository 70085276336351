<div class="product card mb-3">
    <div class="card-body px-2 pt-2 pb-3">
        <div [routerLink]="[agencyRoute, summary.product.agency.uuid, 'events', summary.product.uuid]" style="cursor:pointer; border: 1px solid lightgray">
            <ts-event-header-list-item [event]="summary.product"></ts-event-header-list-item>
        </div>
        <ts-cart-ticket-type [summary]="summary" (update)="updateItem($event)"></ts-cart-ticket-type>
        <ts-cart-ticket-seats [seats]="summary.seats" (remove)="removeSeat($event)"></ts-cart-ticket-seats>
        <div class="mt-3 text-right">
            <div>
                Convenience Fee: <strong>{{summary.items.getTotalFees() | currency}}</strong>
            </div>
            <div *ngIf="summary.hasPromotion()">
                Promotion: <strong style="color:red;">{{summary.items.getTotalDiscount() | currency}}</strong>
                <div class="font-sm text-muted">{{summary.promotion.name}}</div>
            </div>
            <div>
                Subtotal: <strong>{{summary.items.getTotalPrice() | currency}}</strong>
            </div>
        </div>
    </div>
</div>