import { Component, Input } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { GatePassTypes } from '@app/data/models/passes/gate-pass-type.model';
@Component({
    selector: 'ts-pass-header-list-item',
    templateUrl: './pass-header-list-item.component.html',
    styleUrls: ['./pass-header-list-item.component.scss']
})

export class PassHeaderListItemComponent {
    @Input('pass') pass: GatePass;
    @Input('showIcon') showIcon: boolean;
    @Input('showPrices') showPrices: boolean;

    constructor() {}
 
    public getIcon(): string {

        let icon: string = "allAccess";

        switch (this.pass.gatePassType.value) {
            case GatePassTypes.access: {
                "allAccess";
                break;
            } 
            case "Season": {
                this.pass.activity.typeSeason.iconClassName;
                break;
            } 
            case "Activity": {
                this.pass.activity.typeActivity.iconClassName;
                break;
            } 
            default:
                "allAccess";
        }

        return icon;
    }

}
  