import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';
import { PassService } from '@app/data/services/pass.service';
import { Event } from '@app/data/models/event.model';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketModalComponent } from "@app/tickets/ticket/ticket-modal.component";
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { switchMap, map, tap } from 'rxjs/operators';
import { Observable, of, from, forkJoin } from 'rxjs';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { AdmissionSource, IAdmission } from '@app/data/models/tickets/admission.interface';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-consumer-pass-claim-modal',
  templateUrl: './pass-claim-modal.component.html',
  styleUrls: ['../pass.component.scss']
})
export class ConsumerPassClaimModalComponent implements OnInit {

    @Input() admission: EventAdmission;

    public selectedConsumers: ConsumerGatePass[] = new Array<ConsumerGatePass>();
    public passes: ConsumerPass[] = new Array<ConsumerPass>();
    public isCollapsed: boolean = false;

    public isLoading: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        private _userAdmissionService: UserAdmissionService,
        private _passService: PassService,
        private _messageService: MessageService,
        private _spinner: NgxSpinnerService,
        private _modalService: NgbModal
    ) { }

    ngOnInit() {
        this.passes = this.admission.getPasses().filter((pass) => !pass.gatePass.isPunchPass);

        // loop through the passes and get the event availability
        this.passes.map((pass) => {
            this.getPassEventAvailability(pass, this.admission.event).subscribe()
        })

    }

    public getPassEventAvailability(pass: ConsumerPass, event: Event): Observable<ConsumerPass> {
        return this._passService.getPassEventAvailability(pass.gatePass.id, event.uuid).pipe(
            map((consumers) => {
                pass.consumers = consumers;
                return pass
            })
        )
    }

    /**
     * Selects all of the available consumers and attempts to claim the tickets
     *
     */
    public selectAll() {
        this.passes.map((pass) => {
            pass.getUnclaimedTickets().map((cgp) => {

                // first, check to see if the consumers are in the selected collection
                if (this._findConsumer(cgp.uuid) < 0) {
                    // Consumer was not already in the collection, so add it
                    this.passes.map((pass) => {
                        // find the consumer pass that matches the selected uuid
                        var consumer = pass.consumers.find((consumer) => consumer.uuid === cgp.uuid);
                        // if it's found, add it to the selected consumer collection
                        if (consumer) {
                            this.selectedConsumers.push(consumer);
                        }
                    })
                }
            })
        });
        this.claimTickets();
    }

    /**
     * Takes care of actually claiming the tickets
     *
     */
    public claimTickets() {

        let claims: Observable<any>[] = new Array<Observable<any>>();

        if (this.selectedConsumers.length === 0) {
            this._spinner.hide('modal-spinner');
            return;
        }

        this._messageService.ConfirmYesNo("Are you sure you would like to claim the selected tickets for this event?", "Claim Tickets").then(() => {
            this._spinner.show('modal-spinner');
            this.isLoading = true;

            // loop through the passes and buld the claim observables
            this.passes.map((pass) => {
                let customers: ConsumerGatePass[] = this.selectedConsumers.filter((cgp) => cgp.gatePass.id === pass.gatePass.id);

                if (customers.length > 0) {
                    claims.push(this._passService.claimTickets(this.admission.event.uuid, pass.gatePass.id, customers));
                }
            });

            // after figuring out which calls to make, use forkJoin to make them all

            forkJoin(claims).pipe(
                switchMap(() => this._userAdmissionService.getEventTickets(this.admission.event.uuid)),
                tap((tickets) => {
                    let remaining: IAdmission[] = this.admission.admission.filter((admission) => admission.source !== AdmissionSource.ticket);
                    tickets.map((admission) => remaining.push(admission));
                    this.admission.admission = remaining;
                })
            ).subscribe((result) => {
                this._messageService.toast.success('Navigate to <a href="tickets">"My Tickets"</a> to view your claimed tickets', 'Success', { timeOut: 5000, extendedTimeOut: 2500, enableHtml: true });
                this.handlePostClaimActions();
            }, (error) => {
                this._spinner.hide('modal-spinner');
                this.isLoading = false;
                this._messageService.toast.error(error.message || 'Error: Please try again.');
            }, () => {
            });

            this.selectedConsumers = new Array<ConsumerGatePass>();

        })
    }

    public viewTickets() {
        this.activeModal.close(this.admission.event);
        const modalRef = this._modalService.open(TicketModalComponent, { centered: true });
        modalRef.componentInstance.eventTicket = this.admission;
    }

    public close() {
        this.activeModal.dismiss(false);
    }

    private _findConsumer(uuid: string): number {
        return this.selectedConsumers.findIndex((consumer) => consumer.uuid === uuid);
    }

    public toggleConsumer(uuid: string) {

        // first, check to see if the consumers are in the selected collection
        var index = this._findConsumer(uuid);

        if (index >= 0) {
            // if the consumer was found, remove it
            this.selectedConsumers.splice(index, 1);
        } else {
            // Consumer was not already in the collection, so add it
            this.passes.map((pass) => {

                // find the consumer pass that matches the selected uuid
                var consumer = pass.consumers.find((consumer) => consumer.uuid === uuid);

                // if it's found, add it to the selected consumer collection
                if (consumer) {
                    this.selectedConsumers.push(consumer);
                }
            })
        }
    }

    private handlePostClaimActions() {
        this._spinner.hide('modal-spinner');
        this.isLoading = false;
        if (this.admission.event.areGatesOpen()) {
            this.viewTickets();
        } else {
            this._modalService.dismissAll();
        }
    }

    isConsumerSelected(uuid: string): boolean {
        return this._findConsumer(uuid) >= 0;
    }

}
