<div style="overflow:hidden;">
    <div class="container bg-white pb-4" style="position:relative;z-index:100;">
        <div class="coverArt view football">
            <div class="colorOverlay"></div>
            <div class="details">
                <div class="row align-items-center">
                    <div class="col-12">
                        <svg class="icon">
                            <use attr.xlink:href="#football"></use>
                        </svg>
                        <h5 class="name">{{ pass.name }}</h5>
                        <p class="description">{{ pass.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-7">
                <ts-seat-map [config]="config" mode="normal" (seatObjectSelected)='selectSeat($event)' (seatObjectDeselected)='removeSeat($event)' (onRender)="setChart($event)" *ngIf="!isLoading"></ts-seat-map>
            </div>
            <div class="col-md-5">
                <ts-section-header>
                    <i class="fas fa-fw fa-ticket-alt mr-1"></i>Selected Seats ({{ getReservedSeats().length }})
                </ts-section-header>
                <div class="my-4 text-center selection-empty pb-4" *ngIf="getReservedSeats().length == 0">
                    No seats are currently selected.<br />
                    Select a seat from the map.
                </div>
                <div class="text-center list">
                    <div class="seat-selection" *ngFor="let item of getReservedSeats()">
                        <div class="seat-icon">
                            {{item.seat.section}}
                        </div>
                        <div class="details ">
                            <div class="float-right text-right" style="line-height:40px;">
                                <strong>{{item.seat.price | currency}}</strong>
                            </div>
                            <div class="float-left text-left font-sm">
                                Row {{item.seat.row}}<br />
                                Seat {{item.seat.number}}
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                        <div class="remove">
                            <button type="button" class="btn btn-danger rounded-circle btn-sm" (click)="removeSeat(item.seat)">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
                <div class="p-2 checkout-button">
                    <button mdbRippleRadius type="button" class="btn btn-secondary w-100" [routerLink]="cart.cartRoute" [disabled]="getReservedSeats().length == 0">
                        <i class="fa fa-credit-card mr-2"></i>Checkout
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
