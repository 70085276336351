    <ts-ticket-header [agency]="eventTicket.event.agency" class="fallback-gradient"></ts-ticket-header>
    <h5 class="border-0 text-center mt-4 pt-3 pb-0 mb-0">
        {{ eventTicket.event.agency.name }}
    </h5>
    <ts-ticket-event-header [event]="eventTicket.event"></ts-ticket-event-header>
    <div *ngIf="step === 1">
        <div class="p-3 text-left" style="overflow:hidden;">
            <div *ngIf="eventTicket.getUnusedTickets().length > 0">
                <h5 class="border-top border-bottom text-center py-2 text-uppercase mx-4">
                    Are You Ready to Check In?
                </h5>
                <div class="alert alert-warning font-sm text-center mb-4">
                    <strong>Please note:</strong> If you are checking in more than one ticket, your entire party must be present at the time of check in at the gate.
                </div>
                <!-- <div class="text-center mt-4 mb-2 text-uppercase">
                    <strong>Everyone in your party is ready.</strong>
                </div> -->
                <div class="check-in-all text-center my-3">
                    <button type="button" class="btn btn-success btn-block" (click)="selectAll()"><i class="fa fa-check-circle mr-2"></i>USE ALL TICKETS</button>
                </div>
                <div class="text-center row my-2 font-sm align-items-center">
                    <div class="col-5"><hr /></div>
                    <div class="col-2">OR</div>
                    <div class="col-5"><hr /></div>
                </div>
                <div class="text-center mb-2 text-uppercase">
                    <strong>Select individual tickets.</strong>
                </div>
                <mat-list style="background:transparent;">
                    <mat-list-item class="border rounded mb-2" [ngClass]="{'border-success': isTicketSelected(ticket.uuid)}" style="background:white;height:47px;cursor:pointer;" *ngFor="let ticket of eventTicket.getUnusedTickets()" (click)="toggleTicket(ticket.uuid)">
                        <i mat-list-icon class="mt-1 mr-3 fa fa-lg fa-fw" [ngClass]="{'fa-check-square text-success': isTicketSelected(ticket.uuid), 'fa-square-o': !isTicketSelected(ticket.uuid) } "></i>
                        <p class="h5" mat-line *ngIf="!ticket.seatNumber"><strong>{{ ticket.level.value }}</strong> <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                        <p class="h5" mat-line *ngIf="ticket.seatNumber"><strong>Section:</strong> {{ ticket.seat.section }} <strong>Seat:</strong> {{ ticket.seat.row }}-{{ ticket.seat.number }} - <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                    </mat-list-item>
                </mat-list>
                <div class="check-in-all text-center mb-2 mt-3">
                    <button type="button" class="btn btn-secondary btn-block" (click)="updateStep(2)" [disabled]="selectedTickets.length==0">
                        <i class="fa fa-check mr-2"></i>USE SELECTED TICKETS<!-- <span class="ml-3" *ngIf="selectedTickets.length > 0">({{selectedTickets.length}})</span>-->
                    </button>
                </div>
            </div>
            <div *ngIf="eventTicket.getUsedTickets().length > 0" class="pt-4">
                <h5 class="border-bottom text-center pb-0 text-uppercase mx-4">
                    Redeemed Tickets
                </h5>
                <mat-list style="background:transparent;">
                    <mat-list-item *ngFor="let ticket of eventTicket.getUsedTickets()" style="background:white;height:62px;" class="border-bottom mb-2">
                        <i mat-list-icon class="fas fa-lg fa-ticket-alt text-success"></i>
                        <p class="h5" mat-line *ngIf="!ticket.seatNumber"><strong>{{ ticket.level.value }}</strong> <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                        <p class="h5" mat-line *ngIf="ticket.seatNumber"><strong>Section:</strong> {{ ticket.seat.section }} <strong>Seat:</strong> {{ ticket.seat.row }}-{{ ticket.seat.number }} - <span class="font-sm text-muted">(#{{ticket.ticketNumber}})</span></p>
                        <p mat-line><span class="font-sm">{{ticket.dateRedeemed | date: 'MM/dd/yyyy hh:mm:ss a'}}</span></p>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
    <div *ngIf="step === 2" class="mx-auto text-center my-0">
        <!-- <button class="btn btn-sm btn-light mt-3" (click)="updateStep(1)"><i class="fa fa-arrow-left mr-2"></i>Back</button> -->
        <ts-ticket [ticket]="bundle" (checkedIn)="onRedeemed($event)" [service]="service"></ts-ticket>
    </div>
