import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';
import { Ticket } from "@app/data/models/ticket.model";
import { ConsumerPass } from "@app/data/models/passes/consumer-pass.model";
import { ConsumerGatePass } from "@app/data/models/passes/consumer-gate-pass.model";

export class Payment implements IDeserializable {

    public id: number;
    public uuid: string;
    public referenceIdentifier: string;
    public paymentDate: Date;
    public paymentAmount: number;
    public feeAmount: number;
    public isSuccessful: boolean;
    public message: string;

    public tickets: Ticket[];
    public passes: ConsumerPass[];

    public version: string;

    constructor() {}

    deserialize(input: any) {

        Object.assign(this, input);

        this.paymentDate = moment(input.paymentDate).toDate();

        if (input.tickets) {
            this.tickets = input.tickets.map((ticket: any) => new Ticket().deserialize(ticket));
        }

        if (input.passes) {
            this.passes = ConsumerPass.groupConsumerPasses(input.passes.map((pass: any) => new ConsumerGatePass().deserialize(pass)));
        }

        return this;
    }

}
