import { Input, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'ts-mat-confirm-dialog',
    templateUrl: './mat-confirm.component.html'
})
export class TicketSpicketMatConfirmation {

    public title: string = 'Are you sure?';
    public content: string = 'Are you sure?';
    public confirmText: string = 'Yes';
    public cancelText: string = 'No';

    constructor (
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title || 'Are you sure?';
        this.content = data.content || 'Are you sure?';
        this.confirmText = data.confirmText || 'Yes';
        this.cancelText = data.cancelText || 'No';
    }
 
}