import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '@app/data/services/cart.service';
import { UserService } from '@app/data/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutOptionComponent } from './checkout-option/checkout-option.component';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    public step: number = 1;

    private currentUrl = '';
    private summary = '/cart';
    private checkout = '/cart/checkout';
    private confirmation = '/cart/confirmation';
    constructor(
        public _cart: CartService,
        public router: Router,
        public _modal: NgbModal,
        private _userService: UserService
    ) {
        this.router.events.subscribe((route: any) => {
            this.currentUrl = route.url;
            if (this.currentUrl === this.summary) {
                this._cart.setIsGuestCheckout(false);
                this.step = 1;
            } else if (this.currentUrl === this.checkout) {
                this.step = 2;
            } else if (this.currentUrl === this.confirmation) {
                this.step = 3;
            }
        });
    }

    public isCheckoutDisabled(): boolean {
        return this._cart.isEmpty() || this.step === 3;
    }

    public isSummaryDisabled(): boolean {
        return this._cart.isEmpty() || this.step === 3;
    }

    ngOnInit() {
    }

    public checkoutOption() {
        if (this._userService.user.email) {
            this.router.navigateByUrl('/cart/checkout');
        } else {
            const modalRef = this._modal.open(CheckoutOptionComponent, { centered: true, size: 'lg' });
        }
    }
}
