import { Component, OnInit, Input, ɵSafeHtml } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Event } from '@app/data/models/event.model';
import { Agency } from '@app/data/models/agency.model';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-event-registration-modal',
    templateUrl: './show-registration.component.html',
    styleUrls: ['./show-registration.component.scss']
})
export class EventRegistrationModalComponent implements OnInit {

    @Input() event: Event;

    public agency: Agency;

    public home: Agency;
    public visitor: Agency;

    public loading: boolean = false;
    public prefill: string = "";
    
    data: ɵSafeHtml;

    constructor(
        public activeModal: NgbActiveModal,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {

        this.agency = this.event.agency;
        this.home = this.event.getHomeAgency();
        this.visitor = this.event.getVisitorAgency();
        this.prefill = `eventkey=${this.event.uuid}`;
        var formHTML = `<div prefill-inherit="1" prefill="${this.prefill}" data-paperform-id="${this.event.formURI}"></div>`;
        this.data = this.sanitizer.bypassSecurityTrustHtml(formHTML);

    }

    public close() {
        this.activeModal.dismiss(false);
    }

}
