import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'ts-pass-list-item',
  templateUrl: './pass-list-item.component.html',
  styleUrls: ['./pass-list-item.component.scss']
})

export class PassListItemComponent {
    @Input('pass') pass: GatePass;
    @Input('schoolView') schoolView: boolean;
    @Input('isLinked') isLinked: boolean = false;
    @Input('configMode') configMode: boolean;

    constructor() {}

}
