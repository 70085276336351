import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/data/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class UserInviteComponent implements OnInit {
    
    inviteForm: FormGroup;
    isLoading = false;

    constructor (
        private formBuilder: FormBuilder,
        private _userService: UserService,
        private toastr: ToastrService
    ) {
        this.createForm();
        this.inviteForm.setValue({ email: null });
    }

    ngOnInit() { }

    sendInvitation () {
        this.isLoading = true;
        this._userService
            .sendInvitation(this.inviteForm.value)
            .pipe(
                finalize(() => {
                    this.inviteForm.markAsPristine();
                    this.isLoading = false;
                })
        )
        .subscribe(
            (message) => {
                this.toastr.success(message.message);
            },
            (error) => {
                console.log(error);
                this.toastr.error(error.error.message);
            }
        );
    }

    private createForm() {
        this.inviteForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }

}
