<div class="home-container">
    <div class="container-fluid pb-3">
        <div class="text-center">
            <div class="container px-0 px-sm-3" style="z-index:4;">
                <div class="card col-md-6 mt-3 mx-auto">
                    <div class="card-body">
                        <h4 class="card-title text-center m-0">
                            <div class="row">
                                <div class="col-10 col-offset-1 col-sm-8 col-sm-offset-2 text-center mr-auto ml-auto mt-5">
                                    <img alt="Ticket Spicket" routerLink="/home" style="cursor:pointer;" src="https://s3.amazonaws.com/com.ticketspicket.app/logos/ticket_spicket_hometown_logo_black_v1.svg"class="img-fluid flex-center mb-3">
                                    <a routerLink="/home" style="cursor:pointer;" class="font-sm d-block text-center mt-2 mb-3"><i class="fa fa-arrow-left mr-2"></i>Back to Search</a>
                                </div>
                            </div>
                        </h4>
                        <form (ngSubmit)="resetPassword()" [formGroup]="resetForm" novalidate *ngIf="!message" autocomplete="on">
                            <div class="alert alert-danger" [hidden]="!error || isLoading">
                                {{error}}
                            </div>
                            <div class="form-group">
                                <label class="d-block">
                                    <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'"/>
                                    <span hidden>Email</span>
                                    <small [hidden]="resetForm.controls.email.valid || resetForm.controls.email.untouched" class="text-danger">
                                      {{resetForm.controls.email.errors?.required ? 'Email is required': 'Email is invalid'}}
                                    </small>
                                </label>
                            </div>
                            <button mdbRippleRadius class="btn btn-primary w-100 m-0" type="submit" [disabled]="resetForm.invalid || isLoading">
                                <h6 class="m-0" [hidden]="!isLoading">
                                    <i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>
                                    Resetting
                                </h6>
                                <h6 class="m-0" [hidden]="isLoading">Reset Password</h6>
                            </button>
                            <p class="text-center m-2 mt-3">
                                <a [routerLink]="['/auth', 'login']">Return to Login</a>
                            </p>
                        </form>
                        <div *ngIf="message">
                            <div class="alert alert-success" [hidden]="!message || isLoading">
                                {{message}}
                            </div>
                            <button mdbRippleRadius class="btn btn-primary w-100 m-0">
                                <h6 class="m-0" [routerLink]="['/auth', 'login']">Return to Login</h6>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
