import { Component, OnInit } from '@angular/core';
import { TransferService } from '@app/data/services/transfer.service';
import { Transfer } from '@app/data/models/transfer.model';
import { Pagination } from '@app/data/models/pagination.model';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
    selector: 'app-user-transfers',
    templateUrl: './transfers.component.html',
    styleUrls: ['./transfers.component.scss']
})
export class UserTransferHistoryComponent implements OnInit {

    public history: Transfer[] = new Array<Transfer>();
    public pagination: Pagination = new Pagination(6);
    public noResultsMessage = 'transfers at this time';

    constructor(
        public transferService: TransferService,
        private _spinner: SpinnerService
    ) {
        this._spinner.setMessage("Loading Transfer History")
    }

    ngOnInit () {
        this.transferService.getTransferHistory().subscribe (
            () => this._spinner.hide(),
            () => this._spinner.hide()
        );
    }

}
