
export class LinkedAccount {

    id: number;
    name: string;
    email: string;
    role: string;
    uuid: string;
    dateRequested: Date;
    dateAccepted: Date;
    memberType: string;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}
