<div class="modal-body p-0">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="letter-holder">
            <div class="letter">
                <span *ngIf="consumer.holderName">{{ consumer.holderName | limitTo: 1 }}</span>
                <span *ngIf="!consumer.holderName">U</span>
            </div>
        </div>
        <div class="p-3">
            <h5 class="text-center text-uppercase font-weight-bold border-bottom">
                PASS TYPE:<br />{{ consumer.level.value }}
            </h5>
            <div class="name text-center">
                <div class="font-sm text-muted">
                    Name:
                </div>
                <div>
                    <span *ngIf="consumer.holderName">{{ consumer.holderName }}</span>
                    <i *ngIf="!consumer.holderName">Unassigned</i>
                </div>
            </div>
            <hr />
            <div class="row stats">
                <div class="col-8 text-uppercase">Events Available</div>
                <div class="col-4 text-success text-right">100</div>
            </div>
            <div class="row stats">
                <div class="col-8 text-uppercase">Tickets Remaining</div>
                <div class="col-4 text-success text-right">100</div>
            </div>
            <div class="row stats">
                <div class="col-8 text-uppercase">Tickets Used</div>
                <div class="col-4 text-success text-right">100</div>
            </div>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
  <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Done</button>
</div>

  