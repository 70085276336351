import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, AuthenticationService, SentryTransaction, replacer } from '@app/core';

import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { CartService } from '@app/data/services/cart.service';

const log = new Logger('Login');
const rememberMeStorageKey = 'com.ticketspicket.events.login-remember-me';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    version: string = environment.version;
    error: string;
    loginForm: FormGroup;
    isLoading = false;
    returnParam: string = '';

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private _spinner: SpinnerService,
        private cart: CartService
    ) {
        this.createForm();
        const email = localStorage.getItem(rememberMeStorageKey);
        this.loginForm.setValue({ email, password: null, remember: true });
        this.loginForm.markAsPristine();
        this._spinner.setMessage('Logging In');
    }

    ngOnInit() {
        // get the return url from the query parameters
        this.route.queryParams.subscribe((params) => this.returnParam = params['return'] || '/home');
    }

    login() {
        this._spinner.show();
        this.isLoading = true;
        this.authenticationService.login(this.loginForm.value)
            .pipe(
                finalize(() => {
                    this.loginForm.markAsPristine();
                    this.isLoading = false;
                    this._spinner.hide();
                })
            )
            .subscribe(() => {
                if (this.loginForm.value.remember) {
                    localStorage.setItem(rememberMeStorageKey, this.loginForm.value.email);
                } else {
                    localStorage.removeItem(rememberMeStorageKey);
                }
                if (this.cart.countdown) {
                  const info = JSON.stringify(this.cart.getItemsLog(), replacer);
                  SentryTransaction('Chectout', 'Authenticated - login - redirecting to checkout', { cart: info });
                  this.router.navigateByUrl('/cart/checkout');
                } else {
                  this.router.navigateByUrl(this.returnParam);
                }
            }, (error) => {
                log.debug(error);
                this.error = error.error?.response?.message || [error.error?.message];
            });
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }
}
