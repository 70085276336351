import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { InboxService } from '@app/data/services/inbox.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    public tooltipPosition: TooltipPosition = 'below';

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private _inboxService: InboxService,
        public _spinner: SpinnerService
    ) {
      this._spinner.setMessage('Logging Out');
     }

    ngOnInit() { }

    get unreadCount(): number {
        return this._inboxService.getUnreadCount();
    }

    logout() {
        this._spinner.show();
        this.authenticationService.logout()
            .subscribe(() => this.router.navigate(['/home'], { replaceUrl: true }));
            this._spinner.hide();
    }

}
