
import { Location } from "./location.model";
import { ITypeActivity, ITypeSeason, ITypeGender } from "./domain.interface";
import { IDeserializable } from "@app/data/models/deserializable.interface";

export class Activity implements IDeserializable {

    id: number;
    displayName: string;
    typeActivity: ITypeActivity;
    typeSeason: ITypeSeason;
    typeGender: ITypeGender;
    location?: Location;

    constructor() {}

    deserialize(input: any) {
        if(input?.activity_type) {
            this.typeActivity = new ITypeActivity();
            this.typeActivity.iconClassName = input.activity_type;
        }
        Object.assign(this, input);
        if (input?.location) 
            this.location = new Location().deserialize(input.location);
        return this;
    }

}
