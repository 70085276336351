import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as _ from 'lodash';
import { ConsumerGatePass } from "../passes/consumer-gate-pass.model";
import { TicketLevel } from "../ticket-level.model";
import { TicketPrice } from "../ticket-price.model";

export class SeasonTicketExchange implements IDeserializable {

    public uuid: string;
    public seatKey: string;
    public level: TicketLevel;
    public paidAmount: number;
    public exchangeSeatKey: string;
    public exchangeSeatPrice: TicketPrice;

    constructor() {}

    deserialize(input: ConsumerGatePass) {

        this.uuid = input.uuid;
        this.seatKey = input.seatNumber;
        this.level = input.level;
        this.paidAmount = input.paidAmount;

        return this;

    }

    public isExchanged(): boolean {
        return !_.isEmpty(this.exchangeSeatKey);
    }

}
