import { Injectable } from '@angular/core';

@Injectable()
export class ConfigureDropinService {

    constructor() { }

    configureCardHolderName(dropinConfig: any): void {
        dropinConfig.card = {
                cardholderName: {
                required: true
            }
        }
    }

    configurePaypalCheckout(dropinConfig: any, chargeAmount: any, currency: any): void {
        dropinConfig.paypal = {
            flow: 'checkout',
            amount: chargeAmount,
            currency: currency
        }
    }

    configurePaypalVault(dropinConfig: any) {
        dropinConfig.paypal = {
            flow: 'vault'
        }
    }

    configureLocale(dropinConfig: any, locale: any) {
        dropinConfig.locale = locale;
    }

}
