import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { switchMap, finalize } from 'rxjs/operators';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import * as _ from 'lodash';
import { TransferService } from '@app/data/services/transfer.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { PassService } from '@app/data/services/pass.service';

@Component({
    selector: 'app-pass-transfer-modal',
    templateUrl: './pass-transfer.component.html',
    styleUrls: ['./pass-transfer.component.scss']
  })
  export class PassTransferModalComponent implements OnInit {

    @Input('consumer') consumer: ConsumerGatePass
    public transferForm: FormGroup = new FormGroup({
        email: new FormControl('', Validators.required)
    })

    constructor (
        public activeModal: NgbActiveModal,
        private _messageService: MessageService,
        private _transferService: TransferService,
        private _userAdmissionService: UserAdmissionService,
        private _passService: PassService
    ) {
        
    }

    ngOnInit() {
    }

    public close() {
        this.activeModal.dismiss(false);
    }

    public transfer() {

        var passIds: number[] = new Array<number>();
        passIds.push(this.consumer.id);

        this._transferService
            .initiateTransfer(passIds, this.transferForm.get("email").value, "pass")
            .pipe (
                switchMap(() => this._passService.getPasses()),
                finalize(() => {
                    this.transferForm.markAsPristine();
                })
        )
        .subscribe(
            (message) => {
                this._messageService.toast.success("The Pass Transfer has been started.");
                this._userAdmissionService.getTransfers().subscribe();
                this._userAdmissionService.loadClaimableEvents().subscribe();
                this.activeModal.close();
            },
            (error) => {
                console.log(error);
                this._messageService.toast.error(error.error);
            }
        );
    }

}  