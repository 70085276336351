import { Component, Input } from '@angular/core';

@Component({
  selector: 'ts-sponsor-banner',
  templateUrl: './sponsor-banner.component.html',
  styleUrls: ['./sponsor-banner.component.scss']
})
export class SponsorBannerAdComponent {
  constructor() {}
}
