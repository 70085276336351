
import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';

export class RenewalSeasonTicket implements IDeserializable {

    public id: number;
    public name: string;
    public accountUUID: string;
    public dateRenewalStart: Date;
    public dateRenewalEnd: Date;
    public dateExchangeStart: Date;
    public dateExchangeEnd: Date;
    public gatePassUUID: string;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.dateRenewalStart) {
            this.dateRenewalStart = moment(input.dateRenewalStart).toDate();
        }

        if (input.dateRenewalEnd) {
            this.dateRenewalEnd = moment(input.dateRenewalEnd).toDate();
        }

        if (input.dateExchangeStart) {
            this.dateExchangeStart = moment(input.dateExchangeStart).toDate();
        }

        if (input.dateExchangeEnd) {
            this.dateExchangeEnd = moment(input.dateExchangeEnd).toDate();
        }

        return this;
    
    }

    public currentPhase(): number {
        if (this.dateRenewalStart && this.dateRenewalEnd) {
            if (moment().isBetween(moment(this.dateRenewalStart), moment(this.dateRenewalEnd))) {
                return 1;
            }
        }
        if (this.dateExchangeStart && this.dateExchangeEnd) {
            if (moment().isBetween(moment(this.dateExchangeStart), moment(this.dateExchangeEnd))) {
                return 2;
            }
        }
        return 0;
    }

}
