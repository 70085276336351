import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Agency } from '@app/data/models/agency.model';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { User } from '@app/data/models/user.model';
import { Reward } from '@app/data/models/reward.model';
import { Payment } from '@app/data/models/payment.model';
import { InboxService } from '@app/data/services/inbox.service';
import { LinkedAccount } from '@app/data/models/linked-account.model';
import * as _ from 'lodash';
import { UserAdmissionService } from './user-admission.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import * as Sentry from '@sentry/angular';
import { environment } from '@env/environment';

export interface SimpleUser {
    dateRegistered: Date;
    email: string;
    id: number;
    name: string;
    nameFirst: string;
    nameLast: string;
    uuid: string;
    verifyUUID: string;
}

@Injectable()
export class UserService {

    public user: User;
    public payments: Payment[] = new Array<Payment>();
    public baseUrl: string = 'fans/rewards';
    public _fanWebUrl: string = environment.apiUrl.fanweb;
    public user$ = new Subject<User>();

    constructor (
        private _http: HttpClient,
        private _userAdmissionService: UserAdmissionService,
        private _inbox: InboxService,
        private _spinner: SpinnerService
    ) { }

    /**
     * Attempts to get the user's profile
     *
     * @return {Observable<User>} The User object
     */
     public getUser (): Observable<User> {
        // call the auth/profile service to retrieve the user data
        return this._http.get('auth/profile')
            .pipe (
                map ((response: any) => {
                    this.user = new User().deserialize(response);
                    Sentry.configureScope(scope => {
                      scope.setUser({
                        id: String(this.user.id),
                        email: this.user.email,
                      });
                    });
                    this.user$.next(this.user);
                    return this.user;
                }),
                switchMap(() => this.loadUserStuff())
            );
    }

    /**
     * loads some of the other items that
     */
    public loadUserStuff(): Observable<any> {
        return this._inbox.getMessages().pipe(
            switchMap(() => {
                this._spinner.setMessage("Loading My Teams");
                return this.getFollowing()
            }),
            switchMap(() => {
                this._spinner.setMessage("Loading Tickets & Passes");
                return this._userAdmissionService.loadAdmission()
            }),
            switchMap(() => {
                this._spinner.setMessage("Loading Rewards");
                return this.getRewards()
            }),
        );
    }

    get notificationBadgeCount(): number {
        return this._inbox.getUnreadCount();
    }

    /**
     * Updates the user profile
     *
     * @param user
     */
     public updateProfile(user: any): Observable<User> {
        return this._http.post('auth/profile', user).pipe(
            map ( (user: User) => this.user = new User().deserialize(user))
        )
    }

    /**
     * Updates the user profile
     *
     * @param user
     */
    public updatePassword(newPasswordContext: {password: string; confirmPassword: string;}): Observable<{ success: boolean; message: string;}> {
        const url = `${this._fanWebUrl}/auth/change-password`;
        return this._http.post(url, newPasswordContext).pipe(
            map((response: { success: boolean; message: string;}) => {
                return {
                    success: response.success,
                    message: response.message
                }
            })
        );
    }

    getFollowing(): Observable<Agency[]> {
        const url = 'fans/following';
        return this._http.get<Agency[]>(url).pipe (
            map( (agencies) => this.user.following = _.orderBy(agencies, ['name']).map((agency) => new Agency().deserialize(agency)) )
        )
    }

    toggleFollowing(agencyUUID: string): Observable<Agency[]> {
        const url = 'fans/following';
        let following: Object = {
            uuid: agencyUUID
        };

        return this._http.post<string>(url, following).pipe(
            switchMap((response: any) => this.getFollowing())
        );
    }

    getRewards(): Observable<Reward[]> {
        const url = 'fans/rewards';
        return this._http.get<Reward[]>(url).pipe (
            map( (rewards) => this.user.rewards = _.orderBy(rewards, ['dateStart']).map((reward) => new Reward().deserialize(reward)) )
        )
    }

    setAsRead(uuid: string): Observable<Reward> {
        const url = `${this.baseUrl}/${uuid}`;
        return this._http.put<Reward>(url, null).pipe (
            map( (reward) => new Reward().deserialize(reward))
        )
    }

    sendInvitation(invitation: any): Observable<any> {
        const url = 'fans/invite';
        return this._http.post<any>(url, invitation);
    }

    getPayments(): Observable<Payment[]> {
        const url = 'fans/payments';
        return this._http.get<Payment[]>(url).pipe (
            map( (payments) => this.payments = _.orderBy(payments, ['paymentDate'], ['desc']).map((payment) => new Payment().deserialize(payment)) )
        )
    }

    getPayment(uuid: string): Observable<Payment> {
        const url = `fans/payments/${uuid}`;
        return this._http.get<Payment>(url).pipe (
            map((payment) => new Payment().deserialize(payment))
        )
    }

    // takes an email and attempts to find out if they have an account
    findUser(email: string): Observable<User> {
        let params = new HttpParams().append('email', email);
        return this._http.get<User>('auth/find', {params: params});
    }

    getLinkedAccounts(): Observable<LinkedAccount[]> {
        // call the auth/profile service to retrieve the user data
        return this._http.get<LinkedAccount[]>('auth/linked')
        .pipe (
            map ((accounts) => this.user.linkedAccounts = accounts.map((account) => new LinkedAccount().deserialize(account)))
        );
    }

    linkAccount(form: any): Observable<any> {
        return this._http.post<any>('auth/linked', form).pipe(
            switchMap(() => this.getLinkedAccounts())
        );
    }

    unlinkAccount(account: LinkedAccount): Observable<any> {
        return this._http.post<any>('auth/unlink', account).pipe(
            switchMap(() => this.getLinkedAccounts())
        );;
    }

}
