import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Event } from '@app/data/models/event.model';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { Agency } from '@app/data/models/agency.model';
import { CartService } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { CartItemCollection } from '@app/data/models/cart/cart-item-collection.model';
import { EventStoreChannel } from '@app/data/models/events/event-store-channel.model';
import { BehaviorSubject, of } from 'rxjs';

@Component({
    selector: 'app-event-ticket-selection-modal',
    templateUrl: './select-tickets.component.html',
    styleUrls: ['./select-tickets.component.scss']
})
export class TicketSelectionModalComponent implements OnInit {

    @Input() event: Event;
    @Input() channel: EventStoreChannel;
    @Input() ticketPrices: TicketPrice[];

    public agency: Agency;

    public home: Agency;
    public visitor: Agency;

    public loading: boolean = false;
    // public ticketPrices: TicketPrice[] = new Array<TicketPrice>();
    public cartItems: CartItemCollection = new CartItemCollection();

    public isIncreaseButtonDisabled = new BehaviorSubject<boolean>(false);

    public cartChannelCount = 0;

    constructor(
        public activeModal: NgbActiveModal,
        private _cartService: CartService,
        private _messageService: MessageService,
        private _router: Router
    ) { }

    ngOnInit() {
        this.agency = this.event.agency;
        this.home = this.event.getHomeAgency();
        this.visitor = this.event.getVisitorAgency();

        if (!this.ticketPrices) {
            this.ticketPrices = this.event.getGATicketPrices();
        } else {
            this.ticketPrices = this.ticketPrices.filter((price) => !price.isReservedPrice());
        }

        this.cartChannelCount = this._cartService.items.getChannelQty(this.channel);

        // subscribe to the cartitems
        this.cartItems.items$.subscribe((items) => {
            let disabled = false;
            if (this.channel && this.channel.maxPerTrx > 0) {
                disabled = this.cartChannelCount + this.cartItems.getChannelQty(this.channel) === this.channel.maxPerTrx;
            }
            this.isIncreaseButtonDisabled.next(disabled);
        });
          
        for(let i=0;i<this.ticketPrices.length;i++) {
            this.ticketPrices[i].addedCount = 0;
           }

    }

    public addTicket(price: TicketPrice) {
        price.addedCount ++
        this.cartItems.addTicketItem(this.event, price, this.channel);
    }

    public removeTicket(price: TicketPrice) {
        if(price.addedCount>0){
            price.addedCount --
         }
        this.cartItems.removeItem(this.event, price);
    }

    public addToCart() {
        this._cartService.addItems(this.cartItems);
        this._messageService.toast.success('Your tickets have been added to the cart.');
        this.activeModal.close('cart');
    }

    public checkout() {
        this._cartService.addItems(this.cartItems);
        this._router.navigate([this._cartService.cartRoute]);
        this.activeModal.close('checkout');
    }

    public close() {
        this.activeModal.dismiss(false);
    }

}
