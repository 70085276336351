<div class="mb-4" *ngIf="event.hasProtectedChannels">
    <ts-section-header>
        <i class="fa fa-fw fa-lock mr-1"></i>Restricted Tickets
    </ts-section-header>
    <div *ngIf="event.areTicketsAvailable()">
        <p *ngIf="!channel">Enter your access code in order to purchase restricted tickets for this event.</p>
        <p *ngIf="channel">Access code entered to purchase restricted tickets for this event.</p>
        <form (ngSubmit)="findChannel()" [formGroup]="formChannel" novalidate>
            <div class="input-group mb-3">
                <input type="text" class="form-control search-input" name="channelCode" formControlName="channelCode" placeholder="Enter Access Code" autocomplete="off">
                <div class="input-group-append">
                    <button *ngIf="!channel" mdbrippleradius class="btn btn-primary" type="submit" [disabled]="formChannel.invalid || isLoading">
                        <i class="fa fa-spinner fa-spin mr-2" *ngIf="isLoading"></i>
                        <i class="fa fa-search mr-2" *ngIf="!isLoading"></i>
                        Find Tickets
                    </button>
                    <button *ngIf="channel" mdbrippleradius class="btn btn-primary" type="button" (click)="removeChannel()">
                      <i class="fa fa-times mr-2"></i>
                      Clear Access Code
                    </button>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="error">
                This Access Code <strong>{{ channelCode }}</strong> is either invalid  or there are no more tickets available.
            </div>
        </form>
        <div *ngIf="channel">
            <div *ngIf="channel.hasTicketsAvailable">
                <div class="alert alert-info text-center" *ngIf="channel.maxPerTrx">
                    ** Please note that sales using this code are restricted to {{ channel.maxPerTrx }} ticket<span *ngIf="channel.maxPerTrx > 1" >s</span> **
                </div>
                <app-agency-event-pricing [event]="event" [prices]="channel.prices" (buyGeneral)="buyTickets(channel)" (buyReserved)="buyReservedTickets(channel)"></app-agency-event-pricing>
            </div>
            <div class="alert alert-danger" *ngIf="!channel.hasTicketsAvailable">
                Tickets are no longer available under this access code.
            </div>
        </div>
    </div>
    <ts-no-results message="tickets available" *ngIf="!event.areTicketsAvailable()"></ts-no-results>
</div>

<div class="mb-4" *ngIf="!channel && event.hasProtectedChannels && event.unprotectedChannel">
    <div *ngIf="event.unprotectedChannel.hasTicketsAvailable">
      <div class="alert alert-info text-center" *ngIf="event.unprotectedChannel.maxPerTrx && event.unprotectedChannel.maxPerTrx > 0">
          ** Please note that sales are restricted to {{ event.unprotectedChannel.maxPerTrx }} ticket<span *ngIf="event.unprotectedChannel.maxPerTrx > 1" >s</span> **
      </div>
      <app-agency-event-pricing [event]="event" [prices]="event.unprotectedChannel.prices" (buyGeneral)="buyTickets(event.unprotectedChannel)" (buyReserved)="buyReservedTickets(event.unprotectedChannel)"></app-agency-event-pricing>
    </div>
    <div class="alert alert-danger" *ngIf="!event.unprotectedChannel.hasTicketsAvailable">
        Tickets are no longer available under this access code.
    </div>
</div>
