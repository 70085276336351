import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Message } from '@app/data/models/message.model';
import * as _ from 'lodash';

@Injectable()
export class InboxService {

    baseUrl: string = 'fans/messages';

    public messages: Message[] = new Array<Message>();

    constructor (
        private _http: HttpClient
    ) { }


    /**
     * retreives all of the user's messages from the system into the messages array
     * 
     * @returns Observable Message array
     */
    getMessages(): Observable<Message[]> {

        return this._http.get<Message[]>(this.baseUrl).pipe (
            map( (messages) => this.messages = _.orderBy(messages, ['dateReceived'], ['desc']).map((message) => new Message().deserialize(message)) )
        )

    }
    
    getUnreadCount(): number {
        return this.messages.filter((message) => !message.isRead).length;
    }

    getMessage(uuid: string): Observable<Message> {

        const url = `${this.baseUrl}/${uuid}`;

        return this._http.get<Message>(url).pipe (
            switchMap((message) => this.setAsRead(message.uuid)),
            map( (message) => new Message().deserialize(message))
        )

    }

    setAsRead(uuid: string): Observable<Message> {

        const url = `${this.baseUrl}/${uuid}`;

        return this._http.put<Message>(url, null).pipe (
            map( (message) => new Message().deserialize(message))
        )

    }

    deleteMessage(uuid: string): Observable<string> {

        const url = `${this.baseUrl}/${uuid}`;

        return this._http.delete(url).pipe (
            map((response: any) => response.message)
        );

    }

    // temporary add this to test other functionality
    createMessage() {
        return this._http.post<Message>(this.baseUrl, null);
    }
}