import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ResolveStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { filter, map, mergeMap, delay } from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { HttpFanwebClient } from '@app/core/http/http-nest.service';
import { DomainData, FanData } from '@app/data/models/domain-data.interface';
import Cookie from 'js-cookie';
import random from 'lodash/random';

import { environment } from '@env/environment';
import { Logger, AuthenticationService } from '@app/core';
import { UserService } from '@app/data/services/user.service';
import { User } from '@app/data/models/user.model';
import 'hammerjs';
import { SpinnerService } from './shared/ticket-spicket/spinner.service';
import { HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public spinnerMsg = 'Loading';
    public isLoading = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        // do not remove the analytics injection, even if the call in ngOnInit() is removed
        // this injection initializes page tracking through the router
        private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        private _authentication: AuthenticationService,
        private userService: UserService,
        public spinner: SpinnerService,
        public _modal: NgbModal,
        private loadingBar: LoadingBarService,
        private fanweb: HttpFanwebClient,
    ) {
        Sentry.configureScope(function (scope) {
            scope.setTag('app-version', environment.version);
        });
        this.loadingBar.start();
        this.spinner.message.asObservable().pipe(
            delay(0)
        ).subscribe(msg => {
            this.spinnerMsg = msg;
        });
        this.spinner.loading.subscribe(res => {
            this.isLoading = res
        });
    }
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        this._modal.dismissAll();
    }
    ngOnInit() {
        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        this.computeTrafficRedirect();

        if (this._authentication.isAuthenticated()) {

            this.userService.getUser().subscribe(
                () => {

                },
                () => {
                    this._authentication.logout().subscribe();
                    this.userService.user = new User();
                }
            );

        } else {
            this.userService.user = new User();
        }

        this.angulartics2GoogleAnalytics.eventTrack('Launch', { category: 'App initialized', label: environment.version });


        const onResolveStart = this.router.events.pipe(filter(event => event instanceof ResolveStart));
        const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        // subscribe the onResolveStart to start the spinner
        // NOTE: ActivatedRoute.data is not available here
        onResolveStart.subscribe(() => {
            this.spinner.show();
        });

        // once the navigation completes, check to see if the spinner should be stopped
        // if the Route data object does not include showSpinner: true, then stop the spinner
        onNavigationEnd.pipe(
            map(() => {
                let route = this.activatedRoute;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data)
        ).subscribe((data) => {
            const showSpinner = data["showSpinner"] || false;
            if (!showSpinner) {
                this.spinner.hide();
            }
        });


        this.loadingBar.complete();

    }

    private async computeTrafficRedirect() {
        try {
            const { values: { unifiedTraffic }} = await firstValueFrom(this.fanweb.get<DomainData<FanData>>('domain-data/fan_data?view=web'));

            if (unifiedTraffic === 0) {
                return;
            }

            const beenToUnified = Cookie.get('unified');
            const redirect = () => {
                const url = `${ window.location.pathname }${ window.location.search }${ window.location.hash }`;
                window.location.href = `${ environment.unifiedUrl }${ url }`;
            };

            if (beenToUnified) {
                redirect();
                return;
            }

            const cookieChance = Cookie.get('unified_chance');
            const chance = cookieChance ? Number(cookieChance) : random(0, 100);

            if (!cookieChance) {
                Cookie.set('unified_chance', chance);
            }

            if (chance <= unifiedTraffic) {
                Cookie.set('unified', 1);
                redirect();
                return;
            }
        } catch (e) {
            // Ingore
            console.warn('Could not fetch remote traffic config');
        }
    }
}
