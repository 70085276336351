import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html'
})
export class HelpModalComponent implements OnInit {

    @Input() digitalOnly: boolean;

    constructor (
        public activeModal: NgbActiveModal,
    ) { }

    ngOnInit() {
        
    }

}