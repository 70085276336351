import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { HomeModule } from './home/home.module';
import { AuthModule } from './auth/auth.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AgencyModule } from '@app/agency/agency.module';
import { TicketsModule } from '@app/tickets/tickets.module';
import { CartModule } from '@app/cart/cart.module';
import { AccountModule } from '@app/account/account.module';
import { DataModule } from '@app/data/data.module';
import { TicketSpicketModule } from '@app/shared/ticket-spicket/ticket-spicket.module';
import { CordovaService } from '@app/data/services/cordova.service';
//import { MDBBootstrapModule } from 'angular-bootstrap-md';
import 'hammerjs';
import { PassesModule } from './passes/passes.module';
import { SpinnerService } from './shared/ticket-spicket/spinner.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { Angulartics2GoogleAnalytics, Angulartics2Module } from 'angulartics2';
import { errorExtractor } from './core/sentry.util';

// Configs

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
        FormsModule,
        NgbModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
        }),
        CoreModule,
        DataModule,
        SharedModule,
        HomeModule,
        Angulartics2Module.forRoot(),
        AppRoutingModule,
        AgencyModule,
        TicketsModule,
        PassesModule,
        AccountModule,
        CartModule,
        AuthModule,
        TicketSpicketModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        SpinnerService,
        CordovaService,
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
            extractor: errorExtractor
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
