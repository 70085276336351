import { Component, Input } from '@angular/core';
import { Reward } from '@app/data/models/reward.model';

@Component({
    selector: 'ts-reward-card',
    templateUrl: './reward-card.component.html',
    styleUrls: ['./reward-card.component.scss']
})

export class RewardCardComponent {

    @Input('reward') reward: Reward;

    constructor() {
    }
}
