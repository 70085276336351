<mat-nav-list style="margin-bottom:20px;">
    <a *ngIf="data.hasAction(actions.view)" (click)="doAction(actions.view)" mat-list-item  aria-label="Button that lets you view your tickets.">
        <h5 class="text-uppercase" mat-line><i class="fas fa-ticket-alt fa-fw mr-3"></i><strong>View Tickets</strong></h5>
        <span mat-line>Tap here to view your tickets on gameday.</span>
    </a>
    <a *ngIf="data.hasAction(actions.claim)" (click)="doAction(actions.claim)" mat-list-item  aria-label="Button that lets you view your tickets.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-tag fa-fw mr-3"></i><strong>Use My Access Pass</strong></h5>
        <span mat-line>Tap here to use your passes and claim your tickets for gameday.</span>
    </a>
    <a *ngIf="data.hasAction(actions.punch)" (click)="doAction(actions.punch)" mat-list-item  aria-label="Button that lets you use your punch pass.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-id-card fa-fw mr-3"></i><strong>Use My Punch Pass</strong></h5>
        <span mat-line>Tap here to use your punch pass to claim your tickets for gameday.</span>
    </a>
    <a *ngIf="data.hasAction(actions.print)" (click)="doAction(actions.print)"  mat-list-item  aria-label="Button that lets you print your tickets.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-envelope fa-fw mr-3"></i><strong>Print</strong></h5>
        <span mat-line>Have a PDF copy of your tickets emailed to you to print at home.</span>
    </a>
    <a *ngIf="data.hasAction(actions.transfer)" (click)="doAction(actions.transfer)"  mat-list-item  aria-label="Button that lets you transfer your tickets.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-paper-plane fa-fw mr-3"></i><strong>Transfer</strong></h5>
        <span mat-line>Transfer your tickets to another account using their email address.</span>
    </a>
    <a *ngIf="data.hasAction(actions.accept)" (click)="doAction(actions.accept)"  mat-list-item  aria-label="Button that lets you accept your ticket transfer.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-check fa-fw mr-3"></i><strong>Accept</strong></h5>
        <span mat-line>Tap here to accept your ticket transfer and be ready for gameday.</span>
    </a>
    <a *ngIf="data.hasAction(actions.decline)" (click)="doAction(actions.decline)" mat-list-item  aria-label="Button that lets you decline your ticket transfer.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-close fa-fw mr-3"></i><strong>Decline</strong></h5>
        <span mat-line>Tap here to decline your ticket transfer.</span>
    </a>
    <a *ngIf="data.hasAction(actions.cancel)" (click)="doAction(actions.cancel)" mat-list-item  aria-label="Button that lets you view your pending ticket transfers.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-send fa-fw mr-3"></i><strong>View Transfers</strong></h5>
        <span mat-line>Tap here to view your pending ticket transfers.</span>
    </a>
    <a *ngIf="data.hasAction(actions.buy)" (click)="doAction(actions.buy)"  mat-list-item  aria-label="Button that lets you buy more tickets.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-calendar fa-fw mr-3"></i><strong>Buy More Tickets</strong></h5>
        <span mat-line>Need to buy more tickets for your big event? Just click here.</span>
    </a>
</mat-nav-list>
