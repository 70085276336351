import { IDeserializable } from "@app/data/models/deserializable.interface";

export class AccountSettings implements IDeserializable {

    facebookURI: string;
    instagramURI: string;
    snapchatURI: string;
    twitterURI: string;

    logoURI: string;
    logo2xURI: string;
    coverArtURI: string;

    primaryColor: string;
    primaryActive: string;
    primaryHover: string;
    primaryText: string;
    secondaryColor: string;
    secondaryActive: string;
    secondaryHover: string;
    secondaryText: string;

    timezone: string;

    constructor() {}

    deserialize(input: any) {
        if(input.general) {
            this.timezone = input.general.timezone ?? 'US/Eastern';
            this.twitterURI = input.social.twitter;
            this.instagramURI = input.social.instagram;
            this.facebookURI = input.social.facebook;
            this.snapchatURI = input.social.snapchat;

            // fall back to defaults for the platform
            this.primaryColor = input.branding.colors.primary.base ?? '#005CB9';
            this.primaryText = input.branding.colors.primary.text ?? '#FFFFFF';
            this.primaryHover = input.branding.colors.primary.hover ?? '#004080';

            this.secondaryColor = input.branding.colors.secondary.base ?? '#004080';
            this.secondaryText = input.branding.colors.secondary.text ?? '#FFFFFF';
            this.secondaryHover = input.branding.colors.secondary.hover ?? '##003367';

            this.logoURI = input.branding.images.logo;
            this.logo2xURI = input.branding.images.logo2x;
            this.coverArtURI = input.branding.images.coverArt;

            return this;
        }
        Object.assign(this, input);
        return this;
    }

}
