import { IDeserializable } from "@app/data/models/deserializable.interface";
import { Event } from "@app/data/models/event.model";
import * as moment from "moment";
import { IAdmission, AdmissionSource, AdmissionActions, AdmissionStatus } from "./tickets/admission.interface";

export class TransferUser {
    email: string;
    name: string;
    constructor(name: string, email: string) {
        this.name = name;
        this.email = email;
    }
    getName() {
        return this.name == null ? this.email : this.name;
    }
}

export interface ITransferEvent {
    school: string;
    title: string;
    subtitle: string;
    dateStart: Date;
}

export class Transfer implements IAdmission, IDeserializable {

    private static STATUS_PENDING: string = "pending";
    private static STATUS_REJECTED: string = "rejected";
    private static STATUS_ACCEPTED: string = "accepted";
    private static STATUS_CANCELED: string = "cancelled";
    
    public countTotal: number;
    public countAvailable: number = 0;
    public hasTransferred: boolean = true;
    public hasPrintable: boolean = false;
    public hasTransferable: boolean = false;

    public id: number;
    public verificationCode: string;
    public event: Event;
    public ticketCount: number;
    public emailAddress: string;

    public dateInitiated: Date;
    public dateResponded: Date;
    public dateStatus: Date;
    public status: string;
    public hasResponse: boolean;
    public isAccepted: boolean;
    public isCanceled: boolean;

    public isSender: boolean;
    public isReceiver: boolean;

    public sender: TransferUser;
    public receiver: TransferUser;
    public source: string = AdmissionSource.transfer;

    constructor() { }

    deserialize(input: any) {

        Object.assign(this, input);

        if (input.dateInitiated) {
            this.dateInitiated = moment(input.dateInitiated).toDate();
        }

        if (input.dateResponded) {
            this.dateResponded = moment(input.dateResponded).toDate();
        }

        if (input.dateStatus) {
            this.dateStatus = moment(input.dateStatus).toDate();
        }

        if (input.event) {
           this.event = new Event().deserialize(input.event);
        }

        if (input.sender) {
            this.sender = new TransferUser(input.sender.name, input.sender.email);
        }

        if (input.receiver) {
            this.receiver = new TransferUser(input.receiver.name, input.receiver.email);
        }

        this.countTotal = this.ticketCount;

        return this;
    
    }

    getNarrative(): string {

        var narrative: string;

        let tickets: string = 'ticket' + (this.ticketCount > 1 ? 's': '');

        if (this.isReceiver) {
            switch (this.status.toLowerCase()) {
                case (Transfer.STATUS_PENDING): {
                    narrative = `You received ${this.ticketCount} ${tickets} from ${this.sender.getName()}`;
                    break;
                }
                case (Transfer.STATUS_ACCEPTED): {
                    narrative = `You accepted ${this.ticketCount} ${tickets} from ${this.sender.getName()}`;
                    break;
                }
                case (Transfer.STATUS_REJECTED): {
                    narrative = `You rejected ${this.ticketCount} ${tickets} from ${this.sender.getName()}`;
                    break;
                }
                case (Transfer.STATUS_CANCELED): {
                    narrative = `${this.sender.getName()} cancelled sending ${this.ticketCount} ${tickets} to you`;
                    break;
                }
            }
        } else if (this.isSender) {
            switch (this.status.toLowerCase()) {
                case (Transfer.STATUS_PENDING): {
                    narrative = `You sent ${this.ticketCount} ${tickets} from ${this.receiver.getName()}`;
                    break;
                }
                case (Transfer.STATUS_ACCEPTED): {
                    narrative = `${this.receiver.getName()} accepted ${this.ticketCount} ${tickets} from you`;
                    break;
                }
                case (Transfer.STATUS_REJECTED): {
                    narrative = `${this.receiver.getName()} rejected ${this.ticketCount} ${tickets} from you`;
                    narrative = `You rejected ${this.ticketCount} ${tickets} from ${this.receiver.getName()}`;
                    break;
                }
                case (Transfer.STATUS_CANCELED): {
                    narrative = `You cancelled sending ${this.ticketCount} ${tickets} to ${this.receiver.getName()}`;
                    break;
                }
            }
        }

        return narrative;

    }

    public getStatus(): string {
        return this.status;
    }

    public getActions(): string[] {
        let actions: string[];

        actions.push(AdmissionActions.accept);
        actions.push(AdmissionActions.decline);

        return actions;

    }
    
}
