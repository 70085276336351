<div class="modal-body p-0" *ngIf="consumer">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="letter-holder">
            <div class="letter">
                <span *ngIf="consumer.holderName">{{ consumer.holderName | limitTo: 1 }}</span>
                <span *ngIf="!consumer.holderName">U</span>
            </div>
        </div>
        <div class="p-3">
            <h5 class="text-center text-uppercase font-weight-bold border-bottom">
                PASS TYPE:<br />{{ consumer.level.value }}
                <span *ngIf="consumer.seatNumber" class="ml-2">
                    Seat: {{ consumer.seatNumber }}
                </span>
            </h5>
            <p class="text-muted text-center font-sm">
                Assign a name to this individual pass.<br/>Then at the gate easily pick who is at the gate and ready to check-in.
            </p>
            <form (ngSubmit)="assign()" [formGroup]="assignForm" novalidate style="border:none;">
                <div class="form-group">
                    <label class="d-block">
                        <input type="text" (keyup)="checkPassHolderName($event)" formControlName="holderName" class="form-control" autocomplete="first name" maxlength="100" [placeholder]="'Full Name'" ([ngModel])="consumer.holderName"/>
                        <span hidden >Full Name</span>
                        <span class="text-danger" [hidden]="assignForm.get('holderName').errors?.required || isPassNameValid" >Field must have at least 1 character to be a valid entry.</span>
                        <small  [hidden]="assignForm.controls.holderName.valid || assignForm.controls.holderName.untouched" class="text-danger" >
                            Full name is required.
                        </small>
                    </label>
                </div>
                <hr />
                <button class="btn btn-sm btn-secondary btn-block" type="submit" [disabled]="!assignForm.valid || !isPassNameValid">
                    <i class="fa fa-save mr-2"></i>Save
                </button>
            </form>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
  <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Cancel</button>
</div>
  