<div class="background-color">
    <div class="container bg-white pb-4 pt-3">
        <ts-section-header>Payment Methods</ts-section-header>
        <ngx-braintree-vault (addButtonStatus)="onAddButtonStatus($event)" (canMarkAsDefault)="onCanMarkAsDefault($event)">
        </ngx-braintree-vault>
        <div class="my-5 text-center">
            <div class="row d-none d-sm-flex">
                <div class="col-6">
                    <button class="btn btn-secondary btn-block my-2" [disabled]="!enableAddButton" (click)="add()">
                        <span><i class="fa fa-credit-card mr-2"></i>Add</span>
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-secondary btn-block my-2" [disabled]="!canManageDefault" (click)="markAsDefault()">
                        <span><i class="fa fa-credit-card mr-2"></i>Mark Selected as Default</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
