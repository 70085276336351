import { IDeserializable } from "../deserializable.interface";
import { TicketPrice } from "../ticket-price.model";
import * as _ from 'lodash';
import * as moment from 'moment';

export class EventStoreChannelCode implements IDeserializable {
    public code: string;
    public ticketCount: number = 0;

    constructor() { }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}

export class EventStoreChannel implements IDeserializable {

    public id: number;
    public channelName: string;
    public capacity: number = 0;
    public isProtected: boolean = false;

    public dateOnSale: Date;
    public dateOffSale: Date;

    public maxPerCode: number;
    public maxPerUser: number;
    public ticketCount: number;
    public availableCount: number;
    public maxPerTrx: number;

    public prices: TicketPrice[] = new Array<TicketPrice>();

    // transient - this is used by the ticket selection process
    public channelCode: EventStoreChannelCode;

    constructor() { }

    deserialize(input: any) {

        Object.assign(this, input);

        if (input.prices)
            this.prices = _.orderBy(input.prices, ['ticketType.rank']).map((price: any) => new TicketPrice().deserialize(price));

        if (input.dateOnSale)
            this.dateOnSale = moment(input.dateOnSale).toDate();

        if (input.dateOffSale)
            this.dateOffSale = moment(input.dateOffSale).toDate();

        if (input.channelCode)
            this.channelCode = new EventStoreChannelCode().deserialize(input.channelCode);

        if (input.prices) {
            this.prices = input.prices.map((price) => {
              const ticketPrice = new TicketPrice().deserialize(price);
              ticketPrice.overallAvailable = input.availableCount;
              return ticketPrice;
            });
        }

        // figure out the available count
        this.availableCount = this.capacity;

        // first, check to see if the channel code has met capacity
        if (this.channelCode) {
            this.availableCount = this.maxPerCode - this.channelCode.ticketCount;
        }

        this.maxPerTrx = _.min([this.maxPerCode, this.maxPerUser]);

        return this;

    }

    /**
     * Determine if tickets are on sale
     *
     * if now() is after the dateOnSale and before the dateOffSale
     *
     * *NOTE: if there is no dateOffSale, it uses midnight the day of the event
     *
     */
    public get areTicketsOnSale(): boolean {
        var dateOffSale: Date = this.dateOffSale || moment().startOf('day').add(1, 'days').toDate();
        return moment().isAfter(this.dateOnSale) && moment().isBefore(moment(dateOffSale));
    }

    public get hasTicketsAvailable(): boolean {

        return this.availableCount > 0;

    }

    public get hasTicketsAvailable1(): boolean {

        let availableCount: number = this.capacity;

        // first, check to see if the channel code has met capacity
        if (this.channelCode) {
            availableCount = this.maxPerCode - this.channelCode.ticketCount;
        }

        if (availableCount <= 0) {
            return false;
        }

        return (this.capacity - this.ticketCount) > 0;

    }

}
