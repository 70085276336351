import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import { ITicketService } from '@app/shared/ticket-spicket/interfaces/ticket-service.interface';
import { TicketShare } from '@app/data/models/ticket-share.model';
import { Ticket } from '../models/ticket.model';

@Injectable()
export class TicketShareService implements ITicketService {

    public share: TicketShare;

    public basePath: string = 'fans/share'

    constructor (
        private _http: HttpClient
    ) {}

    public getShare(shareUUID: string): Observable<TicketShare> {

        const url = `${this.basePath}/${shareUUID}`;

        return this._http.get<TicketShare>(url).pipe (
            map( (share) => this.share = new TicketShare().deserialize(share)),
        )
    }

    public selfCheckIn(bundle: TicketBundle): Observable<Ticket[]> {

        const url = `${this.basePath}/${this.share.uuid}/redeem`;

        var ticketNumbers: string[] = bundle.tickets.map((ticket) => ticket.ticketNumber);

        var checkin: Object = {
            eventUUID: bundle.event.uuid,
            tickets: ticketNumbers
        };

        return this._http.post(url, checkin).pipe(
            switchMap(() => this.getShare(this.share.uuid)),
            map((share) => share.admission.getTickets())
        );

    }

}
