<!-- <div class="backgroundImage"></div> -->
<!-- <div class="colorOverlay"></div> -->
<div class="home-container">
  <div class="container-fluid">
    <div class="text-center">
      <div class="container align-middle pt-5 pb-3 px-0">
        <div class="ticket-spicket logo noAuth" [ngClass]="{'featuredEventsShown': featuredEvents.length > 0}">
        </div>
        <ng-select [items]="agency$ | async" bindLabel="name" [addTag]="false" [multiple]="false" [hideSelected]="true"
        [trackByFn]="trackByFn" [minTermLength]="2" [loading]="agencyLoading" [typeahead]="agencyInput$"
        placeholder="Search for a School, Organization or Event" [(ngModel)]="selectedAgency"
        (change)="onAgencySelection()" class="search-input home-page-search">
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" style="padding: -10px">
          <ts-agency-list-item [agency]="item"></ts-agency-list-item>
        </ng-template>
      </ng-select>
      </div>
    </div>
  </div>
  <div class="featuredEvents p-3 py-4 mt-5" *ngIf="getTodaysEvents().length > 0">
    <div class="container p-0">
      <ts-section-header class="h5-responsive">Today's Tickets</ts-section-header>
      <ts-ticket-legend class="text-white"></ts-ticket-legend>
      <ts-ticket-list-item [ticket]="ticket" *ngFor="let ticket of getTodaysEvents()" class="mb-4">
      </ts-ticket-list-item>
    </div>
  </div>
  <!-- <div class="text-center my-4">
        <button class="btn btn-sm btn-primary" routerLink="/shared/73b45618-72f8-4f9e-acaf-db08372e0d6c">Shared</button>
        <button class="btn btn-sm btn-primary" routerLink="/shared/73b45618-72f8-4f9e-acaf-db08372e0d62">Shared - Invalid</button>
        <button class="btn btn-sm btn-primary" routerLink="/shared/invalid">Invalid</button>
        <button class="btn btn-sm btn-primary" routerLink="/shared/complete">Event Complete</button>
    </div> -->
  <div class="featuredEvents p-3 py-4 mt-5" *ngIf="featuredEvents.length > 0">
    <div class="container p-0">

      <div class="row">
        <div class="col-12 col-sm-12 col-lg-4 mb-4" *ngFor="let event of featuredEvents"
          [routerLink]="['/agency', event.agency.uuid, 'events', event.uuid]">
          <ts-event-card-h2h [event]="event" [schoolView]="false"></ts-event-card-h2h>
        </div>
      </div>
    </div>
  </div>

  <!-- RJH - Commented out until we can determine what content gets displayed here
    <div class="ticker-wrap">
        <div class="ticker">
            <div class="ticker__item" *ngFor="let item of tickerItems">{{item}}</div>
        </div>
    </div>
    -->
</div>
