<div class="row">
  <div class="col-12">
    <button shareButton="facebook" type="button" class="btn btn-primary w-100 my-3 facebook">
      <i class="fab fa-facebook-square mr-2" aria-hidden="true"></i>Post on Facebook
      <div class="md-ripple-container"></div>
    </button>
  </div>
  <div class="col-12">
    <button shareButton="twitter" type="button" class="btn btn-primary w-100 my-3 twitter">
      <i class="fab fa-twitter mr-2" aria-hidden="true"></i>Send a Tweet
      <div class="md-ripple-container"></div>
    </button>
  </div>
</div>