// `.env.ts` is generated by the `npm run env` command

import { env } from './.env';

export const environment = {
    production: true,
    version: env.npm_package_version,
    fastLaneUrl:'https://go.ticketspicket.com',
    serverUrl: 'https://api.ticketspicket.com/v2',
    apiUrl: {
        fanweb: 'https://fan-web-api.ticketspicket.com',
    },
    defaultLanguage: 'en-US',
    supportedLanguages: [
        'en-US',
        'fr-FR'
    ],
    seatsio: {
        apiUrl: 'https://api.seatsio.net',
        publicKey: '36e67f52-a30e-4d22-a257-195341419030',
        encodedKey: 'MDFmYTQzNGUtODZkYi00MjJkLThjZmMtMWI5N2QzYjM5ZmQyOg=='
    },
    unifiedUrl: 'https://my.hometownticketing.com',
    googlePayMerchantId: 'BCR2DN4TZCR47PS6',
    googleAnalyticsPropertyId: 'G-VKS1VSVRT3'
};
