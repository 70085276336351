<div class="row align-items-center align-content-stretch agency-list-item p-0">
    <div class="col-auto col-lg-1 logo text-center" [ngStyle]="{'background-color': agency.settings?.primaryColor}">
        <img alt="{{ agency.name }} logo" *ngIf="agency.settings?.logoURI" [src]="agency.settings?.logoURI" width="41" />
        <div *ngIf="!agency.settings?.logoURI && agency.settings?.primaryColor" class="default-logo" [ngStyle]="{'background-color': agency.primaryColor}">
            {{ agency.name | limitTo: 1 }}
        </div>

        <div *ngIf="!agency.settings?.logoURI && !agency.settings?.primaryColor" class="default-logo">{{ agency.name | limitTo: 1 }}</div>
    </div>
    <div class="col col-lg-8">
        <div class="name text-truncate">
            <div class="mb-2 text-truncate"><strong>{{ agency.name }}</strong></div>
        </div>
        <div class="location text-muted font-sm">
            <i class="fa fa-map-marker mr-1"></i> {{agency.cityState}}
        </div>
    </div>
    <div class="col-auto col-lg-3 py-2 pl-0 pr-2 pr-lg-4">
        <div class="select font-sm">
            <span class="d-none d-sm-inline">Select</span>
            <i class="fa fa-arrow-right ml-1"></i>
        </div>
    </div>
</div>
