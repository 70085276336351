import { Injectable } from '@angular/core';
import { TicketSpicketConfirmation } from '@app/shared/ticket-spicket/confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { TicketSpicketMatConfirmation } from '@app/shared/ticket-spicket/confirm-dialog/mat-confirm.component';

@Injectable()
export class MessageService {

    constructor (
        private _modal: NgbModal,
        public toast: ToastrService,
        public dialog: MatDialog
    ) {}

    public ConfirmYesNo (content: string, title?: string, configuration?: any): Promise<boolean> {

        const modalTitle:string = title || 'Are you sure?';

        if (!configuration) {
            configuration = { centered: true };
        }

        const modal = this._modal.open(TicketSpicketConfirmation, configuration);
        modal.componentInstance.title = modalTitle;
        modal.componentInstance.content = content;

        modal.result.catch(() => {
            if (document.querySelector('body > .modal')) {
                document.body.classList.add('modal-open');
            }
        });
 
        return modal.result;

    }
    
    public MatConfirmYesNo (message: string, title?: string): MatDialogRef<TicketSpicketMatConfirmation, any> {

        const dialogConfig = new MatDialogConfig();
    
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            content: message,
            title: title
        };

        const dialogRef = this.dialog.open(TicketSpicketMatConfirmation, dialogConfig);
        return dialogRef;
    }
}