<div class="modal-body">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header-color single">
    </div>
    <div class="modal-body pb-3">
        <ts-section-header>Select Payment Method</ts-section-header>

        <div *ngIf="cart.getTotalPrice() === 0" class="p-3">
            <div class="empty-cart text-center" style="margin:50px auto;">
                <h2 class="border-0">Congratulations!</h2><h5>Today, your cart total comes to $0.00, so your purchase is <span style="color:green;">FREE.</span></h5>
                <p>Click Complete Checkout below to complete the purchase process and receive your digital tickets!</p>
                <hr/>
                <div class="row d-block d-sm-none">
                    <button class="btn btn-secondary btn-block px-5 my-2" (click)="completePurchase()">
                        <i class="fa fa-credit-card mr-2"></i>Complete Checkout
                    </button>
                    <button class="btn btn-light btn-block px-5 my-2" [routerLink]="['..']"><i class="fa fa-arrow-left mr-2"></i>Back</button>
                </div>
                <div class="row d-none d-sm-flex">
                    <div class="col-6">
                        <button class="btn btn-light btn-block my-2" [routerLink]="['..']"><i class="fa fa-arrow-left mr-2"></i>Back</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-secondary btn-block px-5 my-2" (click)="completePurchase()">
                            <i class="fa fa-credit-card mr-2"></i>Complete Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="cart.getTotalPrice() > 0">
            <div class="mb-3">
                <ngx-braintree [chargeAmount]="cart.getTotalPrice()" (paymentStatus)="onPaymentStatus($event)"
                  (payButtonStatus)="onPayButtonStatus($event)">
                </ngx-braintree>
            </div>
            <div class="mb-3 summary-options text-right">
                <div class="mb-2 pr-3" *ngIf="cart.getTotalFees() > 0">Convenience Fee: <strong>{{cart.getTotalFees() | currency }}</strong></div>
                <div class="mb-2 pr-3">Total Amount: <strong>{{cart.getTotalPrice() | currency }}</strong></div>
            </div>
            <div class="my-1 text-center">
                <div class="row d-none d-sm-flex">
                    <div class="col-6">
                        <button class="btn btn-light btn-block my-2" [routerLink]="['..']"><i class="fa fa-arrow-left mr-2"></i>Back</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-secondary btn-block my-2" (click)="checkout()" [disabled]="!enablePayButton">
                            <span><i class="fa fa-credit-card mr-2"></i>Pay {{cart.getTotalPrice() | currency }}</span>
                            <!-- <span><i class="fa fa-spinner fa-spin mr-2"></i>Waiting...</span> -->
                        </button>
                    </div>
                </div>
                <div class="d-block d-sm-none">
                    <button class="btn btn-secondary btn-block px-5 my-2" (click)="checkout()" [disabled]="!enablePayButton">
                        <span><i class="fa fa-credit-card mr-2"></i>Pay {{cart.getTotalPrice() | currency }}</span>
                        <!-- <span *ngIf="!enablePayButton"><i class="fa fa-spinner fa-spin mr-2"></i>Waiting...</span> -->
                    </button>
                    <button class="btn btn-light btn-block px-5 my-2" [routerLink]="['..']"><i class="fa fa-arrow-left mr-2"></i>Back</button>
                </div>
            </div>
        </div>
    </div>
</div>
