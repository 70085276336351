import { Component, Input, NgZone, OnInit, OnDestroy, ViewChild, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { ReservedConfiguration } from '@app/data/models/reserved/configuration.model';

@Component({
    selector: 'ts-seat-map',
    templateUrl: './seat-map.component.html',
    styleUrls: ['./seat-map.component.scss']
})

export class SeatMapComponent implements OnInit, OnDestroy {

    @Input('config') config: ReservedConfiguration;
    @Input('mode') mode: string;

    @Output() seatObjectSelected = new EventEmitter();
    @Output() seatObjectDeselected = new EventEmitter();
    @Output() onRender = new EventEmitter<any>();

    @ViewChild('element', { static: false }) public viewElement: ElementRef;

    public mapConfiguration: any = {};
    public chart: any;
    public element: any;

    constructor(
        public renderer: Renderer2,
        private ngZone: NgZone
    ) { }

    //OnInit Add the huge code snippet below.
    public ngOnInit() {
        this.setConfig();
    }

    public ngOnDestroy() {
        // if (this.chart) {
        //     this.chart.destroy();
        // }
    }

    public seatSelected(object: any) {
        this.seatObjectSelected.emit(object);
    }

    public seatDeselected(object: any) {
        this.seatObjectDeselected.emit(object);
    }

    public setConfig() {
        this.mapConfiguration = {
            publicKey: this.config.key,
            holdOnSelect: this.config.holdOnSelect,
            holdToken: this.config.holdToken,
            mode: this.mode,
            showLegend: false,
            // unavailableCategories: this.config.unavailableCategories,
            availableCategories: this.config.availableCategories,
            objectWithoutPricingSelectable: this.config.objectWithoutPricingSelectable,
            objectWithoutCategorySelectable: this.config.objectWithoutCategorySelectable,
            maxSelectedObjects: this.config.maxSelectedObjects,
            numberOfPlacesToSelect: this.config.numberOfPlacesToSelect,
            loading: "<div class='text-center'>Loading Seat Map...</div>",
            pricing: this.config.getPricing(),
            priceFormatter: function (price) {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
            },
            onChartRendered: (createdChart: any) => {
                this.chart = createdChart;
                this.onRender.emit(this.chart);
            },
            onObjectDeselected: (object: any) => {
                this.seatDeselected(object);
            },
            onObjectSelected: (object: any) => {
                this.seatSelected(object);
            },
        };

        if (this.config.isSeason()) {
            this.mapConfiguration.events = this.config.seasonEventKeys;
        } else {
            this.mapConfiguration.event = this.config.eventKey;
        }
    }
}
