<mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" style="background-color:#004080;color:white;height:100vh;">
        <div class="background-gradient"></div>
        <div style="z-index:2;position:relative;">
            <div class="text-center mb-4">
                <button mat-button class="text-white p-0 float-right" style="min-width:40px;font-size:1.3rem;" (click)="sidenav.toggle()"><i class="fa fa-times"></i></button>
                <div style="clear:both;"></div>
                <svg class="ticketSpicketSVG" style="width:calc(100% - 80px);padding-top:20px;">
                    <use xlink:href="#ts-ht-white-logo"></use>
                </svg>
            </div>
            <mat-nav-list class="text-white pt-3">
                <a mat-list-item class="d-block" routerLink="/home" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the home search page.">
                    <i class="fa fa-search mr-3"></i>
                    <span>Search</span>
                    <i class="fa fa-chevron-right float-right"></i>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/tickets" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the Tickets page.">
                    <i class="fas fa-ticket-alt mr-3"></i>
                    <span>My Tickets</span><span [matBadge]="ticketBadgeCount" [matBadgeHidden]="ticketBadgeCount == 0" matBadgeColor="danger"></span>
                    <i class="fa fa-chevron-right float-right"></i>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/passes" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the Passes page.">
                    <i class="fa fa-tag mr-3"></i>
                    <span>My Passes</span>
                    <i class="fa fa-chevron-right float-right"></i>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/favorites" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the Teams page.">
                    <i class="fa fa-thumbs-up mr-3"></i>
                    <span>My Teams</span>
                    <i class="fa fa-chevron-right float-right"></i>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/account" *ngIf="isAuthenticated()" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the My Account page.">
                    <i class="fa fa-user mr-3"></i>
                    <span>My Account</span><span [matBadge]="unreadCount" [matBadgeHidden]="unreadCount == 0" matBadgeColor="danger"></span>
                    <i class="fa fa-chevron-right float-right"></i>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/auth/login" *ngIf="!isAuthenticated()" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the Login page.">
                    <i class="fa fa-user mr-3"></i>
                    <span>Login</span>
                    <i class="fa fa-chevron-right float-right"></i>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" (click)="sidenav.toggle();logout();" *ngIf="isAuthenticated()" aria-label="Button will log you out of your account.">
                    <i class="fa fa-sign-out mr-3"></i>
                    <span>Logout</span>
                    <i class="fa fa-chevron-right float-right"></i>
                </a>
            </mat-nav-list>
            <div class="py-5 text-center font-sm">
                <span style="color: lightblue">v{{currentVersion}}</span>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <header class="d-block d-lg-none" style="margin-bottom:54px;">
            <div *ngIf="isIPhoneX()" class="mobile-top"></div>
            <nav class="navbar navbar-expand-lg navbar-dark text-uppercase mb-0 fixed-top" style="background-color:#004080;display:block;">
                <button mat-button class="text-white p-0" style="min-width:40px;font-size:1.3rem;" (click)="sidenav.toggle()"><span [matBadge]="unreadCount" [matBadgeHidden]="unreadCount" matBadgeColor="danger"><i class="fa fa-bars"></i></span></button>
                <div class="float-right">
                    <button mat-button routerLink='/home' class="text-white p-0" style="min-width:40px;font-size:1.3rem;"><i class="fa fa-search"></i></button>
                    <button mat-button routerLink='/tickets' class="text-white p-0" style="min-width:40px;font-size:1.3rem;"><i class="fas fa-ticket-alt"></i><span [matBadge]="ticketBadgeCount" [matBadgeHidden]="ticketBadgeCount == 0" matBadgeColor="danger"></span></button>
                    <button mat-button routerLink='/favorites' class="text-white p-0" style="min-width:40px;font-size:1.3rem;"><i class="fa fa-thumbs-up"></i></button>
                </div>
                <div style="clear:both;"></div>
            </nav>
        </header>

        <!-- Version Check Alert -->
        <div class="alert alert-warning font-sm text-center tappable" role="alert" style="cursor:pointer;" (tap)="refreshApp()" (click)="refreshApp()" *ngIf="mobileService.deviceInfo?.platform === 'web' && hasNewVersion()">
            <i class="fa fa-lg fa-bell mr-2"></i>We've been working hard to improve our software - <strong>click to get the latest version!</strong>
        </div>

        <app-header class="d-none d-lg-block" *ngIf="!inCart"></app-header>

        <!-- Transfer Alert -->
        <div class="alert alert-danger font-sm text-center tappable d-none d-sm-block text-uppercase" role="alert" style="cursor:pointer;" (click)="$event.stopPropagation();openBottomSheet();" *ngIf="hasIncomingTransfer">
            <i class="fa fa-lg fa-send mr-2"></i>A ticket or pass has been transferred to you - <strong>click here to check it out!</strong>
        </div>

        <div class="timer py-2" style="cursor:pointer;" *ngIf="cart.countdown" routerLink="/cart">
            <div class="container">
                <div class="row align-items-center m-0 pr-0">
                    <div class="col-9 col-lg-10 description font-sm py-1 py-sm-0" [ngClass]="{'agency': isAgencyPage}">
                        <i class="fa fa-info-circle mr-2 d-none d-md-inline-block"></i>Due to limited availability the items in your cart are subject to a validation check at the time of purchase.
                    </div>
                    <div class="col-3 col-lg-2 time my-1">
                        {{cart.countdown}}
                    </div>
                </div>
            </div>
        </div>
        <div class="py-2 tappable text-center alert alert-danger d-block d-sm-none" style="cursor:pointer;" (click)="$event.stopPropagation();openBottomSheet();" *ngIf="hasIncomingTransfer">
            <div class="container">
                <div class="row align-items-center m-0 pr-0">
                    <div class="col-12 font-sm py-1 py-sm-0 text-uppercase" [ngClass]="{'agency': isAgencyPage}">
                        <i class="fa fa-lg fa-send mr-2"></i>A ticket or pass has been transferred to you - <strong>click here to check it out!</strong>
                    </div>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <ts-svg></ts-svg>
        <div *ngIf="mobileService.deviceInfo?.model === 'iPhone'" class="mobile-bottom"></div>
    </mat-sidenav-content>
</mat-sidenav-container>
