<div class="card" style="height:100%;">
    <div class="card-body">
        <div class="card-text py-2 text-uppercase"><i class="fas fa-fw fa-ticket-alt mr-2"></i>{{ link.pass.level.title }}</div>
        <div class="card-text py-2 text-uppercase" *ngIf="!link.isAssigned">- UNASSIGNED -</div>
        <div class="card-text py-2" *ngIf="link.isAssigned">
            <div class="py-1 text-uppercase" *ngIf="link.isTransferred">
                <i class="fa fa-fw fa-link mr-2"></i>{{ link.pass.login.name }} - {{ link.pass.login.email }}
            </div>
            <div class="py-1 text-uppercase" *ngIf="!link.isTransferred">
                <i class="fa fa-fw fa-vcard mr-2"></i>{{ link.pass.holderName }}
            </div>
        </div>
    </div>
    <!-- action footer -->
    <div class="card-footer py-1 px-3">

    </div>
</div>
