import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route, AuthenticationGuard } from '@app/core';
import { HomeComponent } from './home.component';
import { AgencyGenericComponent } from '@app/agency/generic.component';
import { FavoritesComponent } from '@app/home/favorites/favorites.component';
import { TicketRenewalComponent } from '@app/home/renewal/ticket-renewal.component';
import { SharedTicketsComponent } from '@app/home/shared/shared-tickets.component';
import { InvalidBundleComponent } from '@app/home/shared/invalid-bundle.component';
import { EventCompleteComponent } from '@app/home/shared/event-complete.component';
import { SharedComponent } from './shared/shared.component';

const routes: Routes = [
  Route.withShell([
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, data: { title: 'Ticket Spicket' } },
    { path: 'group/:nces-id', component: AgencyGenericComponent},
    { path: 'favorites', component: FavoritesComponent, canActivate: [AuthenticationGuard], data: { title: 'My Teams - Ticket Spicket'}},
    { path: 'renewal', component: TicketRenewalComponent, data: { title: 'Ticket Renewals - Ticket Spicket'}},
    { path: 'shared', component: SharedComponent, data: { title: 'Shared Tickets'}, children: [
        { path: 'invalid', component: InvalidBundleComponent, data: { title: '404 - Invalid Link - Ticket Spicket'}},
        { path: 'complete', component: EventCompleteComponent, data: { title: 'Event Complete - Ticket Spicket'}},
        { path: ':share-uuid', component: SharedTicketsComponent, data: { title: 'Shared Tickets - Ticket Spicket'}},
    ]},

  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule { }
