<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency && event" style="position:relative;z-index:100;" [class.embedded]="_agencyService.embedded">
        <div class="coverArt view {{ event.activity.typeActivity.iconClassName }}" [ngClass]="{'custom': event.coverArtURI}">
        <div class="overlayContainer">
            <div class="customCoverArtUnderlay"></div>
            <div class="colorOverlay"></div>
            <div class="customCoverArt"></div>
        </div>
        <div class="corner-tag" *ngIf="event.showHomeIndicator(agency)" [ngClass]="{'home': homeIndicator(), 'away': !homeIndicator()}">
            <div>{{homeIndicator()}}</div>
        </div>
        <div class="details border-0">
            <div class="row align-items-center">
                <div class="col-12 col-sm-9">
                    <svg class="icon d-block d-sm-none">
                        <use attr.xlink:href="#{{ event.activity?.typeActivity.iconClassName }}"></use>
                    </svg>
                    <h5 *ngIf="!event.isScheduled()">
                        <span *ngIf="event.isPostponed()">
                            Postponed&nbsp;
                            <span *ngIf="event.dateStartOriginal">(From {{ event.dateStartOriginal | date: 'MM/dd/yyyy' }})</span>
                        </span>
                        <span *ngIf="event.isCancelled()">Cancelled</span>
                    </h5>
                    <h5 class="title">{{ event.title }}</h5>
                    <h5 class="subtitle" *ngIf="event.isSportingEvent()">{{ event.homeName }} {{ event.visitorName }}</h5>
                    <h5 class="subtitle" *ngIf="!event.isSportingEvent()">{{ event.subtitle }}</h5>
                    <p class="special-event">{{ event.specialEvent }}</p>
                    <div class="location">
                        <div *ngIf="!mobileService.isCapacitor">
                            <a [href]="event.location.address.mapString" target="_blank" style="color:white;text-decoration: underline;">
                                <i class="fa fa-fw fa-map-marker mr-1"></i>{{ event.location.address.name }}, {{ event.location.address.cityState }}
                            </a>
                        </div>
                        <div *ngIf="mobileService.isCapacitor" style="color:white;text-decoration: underline;">
                            <a (click)="mobileService.openURL(event.location.address.mapString)" target="_blank">
                                <i class="fa fa-fw fa-map-marker mr-1"></i>{{ event.location.address.name }}, {{ event.location.address.cityState }}
                            </a>
                        </div>
                    </div>
                    <p class="d-block d-sm-none date-time-single-line">
                        <i class="fa fa-fw fa-calendar mr-1"></i>{{ event.dateStart | date: 'EEE, MMM d, h:mm a'}}
                    </p>
                </div>
                <div class="d-none d-sm-inline-block col-sm-3" style="position:relative;">
                    <svg class="icon">
                        <use attr.xlink:href="#{{ event.activity?.typeActivity.iconClassName }}"></use>
                    </svg>
                    <div class="date-time text-center" [ngStyle]="{'background-color': agency.settings?.primaryColor, 'color': agency.settings?.primaryText}">
                        <div class="day">
                            {{ event.dateStart | date: 'EEE' }}
                        </div>
                        <div class="date">
                            {{ event.dateStart | date: 'd' }}
                        </div>
                        <div class="month">
                            {{ event.dateStart | date: 'MMM' }}
                        </div>
                        <div class="time" ng-show="includeTime">
                            {{ event.dateStart | date:'h:mm a' }}
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="background:#008CF4;color:white;">
        <div class="col-12 text-center p-2 font-sm" *ngIf="hasCartItem()" [routerLink]="_cart.cartRoute">
            <i class="mr-2 fa fa-info-circle"></i>This item is already in the cart. Click here to go to your cart.
        </div>
        <div class="col-12 text-center p-2 font-sm" *ngIf="!event.areTicketsOnSale()">
            <i class="mr-2 fa fa-info-circle"></i>Ticket sales have ended for this event.
        </div>
    </div>
    <nav aria-label="breadcrumb" class="d-none d-sm-block">
        <ol class="breadcrumb">
            <li class="breadcrumb-item font-sm">
                <a routerLink="../../">{{ _agencyService.embedded ? 'Events & Passes' : agency.name }}</a>
            </li>
            <li class="breadcrumb-item font-sm">
                <a routerLink="../">All Events</a>
            </li>
            <li class="breadcrumb-item active font-sm" aria-current="page">{{ event.title }} {{ event.subtitle }}</li>
        </ol>
    </nav>
    <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center my-3" routerLink="../">
        <i class="fa fa-arrow-left mr-2"></i>Back to All Events
    </button>

    <!-- Existing Event Ticket Notification -->
    <div class="mt-2" style="cursor:pointer;" *ngIf="hasTickets() || hasPasses()" (click)="showTicketActions()">
        <div class="alert alert-info text-center font-sm">
            <i class="fas fa-ticket-alt mr-2"></i>
            <span style="text-decoration:underline;">You already have tickets for this event. Tap here to view your tickets now.</span>
        </div>
    </div>

    <div class="row mt-2">

        <div class="col-12 col-md-6 col-lg-8">

            <!-- Mobile Buy Tickets Button -->
            <div class="d-block d-md-none align-items-center">
                <!-- <div class="mb-3">
                    <button mdbRippleRadius [disabled]="!event.areTicketsAvailable() && !event.isPassOnly()" type="button" class="btn btn-primary w-100" (click)="buyTickets()">
                        <i class="fa mr-2" [ngClass]="{'fa-ticket': event.areTicketsAvailable(), 'fa-ban': !event.areTicketsAvailable()}"></i>Buy
                        <span *ngIf="event.hasReservedSeating()">General Admission</span> Tickets
                    </button>
                </div>
                <div class="mb-3" *ngIf="event.hasReservedSeating()">
                    <button mdbRippleRadius [disabled]="!event.areTicketsAvailable() && !event.isPassOnly()" type="button" class="btn btn-primary w-100" (click)="buyReservedTickets()">
                        <i class="fa mr-2" [ngClass]="{'fa-ticket': event.areTicketsAvailable(), 'fa-ban': !event.areTicketsAvailable()}"></i>
                        BuyReserved Seats
                    </button>
                </div> -->
                <ts-ticket-help-cta [isMobileOnly]="event.allowSelfCheckin"></ts-ticket-help-cta>
            </div>

            <div class="mb-4">

                <!-- If this is a PassOnly Event - CTA to Passes -->
                <div class="text-center py-3" *ngIf="event.isPassOnly()">
                    <h5 class="h4-responsive px-3 mt-3 border-0" style="font-weight:400;">
                        Online Tickets for this event are only available with a Digital Pass.
                    </h5>
                    <button mdbRippleRadius type="button" class="btn btn-primary px-4" [routerLink]="['/agency', agency.uuid, 'passes']">
                        <i class="fas mr-2 fa-ticket-alt"></i>View Available Passes
                    </button>
                </div>

                <app-agency-event-channel [event]="event" *ngIf="event.hasProtectedChannels"></app-agency-event-channel>

                <app-agency-event-pricing [event]="event" *ngIf="!event.hasProtectedChannels" (buyGeneral)="buyTickets()" (buyReserved)="buyReservedTickets()"></app-agency-event-pricing>

                <!-- Event Ticket Help CTA -->
                <ts-ticket-help-cta [isMobileOnly]="event.allowSelfCheckin" class="d-none d-md-block"></ts-ticket-help-cta>

            </div>

            <!-- Event Description -->
            <div class="mb-4" *ngIf="event.description">
                <ts-section-header>
                    <i class="fa fa-fw fa-info-circle mr-1"></i>About this Event
                </ts-section-header>
                <div class="px-2" [innerHTML]="getDescription()"></div>
            </div>

            <!-- Sub-Event Schedule -->
            <div *ngIf="event.schedule.length > 0" class="mb-4">
                <ts-section-header>
                    <i class="fa fa-fw mr-1 fa-clock"></i>Event Schedule
                </ts-section-header>
                <div class="px-2">
                    <div class="row align-items-center mx-0 my-2 schedule pb-2" *ngFor="let game of event.schedule">
                        <div class="col-6 text-left p-0"><strong>{{ game.description }}</strong></div>
                        <div class="col-6 text-right p-0">{{ game.dateStart | date: 'h:mm a' }}</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-12 col-md-6 col-lg-4">
            <ts-section-header>Brought to you by</ts-section-header>
            <div class="sponsorLogo my-4">
                <a *ngIf="!event.sponsor?.id" class="d-block text-center"
                    (click)="sponsorLinkClicked('http://www.ticketspicket.com')">
                    <img class="sponsor-image" [src]="event.sponsor?.logoURI" alt="{{ event.sponsor?.name }}" width="100%"
                    style="margin:0 auto;max-width:200px;" #sponsorImage>
                </a>
                <a *ngIf="event.sponsor?.id" class="d-block text-center" (click)="sponsorLinkClicked(event.sponsor?.website)">
                    <img class="sponsor-image" [src]="event.sponsor?.logoURI" alt="{{ event.sponsor?.name }}" width="100%"
                    style="margin:0 auto;max-width:300px;" #sponsorImage>
                </a>
            </div>
            <div class="d-none d-md-block">
                <ts-section-header>Share</ts-section-header>
                <ts-social-share></ts-social-share>
            </div>
        </div>
    </div>
    <svg class="background">
        <use xlink:href="#background" x="0px" y="0px"></use>
    </svg>
</div>
<div *ngIf="event" [innerHTML]="getCoverArtStyle()"></div>
