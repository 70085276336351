import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ts-read-only',
  templateUrl: './read-only.component.html',
  styleUrls: ['./read-only.component.scss']
})
export class ReadOnlyComponent implements OnInit {

    @Input() value: any;
    @Input() label: string;

    public isBoolean: boolean = false;

    constructor() { }

    ngOnInit() {
        if (_.isBoolean(this.value)) {
            this.isBoolean = true;
        }
    }
}
