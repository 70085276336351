import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SentryTransaction } from '..';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor (
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authenticationService.isAuthenticated()) {
            return true;
        }
        log.debug('Not authenticated, redirecting...');
        if (route.data?.['checkout']) {
            return true;
            SentryTransaction('Chectout', 'Checkout - Not authenticated');
        }
        this.router.navigate(['/auth/login'], {
            replaceUrl: true,
            queryParams: {
                return: state.url
            }
        });
        return false;
    }

}
