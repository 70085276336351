import { IDeserializable } from "../deserializable.interface";

export enum GatePassTypes {
    punch = "Punch Pass",
    access = "All Access",
    series = "Series Pass",
    season = "Season Tickets",
    bundle = "Bundle"
}

export class GatePassType implements IDeserializable {

    public id: number;
    public value: string;
    
    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    public isType(type: string): boolean {
        return this.value === type;
    }

}
