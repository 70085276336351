import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, of, interval, from, throwError } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PurchaseConfirmation } from '@app/data/models/purchase-confirmation.model';
import { PromotionCode } from '../models/promotion-code.model';
import { Promotion } from '../models/promotion.model';
import { ReservedSeat } from '../models/reserved/seat.model';
import { ReservedSeatsService } from './reserved-seats.service';
import { CartItemCollection } from '../models/cart/cart-item-collection.model';
import { PaymentMethod } from '../models/payment-method.model';
import { CartItem, CartItemProduct } from '../models/cart/cart-item.model';
import { CartExpiration } from '../models/cart/cart-expiration.model';
import { CartItemEventSummary } from '../models/cart/cart-item-event';
import { CartItemPassSummary } from '../models/cart/cart-item-pass';
import { Event } from '../models/event.model';
import { CartItemTypes } from '../models/cart/cart-item-types.enum';
import { GatePass } from '../models/passes/gate-pass.model';
import { TicketPrice } from '../models/ticket-price.model';
import { IDeserializable } from '../models/deserializable.interface';
import { ReservedHoldToken } from '../models/reserved/configuration.model';
import { EventStoreChannel } from '../models/events/event-store-channel.model';

/**
 * This interface is used to communicate with the API
 *
 */
export interface ICart {

    items: any[];
    bundledItems: any[];
    method: PaymentMethod;
    holdToken: string;
    totalAmount: number;
    promotionCode: string;
    promotion?: Promotion;
    other?: CartOtherFees;
    phoneNumber?: string
}

export class CartOtherFees implements IDeserializable {
    name: string = null;
    amount: number = 0;

    constructor() { }

    public deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}

@Injectable()
export class CartService {

    storageKey: string = "com.ticketspicket.cart";

    public expiration: CartExpiration = new CartExpiration();
    public interval: any;
    public countdown: string;
    public purchaseId: string;
    public items: CartItemCollection = new CartItemCollection();
    public bundledItems: CartItemCollection = new CartItemCollection();

    public holdToken: ReservedHoldToken = new ReservedHoldToken();

    public hasPaymentMethod: boolean = false;
    public paymentMethod: PaymentMethod;

    public promotionCode: PromotionCode = new PromotionCode();

    public errors: Object;
    public processing: boolean = false;
    public nonce: string;

    public other: CartOtherFees = new CartOtherFees();

    public confirmation: PurchaseConfirmation;
    public phone: string = '';

    public embedded: boolean = false;
    public embeddedAgencyId: string = 'unknown';

    public isGuestCheckout: boolean = false;

    constructor(
        private _http: HttpClient,
        private _reserved: ReservedSeatsService
    ) {
        this._loadCartFromStorage();
    }

    public get cartRoute(): string {
        if (this.embedded) {
            return '/embed/agency/' + this.embeddedAgencyId + '/cart/'
        }
        return '/cart';
    }

    private _startExpirationClock(dateAdded?: Date) {
        if (!this.interval) {
            this.expiration.start(dateAdded);
            this.interval = interval(1000).subscribe(() => this._checkExpiration());
        }
    }

    /**
     * Checks to see if the cart is expired - if true, it clears the cart
     *
     */
    private _checkExpiration() {
        this.countdown = moment.utc(moment(this.expiration.dateExpiration).diff(moment())).format("mm:ss");
        if (this.expiration.isExpired()) {
            this.clearCart();
        }
    }

    public setIsGuestCheckout(value: boolean) {
        this.isGuestCheckout = value;
        this.saveCart();
    }

    /**
     * returns true if there are no items in the cart
     */
    public isEmpty(): boolean {
        return this.items.isEmpty();
    }

    /**
     * returns the number of items in the cart
     */
    public itemCount(): number {
        return this.items.items.length;
    }

    public getEventItems(): CartItemEventSummary[] {
        return this.items.getEventItems();
    }

    public getProductItemSummary(product: CartItemProduct): CartItemEventSummary | CartItemPassSummary {
        return this.items.getProductItemSummary(product);
    }

    public getPassItems(): CartItemPassSummary[] {
        return this.items.getPassItems();
    }

    public getTotalFees(): number {
        return this.items.getTotalFees();
    }

    public getOtherFees(): number {
        return this.other.amount;
    }

    public getDiscount(): number {
        return this.promotionCode.amountDiscount;
    }

    public getTotalPrice(): number {
        return this.items.getTotalPrice() + this.getOtherFees() - this.getDiscount();
    }

    /**
     * returns the number of items in the cart
     */
    public getItemCount(): number {
        return this.getEventItems().length + this.getPassItems().length;
    }

    public getFinalSalesLanguage(): string {
        return "All sales are final and non-refundable.";
    }

    public hasItem(product: CartItemProduct): boolean {
        return this.items.hasProduct(product);
    }

    public clearOtherEventItems(product: CartItemProduct) {
        const firstUuid = this.getProductItemSummary(product).product.uuid;
        const badItems = [];
        const items = this.items.items;
        items.forEach(item => {
            const itemUuid = this.getProductItemSummary(item.product).product.uuid;
            if (itemUuid !== firstUuid) {
                badItems.push(item);
            }
        });
        // Remove Items AFTER items array is stable, causes issues otherwise...
        badItems.forEach(item => {
            this.removeItem(item);
            this.saveCart();
        });
    }

    public addReservedItem(product: CartItemProduct, seat: ReservedSeat, price: TicketPrice, channel: EventStoreChannel) {        
        this.items.addReservedItem(product, seat, price, channel);
        this._startExpirationClock();
        this.extendHoldToken().subscribe((response: any) => {
            this.saveCart();
        }, (error) => {
            console.log(error);
            this.saveCart();
        });
        this.saveCart();
        this.clearOtherEventItems(product);
    }

    public addSeatRenewal(
        product: CartItemProduct,
        seat: ReservedSeat,
        price: TicketPrice,
        channel: EventStoreChannel) {
        this.items.addReservedItem(product, seat, price, channel);
        this._startExpirationClock();
        this.saveCart();
        this.clearOtherEventItems(product);
    }
    /**
     * Adds an independent collection of items to the item collection
     *
     * @param items
     */
    public addItems(items: CartItemCollection) {
        this.items.fold(items.items)
        this._startExpirationClock();
        this.saveCart();
        items.items.forEach(item => {
            this.clearOtherEventItems(item.product);
        });
    }

    /**
     * removes an item from the cart
     *
     * @param item
     */
    public removeItem(item: CartItem) {
        if (item.isReserved) {
            if (this.holdToken.isExpired()) {
                this.items.removeReservedItem(item.product, item.seat);
            } else {
                this.removeReservedSeat(item.product, item.seat).subscribe();
            }
            this.saveCart();
        } else {
            this.items.removeItem(item.product, item.ticketPrice);
            this.saveCart();
        }
    }

    public removeBundleItem(item: CartItem) {
        if (item.isReserved) {
            if (this.holdToken.isExpired()) {
                this.bundledItems.removeReservedItem(item.product, item.seat);
            } else {
                this.removeReservedSeat(item.product, item.seat).subscribe();
            }
            this.saveCart();
        } else {
            this.bundledItems.removeItem(item.product, item.ticketPrice);
            this.saveCart();
        }
    }

    /**
     * remove the selcted seat from the cart
     * @param seat
     */
    public removeReservedSeat(product: CartItemProduct, seat: ReservedSeat): Observable<boolean> {
        return this._reserved.deselectSeat(product, seat, this.holdToken.holdToken).pipe(
            catchError(error => {
                console.log(error);
                return of([]);
            }),
            tap(() => {
                this.items.removeReservedItem(product, seat);
                this.saveCart();
                return true;
            }),
        );
    };

    /**
     * Get hold token from seats.io
     *
     */
    public getHoldToken(): Observable<string> {

        if (this.holdToken.isExpired()) {
            return this._reserved.generateHoldToken().pipe(
                map((token) => this.holdToken = token),
                map(() => this.holdToken.holdToken)
            )
        } else {
            return of(this.holdToken.holdToken);
        }

    };

    /**
     * Update hold token expiration time from seats.io
     *
     */
    public extendHoldToken(): Observable<any> {
        if (this.holdToken.holdToken) {
            return this._reserved.extendHoldToken(this.holdToken.holdToken).pipe(
                map((token) => this.holdToken = token),
                map(() => this.holdToken)
            )
        } else {
            this.getHoldToken();
        }

        return null;
    };

    /**
     * Saves the cart to local storage
     *
     */
    public saveCart() {

        let cart: any = {
            "dateAdded": this.expiration.dateAdded,
            "items": this.items.items,
            "bundledItems": this.bundledItems.items,
            "holdToken": this.holdToken,
            "promotionCode": this.promotionCode,
            "totalAmount": this.getTotalPrice(),
            "isGuestCheckout": this.isGuestCheckout
        }

        this.calculateCart().subscribe(() => {
            if (this.isEmpty()) {
                this.clearCart();
            } else {
                localStorage.setItem(this.storageKey, JSON.stringify(cart));
            }
        });

    }

    /**
     * removes all of the items from the cart and saves it
     *
     */
    public clearCart(purchased: boolean = false) {
        this.isGuestCheckout = false;
        if (this.interval) {
            this.interval.unsubscribe();
        }

        this.countdown = null;
        this.interval = null;
        this.expiration = new CartExpiration();

        // need to loop backwards in order to ensure all of the items are cleared
        for (var i = this.items.items.length - 1; i >= 0; i--) {
            if (purchased) {
                this.items.removeItem(this.items.items[i].product, this.items.items[i].ticketPrice);
            } else {
                this.removeItem(this.items.items[i]);
            }
        }

        if (this.items.items.length > 0) {
            // need to loop backwards in order to ensure all of the bundled items are cleared
            for (var i = this.bundledItems.items.length - 1; i >= 0; i--) {
                if (purchased) {
                    this.bundledItems.removeItem(this.bundledItems.items[i].product, this.bundledItems.items[i].ticketPrice);
                } else {
                    this.removeItem(this.bundledItems.items[i]);
                    this.removeBundleItem(this.bundledItems.items[i]);
                }
            }
        }

        localStorage.removeItem(this.storageKey);
    }

    /**
     * Returns true if the attempt to load the cart from storage is successful
     *
     * If the stored cart is expired, it clears it
     *
     */
    private _loadCartFromStorage(): boolean {

        var storedCart = localStorage.getItem(this.storageKey);

        if (storedCart) {

            try {

                let cart: any = JSON.parse(storedCart);

                this.holdToken = new ReservedHoldToken().deserialize(cart.holdToken);

                if (this.holdToken.holdToken && this.holdToken.isExpired()) {
                    this.clearCart();
                    return false;
                } else {

                    // loops through the items and adds them to the cart proper
                    cart.items.map((item: CartItem) => {

                        let product: CartItemProduct;

                        if (item.itemType === CartItemTypes.event) {
                            product = new Event().deserialize(item.product);
                        }

                        if (item.itemType === CartItemTypes.pass) {
                            product = new GatePass().deserialize(item.product);
                        }

                        if (item.seat) {
                            this.items.addReservedItem(product, new ReservedSeat().deserialize(item.seat), new TicketPrice().deserialize(item.ticketPrice), item.channel);
                        } else {
                            this.items.addItem(product, new TicketPrice().deserialize(item.ticketPrice), item.selectedQty, item.members, item.channel)
                        }

                    })

                    // loops through the bundled items and adds them to the cart proper
                    cart.bundledItems.map((item: CartItem) => {

                        let product: CartItemProduct;

                        if (item.itemType === CartItemTypes.event) {
                            product = new Event().deserialize(item.product);
                        }

                        if (item.itemType === CartItemTypes.pass) {
                            product = new GatePass().deserialize(item.product);
                        }

                        if (item.seat) {
                            this.bundledItems.addReservedItem(product, new ReservedSeat().deserialize(item.seat), new TicketPrice().deserialize(item.ticketPrice), item.channel);
                        } else {
                            this.bundledItems.addItem(product, new TicketPrice().deserialize(item.ticketPrice), item.selectedQty, item.members, item.channel)
                        }

                    })

                    this.promotionCode = new PromotionCode().deserialize(cart.promotionCode);
                    this._startExpirationClock(moment(cart.dateAdded).toDate());
                    this.calculateCart().subscribe();

                }
            }
            catch (error) {
                console.log(error);
                this.clearCart();
                return false;
            }

            return true;
        }
        return false;
    }

    private _serialize(): ICart {
        return {
            items: this.items.serialize(),
            bundledItems: this.bundledItems.serialize(),
            holdToken: this.holdToken.holdToken,
            totalAmount: this.getTotalPrice(),
            promotionCode: this.promotionCode.code,
            method: this.paymentMethod,
            phoneNumber: ''
        }
    }

    public calculateCart(): Observable<ICart> {

        const url = 'fans/payments/new-calculate-cart';
        let cart = this._serialize();
        cart.bundledItems.map((item) =>
            cart.items.push(item)
        );
        return this._http.post<ICart>(url, cart).pipe(
            tap((cart) => {
                this.items.setCalculations(cart.items)
                if (cart.promotion) {
                    this.promotionCode.setPromotion(cart.promotion);
                } else {
                    this.promotionCode = new PromotionCode().create(null);
                }
                if (cart.other) {
                    this.other = new CartOtherFees().deserialize(cart.other);
                } else {
                    this.other = new CartOtherFees();
                }
            })
        );

    }

    /**
     *
     *
     */
    public setPaymentMethod(paymentMethod: any) {
        this.paymentMethod = paymentMethod;
    }

    /**
     *
     */
    public getClientToken(): Observable<string> {
        let url = ''
        if (!this.isGuestCheckout) {
            url = 'fans/payments/client-token';
        } else {
            url = 'fans/guest/client-token';
        }
        return this._http.get<string>(url).pipe(
            map((response: any) => this.nonce = response.token)
        )
    };

    public checkout(paymentMethod: any): Observable<any> {
        this.setPaymentMethod(paymentMethod);
        let cart = this._serialize();


        cart.bundledItems.map((item) =>
            cart.items.push(item)
        );

        const phone = paymentMethod.phoneNumber;
        let url = ''
        if (this.isGuestCheckout) {
            url = 'fans/guest/checkout';

            cart.phoneNumber = "1" + paymentMethod.phoneNumber.replace(/[^\d]/g, "");
        } else {
            url = 'fans/payments/new-checkout';
        }
        return this._http.post<any>(url, cart).pipe(
            map((confirmation) => {
                this.purchaseId = confirmation.payment.uuid
                this.confirmation = confirmation;
                this.phone = phone
                this.clearCart(true);
            })
        );
    }

    public hasPromoCode(): boolean {
        return !_.isEmpty(this.promotionCode.code);
    }

    public hasOtherFees(): boolean {
        return !_.isEmpty(this.other.name);
    }

    /**
     * Method that takes a promotion code and attempts to apply it to a given cart item
     *
     * @param code string
     *
     */
    public applyPromotionCode(code: string): Observable<ICart> {

        this.promotionCode = new PromotionCode().create(code);
        return this.calculateCart();

    }

    public getItemsLog() {
        if (this.getItemCount() > 0) {
            const cartItems = [];
            this.items.getEventItems().forEach(i => {
                cartItems.push({
                    id: i.product.id,
                    product: 'event',
                    prices: i.ticketPrices.map(p => {
                        return {
                            quantity: p.quantity,
                            price: p.price.id
                        };
                    })
                });
            });
            this.items.getPassItems().forEach(i => {
                cartItems.push({
                    id: i.product.id,
                    product: 'pass',
                    prices: i.ticketPrices.map(p => {
                        return {
                            quantity: p.quantity,
                            price: p.price.id
                        };
                    })
                });
            });
            return cartItems;
        }
        return [];
    }
}
