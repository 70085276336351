import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-agency-passes',
  templateUrl: './listing.component.html'
})
export class AgencyPassesComponent implements OnInit {

    public passes: GatePass[];
    public showPassList: boolean = true;
    public noResultsMessage = 'passes at this time';

    constructor (
        private _agencyService: AgencyService,
        private _spinner: SpinnerService,
    ) {
        this._spinner.setMessage("Loading Passes...")
    }

    get agency(): Agency {
        return this._agencyService.agency;
    }

    ngOnInit() {
        this.passes = this.agency.passes;
        this._agencyService.getPasses(this.agency.uuid).subscribe((passes) => {
            this.passes = passes;
            this._spinner.hide()
        });
    }

    togglePassList() {
        this.showPassList = this.showPassList ? false : true;
    }

    getPasses() {
        return this.agency.passes;
    }

}
