import { IDeserializable } from "./deserializable.interface";
import { AccountSettings } from "@app/data/models/settings.model";

export class AgencySearchResult implements IDeserializable {


    public uuid: string;
    public name: string;
    public nameShort: string;
    public address: string;
    public city: string;
    public cityState: string;
    public state: string;
    public zip: string;
    public lat: number;
    public lng: number;
    public phone: string;
    public rank: number;
    public settings: AccountSettings;

    

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.settings) {
            this.settings = new AccountSettings().deserialize(input.settings);
        }

        return this;
    }

}