import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { TooltipPosition } from '@angular/material/tooltip';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';

@Component({
  selector: 'ts-consumer-pass-list',
  templateUrl: './consumer-pass-list.component.html',
  styleUrls: ['./consumer-pass-list.component.scss']
})

export class ConsumerPassListComponent {

    @Input('pass') pass: ConsumerPass;

    constructor() {}

}
