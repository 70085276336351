import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';

@Injectable()
export class MobileService {

    public deviceInfo: any;
    public isCapacitor: boolean = false;

    async deviceCheck() {
        // comment below line for web application
        // this.deviceInfo = await Device.getInfo();
    }

    async openURL(url: string) {
        // comment below line for web application
        // await Browser.open({ 'url': url });
    }

}
