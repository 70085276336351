<div class="modal-body p-0">
    <p class="pt-3 text-center font-weight-bold">Click on a shaded area to make a selection</p>
    <button mdbRippleRadius type="button" class="close"
        style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;box-shadow:none;"
        aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0" style="overflow:hidden;overflow-y: auto;height: 80vh;">
        <ts-seat-map [config]="config" mode="normal" (seatObjectSelected)='selectSeat($event)'
            (seatObjectDeselected)='removeSeat($event)' (onRender)="setChart($event)" *ngIf="!isLoading">
        </ts-seat-map>
    </div>
</div>
<div class="modal-footer border-top text-center" style="justify-content: center;">
    <button class="btn btn-primary" (click)="close()">
        Confirm Selection
    </button>
</div>
<!-- <div class="text-center modal-close-button mt-3">
    <button mdbRippleRadius type="button" class="btn btn-primary" aria-label="Cancel" style="min-width:150px;"
        (click)="close()">Confirm Selection</button>
</div> -->
