import { IDeserializable } from "@app/data/models/deserializable.interface";
import { GatePass } from "@app/data/models/passes/gate-pass.model";
import { TicketLevel } from "@app/data/models/ticket-level.model";
import { Ticket } from "@app/data/models/ticket.model";
import { User } from "@app/data/models/user.model";
import * as _ from 'lodash';

export class ConsumerGatePassHolder {
    public name: string;
    
    constructor() {}

}

export class ConsumerGatePass implements IDeserializable {

    public id: number;
    public uuid: string;
    
    public gatePass: GatePass;
    public level: TicketLevel;
    public holderName: string;

    public paidAmount: number;
    public feeAmount: number;
    public ticketCount: number;
    public remainingUses: number = 0;
    public seatNumber: string;

    public version: string;

    public ticket: Ticket;

    public parent: ConsumerGatePass;

    public login: User;

    //transient property to hold the email address during transfer
    public holderEmail: string;

    // used to store the linked children passes for family passes
    public children: ConsumerGatePass[] = new Array<ConsumerGatePass>();

    public transferred: boolean;
    public transferable: boolean;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.gatePass) {
            this.gatePass = new GatePass().deserialize(input.gatePass);
        }

        if (input.level) {
            this.level = new TicketLevel().deserialize(input.level);
        }

        if (input.ticket) {
            this.ticket = new Ticket().deserialize(input.ticket);
        }

        if (input.parent) {
            this.parent = new ConsumerGatePass().deserialize(input.parent);
        }

        if (input.login) {
            this.login = new User().deserialize(input.login);
        }

        if (input.children) {
            this.children = input.children.map((child: ConsumerGatePass) => new ConsumerGatePass().deserialize(child));
        }

        return this;
    }

    get isAssigned(): boolean {
        return !_.isEmpty(this.holderName);
    }

    /**
     * determine if the child pass has already been transferred
     * if the parent pass login is the same as the child pass, then it has not been transferred
     */
    get isTransferred(): boolean {
        return this.transferred;
    }

    /**
     * Function to determine if the consumer gate pass is transferrable
     * 
     */
     get isTransferrable(): boolean {
        return this.transferable;
    }

    /**
     * Function to determine if the consumer gate pass is transferrable
     * 
     */
    get name(): string {
        return this.holderName || "Unassigned";
    }

    get isParent(): boolean {
        return this.parent !== null;
    }

}