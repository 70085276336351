<div *ngIf="seats.length > 0">
    <div *ngFor="let item of seats">
        <div class="row my-2 pb-2 mx-0 align-items-center" style="border-bottom:1px dotted #e5e5e5;" *ngIf="!item.product.isSeasonPass">
            <div class="col-6 p-0" style="line-height:35px;">
                Section: {{item.seat.section}} Row: {{item.seat.row}} Seat: {{item.seat.number}}
            </div>
            <div class="col-4 col-md-5 text-right p-0">
                <i class="fas fa-ticket-alt mr-2"></i> x&nbsp;&nbsp;&nbsp;<strong>{{item.price | currency}}</strong>
            </div>
            <div class="col-2 col-md-1 px-0 px-md-2">
                <button type="button" class="btn btn-danger rounded-circle btn-sm float-right" (click)="removeSeat(item)">
                    <i class="fa fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="row my-2 pb-2 mx-0 align-items-center" style="border-bottom:1px dotted #e5e5e5;" *ngIf="item.product.isSeasonPass">
            <div class="col-6 p-0" style="line-height:35px;">
                Section: {{item.seat.section}} Row: {{item.seat.row}} Seat: {{item.seat.number}}
            </div>
            <div class="col-6 text-right p-0">
                <i class="fas fa-ticket-alt mr-2"></i> x&nbsp;&nbsp;&nbsp;<strong>{{item.price | currency}}</strong>
            </div>
        </div>
    </div>
</div>
