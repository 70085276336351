import { Component, Input, OnInit } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { WeatherService } from '@app/data/services/weather.service';
import { MobileService } from '@app/data/services/mobile.service';
import { Router } from '@angular/router';

@Component({
    selector: 'ts-agency-submenu',
    templateUrl: './agency-submenu.component.html',
    styleUrls: ['./agency-submenu.component.scss']
})

export class AgencySubmenuComponent implements OnInit {
    @Input('agency') agency: Agency;
    lat: number;
    lng: number;
    forecast: any;

    constructor(
        public router: Router,
        public _weatherService: WeatherService,
        public _agencyService: AgencyService,
        public mobileService: MobileService
    ) {}

    getForecast() {
        this.forecast = this._weatherService.currentForecast(this.lat, this.lng)
          .subscribe(data => {});
    }

    ngOnInit() {
        if (this.agency.primaryAddress.lat && this.agency.primaryAddress.lng) {
            this.lat = this.agency.primaryAddress.lat;
            this.lng = this.agency.primaryAddress.lng;
        }
    }


    public goToAgency() {
        let agency = this._agencyService.agency;
        const uuid = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

        if (uuid.test(agency.uuid)) {
            this.router.navigate(['/agency', agency.uuid]);
        } else {
            this.router.navigate(['/group', agency.uuid]);
        }
    }

}
