import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxBraintreeService } from './ngx-braintree.service';
import { ConfigureDropinService } from './configure-dropin.service';

import { NgxBraintreeComponent } from './ngx-braintree.component';
import { NgxBraintreeDirective } from './ngx-braintree.directive';
import { NgxBraintreePayDirective } from './ngx-braintree-pay.directive';
import { NgxBraintreeRenewalComponent } from './ngx-braintree-exchange.component';
import { NgxBraintreeVaultComponent } from './ngx-braintree-vault/ngx-braintree-vault.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        NgxBraintreeComponent,
        NgxBraintreeRenewalComponent,
        NgxBraintreeDirective,
        NgxBraintreePayDirective,
        NgxBraintreeVaultComponent
    ],
    exports: [
        NgxBraintreeComponent,
        NgxBraintreeRenewalComponent,
        NgxBraintreePayDirective,
        NgxBraintreeVaultComponent
    ],
    providers: [
        { provide: NgxBraintreeService, useClass: NgxBraintreeService },
        { provide: ConfigureDropinService, useClass: ConfigureDropinService }
    ]
})
export class NgxBraintreeModule { }
