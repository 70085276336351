<div class="container-fluid" style="min-height:calc(100% - 131px);">
    <div class="background-color">
        <div class="container bg-white pb-4 pt-3">
            <div class="py-3 my-3 mt-md-0 mb-md-5 mx-auto">
                <div class="row">
                    <div class="col-12 my-0 mx-auto text-center col-sm-10 col-md-8 col-lg-6 my-0 mx-auto">
                        <div class="card">
                            <div class="card-body">
                                <h4>Invite Your Friends</h4>
                                <svg class="icon">
                                    <use xlink:href="#invite"></use>
                                </svg>
                                <div class="my-3 p-2">
                                    Tell your friends how they can buy their tickets online and skip the line. Send invitations using their email.
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <form (ngSubmit)="sendInvitation()" [formGroup]="inviteForm" novalidate>
                                            <div class="form-group">
                                                <label class="d-block">
                                                    <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'" email/>
                                                    <span hidden >Email</span>
                                                    <small [hidden]="inviteForm.get('email').valid || inviteForm.get('email').untouched" class="text-danger">
                                                    Email is required
                                                    </small>
                                                </label>
                                            </div>
                                            <button class="btn btn-secondary w-100 m-0" mdbRippleRadius type="submit" [disabled]="inviteForm.invalid || isLoading">
                                                <span [hidden]="!isLoading">
                                                    <i class="fa fa-cog fa-spin mr-2 d-inline-block"></i>
                                                    Sending Invitation...
                                                </span>
                                                <span [hidden]="isLoading"><i class="fa fa-send mr-3"></i>Send Invitation</span>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>