import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { UserProfileComponent } from '@app/account/profile/profile.component';
import { UserPaymentsComponent } from '@app/account/payments/payments.component';
import { UserRewardsComponent } from '@app/account/rewards/rewards.component';
import { UserTransferHistoryComponent } from '@app/account/transfers/transfers.component';
import { UserInviteComponent } from '@app/account/invite/invite.component';
import { UserMessagesComponent } from '@app/account/messages/messages.component';
import { SharedModule } from '@app/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentDetailComponent } from '@app/account/payments/detail.component';
import { UserPaymentsListingComponent } from '@app/account/payments/listing.component';
import { UserPaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { NgxBraintreeModule } from '@app/cart/braintree/ngx-braintree.module';

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    NgxBraintreeModule
  ],
  declarations: [
    AccountComponent,
    UserProfileComponent,
    UserPaymentsComponent,
    UserPaymentsListingComponent,
    UserRewardsComponent,
    UserTransferHistoryComponent,
    UserInviteComponent,
    UserMessagesComponent,
    PaymentDetailComponent,
    UserPaymentMethodsComponent
  ]
})
export class AccountModule { }
