<div *ngIf="agency" class="background-color">
  <div style="z-index:2;">
    <ts-agency-submenu [agency]="agency"></ts-agency-submenu>
    <div style="overflow:hidden;">
        <div class="container bg-white pb-4" *ngIf="agency" style="position:relative;z-index:100;">
            <div class="coverArt default">
                <div class="colorOverlay"></div>
                <div class="textOverlay"></div>
                <div class="text text-center">
                    <div class="row">
                        <div class="col-12 text-uppercase">
                            {{ hashtag }}
                        </div>
                    </div>
                </div>
            </div>
            <div id="events" class="mt-3" style="min-height:300px;">
                <ts-section-header>Events</ts-section-header>
                <ts-no-results [message]="noResultsMessage"></ts-no-results>
            </div>
        </div>
    </div>
    <ts-agency-footer [agency]="agency"></ts-agency-footer>
  </div>
</div>
