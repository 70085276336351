import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import * as _ from 'lodash';

@Component({
    selector: 'app-pass-detail-modal',
    templateUrl: './pass-detail.component.html',
    styleUrls: ['./pass-detail.component.scss']
  })
  export class PassDetailModalComponent implements OnInit {

    @Input('consumer') consumer: ConsumerGatePass

    constructor (
        public activeModal: NgbActiveModal,
        private _messageService: MessageService
    ) { }

    ngOnInit() {
    }

    public close() {
        this.activeModal.dismiss(false);
    }

}