import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Event } from '@app/data/models/event.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventStoreChannel } from '@app/data/models/events/event-store-channel.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketSelectionModalComponent } from '../select-tickets.component';
import { AgencyEventService } from '@app/data/services/event.service';
import { ReservedTicketSelectionModalComponent } from '../select-res-tickets.component';

@Component({
  selector: 'app-agency-event-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class EventChannelComponent implements OnInit {

    @Input()
    public event: Event;

    public channel: EventStoreChannel;
    public formChannel: FormGroup;
    public isLoading: boolean = false;
    public error: boolean = false;

    public channelCode: string;

    constructor (
        private _eventService: AgencyEventService,
        private formBuilder: FormBuilder,
        public _modal: NgbModal,
    ) { }

    ngOnInit() {
        this.formChannel = this.formBuilder.group({
            channelCode: ['', Validators.required]
        });
    }

    public findChannel() {

        this.isLoading = true;
        this.error = false;
        this.channel = null;

        this.channelCode = this.formChannel.get("channelCode").value;

        this._eventService.getAgencyEventChannel(this.event.uuid, this.channelCode).subscribe((channel) => {
            this.channel = channel;
            this.formChannel.get('channelCode').disable();
            this.isLoading = false;
        },
        (error) => {
            console.log(error);
            this.error = true;
            this.isLoading = false;
        },
        () => {
            this.isLoading = false;
        });

    }

    public removeChannel() {
        this.channel = null;
        this.channelCode = null;
        this.formChannel.get('channelCode').setValue(null);
        this.formChannel.get('channelCode').enable();
    }

    public buyTickets(channel: EventStoreChannel) {
        const modalRef = this._modal.open(TicketSelectionModalComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.event = this.event;
        modalRef.componentInstance.channel = channel;
        modalRef.componentInstance.ticketPrices = channel.prices;
    }

    public buyReservedTickets(channel: EventStoreChannel) {
        const modalRef = this._modal.open(ReservedTicketSelectionModalComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.event = this.event;
        modalRef.componentInstance.channel = channel;
        modalRef.componentInstance.ticketPrices = channel.prices;
    }

}
