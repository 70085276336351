<div class="default" [ngStyle]="getGradient()" *ngIf="agency">
    <div class="text text-center col-12">
        <div class="row">
            <div class="col-12">
                <div class="ticket-header-text text-uppercase d-inline-block">
                    <h3>{{ agency.name }}</h3>
                    <h4 *ngIf="agency.nameMascot">{{ agency.nameMascot }}</h4>
                    <h4 *ngIf="!agency.nameMascot">Events</h4>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="logo" *ngIf="agency.settings?.logoURI" [ngStyle]="{'background-image': 'url(' + agency.settings?.logoURI + ')'}"></div> -->

