import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, Logger } from '@app/core';
import { environment } from '@env/environment';

const log = new Logger('ForgotPassword');

interface ForgotPasswordContext {
    email: string;
    password: string;
    passwordConfirm: string;
}

@Component({
    selector: 'app-auth-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    public error: string;
    public isLoading = false;
    public returnParam: string = '';
    public selected = 0;

    public forgotPasswordContext: ForgotPasswordContext = {
        email: '',
        password: '',
        passwordConfirm: '',
    };

    private verificationCode: string = '';
    private verification_confirmation: string = '';

    constructor(
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => this.returnParam = params['return'] || '/home');
    }

    public getWidth(): string {
        const fraction = 1 / 4 * 100;
        return `${(this.selected * fraction) + fraction}%`;
    }

    public updateSelected(value: number) {
        this.selected = value;
    }

    public generateVerificationCode(value: {
        email: string
    }) {
        this.isLoading = true;
        this.error = null;
        this.forgotPasswordContext.email = value.email;
        this.authenticationService.generateVerificationCodeForPasswordReset(this.forgotPasswordContext.email).subscribe((response) => {
            if (response.success) {
                this.updateSelected(1);
                this.isLoading = false;
            } else {
                log.debug(`Forgot Password error: ${response.message}`);
                this.error = response.message;
                this.isLoading = false;
            }
        })
    }

    public resendCode() {
        this.generateVerificationCode({email: this.forgotPasswordContext.email});
    }

    public sendVerificationCode(
        code: string
    ) {
        this.isLoading = true;
        this.error = null;
        this.verificationCode = code;
        this.authenticationService.verifyVerificationCodeForPasswordReset(this.forgotPasswordContext.email, this.verificationCode).subscribe((response) => {
            if (response.success) {
                this.verification_confirmation = response.confirmation_code;
                this.updateSelected(2);
                this.isLoading = false;
            } else {
                log.debug(`Forgot Password error: ${response.message}`);
                this.error = response.message;
                this.isLoading = false;
            }
        });
    }

    public setPassword() {

        this.isLoading = true;
        this.error = null;
        this.authenticationService.resetPasswordWithVerification(this.verification_confirmation, this.forgotPasswordContext.email, this.forgotPasswordContext.password).subscribe((response) => {
            if (response.success) {
                this.updateSelected(3);
                this.isLoading = false;
            } else {
                log.debug(`Forgot Password error: ${response.message}`);
                this.error = response.message;
                this.isLoading = false;
            }
        });

    }

    public updatePassword(value: {
        password: string,
        passwordConfirm: string
    }) {
        this.forgotPasswordContext.password = value.password;
        this.forgotPasswordContext.passwordConfirm = value.passwordConfirm;
        this.setPassword();
    }

}
