<div class="align-items-center mx-0 my-2 pricing py-2 b-0" *ngFor="let group of priceGroup">
    <div class="price title">{{ group.ticketLevel.title }}</div>
    <div class="description pb-3" *ngIf="group.ticketLevel.description">{{ group.ticketLevel.description }}</div>
    <div class="row align-items-center mx-0 my-2 pricing pb-2" *ngFor="let price of group.prices">
        <div class="col-8 text-left p-0">
            <div class="price title">{{ price.displayName }}</div>
            <div class="discount-message" *ngIf="price.hasDiscount()">Discount valid until: {{ price.dateEnd | date: 'M/dd/yyyy @ h:mm a' }}</div>
        </div>
        <div class="col-4 text-right p-0">
            <div class="price discount" [ngClass]="{'has-discount': price.hasDiscount()}" *ngIf="price.hasDiscount()"><span *ngIf="price.priceAmount > 0">{{ price.priceAmount | currency }}</span><span *ngIf="price.priceAmount === 0">FREE</span></div>
            <div class="price" [ngClass]="{'has-discount': price.hasDiscount()}" ><div></div><span *ngIf="price.priceAmountOriginal > 0">{{ price.priceAmountOriginal | currency }}</span><span *ngIf="price.priceAmountOriginal === 0">FREE</span></div>
        </div>
    </div>
</div>