import * as _ from 'lodash';
import { ReservedSeat } from "../reserved/seat.model";
import { TicketLevel } from "../ticket-level.model";
import { Event } from "../event.model";
import { CartItem, CartItemSummary } from "./cart-item.model";
import { CartItemTypes } from "./cart-item-types.enum";
import { TicketPrice } from '../ticket-price.model';
import { EventStoreChannel } from '../events/event-store-channel.model';

export class CartItemEvent extends CartItem {

    constructor (event: Event, price: TicketPrice, channel?: EventStoreChannel) {

        super();

        this.itemType = CartItemTypes.event;
        this.product = event;
        this.ticketPrice = price;
        this.price = price.priceAmount;
        this.channel = channel;

    }

}

export class CartItemEventReserved extends CartItem {

    constructor (event: Event, seat: ReservedSeat, price: TicketPrice, channel?: EventStoreChannel) {

        super();

        this.itemType = CartItemTypes.event;
        this.product = event;
        this.seat = seat;
        this.ticketPrice = price;
        this.price = price.priceAmount;
        this.channel = channel;

    }

}

export class CartItemEventSummary extends CartItemSummary {

    public override product: Event;

    constructor(event: Event, items: CartItemEvent[]) {

        super(event, items);

    }

}