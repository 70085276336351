import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Transfer } from '@app/data/models/transfer.model';
import { TransferUser } from '@app/data/models/transfer-user.model';
import { UserAdmissionService } from './user-admission.service';

@Injectable()
export class TransferService {

    public basePath: string = 'fans/transfers'

    public currentTransfers = {
        incoming: new Array<Transfer>(),
        outgoing: new Array<Transfer>()
    };

    public history: Transfer[] = new Array<Transfer>();

    constructor (
        private _http: HttpClient
    ) { }

    /**
     * returns a list of Transfer objects
     * 
     * @returns Observable array of Transfer objects
     */
    getTransfers(): Observable<any> {
        return this._http.get(this.basePath).pipe (
            map ( (transfers:any) => {
                this.currentTransfers.incoming = _.orderBy(transfers.incoming, ['dateInitiated'], ['desc']).map((transfer) => new Transfer().deserialize(transfer));
                this.currentTransfers.outgoing = _.orderBy(transfers.outgoing, ['dateInitiated'], ['desc']).map((transfer) => new Transfer().deserialize(transfer));
                return this.currentTransfers;
            })
        )
    }

    hasCurrentTransfers(): boolean {
        return (this.currentTransfers.incoming.length + this.currentTransfers.outgoing.length) > 0;
    }

    /**
     * returns the number of the current incoming transfers
     * 
     * @returns number - count of transfers
     *  
     */
    getIncomingTransferCount(): number {
        return this.currentTransfers.incoming.length;
    }

    /**
     * returns a list of Transfer objects
     * 
     * @returns Observable array of Transfer objects
     */
    getTransferHistory(): Observable<Transfer[]> {
        const url = `${this.basePath}/history`;
        return this._http.get<Transfer[]>(url).pipe (
            map( (transfers) => this.history = _.orderBy(transfers.filter((transfer) => transfer.status !== 'Pending'), ['dateInitiated'], ['desc']).map((transfer) => new Transfer().deserialize(transfer)) ) 
        )
    }

    /**
     * returns a list of Transfer objects
     * 
     * @returns Observable array of Transfer objects
     */
    getTransferUsers(): Observable<TransferUser[]> {
        const url = `${this.basePath}/users`;
        return this._http.get<TransferUser[]>(url).pipe (
            map ( (users) => _.orderBy(users, ['name']).map((user) => new TransferUser().deserialize(user)) ) 
        )
    }

    initiateTransfer(ids: number[], email: string, productType: string) {

        const url = `${this.basePath}`;
        var share = {
            product: productType,
            tickets: ids,
            email: email
        };
        return this._http.post(url, share);
    }

    updateTransfer(transfer: Transfer, response: string): Observable<any> {
        const url = `${this.basePath}`;
        return this._http.put(url, {transfer: transfer, response: response}, {responseType: 'text'});
    }

    acceptTransfer(transfer: Transfer): Observable<any> {
        return this.updateTransfer(transfer, "ACCEPT").pipe(
            switchMap(() => this.getTransfers())
        );
    }

    declineTransfer(transfer: Transfer): Observable<any> {
        return this.updateTransfer(transfer, "REJECT").pipe(
            switchMap(() => this.getTransfers())
        );
    }

    cancelTransfer(transfer: Transfer): Observable<any> {
        const url = `${this.basePath}/cancel`;
        return this._http.put(url, {id: transfer.id}, {responseType: 'text'}).pipe(
            switchMap(() => this.getTransfers())
        );
    }

    resendTransfer(transfer: Transfer): Observable<any> {
        const url = `${this.basePath}/resend`;
        return this._http.put(url, {id: transfer.id}, {responseType: 'text'});
    }


}