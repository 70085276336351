import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core';


@Component({
    selector: 'app-auth-email',
    templateUrl: './email.component.html'
})
export class EmailComponent {

    @Input() public isLoading: boolean = false;

    @Output()
    public generateVerificationCode = new EventEmitter<{
        email: string
    }>();
    forgotPasswordForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
    ) {
        this.createForm();
    }

    private createForm() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', Validators.required],
        });
        if (this.authenticationService.credentials) {
            this.forgotPasswordForm.setValue({ email: null });
        }
    }

    public postEmail() {
        this.generateVerificationCode.emit(this.forgotPasswordForm.value);
    }

}
