import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Route } from '@app/core';

const routes: Routes = [
  Route.withShell([
    { path:'r/agency/:agency-id/events/:event-id',redirectTo: 'agency/:agency-id/events/:event-id', pathMatch: 'full' },
    { path:'r/agency/:agency-id',redirectTo: 'agency/:agency-id', pathMatch: 'full' },
    { path: 'agency', loadChildren: ()=>  import('./agency/agency.module').then(m => m.AgencyModule) },
    { path: 'tickets', loadChildren: ()=>  import('./tickets/tickets.module').then(m => m.TicketsModule) },
    { path: 'passes', loadChildren: ()=>  import('./passes/passes.module').then(m => m.PassesModule) },
    { path: 'account', loadChildren: ()=>  import('./account/account.module').then(m => m.AccountModule) }, 
    { path: 'auth', loadChildren: ()=>  import('./auth/auth.module').then(m => m.AuthModule) },
    { path: 'cart', loadChildren: ()=>  import('./cart/cart.module').then(m => m.CartModule) }
  ]),
    
  // old urls from v1
  { path: 'app/school/:uuid', redirectTo: '/agency/:uuid'},
  { path: 'app/school/:uuid/events/:eventid', redirectTo: '/agency/:uuid/events/:eventid'},
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
