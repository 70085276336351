<div *ngIf="cart.isEmpty()" class=" bg-white p-3">
    <div class="empty-cart text-center" style="margin:50px auto;">
        <h2 class="border-0">Your Cart Is Empty.</h2>
        <button mdbRippleRadius class="btn btn-secondary" [routerLink]="homeRoute">Find An Event</button>
    </div>
</div>
<div *ngIf="!cart.isEmpty()" class="bg-white p-3">
    <ts-section-header>Selection Summary</ts-section-header>
    <div class="alert alert-info font-sm text-center">
        Please double-check your purchase and confirm the tickets are for the desired event(s), as all sales are final.
    </div>
    <div class="mb-3">
        <div style="position:relative;" *ngFor="let item of events">
            <ts-cart-ticket [summary]="item" (remove)="remove($event)" (update)="update($event)"></ts-cart-ticket>
        </div>
        <div *ngFor="let item of passes">
            <ts-cart-pass [summary]="item" (remove)="remove($event)" (update)="update($event)"></ts-cart-pass>
        </div>
    </div>
    <!-- PROMOTION CODE -->
    <div class="row my-3 py-3 mx-0 promo-code" style="border-bottom: 1px solid #717171;">
        <div class="col-12 col-md-7 px-2">
            <form (ngSubmit)="checkPromoCode()" [formGroup]="promoCodeForm" novalidate style="border:none;">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="promoCode" autocomplete="promoCode"
                        [placeholder]="'Enter Promo Code'" />
                    <div class="input-group-append">
                        <button mdbRippleRadius class="btn btn-primary m-0 w-sm" type="submit"
                            [disabled]="promoCodeForm.invalid || isLoading">
                            <span class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2"
                                    style="display:inline-block;"></i>Applying</span>
                            <span class="m-0" [hidden]="isLoading">Apply</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 col-md-5 text-right px-2 summary-options"
            *ngIf="cart.hasPromoCode() && cart.promotionCode.isValid && !isLoading">
            <div class="mb-2 pr-0">
                <div class="text-center d-inline-block mr-2" style="cursor:pointer;"
                    (click)="showPromotionTerms(promotionTermsModal)">
                    <i class="ml-2 fa fa-question-circle-o"></i>
                </div>Promo Code: <strong>{{ cart.promotionCode.code }}</strong>
                <div class="font-sm text-muted">{{cart.promotionCode.promotion?.name}}</div>
            </div>
        </div>
        <div class="alert alert-danger mx-2 w-100 font-sm" *ngIf="promoCode.message && !isLoading">
            <strong>{{promoCode.code}}</strong>: {{promoCode.message}}
        </div>
    </div>
    <!-- CART TOTALS -->
    <div class="row my-3 pt-3 mx-0 summary-options">
        <div class="col-12 text-right px-2">
            <div class="mb-2 pr-0" *ngIf="cart.getTotalFees() > 0">Convenience Fee:
                <strong>{{cart.getTotalFees() | currency }}</strong>
            </div>
            <div class="mb-2 pr-0" *ngIf="cart.hasOtherFees()">{{cart.other.name}}:
                <strong>{{cart.other.amount | currency }}</strong>
            </div>
            <div class="mb-2 pr-0">Total Amount: <strong>{{cart.getTotalPrice() | currency }}</strong></div>
        </div>
    </div>
    <div class="row mt-5 mb-3 text-center">
        <div class="col-6 px-2">
            <button mdbRippleRadius class="btn btn-outline-danger btn-block px-5 my-2 mx-auto" (click)="clearCart()">
                <i class="fa fa-cart-arrow-down mr-2"></i>Cancel
            </button>
        </div>
        <div class="col-6 px-2">
            <button mdbRippleRadius class="btn btn-secondary btn-block px-5 my-2 mx-auto" (click)="checkoutOption()"
                [disabled]="cart.isEmpty()">
                <i class="fa fa-credit-card mr-2"></i>Checkout
            </button>
        </div>
    </div>
</div>
<ng-template #promotionTermsModal let-c="close" let-d="dismiss">
    <button mdbRippleRadius type="button" class="close"
        style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close"
        (click)="d('Cross click')">
        <span style="font-size:3rem;font-weight:300;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body mb-2">
        <ts-section-header>Promo Code Terms and Conditions</ts-section-header>
        <div class="font-sm">
            {{cart.promotionCode.promotion?.message}}
        </div>
    </div>
    <div class="text-center modal-close-button">
        <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;"
            (click)="d('Cross click')">
            Close
        </button>
    </div>
</ng-template>
