import * as moment from 'moment';

export const expTime = 10;
export class CartExpiration {

    // number of minutes that the cart expires
    public _minutes: number =  expTime;

    public extensionMinutes: number = 5;
    public extensionCount: number = 0;
    public extensionMinMinutes: number = 5;
    public extensionMaxCount: number = 1;

    public dateAdded: Date;
    public dateExpiration: Date;

    public constructor() {

    }

    public start(dateAdded?: Date) {
        this.dateAdded = new Date();
        if (dateAdded != null) {
            this.dateAdded = moment(dateAdded).toDate();
        }
        this.dateExpiration = moment(this.dateAdded).add(this._minutes, 'minute').toDate();
        
    }

    public stop() {
        this.dateAdded = null;
        this.dateExpiration = null;
    }

    public canExtend(): boolean {
        const mins: number = moment(this.dateExpiration).diff(moment(), 'minutes');
        return (this.extensionCount < this.extensionMaxCount) && (mins < this.extensionMinMinutes);
    }

    public extendExpiration() {
        if (this.canExtend()) {
            this.extensionCount++;
            this.dateExpiration = moment(this.dateExpiration).add(this.extensionMinutes, 'minute').toDate();
        }
    }

    /**
     * checks to see if the Cart has expired
     * 
     */
    public isExpired(): boolean {

        if (this.dateAdded) {
            return moment().isAfter(this.dateExpiration);
        }
        return true;

    }

}
