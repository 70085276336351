import { Component, Input } from '@angular/core';
import { Event } from '@app/data/models/event.model';

@Component({
    selector: 'ts-ticket-event-header',
    templateUrl: './ticket-event.component.html',
    styleUrls: ['./ticket-event.component.scss']
})
export class TicketEventHeaderComponent {
    
    @Input('event') event: Event;

    constructor (
    ) { }

}
