import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, AuthenticationService } from '@app/core';
import { strongPasswordValidator, confirmPasswordValidator } from '@app/shared/ticket-spicket/validators';

const log = new Logger('ChangePasswordComponent');

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    version: string = environment.version;
    error: string;
    passwordForm: FormGroup;
    isLoading = false;
    message: string;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService
    ) {
        this.createForm();
        if (authenticationService.credentials) {
            this.passwordForm.setValue({ email: null });
        }
    }

    ngOnInit() {}

    changePassword() {

        this.isLoading = true;
        this.message = null;
        this.error = null;

        this.authenticationService
            .changePassword(this.passwordForm.value)
            .pipe(
                finalize(() => {
                    this.passwordForm.markAsPristine();
                    this.isLoading = false;
                })
        )
        .subscribe(
            message => {
                this.message = message;
                this.router.navigate(['/'], { replaceUrl: true });
            },
            error => {
                log.debug(`Change Password error: ${error}`);
                this.error = 'An error occurred while trying to change your password, please try again.';
            }
        );
    }

    private createForm() {
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, strongPasswordValidator()]],
            confirmPassword: ['', Validators.required]
        });
        this.passwordForm.setValidators(confirmPasswordValidator( this.passwordForm.get('password'), this.passwordForm.get('confirmPassword')));
    }

}
