import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { Agency } from '@app/data/models/agency.model';
import { CartService } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { CartItemCollection } from '@app/data/models/cart/cart-item-collection.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';

@Component({
    selector: 'app-pass-ticket-selection-modal',
    templateUrl: './select-tickets.component.html'
  })
  export class PassSelectionModalComponent implements OnInit {

    @Input() product: GatePass;

    public agency: Agency;
    public loading: boolean = false;

    public selectedQty: number = 0;

    public cartItems: CartItemCollection = new CartItemCollection();

    public ticketPrices: TicketPrice[] = new Array<TicketPrice>();

    constructor (
        public activeModal: NgbActiveModal,
        private _cartService: CartService,
        private _messageService: MessageService,
        private _router: Router
    ) { }

    ngOnInit() {
        this.agency = this.product.agency;
        this.ticketPrices = this.product.getGATicketPrices();
        this.selectedQty = this.product.ticketQuantity;

        if (this.product.isFamilyPass) {
            this.ticketPrices.map((price) => this.addTicket(price));
        }
    }

    public updateSelectedQty(value: number) {
        this.selectedQty += value;
        this.cartItems.items.map((item) => item.selectedQty = this.selectedQty)
    }

    public addTicket(price: TicketPrice) {
        this.cartItems.addPassItem(this.product, price, this.selectedQty);
    }

    public removeTicket(price: TicketPrice) {
        this.cartItems.removeItem(this.product, price);
    }

    public addToCart() {
        this._cartService.addItems(this.cartItems);
        this._messageService.toast.success('Your tickets have been added to the cart.');
        this.activeModal.close('cart');
    }

    public checkout() {
        this._cartService.addItems(this.cartItems);
        this._router.navigate([this._cartService.cartRoute]);
        this.activeModal.close('checkout');
    }

    public close() {
        this.activeModal.dismiss(false);
    }

}
