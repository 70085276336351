<div *ngIf="agency" class="agency-footer">
    <div class="container">
        <div class="row py-3 py-lg-0">
            <div class="col-12 col-md-6 py-3 py-md-5 text-center text-md-left">
                <h5 class="h5-responsive b-0 text-uppercase">
                    {{ agency.name }}
                </h5>
                <div class="font-sm my-2">
                    <div *ngIf="!mobileService.isCapacitor">
                        <a [href]="this.agency.primaryAddress.mapString" target="_blank" style="text-decoration:underline;" [ngStyle]="{'color': agency.settings?.secondaryText}" >
                            <i class="fa fa-map-marker mr-1"></i> {{ agency.primaryAddress?.formatted }}
                        </a>
                    </div>
                    <div *ngIf="mobileService.isCapacitor"  style="text-decoration:underline;" [ngStyle]="{'color': agency.settings?.secondaryText}" >
                        <a (click)="mobileService.openURL(this.agency.primaryAddress.mapString)" target="_blank">
                            <i class="fa fa-map-marker mr-1"></i> {{ agency.primaryAddress?.formatted }}
                        </a>
                    </div>
                </div>
                <p *ngIf="agency.website && !mobileService.isCapacitor" class="font-sm">
                    <a [href]="agency.website" target="_blank" style="text-decoration:underline;" [ngStyle]="{'color': agency.settings?.secondaryText}" >
                        <span *ngIf="agency.vanityURI"><i class="fa fa-globe mr-1"></i>{{ agency.vanityURI }}</span>
                        <span *ngIf="!agency.vanityURI"><i class="fa fa-globe mr-1"></i>{{ agency.name }} website</span>
                    </a>
                </p>
                <p *ngIf="agency.website && mobileService.isCapacitor" class="font-sm">
                    <a (click)="mobileService.openURL(agency.website)" style="text-decoration:underline;" [ngStyle]="{'color': agency.settings?.secondaryText}" >
                        <span *ngIf="agency.vanityURI">{{ agency.vanityURI }}</span>
                        <span *ngIf="!agency.vanityURI">{{ agency.name }} website</span>
                    </a>
                </p>
                <div *ngIf="!mobileService.isCapacitor" class="social-icons">
                    <a [href]="agency.settings?.facebookURI" target="_blank" *ngIf="agency.settings?.facebookURI"><i class="fa fa-facebook ml-1 mr-2"></i></a>
                    <a [href]="agency.settings?.twitterURI" target="_blank" *ngIf="agency.settings?.twitterURI"><i class="fa fa-twitter mr-2"></i></a>
                    <a [href]="agency.settings?.instagramURI" target="_blank" *ngIf="agency.settings?.instagramURI"><i class="fa fa-instagram mr-2"></i></a>
                </div>
                <div *ngIf="mobileService.isCapacitor" class="social-icons">
                    <a (click)="mobileService.openURL(agency.settings?.facebookURI)" *ngIf="agency.settings?.facebookURI"><i class="fa fa-facebook ml-1 mr-2"></i></a>
                    <a (click)="mobileService.openURL(agency.settings?.twitterURI)" *ngIf="agency.settings?.twitterURI"><i class="fa fa-twitter mr-2"></i></a>
                    <a (click)="mobileService.openURL(agency.settings?.instagramURI)" *ngIf="agency.settings?.instagramURI"><i class="fa fa-instagram mr-2"></i></a>
                </div>
            </div>
            <div class="col-12 col-md-6 py-3 py-md-5 text-center text-md-left" *ngIf="user?.uuid">
                <h5 class="h5-responsive b-0 text-uppercase">
                    Want To Receive The Latest Updates?
                </h5>
                <p class="font-sm">
                    You can get the latest details about your favorite events just by adding this organization to your Teams. One click and done.
                </p>
                <button class="btn mt-3" [ngClass]="{'btn-primary' : !isFollowing() , 'btn-success' : isFollowing() }" (click)="toggleFollowing()" [disabled]="agency.getType() !== 'agency'">
                    <i class="fa mr-2" [ngClass]="{'fa-thumbs-up' : !isFollowing() , 'fa-check' : isFollowing() }"></i>
                    <span *ngIf="!isFollowing()">Add to My Teams</span>
                    <span *ngIf="isFollowing()">Added</span>
                </button>
            </div>
        </div>
    </div>
</div>
