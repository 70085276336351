import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';
import { TicketLevel } from "@app/data/models/ticket-level.model";

export class TicketPrice implements IDeserializable {

    id: number;
    ticketType: TicketLevel;
    addedCount : number;

    available: number;
    capacity: number;
    priceAmount: number;
    discountAmount: number;
    discountPercent: number;
    nextAmount: number;

    optionQuantity: number;
    optionName: string;

    dateStart: Date;
    dateEnd: Date;

    // this is used by the cart/checkout process
    quantity: number = 0;
    itemTotalAmount: number = 0;

    passTicket: boolean;
    overallAvailable: number = 0;

    // amount: number;

    set priceAmountOriginal(val: number) {}

    get priceAmountOriginal(): number {
        return this.priceAmount + this.discountAmount;
    }

    get displayName(): string {
        if (this.optionName) {
           return this.optionName;
        }
        if (this.optionQuantity) {
            //return `${this.ticketType.title} - ${this.optionQuantity} tickets`
            return `${this.optionQuantity} tickets`;
        }
        return this.ticketType.title;
    }

    get hasAvailability(): boolean {
        const ticket = this.capacity === 0 || (this.capacity > 0 && this.available > 0);
        const overall = (this.passTicket && this.overallAvailable === -1) || this.overallAvailable > 0;
        return ticket && overall;
    }

    get calulatedAvailable(): number {
      let overall = this.overallAvailable;
      if (this.overallAvailable === -1) {
          overall = 9999;
      }
      return this.capacity > 0 ? Math.min(overall, this.available) : overall;
    }

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        this.ticketType = new TicketLevel().deserialize(input.ticketType);

        if (input.dateStart)
            this.dateStart = moment(input.dateStart).toDate();

        if (input.dateEnd)
            this.dateEnd = moment(input.dateEnd).toDate();

        return this;
    }

    public hasDiscount(): boolean {
        return this.discountAmount != 0;
    }

    public isReservedPrice(): boolean {
        return this.ticketType.isReserved;
    }

}
