import { GatePass } from "@app/data/models/passes/gate-pass.model";
import { ConsumerGatePass } from "@app/data/models/passes/consumer-gate-pass.model";
import { ClaimableEvent } from "./claimable-events.model";
import { Event } from "../event.model";
import { IAdmission, AdmissionActions, AdmissionSource } from "../tickets/admission.interface";
import * as _ from 'lodash';
import { TicketLevel } from "../ticket-level.model";

export class ConsumerPunchPassLevel {

    public ticketType: TicketLevel;
    public remainingUses: number = 0;
    public selectedQty: number = 0;

    constructor(type: TicketLevel, remainingUses: number) {
        this.ticketType = type;
        this.remainingUses = remainingUses;
    }

    public isMaxedOut(): boolean {
        return this.remainingUses === this.selectedQty;
    }

    public updateQty(count: number) {
        this.selectedQty += count;
    }

}

export class ConsumerPunchPass {

    public levels: ConsumerPunchPassLevel[] = new Array<ConsumerPunchPassLevel>();

    constructor() {}

    private _getLevelIndex(type: TicketLevel) {
        return this.levels.findIndex((level) => level.ticketType.id === type.id);
    }

    private _hasLevel(level: TicketLevel) {
        return this._getLevelIndex(level) > -1
    }

    public getTotalSelectedQty(): number {
        let total: number = _.sumBy(this.levels, function(level) { return level.selectedQty}) | 0;
        return total;
    }

    public addConsumerGatePass(pass: ConsumerGatePass) {

        if (this._hasLevel(pass.level)) {
            this.levels[this._getLevelIndex(pass.level)].remainingUses += pass.remainingUses;
        } else {
            this.levels.push(new ConsumerPunchPassLevel(pass.level, pass.remainingUses));
        }

    }

}