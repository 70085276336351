import { Component, OnInit } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-agency-events',
  templateUrl: './listing.component.html'
})
export class AgencyEventsComponent implements OnInit {
    public showEventList: boolean = true;
    public noResultsMessage = 'events at this time';

    constructor (
        private _agencyService: AgencyService,
        private _spinner: SpinnerService,
    ) {}

    ngOnInit() {
        this._agencyService.getEvents(this.agency.uuid).subscribe(() => {
            this._spinner.hide();
        });
    }

    get agency(): Agency {
        return this._agencyService.agency;
    }

}
