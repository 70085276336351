<header>
  <nav class="navbar navbar-expand-lg navbar-dark text-uppercase mb-0" style="background-color:#004080;">
    <div class="header-margins">
      <div class="agencyLogo pointer d-none d-lg-inline-block float-left" (click)="goToAgency()" *ngIf="isAgencyPage && _agencyService.agency.settings?.logoURI"></div>
      <div class="agencyLogo pointer logo default d-none d-lg-inline-block float-left" (click)="goToAgency()" *ngIf="isAgencyPage && !_agencyService.agency.settings?.logoURI">{{ _agencyService.agencyName | limitTo: 1 }}</div>
      <button class="navbar-toggler ml-auto float-right" type="button" aria-controls="navbar-menu"
              aria-label="Toggle navigation" (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbar-menu" class="collapse navbar-collapse float-xs-none text-right" [ngbCollapse]="menuHidden">
        <div class="navbar-nav d-none d-lg-inline text-left" *ngIf="isAgencyPage" style="padding-left:112px;">
        </div>
        <div class="navbar-nav ml-auto navbar-mobile-margin-right font-sm">
          <a class="nav-item nav-link" routerLink="/home" routerLinkActive="active" (click)="toggleMenu()">
            <i class="fa fa-search mr-1"></i>
            <span>Search</span>
          </a>
          <a class="nav-item nav-link" routerLink="/tickets" routerLinkActive="active" (click)="toggleMenu()">
            <i class="fas fa-ticket-alt mr-1" data-fa-transform="rotate-45"></i>
            <span [matBadge]="ticketBadgeCount" [matBadgeHidden]="ticketBadgeCount == 0" matBadgeColor="danger">My Tickets</span>
          </a>
          <a class="nav-item nav-link" routerLink="/passes" routerLinkActive="active" (click)="toggleMenu()">
            <i class="fa fa-tags mr-1"></i>
            <span>My Passes</span>
          </a>
          <a class="nav-item nav-link" routerLink="/favorites" routerLinkActive="active" (click)="toggleMenu()">
            <i class="fa fa-thumbs-up mr-1"></i>
            <span>My Teams</span>
          </a>
          <a class="nav-item nav-link" routerLink="/auth/login" routerLinkActive="active" (click)="toggleMenu()" *ngIf="!isAuthenticated()">
            <i class="fa fa-user mr-1"></i>
            <span>Login</span>
          </a>
          <div class="nav-item" ngbDropdown placement="bottom-right" *ngIf="isAuthenticated()" >
            <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
                <i class="fa fa-user mr-1"></i>
                <span [matBadge]="unreadCount" [matBadgeHidden]="unreadCount == 0" matBadgeColor="danger">Hi, {{ firstname }}</span>
            </a>
            <div ngbDropdownMenu aria-labelledby="user-dropdown" class="font-sm" style="min-width: 200px;">
                <h6 class="dropdown-header">
                    <b>{{ getUsername() }}</b>
                </h6>
                <div class="d-none d-lg-block dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/account" (click)="toggleMenu()">
                    <i class="fa fa-user fa-fw mr-1"></i>
                    Profile
                </a>
                <a class="dropdown-item" routerLink="/account/invite" (click)="toggleMenu()">
                    <i class="fa fa-share-alt fa-fw mr-1"></i>
                    Invites
                </a>
                <a class="dropdown-item" routerLink="/account/payments" (click)="toggleMenu()">
                    <i class="fa fa-credit-card fa-fw mr-1"></i>
                    Payments
                </a>
                <a class="dropdown-item" routerLink="/account/payment-methods" (click)="toggleMenu()">
                    <i class="fa fa-credit-card fa-fw mr-1"></i>
                    Payment Methods
                </a>
                <a class="dropdown-item" routerLink="/account/rewards" (click)="toggleMenu()">
                    <i class="fa fa-gift fa-fw mr-1"></i>
                    Rewards
                </a>
                <a class="dropdown-item" routerLink="/account/transfers" (click)="toggleMenu()">
                    <i class="fa fa-paper-plane fa-fw mr-1"></i>
                    Transfer History
                </a>
                <div class="d-none d-lg-block dropdown-divider"></div>
                <a class="dropdown-item" href (click)="logout()">
                    <i class="fa fa-sign-out fa-fw mr-1"></i>
                    Logout
                </a>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </nav>
</header>
