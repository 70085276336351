<form (ngSubmit)="postUser()" [formGroup]="signUpForm" novalidate autocomplete="on">
    <div class="form-group">
        <label class="d-block my-3">
            <input type="text" class="form-control" formControlName="nameFirst" autocomplete="given-name" [placeholder]="'First Name'"/>
            <span hidden >First Name</span>
            <small [hidden]="signUpForm.get('nameFirst').valid || signUpForm.get('nameFirst').untouched" class="text-danger" >
            First Name is required
            </small>
        </label>
        <label class="d-block mb-3">
            <input type="text" class="form-control" formControlName="nameLast" autocomplete="family-name" [placeholder]="'Last Name'"/>
            <span hidden >Last Name</span>
            <small [hidden]="signUpForm.get('nameLast').valid || signUpForm.get('nameLast').untouched" class="text-danger" >
            Last Name is required
            </small>
        </label>
        <label class="d-block mb-3">
            <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'" />
            <span hidden >Email</span>
            <div [hidden]="signUpForm.get('email').untouched" *ngIf="signUpForm.get('email').invalid" class="text-danger" style="font-size: .8em">
                <div *ngIf="signUpForm.get('email').errors?.required">Email is required</div>
                <div *ngIf="signUpForm.get('email').errors?.email">Email must be a valid email format</div>
            </div>
        </label>
    </div>
    <div class="text-center m-3 p-2 font-sm">By creating an account, I consent to Ticket Spicket's <a rel="noopener" href="http://www.ticketspicket.com/terms-and-conditions/" target="_blank">Terms of Use</a> and <a rel="noopener" href="http://www.ticketspicket.com/privacy-policy/" target="_blank">Privacy Policy</a>, and to receive email from Ticket Spicket.</div>
    <button mdbRippleRadius class="btn btn-primary w-100 m-0" type="submit" [disabled]="signUpForm.pristine || signUpForm.invalid || isLoading">
        <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
        <h6 class="m-0" [hidden]="isLoading">Sign Up</h6>
    </button>
    <p class="text-center mx-2 my-4">
        <strong>Already have an account?</strong>
    </p>
    <button type="button" mdbRippleRadius class="btn btn-outline-primary w-100 m-0 mb-4" [routerLink]="['/auth', 'login']">
        <h6 class="m-0">Login</h6>
    </button>
</form>