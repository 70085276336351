export enum AdmissionActions {
    buy = "buy",
    view = "view",
    print = "print",
    transfer = "transfer",
    accept = "accept",
    decline = "decline",
    claim = "claim",
    punch = "punch",
    cancel = "cancel",
    claimAll = "claimAll"
}

export enum AdmissionStatus {
    gates = "gates",
    redeemed = "redeemed",
    transferred = "transferred",
    claimable = "claimable",
    ready = "ready",
    cancel = "cancel"
}

export enum AdmissionStatusIcon {
    gates = "fa-clock-o",
    redeemed = "fa-check",
    transferred = "fa-paper-plane",
    claimable = "claimable",
    ready = "ready",
    cancel = "cancel"
}

export enum AdmissionSource {
    ticket = "ticket",
    pass = "pass",
    transfer = "transfer"
}

export interface IAdmission {

    source: string;

    // getStatus(): string;
    getActions(): string[];

}
