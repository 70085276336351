import { IAdmission, AdmissionSource, AdmissionStatus, AdmissionStatusIcon } from "./admission.interface";
import { Event } from "../event.model";
import * as _ from 'lodash';
import { Ticket } from "@app/data/models/ticket.model";
import { ConsumerPass } from "@app/data/models/passes/consumer-pass.model";
import { ConsumerPunchPass } from "../passes/consumer-punch-pass.model";

export class EventAdmission {

    public event: Event;
    public admission: Array<IAdmission> = new Array<IAdmission>();

    constructor (event: Event) {
        this.event = event;
    }

    public setAdmission(admission: IAdmission[]) {
        this.admission = admission;
        return this;
    }

    private _get(source: string): IAdmission[] {
        return this.admission.filter((admission) => admission.source == source);
    }

    private _has(source: string): boolean {
        return this._get(source).length > 0;
    }

    public getTickets(): Ticket[] {
        return <Ticket[]> this._get(AdmissionSource.ticket);
    }

    public getAvailableTickets(): Ticket[] {
        return this.getTickets().filter((ticket) => !ticket.transferred)
    }

    public getUnusedTickets(): Ticket[] {
        return _.sortBy(this.getAvailableTickets().filter((ticket) => ticket.dateRedeemed === null),  function (ticket) {
            return ticket.level.rank;
        })
    }

    public getUsedTickets(): Ticket[] {
        return _.orderBy(this.getAvailableTickets().filter((ticket) => ticket.dateRedeemed !== null), ['dateRedeemed'], ['desc']);
    }

    public hasUnusedTickets(): boolean {
        return this.getUnusedTickets().length > 0
    }

    public getTransferredTickets(): Ticket[] {
        return this.getTickets().filter((ticket) => ticket.transferred)
    }

    public hasTickets(): boolean {
        return this._has(AdmissionSource.ticket);
    }

    public getPasses(): ConsumerPass[] {
        return <ConsumerPass[]> this._get(AdmissionSource.pass);
    }

    public getAccessPasses(): ConsumerPass[] {
        return this.getPasses().filter((pass) => !pass.gatePass.isPunchPass);
    }

    public getPunchPass(): ConsumerPunchPass {
        let punchPass: ConsumerPunchPass = new ConsumerPunchPass();
        this.getPasses().filter((pass) => pass.gatePass.isPunchPass).map((pass) => pass.consumers.map((consumer) => punchPass.addConsumerGatePass(consumer)));
        return punchPass;
    }

    public hasPasses(): boolean {
        return this._has(AdmissionSource.pass);
    }

    public getUnclaimedTicketCount(): number {
        let count: number = 0;
        this.getAccessPasses().map((pass) => count += pass.getUnclaimedTickets().length)
        return count;
    }

    public getClaimedTicketCount(): number {
        let count: number = 0;
        this.getAccessPasses().map((pass) => count += pass.getClaimedTickets().length)
        return count;
    }

    public getTransfers(): IAdmission[] {
        return this._get(AdmissionSource.transfer);
    }

    public hasTransfers(): boolean {
        return this._has(AdmissionSource.transfer);
    }

    public getTransferableTickets(): Ticket[] {
        return this.getUnusedTickets().filter((ticket: Ticket) => ticket.transferable);
    }

    public hasTransferableTickets(): boolean {
        return this.getTransferableTickets().length > 0;
    }

    private _getStatuses(): string[] {

        let statuses: string[] = new Array<string>()

        if (this.hasTransfers()) {
            statuses.push(AdmissionStatus.transferred)
        }

        if (this.getUnclaimedTicketCount() > 0) {
            statuses.push(AdmissionStatus.claimable)
        }

        if (this.hasUnusedTickets() && !this.event.areGatesOpen()) {
            statuses.push(AdmissionStatus.gates)
        }

        if (this.hasUnusedTickets() && this.event.areGatesOpen()) {
            statuses.push(AdmissionStatus.ready)
        }

        if (!this.hasUnusedTickets()) {
            statuses.push(AdmissionStatus.redeemed)
        }

        return statuses;

    }
    /**
     * Returns a single status for the given Event Admission object based on a hierarchy of status
     * since it's possible that each of these can have multiple statuses
     *
     */
    public getStatus(): string {

        if (this.hasTransfers()) {
            return AdmissionStatus.transferred
        }

        if (this.getTransferredTickets().length > 0) {
            return AdmissionStatus.cancel
        }

        if (this.hasUnusedTickets() && this.event.areGatesOpen()) {
            return AdmissionStatus.ready
        }

        if (this.getUnclaimedTicketCount() > 0) {
            return AdmissionStatus.claimable
        }

        if (this.hasUnusedTickets() && !this.event.areGatesOpen()) {
            return AdmissionStatus.gates
        }

        if (!this.hasUnusedTickets()) {
            return AdmissionStatus.redeemed
        }
        return '';
    }

    public getStatusIcon(): string {
        return AdmissionStatusIcon[this.getStatus()];
    }

    public getActions(): string[] {
        let actions: string[] = new Array<string>();
        this.admission.map((admission) => {
            admission.getActions().map((action) => {
                if (!actions.find((some) => some == action)) {
                    actions.push(action);
                }
            })
        });
        return actions;
    };

    public hasAction(action: string): boolean {
        return this.getActions().findIndex((existing) => existing == action) > -1
    }

}
