import { IDeserializable } from "@app/data/models/deserializable.interface";
import { RenewalTicketHolderSeat } from "./ticket-holder-seat.model";
import * as _ from 'lodash';
import { RenewalSeasonTicket } from "./season-ticket.model";

export class RenewalTicketHolder implements IDeserializable {

    public id: number;
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public phoneNumber: string;
    public accessKey: string;
    public seats: RenewalTicketHolderSeat [] = new Array<RenewalTicketHolderSeat>();
    public seasonTicket: RenewalSeasonTicket = new RenewalSeasonTicket();

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.seats) {
            this.seats = _.orderBy(input.seats.map((seat) => new RenewalTicketHolderSeat().deserialize(seat)), ["seatKey"]);
        }

        if (input.seasonTicket) {
            this.seasonTicket = new RenewalSeasonTicket().deserialize(input.seasonTicket);
        }

        return this;
    
    }

    public isValid(): boolean {
        return !_.isEmpty(this.accessKey);
    }

    public currentPhase(): number {
        return this.seasonTicket.currentPhase();
    }

}
