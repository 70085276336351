<div class="home-container">
    <div class="container-fluid pb-3">
        <div class="text-center">
            <div class="container px-0 px-sm-3" style="z-index:4;">
                <div class="card col-md-6 mt-3 mx-auto">
                    <div class="card-body">
                        <h4 class="card-title text-center m-0" style="border:none;">
                            <div class="row">
                                <div class="col-10 col-offset-1 col-sm-8 col-sm-offset-2 text-center mr-auto ml-auto mt-5">
                                    <img alt="Ticket Spicket" routerLink="/home" style="cursor:pointer;" src="https://s3.amazonaws.com/com.ticketspicket.app/logos/ticket_spicket_hometown_logo_black_v1.svg" class="img-fluid flex-center mb-3">
                                    <a routerLink="/home" style="cursor:pointer;" class="font-sm d-block text-center mt-2 mb-3"><i class="fa fa-arrow-left mr-2"></i>Back to Search</a>
                                </div>
                            </div>
                        </h4>
                        <form (ngSubmit)="register()" [formGroup]="registrationForm" novalidate autocomplete="on">
                            <div class="alert alert-danger" [hidden]="!error || isLoading" >
                                {{error}}
                            </div>
                            <div class="form-group">
                                <label class="d-block mb-3">
                                    <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'" />
                                    <span hidden >Email</span>
                                    <div [hidden]="registrationForm.get('email').untouched" *ngIf="registrationForm.get('email').invalid" class="text-danger" style="font-size: .8em">
                                        <div *ngIf="registrationForm.get('email').errors?.required">Email is required</div>
                                        <div *ngIf="registrationForm.get('email').errors?.email">Email must be a valid email format</div>
                                    </div>
                                </label>
                                <label class="d-block mb-3">
                                    <input type="email" class="form-control" formControlName="confirmEmail" autocomplete="off" [placeholder]="'Confirm Email Address'" />
                                    <span hidden >Confirm Email</span>
                                    <div [hidden]="registrationForm.get('confirmEmail').untouched" *ngIf="registrationForm.get('confirmEmail').invalid" class="text-danger" style="font-size: .8em">
                                        <div *ngIf="registrationForm.get('confirmEmail').errors?.required">Confirm Email is required</div>
                                        <div *ngIf="registrationForm.get('confirmEmail').errors?.email">Confirm Email must be a valid email format</div>
                                    </div>
                                    <div [hidden]="registrationForm.get('confirmEmail').untouched" *ngIf="registrationForm.get('confirmEmail').errors" class="text-danger" style="font-size: .8em">
                                        Confirm Email does not match Email
                                    </div>
                                </label>
                                <label class="d-block mb-3">
                                    <input type="text" class="form-control" formControlName="nameFirst" autocomplete="given-name" [placeholder]="'First Name'"/>
                                    <span hidden >First Name</span>
                                    <small [hidden]="registrationForm.get('nameFirst').valid || registrationForm.get('nameFirst').untouched" class="text-danger" >
                                    First Name is required
                                    </small>
                                </label>
                                <label class="d-block mb-3">
                                    <input type="text" class="form-control" formControlName="nameLast" autocomplete="family-name" [placeholder]="'Last Name'"/>
                                    <span hidden >Last Name</span>
                                    <small [hidden]="registrationForm.get('nameLast').valid || registrationForm.get('nameLast').untouched" class="text-danger" >
                                    Last Name is required
                                    </small>
                                </label>
                                <div class="py-1 font-sm text-left">
                                    <p class="m-0 p-0 pb-1 text-center"><strong>In order to maintain a secure account, your password must contain the following:</strong></p>
                                    <div><i class="fa fa-fw mr-2" [ngClass]="registrationForm.get('password').errors?.['strong']?.length ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least 6 characters</div>
                                    <div><i class="fa fa-fw mr-2" [ngClass]="registrationForm.get('password').errors?.['strong']?.alpha ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one letter</div>
                                    <div><i class="fa fa-fw mr-2" [ngClass]="registrationForm.get('password').errors?.['strong']?.number ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one number</div>
                                </div>
                                <label class="d-block my-3">
                                    <input type="password" class="form-control" formControlName="password" autocomplete="new-password" [placeholder]="'Password'"/>
                                    <span hidden >Password</span>
                                    <div [hidden]="registrationForm.get('password').untouched" *ngIf="registrationForm.get('password').invalid" class="text-danger" style="font-size: .8em">
                                        <div *ngIf="registrationForm.get('password').errors?.required">Password is required</div>
                                        <div *ngIf="registrationForm.get('password').errors?.strong">Password is not strong enough</div>
                                    </div>
                                </label>
                                <label class="d-block">
                                    <input type="password" class="form-control" formControlName="confirmPassword" autocomplete="off" [placeholder]="'Confirm Password'"/>
                                    <span hidden >Confirm Password</span>
                                    <small [hidden]="registrationForm.get('confirmPassword').valid || registrationForm.get('confirmPassword').untouched" class="text-danger" >
                                        Confirm Password is required
                                    </small>
                                    <div [hidden]="registrationForm.controls.confirmPassword.untouched" *ngIf="registrationForm.get('confirmPassword').errors" class="text-danger" style="font-size: .8em">
                                        Confirm Password does not match Password
                                    </div>
                                </label>
                            </div>
                            <div class="text-center m-3 p-2 font-sm">By creating an account, I consent to Ticket Spicket's <a rel="noopener" href="http://www.ticketspicket.com/terms-and-conditions/" target="_blank">Terms of Use</a> and <a rel="noopener" href="http://www.ticketspicket.com/privacy-policy/" target="_blank">Privacy Policy</a>, and to receive email from Ticket Spicket.</div>
                            <button mdbRippleRadius class="btn btn-primary w-100 m-0" type="submit" [disabled]="registrationForm.invalid || isLoading">
                                <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
                                <h6 class="m-0" [hidden]="isLoading">Sign Up</h6>
                            </button>
                            <p class="text-center m-2 my-4">
                                <strong>Already have an account?</strong>
                            </p>
                            <button mdbRippleRadius class="btn btn-primary w-100 m-0 mb-4" [routerLink]="['/auth', 'login']">
                                <h6 class="m-0">Login</h6>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
