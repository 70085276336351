import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';

export class Message implements IDeserializable {

    public id: number;
    public uuid: string;
    public sender: string;
    public subject: string;
    public body: string;
    public dateReceived: Date;
    public dateRead: Date;
    public selected: boolean;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if (this.dateReceived)
            this.dateReceived = moment(input.dateReceived).toDate();

        if (this.dateRead)
            this.dateRead = moment(input.dateRead).toDate();

        return this;

    }

    set isRead(val: boolean) {
    }

    get isRead(): boolean {
        return this.dateRead !== null;
    }

    set isSelected(val: boolean) {
    }

    get isSelected(): boolean {
        return this.selected !== null;
    }

}