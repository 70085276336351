<div class="modal-agency-header">
    <div class="modal-agency-logos text-center">        
        <div class="modal-agency-single" *ngIf="!event.opponent?.settings">
            <img [src]="agency.settings?.logo2xURI" alt="" />
        </div>
        <div class="modal-agency-double" *ngIf="event.opponent?.settings">
            <div class="float-right">
                <img [src]="home.settings?.logo2xURI" alt="" class="mb-2 mx-auto d-block float-none" />
                <div class="mx-auto text-uppercase font-sm d-block" style="color:white;"><strong>{{ home.nameMascot }}</strong></div>
                <div class="clearfix"></div>
            </div>
        <div class="d-inline-block mx-auto float-none versus">VS</div>
        <div class="float-left">
            <img [src]="visitor?.settings?.logo2xURI" alt="" class="mb-2 mx-auto d-block float-none" />
            <div class="mx-auto text-uppercase font-sm d-block" style="color:white;"><strong>{{ visitor.nameMascot }}</strong></div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        </div>
    </div>
</div>
