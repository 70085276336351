<div style="overflow:hidden;">
    <div class="container bg-white pb-4" style="position:relative;z-index:100;">
        <div class="coverArt view football">
            <div class="colorOverlay"></div>
            <div class="details">
                <div class="row align-items-center">
                    <div class="col-12">
                        <svg class="icon">
                            <use attr.xlink:href="#football"></use>
                        </svg>
                        <h5 class="name">{{ pass.name }}</h5>
                        <p class="description">{{ pass.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a [routerLink]="['/agency', agency.uuid]">{{ agency.name }}</a>
                </li>
                <li class="breadcrumb-item font-sm">
                    <a [routerLink]="['/agency', agency.uuid, 'passes']">All Passes</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">{{ pass.name }}</li>
            </ol>
        </nav>

        <app-agency-season-seatmap *ngIf="!isLoading" [pass]="pass" [eventUUID]="pass.eventKeys[0]" [ticketPrices]="pass.prices"></app-agency-season-seatmap>

    </div>
</div>
