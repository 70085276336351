import { Component, OnInit, Input } from '@angular/core';
import { Event } from '@app/data/models/event.model';
import { Agency } from '@app/data/models/agency.model';

@Component({
    selector: 'ts-ticket-selection-event-header',
    templateUrl: './select-ticket-header.component.html'
  })
  export class TicketSelectionModalHeaderComponent implements OnInit {

    @Input() event: Event;

    public agency: Agency;

    public home: Agency;
    public visitor: Agency;

    constructor () { }

    ngOnInit() {
        this.agency = this.event.agency;
        this.home = this.event.getHomeAgency();
        this.visitor = this.event.getVisitorAgency();
    }

}