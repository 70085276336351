import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { CartItemPassSummary } from '@app/data/models/cart/cart-item-pass';
import { CartItem } from '@app/data/models/cart/cart-item.model';

@Component({
  selector: 'ts-cart-pass',
  templateUrl: './cart-pass.component.html',
  styleUrls: ['./cart-pass.component.scss']
})

export class CartPassComponent {

  @Input('summary') summary: CartItemPassSummary;

  @Output() update: EventEmitter<CartItemPassSummary> = new EventEmitter<CartItemPassSummary>();

  constructor(
    private _cart: CartService,
    private _messageService: MessageService
  ) { }

  ngOnInit() { }

  public updateItem(item: any) {

    this._cart.items.setProductTicketTypeQuantity(item.product, item.ticketPrice, item.quantity);
    this._messageService.toast.success('Your tickets have been updated in the cart.');
    this.update.emit(this.summary);

  }

  public removeSeat(item: CartItem) {
    this._cart.removeReservedSeat(item.product, item.seat).subscribe(() => {
      this._messageService.toast.success('Your seat has been removed from the cart.');
      this.update.emit(this.summary);
    });
  }

  public get agencyRoute() {
    return this._cart.embedded ? '/embed/agency' : '/agency';
  }
}
