import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferUser } from '@app/data/models/transfer-user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { finalize } from 'rxjs/operators';
import { TicketService } from '@app/data/services/ticket.service';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { Ticket } from '@app/data/models/ticket.model';
import { TransferService } from '@app/data/services/transfer.service';
import { Transfer } from '@app/data/models/transfer.model';
import { UserAdmissionService } from '@app/data/services/user-admission.service';

@Component({
    selector: 'app-ticket-cancel-transfer-modal',
    templateUrl: './cancel-transfer-modal.component.html',
    styleUrls: ['./cancel-transfer-modal.component.scss']
})
export class TicketCancelTransferModalComponent implements OnInit {

    @Input() admission: EventAdmission;
    public isLoading: boolean = true;
    validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    constructor(
        public transferService: TransferService,
        private _messageService: MessageService,
        private _userAdmissionService: UserAdmissionService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        this.transferService.getTransfers().subscribe(() => this.isLoading = false);
    }

    public close() {
        this.activeModal.dismiss(false);
    }

    public getEventTransfers(): Transfer[] {
        return this.transferService.currentTransfers.outgoing
            .filter((transfer) => transfer.event?.uuid === this.admission.event.uuid);
    }

    cancelTransfer(transfer: Transfer) {
        this._messageService.ConfirmYesNo("Are you sure you want to cancel this Ticket Transfer?", "Are you sure")
            .then((value) => {
                this.transferService.cancelTransfer(transfer).subscribe(() => {
                    this._messageService.toast.success("Your Ticket Transfer has been cancelled");
                    this._userAdmissionService.getEventTickets(this.admission.event.uuid).subscribe();
                    this.close();
                });
            });
    }

    resendTransfer(transfer: Transfer) {
        this._messageService.ConfirmYesNo("Are you sure you want to resend this Ticket Transfer?", "Are you sure")
            .then((value) => {
                this.transferService.resendTransfer(transfer).subscribe(() => {
                    this._messageService.toast.success("Your Ticket Transfer has been resent");
                    this.close();
                });
            });
    }
}
