import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { of, Observable } from 'rxjs';
import { map, switchMap, flatMap, filter } from 'rxjs/operators';
import { Message } from '@app/data/models/message.model';
import { InboxService } from '@app/data/services/inbox.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from '@app/data/models/pagination.model';

@Component({
  selector: 'app-user-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class UserMessagesComponent implements OnInit {

    public messages: Message[] = new Array<Message>();
    public message: Message;
    public noResultsMessage = 'messages at this time';
    public selectAll = false;
    public isLoading: boolean = false;
    public pagination: Pagination = new Pagination(10);

    constructor (
        private _message: MessageService,
        private _inbox: InboxService,
        public _modal: NgbModal
    ) { }

    ngOnInit() {
        this.loadMessages();
    }

    loadMessages() {
        this.isLoading = true;
        this._inbox.getMessages().pipe (
            map((messages) => this.messages = messages)
        ).subscribe(() => {
            this.isLoading = false;
            this.selectAll = false;
        });
    }

    getSelectedMessages(): Observable<Message[]> {
        return of (this.messages.filter((message) => message.selected));
    }

    /**
     * delete all of the selected messages and then show success message
     *
     */
    deleteSelected() {
        this._message.ConfirmYesNo('Do you want to delete the selected messages?').then(
            (value) => {
                this.getSelectedMessages().pipe(
                    flatMap((messages) => messages),
                    flatMap((message) => this._inbox.deleteMessage(message.uuid))
                ).subscribe (
                    (message) => {},
                    (error) => this._message.toast.error(error.message, "Error!"),
                    () => { this._message.toast.success("The Selected Messages have been deleted", "Success"); this.loadMessages() }
                )
            }
        );
    }

    createMessage() {
        this._inbox.createMessage().subscribe (
            (message: any) => {
                this.loadMessages();
            }
        )
    }

    markSelectedAsRead() {
        this.getSelectedMessages().pipe(
            flatMap((messages) => messages),
            flatMap((message) => this._inbox.setAsRead(message.uuid))
        ).subscribe (
            () => {},
            (error) => this._message.toast.error(error.message, "Error!"),
            () => this.loadMessages()
        );
    }

    public readMessage(content: any, message: Message) {
        this._inbox.getMessage(message.uuid).pipe(
            flatMap((message) => this._inbox.setAsRead(message.uuid))
        ).subscribe (
            (message) => {
                this.message = message;
                this.loadMessages();
                this._modal.open(content, { centered: true, size: 'lg' }).result.then(() => {
                }, () => {
                })
            }
        )
    }

    selectMessage(message: Message) {
        message.selected = message.selected ? false : true;
    }

    selectAllMessages() {
        this.selectAll = this.selectAll ? false : true;
        for (let m of this.messages) {
            m.selected = this.selectAll;
        }
    }

}
