import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Event } from '@app/data/models/event.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';

@Component({
  selector: 'app-agency-event-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class EventPricingComponent implements OnInit {

    @Input()
    public event: Event;

    @Input()
    public prices: TicketPrice[];

    public hasGeneralAdmission: boolean = false;
    public hasReservedSeating: boolean = false;

    @Output() buyGeneral: EventEmitter<any> = new EventEmitter<any>();
    @Output() buyReserved: EventEmitter<any> = new EventEmitter<any>();

    constructor ( ) { }

    ngOnInit() {
        if (!this.prices) {
            this.prices = this.event.prices;
        }
        this.hasGeneralAdmission = this.getGATicketPrices().length > 0;
        this.hasReservedSeating = this.getRESVTicketPrices().length > 0;
    }

    public getGATicketPrices(): TicketPrice[] {
        return this.prices.filter((price) => !price.isReservedPrice())
    }

    public getRESVTicketPrices(): TicketPrice[] {
        return this.prices.filter((price) => price.isReservedPrice())
    }

    public buyTickets() {
        this.buyGeneral.emit(true);
    }

    public buyReservedTickets() {
        this.buyReserved.emit(true);
    }

    public isPriceAvailable(price: TicketPrice): boolean {
        return this.prices.findIndex((p) => p.ticketType.id === price.ticketType.id) >= 0;
    }
}
