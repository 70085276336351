<div class="home-container">
  <div class="container-fluid pb-3">
        <div class="text-center">
            <div class="container px-0 px-sm-3" style="z-index:4;">
                <div class="card col-md-6 mt-3 mx-auto">
                    <div class="card-body">
                    <h4 class="card-title text-center m-0" style="border:none;">
                        <div class="row">
                            <div class="col-10 col-offset-1 col-sm-8 col-sm-offset-2 text-center mr-auto ml-auto mt-5">
                                <img alt="Ticket Spicket" routerLink="/home" style="cursor:pointer;" src="https://s3.amazonaws.com/com.ticketspicket.app/logos/ticket_spicket_hometown_logo_black_v1.svg" class="img-fluid flex-center mb-3">
                                <a routerLink="/home" [hidden]="isLoading" style="cursor:pointer;" class="font-sm d-block text-center mt-2 mb-3"><i class="fa fa-arrow-left mr-2"></i>Back to Search</a>
                            </div>
                        </div>
                    </h4>
                    <ts-loading message="Logging In..." [show]="isLoading"></ts-loading>
                    <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate style="border:none;" [hidden]="isLoading" autocomplete="on">
                        <div class="alert alert-danger" [hidden]="!error || isLoading" >
                            <ul>
                                <li *ngFor="let message of error; index as i; first as isFirst">
                                    {{message}}
                                </li>
                            </ul>
                        </div>
                        <div class="form-group mb-1">
                            <label class="d-block">
                                <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'"/>
                                <span hidden >Email</span>
                                <small [hidden]="loginForm.get('email').valid || loginForm.get('email').untouched" class="text-danger" >
                                Email is required
                                </small>
                            </label>
                            <label class="d-block">
                                <input type="password" class="form-control" formControlName="password" autocomplete="current-password" [placeholder]="'Password'" required/>
                                <span hidden >Password</span>
                                <small [hidden]="loginForm.get('password').valid || loginForm.get('password').untouched" class="text-danger" >
                                Password is required
                                </small>
                            </label>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group pt-2 text-left">
                                        <input mdbDeepDirective type="checkbox" id="checkbox" formControlName="remember">
                                        <label for="checkbox">Remember Me</label>
                                    </div>
                                </div>
                                <div class="col-6 text-right pt-2">
                                    <a [routerLink]="['/auth', 'reset']">Forgot Password?</a>
                                </div>
                            </div>
                        </div>
                        <button mdbRippleRadius class="btn btn-secondary btn-updated w-100 m-0" type="submit" [disabled]="loginForm.pristine || loginForm.invalid || isLoading">
                            <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
                            <h6 class="m-0" [hidden]="isLoading">Login</h6>
                        </button>
                        <p class="text-center m-2 my-4">
                            <strong>Don't have an account?</strong>
                        </p>
                        <button mdbRippleRadius class="btn btn-outline-secondary btn-updated w-100 m-0 mb-4" [routerLink]="['/auth', 'register']" [queryParams]="{ return: returnParam }">
                            <h6 class="m-0">Sign Up</h6>
                        </button>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
