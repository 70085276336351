<div style="overflow:hidden;">
  <div class="container bg-white pb-4" *ngIf="agency && pass" style="position:relative;z-index:100;"
    [class.embedded]="_agencyService.embedded">
    <div class="coverArt view allAccess {{ pass.activity?.typeActivity.iconClassName }} {{pass.season?.iconClassName}}">
      <div class="colorOverlay"></div>
      <div class="details">
        <div class="row align-items-center">
          <div class="col-12">
            <svg class="icon" *ngIf="pass.gatePassType.value === 'All Access'">
              <use xlink:href="#allAccess"></use>
            </svg>
            <svg class="icon" *ngIf="pass.gatePassType.value === 'Season'">
              <use attr.xlink:href="#{{pass.season.iconClassName}}"></use>
            </svg>
            <svg class="icon" *ngIf="pass.gatePassType.value === 'Activity'">
              <use attr.xlink:href="#{{pass.activity.typeActivity?.iconClassName}}"></use>
            </svg>
            <svg class="icon" *ngIf="pass.gatePassType.value === 'Event' && pass.activity">
              <use *ngIf="pass.activity" attr.xlink:href="#{{pass.activity.typeActivity?.iconClassName}}"></use>
            </svg>
            <h5 class="name">{{ pass.name }}</h5>
            <p class="description">{{ pass.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center p-2 font-sm" style="background:#008CF4;color:white;" *ngIf="hasCartItem()">
        <i class="mr-2 fa fa-info-circle"></i>This item is already in the cart
      </div>
    </div>
    <nav aria-label="breadcrumb" class="d-none d-sm-block">
      <ol class="breadcrumb">
        <li class="breadcrumb-item font-sm">
          <a routerLink="../../">{{ _agencyService.embedded ? 'Events & Passes' : agency.name }}</a>
        </li>
        <li class="breadcrumb-item font-sm">
          <a routerLink="../">All Passes</a>
        </li>
        <li class="breadcrumb-item active font-sm" aria-current="page">{{ pass.name }}</li>
      </ol>
    </nav>
    <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center mt-3" routerLink="../">
      <i class="fa fa-arrow-left mr-2"></i>Back to All Passes
    </button>
    <div class="row mt-2">
      <div class="col-12 col-md-6 col-lg-8">
        <div class="d-block d-md-none align-items-center mb-3">
          <button mdbRippleRadius type="button" class="btn btn-primary w-100" (click)="buyPasses()">
            <i class="fa fa-tags mr-2"></i>Buy Pass
          </button>
          <ts-ticket-help-cta [isMobileOnly]="true"></ts-ticket-help-cta>
        </div>
        <div *ngIf="pass.prices.length > 0" class="mb-4">
          <ts-section-header>
            <i class="fa fa-fw fa-tags mr-1"></i>Pass Prices
          </ts-section-header>
          <div class="px-2" *ngIf="!pass.isPunchPass && !pass.isFamilyPass">
            <ts-ticket-price-general [price]="price" *ngFor="let price of pass.prices"></ts-ticket-price-general>
          </div>
          <div class="px-2" *ngIf="pass.isPunchPass || pass.isFamilyPass">
            <ts-ticket-price-group [prices]="pass.prices"></ts-ticket-price-group>
          </div>
        </div>
        <div class="d-none d-md-block row align-items-center my-5">
          <div class="col-12 mt-4 mb-2">
            <button mdbRippleRadius type="button" class="btn btn-primary w-100" (click)="buyPasses()">
              <i class="fa fa-tags mr-2"></i>Buy Pass
            </button>
            <ts-ticket-help-cta [isMobileOnly]="true"></ts-ticket-help-cta>
          </div>
        </div>
        <div class="mb-4" *ngIf="hasTerms()">
          <ts-section-header>
            <i class="fa fa-fw fa-info-circle mr-1"></i>Additional Info / Terms
          </ts-section-header>
          <div class="px-2" [innerHTML]="getTerms()"></div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <ts-section-header>Brought to you by</ts-section-header>
        <div class="sponsorLogo my-4">
          <a *ngIf="!pass.sponsor?.id" class="d-block text-center"
            (click)="sponsorLinkClicked('http://www.ticketspicket.com')">
            <img class="sponsor-image" [src]="pass.sponsor?.logoURI" alt="{{ pass.sponsor?.name }}" width="100%"
              style="margin:0 auto;max-width:200px;" #sponsorImage>
          </a>
          <a *ngIf="pass.sponsor?.id" class="d-block text-center" (click)="sponsorLinkClicked(pass.sponsor?.website)">
            <img class="sponsor-image" [src]="pass.sponsor?.logoURI" alt="{{ pass.sponsor?.name }}" width="100%"
              style="margin:0 auto;max-width:300px;" #sponsorImage>
          </a>
        </div>
      </div>
    </div>
    <svg class="background">
        <use xlink:href="#background" x="0px" y="0px"></use>
    </svg>
</div>
<!--

    <div class="row mt-2 mb-4 align-items-center">
        <div class="col-12 col-md-6 col-lg-8">
            <button mdbRippleRadius type="button" class="btn btn-secondary w-100 float-left" (click)="buyPasses()">
                <i class="fas fa-ticket-alt mr-2"></i>Buy Passes
            </button>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mt-4 mt-md-0">
            <div class="d-inline-block float-left mr-2">
                <i class="fa fa-2x fa-mobile"></i>
            </div>
            <div class="d-inline-block" style="width:calc(100% - 40px);font-size:0.8rem;">This is a digital only pass, you can redeem your digital ticket using your mobile device at the event.
                <a href="#">Learn more.</a>
            </div>
        </div>
    </div>

-->
