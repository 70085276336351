<div class="modal-body p-0 pb-4 mb-3">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header-color single">
        <div class="modal-agency-header">
            <div class="modal-agency-logos text-center">
                <div class="modal-agency-single">
                    <img [src]="agency.settings?.logo2xURI" alt=""/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body pb-3">
        <div class="row">
            <div class="col-12 col-md-12 border-right mb-4">
                <ts-section-header>
                    <i class="fa fa-fw fa-tags mr-1"></i>Family Pass Option
                </ts-section-header>
                <form [formGroup]="selectionForm" novalidate autocomplete="off" class="w-100">
                <div class="row m-2 schedule pb-1">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label class="d-block">
                                <div class="label pb-2 font-weight-bold text-uppercase">Select A Pass Option: <span class="text-danger">*</span></div>
                                <select formControlName="selectedOption" [(ngModel)]="selectedOption" class="form-control w-100" (change)="selectOption(this.selectedOption)" required>
                                    <option value="0"> - Select a Family Pass Option - </option>
                                    <option *ngFor="let price of ticketPrices" [value]="price.id">
                                        {{ price.priceAmount | currency }} - {{price.displayName}}
                                    </option>
                                </select>
                                <small [hidden]="selectionForm.get('selectedOption').valid || selectionForm.get('selectedOption').untouched" class="text-danger">
                                    You must select a Family Pass Option
                                </small>
                            </label>
                        </div>
                        <div class="m-0 schedule pb-3">
                            <hr/>
                            <div class="row">
                                <div class="col-8 text-left">
                                    <div class="price title text-uppercase"># OF FAMILY MEMBERS: <span class="text-danger">*</span></div>
                                </div>
                                <div class="col-4 text-right">
                                    <div class="price" *ngIf="hasSelectedPrice"> (up to {{selectedPrice.optionQuantity}})</div>
                                </div>
                                <div class="col-12 pt-2">
                                    <p><small>Select the number of family members on the pass</small></p>
                                    <div class="quantity-selector">
                                        <div class="row align-items-center text-center m-0">
                                            <div class="col-4 p-0">
                                                <button mdbRippleRadius type="button" class="btn btn-light quantity-decrease border-0" style="width:100%;height:100%;" (click)="updateSelectedQty(-1)" [disabled]="selectedQty <= 1">-</button>
                                            </div>
                                            <div class="col-4 border-right border-left">
                                                <div class="quantity">{{ selectedQty }}</div>
                                            </div>
                                            <div class="col-4 p-0">
                                                <button mdbRippleRadius type="button" class="btn btn-light quantity-increase border-0" style="width:100%;height:100%;" (click)="updateSelectedQty(1)" [disabled]="!hasSelectedPrice || selectedQty == selectedPrice.optionQuantity">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label class="d-block">
                                <div class="label pb-2 font-weight-bold text-uppercase">Family Member Names: <span class="text-danger">*</span></div>
                                <p><small>You must enter the names of the people assigned to the pass in order to continue.</small></p>
                                <div class="text-center py-2" *ngIf="selectedQty == 0">
                                    <h6 class="text-muted"># of Family Members must be selected</h6>
                                </div>
                                <div formArrayName="members" *ngFor="let item of selectionForm.get('members')['controls']; let i = index;">
                                    <div [formGroupName]="i">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">#{{i + 1}}</span>
                                            </div>
                                            <input formControlName="name" class="form-control py-2" placeholder="Enter Family Member Name" required>
                                        </div>

                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    </div>
                </form>
            </div>
            <div class="col-12 col-md-12">
                <ts-section-header>
                    <i class="fa fa-fw fa-list-alt mr-1"></i>Selection Summary
                </ts-section-header>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row align-items-center" *ngIf="hasSelectedPrice">
                            <div class="col-6 text-left">
                                <div class="price title">{{selectedPrice.displayName}}</div>
                            </div>
                            <div class="col-6 text-right ml-auto">
                                <div class="d-inline-block">x</div>
                                <div class="d-inline-block" style="width:20px;">{{ cartItems.getProductTicketPriceQty(product, selectedPrice) | async }}</div>
                                <div class="ml-2 d-inline-block">=</div>
                                <div class="d-inline-block" style="width:89px;">{{ cartItems.getProductTicketPriceTotalPrice(product, selectedPrice) | async | currency }}</div>
                            </div>
                        </div>
                        <div class="align-items-center text-center py-2" *ngIf="!hasSelectedPrice">
                            <h6 class="text-muted">Family Pass Option has not been selected</h6>
                        </div>
                        <hr />
                        <div class="text-right mb-3">
                            <!-- <div class="mb-2">
                                <strong>Convenience Fee:</strong>
                                <div class="d-inline-block text-right" style="width:89px;"><span *ngIf="!loading">{{cartItems.getTotalFees() | currency }}</span><span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span></div>
                            </div> -->
                            <div class="mb-2">
                                <strong>Total Amount:</strong>
                                <div class="d-inline-block text-right" style="width:89px;"><span *ngIf="!loading">{{cartItems.getTotalPrice() | currency }}</span><span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span></div>
                            </div>
                            <div class="mb-2 text-right text-muted">
                                (plus applicable fees)
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row text-center my-3">
                            <div class="col-12">
                                <button mdbRippleRadius type="button" [disabled]="!canAddToCart()" class="btn btn-secondary w-100" (click)="checkout()">
                                    <i class="fa fa-credit-card mr-2"></i>Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Done</button>
</div>
