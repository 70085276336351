<div class="product card mb-3">
  <div class="card-body px-2 pt-2 pb-3">
    <div *ngIf="!summary.product.isSeasonPass" style="cursor:pointer;" [routerLink]="[agencyRoute, summary.product.agency.uuid, 'passes', summary.product.id]">
      <ts-pass-header-list-item [pass]="summary.product" [showIcon]="true"></ts-pass-header-list-item>
    </div>
    <div *ngIf="summary.product.isSeasonPass">
      <ts-pass-header-list-item [pass]="summary.product" [showIcon]="true"></ts-pass-header-list-item>
    </div>
    <ts-cart-ticket-type [summary]="summary" (update)="updateItem($event)"></ts-cart-ticket-type>
    <div *ngIf="summary.product.isFamilyPass">
      <div class="row my-2 pb-2 mx-0" style="border-bottom:1px dotted #e5e5e5;">
        <div class="col-7 p-0">
          Number of Famly Members:
        </div>
        <div class="col-5 text-right p-0">
          <div class="pr-2"><strong>{{summary.items.items[0].selectedQty}}</strong></div>
          <!-- <div class="pr-2"><strong>{{item.selectedQty}}</strong></div> -->
        </div>
      </div>
      <div class="row my-2 pb-2 mx-0" style="border-bottom:1px dotted #e5e5e5;">
        <div class="col-6 p-0">
          Family Member Names:
        </div>
        <div class="col-6 text-right p-0">
          <div class="pr-2" *ngFor="let member of summary.items.items[0].members"><strong>{{member.name}}</strong></div>
        </div>
      </div>
    </div>
    <ts-cart-ticket-seats [seats]="summary.seats" (remove)="removeSeat($event)"></ts-cart-ticket-seats>
    <div class="mt-3 text-right">
      <div>
        Convenience Fee: <strong>{{summary.items.getTotalFees() | currency}}</strong>
      </div>
      <div *ngIf="summary.hasPromotion()">
        Promotion: <strong style="color:red;">{{summary.items.getTotalDiscount() | currency}}</strong>
        <div class="font-sm text-muted">{{summary.promotion.name}}</div>
      </div>
      <div>
        Subtotal: <strong>{{summary.items.getTotalPrice() | currency}}</strong>
      </div>
    </div>
  </div>
</div>
