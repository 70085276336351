import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { CartService } from '@app/data/services/cart.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { CartItem, CartItemSummary } from '@app/data/models/cart/cart-item.model';
import { ReservedConfiguration } from '@app/data/models/reserved/configuration.model';
import { ReservedPricing } from '@app/data/models/reserved/pricing.model';
import { ReservedSeatCategory, ReservedSeat } from '@app/data/models/reserved/seat.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';

@Component({
  selector: 'app-agency-ticket-renewal',
  templateUrl: './ticket-renewal.component.html',
  styleUrls:['./ticket-renewal.component.scss']
})
export class AgencyTicketRenewalComponent implements OnInit {

    public seats: {};
    public chart: any;
    public isLoading: boolean = true;

    public pass: GatePass = new GatePass();
    public config: ReservedConfiguration;

    constructor (
        public cart: CartService,
        private _agencyService: AgencyService,
        private _message: MessageService
    ) {}

    ngOnInit() {

        this._agencyService.getSeasonTicket("e91624e2-f582-4ef9-80ce-39ac78a75547").pipe (
            map((season) => this.pass = season),
            switchMap(() => this.cart.getHoldToken()),
            map((token) => this.config = new ReservedConfiguration(token)),
            switchMap(() => this._agencyService.getSeasonTicketEvents(this.pass.uuid)),
            map((events) => events.map((event) => this.config.setSeasonEvent(event.uuid)))
        ).subscribe((events) => {
            // loop through the pricing and set the pricing collection
            this.pass.prices.map((price) => {
                this.config.pricing.push(new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price));
            });
            this.pass.eventKeys = this.config.seasonEventKeys;
            this.isLoading = false;
        });

    }

    public selectSeat(seat: any) {
        this.cart.addSeatRenewal (
            this.pass,
            new ReservedSeat().deserialize(seat.id),
            this.config.getTicketPriceByCategoryKey(seat.category.key, null),
            null
        );
    }

    public removeSeat(seat: ReservedSeat) {
        this.cart.removeReservedSeat(this.pass, seat);
        this.removeCartSeat(seat);
    }

    public removeCartSeat(seat: ReservedSeat) {
        let seats: Array<string> = new Array<string>();
        seats.push(seat.key);
        this.chart.deselectObjects(seats);
    }

    public getReservedSeats(): Array<CartItem> {
        let summary: CartItemSummary = this.cart.items.getProductItemSummary(this.pass);
        if (summary) {
            return summary.seats;
        } else {
            return new Array<CartItem>();
        }
    }

    public setChart(chart: any) {
        this.chart = chart;
    }

    public ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.chart.destroy();
    }

}
