import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, Logger, LoginContext, SignUpContext, replacer, SentryTransaction } from '@app/core';
import { CartService } from '@app/data/services/cart.service';
import { ToastrService } from 'ngx-toastr';

const log = new Logger('SignUp');

@Component({
    selector: 'app-auth-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

    public error: string;
    public isLoading = false;
    public returnParam: string = '';
    public selected = 0;

    public signUpContext: SignUpContext = {
        email: '',
        nameFirst: '',
        nameLast: '',
        password: '',
        passwordConfirm: '',
        verification_confirmation: '',
    };

    private loginContext: LoginContext = {
        email: null,
        password: null
    };

    private verificationCode: string = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private cart: CartService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => this.returnParam = params['return'] || '/home');
    }

    public getWidth(): string {
        const fraction = 1 / 3 * 100;
        return `${(this.selected * fraction) + fraction}%`;
    }

    public updateSelected(value: number) {
        this.selected = value;
    }

    public generateVerificationCode(value: {
        nameFirst: string,
        nameLast: string,
        email: string
    }) {
        this.isLoading = true;
        this.error = null;
        this.signUpContext.nameFirst = value.nameFirst;
        this.signUpContext.nameLast = value.nameLast;
        this.signUpContext.email = value.email;
        this.authenticationService.generateVerificationCodeForSignup(this.signUpContext.email).subscribe((response) => {
            if (response.success) {
                this.updateSelected(1);
                this.isLoading = false;
            } else {
                log.debug(`Sign Up error: ${response.message}`);
                this.error = response.message;
                this.isLoading = false;
            }
        });
    }

    public resendCode() {
        this.generateVerificationCode({
            nameFirst: this.signUpContext.nameFirst,
            nameLast: this.signUpContext.nameLast,
            email: this.signUpContext.email,
        });
    }

    public sendVerificationCode(
        code: string
    ) {
        this.isLoading = true;
        this.error = null;
        this.verificationCode = code;
        this.authenticationService.verifyVerificationCodeForSignup(this.signUpContext.email, this.verificationCode).subscribe((response) => {
            if (response.success) {
                this.updateSelected(2);
                this.signUpContext.verification_confirmation = response.confirmation_code;
                this.isLoading = false;
            } else {
                log.debug(`Sign Up error: ${response.message}`);
                this.error = response.message;
                this.isLoading = false;
            }
        });
    }

    public signUp() {

        this.isLoading = true;
        this.error = null;
        this.authenticationService.signup(this.signUpContext).subscribe((response) => {
            if (response.success) {
                this.isLoading = false;
                this.loginContext.email = this.signUpContext.email;
                this.loginContext.password = this.signUpContext.password;
                this.authenticationService.login(this.loginContext).subscribe(() => {
                    this.toastr.success('You have successfully signed up!', 'Welcome!');
                    if (this.cart.countdown) {
                        const info = JSON.stringify(this.cart.getItemsLog(), replacer);
                        SentryTransaction('Checkout', 'Authenticated - register - redirecting to checkout', { cart: info });
                        this.router.navigateByUrl('/cart/checkout');
                    } else {
                        this.router.navigateByUrl(this.returnParam);
                    }
                }, (error) => {
                    log.debug(`Login error: ${error}`);
                    if (error.status === 400) {
                        this.error = error.error;
                    } else {
                        this.error = error.message;
                    }
                    this.isLoading = false;
                });
            } else {
                log.debug(`Sign Up error: ${response.message}`);
                this.error = response.message;
                this.isLoading = false;
            }
        });
    }

    public updatePassword(value: {
        password: string,
        passwordConfirm: string
    }) {
        this.signUpContext.password = value.password;
        this.signUpContext.passwordConfirm = value.passwordConfirm;
        this.signUp();
    }

}
