import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '@app/core';
import { AccountComponent } from './account.component';
import { UserProfileComponent } from '@app/account/profile/profile.component';
import { UserPaymentsComponent } from '@app/account/payments/payments.component';
import { UserRewardsComponent } from '@app/account/rewards/rewards.component';
import { UserTransferHistoryComponent } from '@app/account/transfers/transfers.component';
import { UserInviteComponent } from '@app/account/invite/invite.component';
import { PaymentDetailComponent } from '@app/account/payments/detail.component';
import { UserPaymentsListingComponent } from '@app/account/payments/listing.component';
import { UserPaymentMethodsComponent } from './payment-methods/payment-methods.component';

const routes: Routes = [
    // Module is lazy loaded, see app-routing.module.ts
    {
        path: '', component: AccountComponent, canActivate: [AuthenticationGuard], data: { title: 'Account - Ticket Spicket' },
        children: [
            { path: '', component: UserProfileComponent, data: { title: 'Profile - Ticket Spicket' } },
            { path: 'invite', component: UserInviteComponent, data: { title: 'Invites - Ticket Spicket' } },
            {
                path: 'payments', component: UserPaymentsComponent, data: { showSpinner: true, title: 'Payments - Ticket Spicket' }, children: [
                    { path: '', component: UserPaymentsListingComponent },
                    { path: ':payment-id', component: PaymentDetailComponent, data: { showSpinner: true, title: 'Payment Detail - Ticket Spicket' } }
                ]
            },
            { path: 'rewards', component: UserRewardsComponent, data: { title: 'Rewards - Ticket Spicket' } },
            { path: 'transfers', component: UserTransferHistoryComponent, data: { showSpinner: true, title: 'Transfer History - Ticket Spicket' } },
            {
                path: 'payment-methods', component: UserPaymentMethodsComponent,
                data: { showSpinner: true, title: 'Payment Methods - Ticket Spicket' }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class AccountRoutingModule { }
