import { GatePass } from "@app/data/models/passes/gate-pass.model";
import { ConsumerGatePass } from "@app/data/models/passes/consumer-gate-pass.model";
import { ClaimableEvent } from "./claimable-events.model";
import { Event } from "../event.model";
import { IAdmission, AdmissionActions, AdmissionSource } from "../tickets/admission.interface";
import * as _ from 'lodash';
import { ConsumerPunchPass } from "./consumer-punch-pass.model";

export class ConsumerPass implements IAdmission {

    public gatePass: GatePass;
    public source: string = AdmissionSource.pass;

    public consumers: ConsumerGatePass[];
    public events: ClaimableEvent[] = new Array<ClaimableEvent>();
    public isTransferred: boolean = false;
    public remainingUses: number = 0;
    public punchPass: ConsumerPunchPass = new ConsumerPunchPass();

    constructor(pass: GatePass) {
        this.gatePass = new GatePass().deserialize(pass);
        this.consumers = new Array<ConsumerGatePass>();
        this.events = new Array<ClaimableEvent>();

        if (pass.consumers) {
            this.setConsumers(pass.consumers);
        }

        if (this.gatePass.isPunchPass) {
            this.consumers.map((consumer) => this.punchPass.addConsumerGatePass(consumer))
        }

        // if this is a family pass, loop through and see if the pass is transferred
        if (this.gatePass.isFamilyPass) {
            this.isTransferred = this.gatePass.consumers.filter((consumer) => !consumer.isTransferred).length == 0
        }

    }


    public static groupConsumerPasses(passes: ConsumerGatePass[]): ConsumerPass[] {
        return _.chain(passes)
                .groupBy((value) => value.gatePass.id)
                .map((value) =>  {
                    let cp = new ConsumerPass(value[0].gatePass)
                    cp.setConsumers(value);
                    return cp;
                })
                .value();

    }

    public setConsumers(consumers: ConsumerGatePass[]) {
        this.consumers = _.orderBy(consumers.map((consumer: any) => new ConsumerGatePass().deserialize(consumer)), ["parent", "name", "seatNumber"]);
    }

    public addEvent(event: Event) {
        let ce: ClaimableEvent = new ClaimableEvent(event);
        this.events.push(ce);
    }

    public getActions(): string[] {
        let actions: string[] = new Array<string>();

        actions.push(AdmissionActions.buy);

        if (!this.gatePass.isPunchPass && this.getUnclaimedTickets().length > 0) {
            actions.push(AdmissionActions.claim);
        }

        if (this.gatePass.isPunchPass) {
            actions.push(AdmissionActions.punch);
        }

        return actions;

    }
    
    public getStatus(): string {
        // if (this.countAvailable > 0) {
        //     return AdmissionStatus.claimable;
        // }
        return null;
    }

    public getUnclaimedTickets(): ConsumerGatePass[] {
        return this.consumers.filter((consumer) => consumer.ticket === null);
    }
    
    public getClaimedTickets(): ConsumerGatePass[] {
        return this.consumers.filter((consumer) => consumer.ticket !== null);
    }

}