import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core';
import { confirmPasswordValidator, strongPasswordValidator } from '@app/shared/ticket-spicket/validators';

@Component({
    selector: 'app-auth-password',
    templateUrl: './password.component.html'
})
export class PasswordComponent {

    @Input() public isLoading: boolean = false;

    @Output()
    public updateSelected = new EventEmitter<number>();
    @Output()
    public updatePassword = new EventEmitter<{
        password: string,
        passwordConfirm: string,
    }>();
    passwordForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
    ) {
        this.createForm();
        if (authenticationService.credentials) {
            this.passwordForm.setValue({ password: null, passwordConfirm: null });
        }
    }

    private createForm() {
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, strongPasswordValidator()]],
            passwordConfirm: ['', Validators.required]
        });
        this.passwordForm.setValidators([
            confirmPasswordValidator(this.passwordForm.get('password'), this.passwordForm.get('passwordConfirm')),
        ]);
        if (this.authenticationService.credentials) {
            this.passwordForm.setValue({ password: null, passwordConfirm: null });
        }
    }

    public postPassword() {
        this.updatePassword.emit(this.passwordForm.value);
    }

}
