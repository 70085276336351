<div class="card version {{ version }} b-0">
  <div class="row m-0 align-items-center" style="z-index:1;overflow:hidden;">
    <div class="col-3 d-none d-md-inline-block p-0">
    <div class="view football" style="height:100%;background-image:url('https://s3.amazonaws.com/com.ticketspicket.app/misc-images/season-pass-image-2019-compressor.jpg');">
        </div>
    </div>
    <div class="col-2 d-none d-md-inline-block col-sm-2 col-md-1 text-center p-0 px-md-2">
        <svg class="icon">
            <use xlink:href="#allAccess"></use>
        </svg>
    </div>
    <div class="col-10 col-sm-10 col-md-8">
        <div *ngIf="version === 'v1'">
            <h5 class="text-uppercase p-0 m-0 pt-2 mt-1">
                GET YOUR {{ agency.currentYear.abbreviatedName}} SPORTS PASS TODAY!
            </h5>
            <div class="pb-2 font-sm">
                Support your team each and every gameday with {{ agency.name }} sports passes!
            </div>
        </div>
        <div *ngIf="version === 'v2'">
            <h5 class="text-uppercase p-0 m-0 pt-2 mt-1">
                RENEW YOUR TICKETS FOR THE {{ agency.currentYear.abbreviatedName}} SEASON TODAY!
            </h5>
            <div class="pb-2 font-sm">
                Support your team each and every gameday with {{ agency.name }} season tickets!
            </div>
        </div>
        <div class="card-text pb-2">
            <div class="pb-2 text-uppercase" style="font-weight:700;font-style:italic;">
                CLICK HERE  <i class="fa fa-arrow-right ml-1"></i>
            </div>
        </div>
    </div>
    <div class="col-2 d-inline-block d-md-none col-sm-2 col-md-1 text-center p-0 px-md-2">
        <svg class="icon">
            <use xlink:href="#allAccess"></use>
        </svg>
    </div>
  </div>
  <div class="bars">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
  </div>
</div>
<!-- https://s3.amazonaws.com/com.ticketspicket.app/misc-images/crowd.jpeg -->