<div class="background-color">
    <div class="container bg-white pb-4 pt-3">
        <ts-section-header>Messages
            <div class="actions btn-group float-right" role="group" aria-label="Use these options to impact your inbox.">
                <button type="button" mdbRippleRadius class="btn btn-outline-primary btn-sm" title="Refresh" (click)="loadMessages()"><i class="fa fa-refresh mr-0 mr-md-2" [ngClass]="{'fa-spin': isLoading}"></i><span class="d-none d-md-inline">Refresh</span></button>
                <button type="button" mdbRippleRadius class="btn btn-outline-primary btn-sm" title="Delete" (click)="deleteSelected()"><i class="fa fa-trash-o mr-0 mr-md-2"></i><span class="d-none d-md-inline">Delete</span></button>
                <button type="button" mdbRippleRadius class="btn btn-outline-primary btn-sm" title="Mark As Read" (click)="markSelectedAsRead()"><i class="fa fa-envelope-open-o mr-0 mr-md-2"></i><span class="d-none d-md-inline">Mark As Read</span></button>
            </div>
        </ts-section-header>
        <ts-no-results message="messages" *ngIf="messages.length == 0"></ts-no-results>
        <div *ngIf="messages.length > 0">
            <mat-list class="my-1 mb-4">
                <mat-list-item class="py-0 m-0" (click)="selectAllMessages()">
                    <i mat-list-icon class="fa fa-fw mr-3" [ngClass]="{'fa-square-o' : !selectAll , 'fa-check-square-o' : selectAll }"></i>
                    <h5 mat-line><strong>SELECT ALL</strong></h5>
                    <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item *ngFor="let m of messages.slice(pagination.startItem(), pagination.endItem())" (click)="readMessage(messageModal, m)" class="py-0 m-0" [ngClass]="{'unread' : !m.dateRead , 'selected' : m.selected }" >
                    <i click-stop-propagation mat-list-icon class="fa fa-fw mr-3" [ngClass]="{'fa-square-o' : !m.selected , 'fa-check-square-o' : m.selected }" (click)="selectMessage(m)"></i>
                    <i mat-list-icon class="fa fa-sm fa-fw" [ngClass]="{'fa-circle' : !m.dateRead}"></i>
                    <h5 mat-line><strong>{{m.subject}}</strong></h5>
                    <div mat-line class="font-sm"><i class="fa fa-user mr-2"></i><strong>From:</strong> {{m.sender}}</div>
                    <div mat-line class="font-sm"><i class="fa fa-clock mr-2"></i><strong>Date Received:</strong> {{m.dateReceived | date: 'MM/dd/yyyy hh:mm a'}}</div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
            <ts-pagination [pagination]="pagination" [collectionSize]="messages.length"></ts-pagination>
        </div>
        <!-- <button class="btn btn-primary btn-sm mx-3 float-right" (click)="createMessage()">Create</button> -->
        <!-- <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col" class="text-center" style="width:50px;">&nbsp;</th>
                        <th scope="col" class="text-center" style="width:50px;">
                            <span *ngIf="!selectAll" (click)="selectAllMessages()">
                                <i class="fa fa-square-o"></i>
                            </span>
                            <span *ngIf="selectAll" (click)="selectAllMessages()">
                                <i class="fa fa-check-square-o"></i>
                            </span>
                        </th>
                        <th scope="col">Subject</th>
                        <th scope="col">Sender</th>
                        <th scope="col">Date Received</th>
                    </tr>
                </thead>
                <tbody *ngIf="messages">
                    <tr *ngFor="let m of messages.slice(pagination.startItem(), pagination.endItem())" (click)="readMessage(messageModal, m)" [ngClass]="{'unread' : !m.dateRead , 'selected' : m.selected }">
                        <td class="text-center">
                            <span *ngIf="!m.dateRead">
                                <i class="fa fa-circle"></i>
                            </span>
                        </td>
                        <td class="text-center" click-stop-propagation (click)="selectMessage(m)">
                            <span *ngIf="!m.selected">
                                <i class="fa fa-square-o"></i>
                            </span>
                            <span *ngIf="m.selected">
                                <i class="fa fa-check-square-o"></i>
                            </span>
                        </td>
                        <td>{{m.subject}}</td>
                        <td>{{m.sender}}</td>
                        <td>{{m.dateReceived | date: 'MM/dd/yyyy hh:mm a'}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!messages">
                    <tr>
                        <td colspan="5">
                            <ts-no-results [message]="noResultsMessage"></ts-no-results>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
</div>
<ng-template #messageModal let-c="close" let-d="dismiss">
    <button type="button" mdbRippleRadius class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="d('Cross click')">
        <span style="font-size:3rem;font-weight:300;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-2 mb-2">
        <div class="row" *ngIf="message">
            <div class="col-12 mb-2">
                <div class="card-body">
                    <div>
                        <h5 class="border-0">
                            {{ message.subject }}
                        </h5>
                        <div>
                            <i class="fa fa-user mr-2"></i><strong>From:</strong> {{ message.sender }}
                        </div>
                        <div>
                            <i class="fa fa-clock mr-2"></i><strong>Date Received:</strong> {{ message.dateReceived | date: 'EEEE, MMM. dd, yyyy' }}
                        </div>
                    </div>
                    <hr />
                    <p class="py-2">{{ message.body }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center modal-close-button">
        <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>