import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-auth-verification-code',
    templateUrl: './verification-code.component.html'
})
export class VerificationCodeComponent {

    @Input() public isLoading: boolean = false;
    @Input() public email: string = null;

    @Output()
    public updateSelected = new EventEmitter<number>();
    @Output()
    public sendVerificationCode = new EventEmitter<string>();
    @Output()
    public resendCode = new EventEmitter();
    verificationCodeForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
    ) {
        this.createForm();
    }

    private createForm() {
        this.verificationCodeForm = this.formBuilder.group({
            one: ['', Validators.required],
            two: ['', Validators.required],
            three: ['', Validators.required],
            four: ['', Validators.required],
            five: ['', Validators.required],
            six: ['', Validators.required]
        });
    }

    public onDigitInput(event) {
        let element;
        if (event.code !== 'Backspace') {
            element = event.target.nextElementSibling;
        }
     
         if (event.code === 'Backspace')
             element = event.target.previousElementSibling;
     
         if(element == null)
             return;
         else
             element.focus();
     }

    public verifyCode(event?) {
        if (event) {
            let clipboardData = event.clipboardData;
            let pastedText = clipboardData.getData('text');
            if (pastedText.length === 6) {
                this.verificationCodeForm.setValue({
                    one: pastedText[0],
                    two: pastedText[1],
                    three: pastedText[2],
                    four: pastedText[3],
                    five: pastedText[4],
                    six: pastedText[5]
                });
            }
        }
        const code = `${this.verificationCodeForm.value.one}${this.verificationCodeForm.value.two}${this.verificationCodeForm.value.three}${this.verificationCodeForm.value.four}${this.verificationCodeForm.value.five}${this.verificationCodeForm.value.six}`;
        this.sendVerificationCode.emit(code);
    }

}
