import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { map, tap } from 'rxjs/operators';
import { Event } from '@app/data/models/event.model';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { ClaimableEvent } from '../models/passes/claimable-events.model';
import { ConsumerPunchPass } from '../models/passes/consumer-punch-pass.model';
import * as moment from 'moment';

@Injectable()
export class PassService {

    public claimableEvents: ClaimableEvent[] = new Array<ClaimableEvent>();
    private _passes = new BehaviorSubject<ConsumerPass[]>(new Array<ConsumerPass>());
    public passes: ConsumerPass[] = new Array<ConsumerPass>();
    public linkedPasses: ConsumerPass[] = new Array<ConsumerPass>();

    public basePath: string = 'fans/passes'

    constructor (
        private _http: HttpClient
    ) { }


    public myPasses(): Observable<ConsumerPass[]> {
        return this._passes.asObservable();
    }

    private _groupConsumerPass(passes: ConsumerGatePass[]): ConsumerPass[] {

        return _.chain(passes)
                .groupBy((value) => value.gatePass.id)
                .map((value) =>  {
                    let cp = new ConsumerPass(value[0].gatePass)
                    cp.setConsumers(value);
                    return cp;
                })
                .value();

    }

    /**
     * returns a list of Agency objects
     *
     * @returns Observable array of Agency objects
     */
    public getClaimableEvents(): Observable<ClaimableEvent[]> {
        const url = `${this.basePath}/events`;
        const currentDate = moment().startOf('day').toDate();
        return this._http.get<any[]>(url).pipe (
            map( (events) =>
                this.claimableEvents = events.map( (event) => {
                    let ce: ClaimableEvent = new ClaimableEvent(new Event().deserialize(event));
                    ce.passes = this._groupConsumerPass(event.passes)
                    return ce;
                }).filter(x => moment(x.event.dateStart).isAfter(currentDate))
            )
        )
    }

    /**
     * returns a list of Agency objects
     *
     * @returns Observable array of Agency objects
     */
    public getPasses(): Observable<ConsumerPass[]> {
        return this._http.get<GatePass[]>(this.basePath).pipe (
            map( (passes) => this.passes = passes.map((pass) => new ConsumerPass(pass))),
            tap( () => this._passes.next(this.passes))
        )
    }

    /**
     * returns a list of Agency objects
     *
     * @returns Observable array of Agency objects
     */
    public getLinkedPasses(): Observable<ConsumerPass[]> {

        const url = `${this.basePath}/linked`;
        return this._http.get<GatePass[]>(url).pipe (
            map( (passes) => this.linkedPasses = passes.map((pass) => new ConsumerPass(pass)) )
        )
    }

    /**
     * Retrieves all of the events that are eligibile to be claimed for this pass
     *
     * @param id
     * @returns Observable<Event[]> list of event objects that are eligible for the pass
     */
    public getAvailablePassEvents(id: number): Observable<Event[]> {

        const url = `${this.basePath}/${id}/events`;
        const currentDate = moment().startOf('day').toDate();
        return this._http.get<Event[]>(url).pipe(
            map(
              (events) => _.orderBy(events, ['dateStart'])
                .map((event) => new Event().deserialize(event))
                .filter(x => moment(x.dateStart).isAfter(currentDate))
            )
        );

    }

    /**
     *
     * @param id
     * @param uuid
     */
    public getPassEventAvailability(id: number, uuid: string): Observable<ConsumerGatePass[]> {

        const url = `${this.basePath}/${id}/events/${uuid}`;

        return this._http.get<ConsumerGatePass[]>(url).pipe(
            map( (passes) => passes.map( (pass) => new ConsumerGatePass().deserialize(pass)))
        );

    }

    /**
     * eventUUID: string,
     * gatePassID: number,
     * passes: [
     *    {
     *        id: number,
     *        uuid: string
     *    },
     *    ...
     */
    public claimTickets(eventUUID: string, gatePassID: number, passes: ConsumerGatePass[]): Observable<any> {

        const url = `${this.basePath}/claim`;

        const passesUniq = _.chain(passes).uniqBy(x => x.uuid).map((pass) => {
          return { id: pass.id, uuid: pass.uuid };
        });
        const claim = {
            eventUUID: eventUUID,
            gatePassID: gatePassID,
            passes: passesUniq
        };

        return this._http.post(url, claim);
    }

    /**
     * eventUUID: string,
     * gatePassID: number,
     * passes: [
     *    {
     *        id: number,
     *        uuid: string
     *    },
     *    ...
     */
    public usePunchPass(event: Event, pass: ConsumerPunchPass): Observable<any> {

        const url = `${this.basePath}/claim/punch`;

        let tickets = pass.levels.filter((level) => level.selectedQty > 0).map((level) => {
            return {
                ticketType: {id: level.ticketType.id},
                qty: level.selectedQty
            }
        });

        var claim = {
            eventUUID: event.uuid,
            tickets: tickets
        };

        return this._http.post(url, claim);
    }

    configurePass(pass: ConsumerGatePass): Observable<ConsumerGatePass> {
        const url = `${this.basePath}/${pass.uuid}`;
        return this._http.put<ConsumerGatePass>(url, pass);
    }

}
