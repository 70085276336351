<div class="card" style="overflow:hidden;">
    <div class="view card-img-top" [ngStyle]="{'background-image': 'url(' + reward.deal.featureGraphicURI + ')'}"><div class="image-overlay"></div></div>
    <div class="logo float-right mr-2" [ngStyle]="{'background-image': 'url(' + reward.deal.sponsor.logoURI + ')'}"></div>
    <div class="card-body">
        <p class="h5 pb-0 mb-0"> {{ reward.deal.title }} </p>
        <p> {{ reward.deal.subtitle}}&nbsp;</p>
    </div>
    <div class="card-footer py-1 px-3">
        Brought to you by: {{ reward.deal.sponsor.name }}
    </div>
</div>
