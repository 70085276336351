<div class="card text-left" style="height:100%;cursor:pointer;">
    <!-- Only show corner tag if the game is on an agency view. - ELH -->
    <div *ngIf="event.isSportingEvent() && !parentSchoolView" class="corner-tag" [ngClass]="{'home': homeIndicator(), 'away': !homeIndicator()}">
        <div>{{homeIndicator()}}</div>
    </div>
<!-- <img class="card-img-top" src="https://s3.amazonaws.com/com.ticketspicket.app/background-images/bg003.png" alt="Card image cap"> -->
    <div class="view card-img-top {{ event.activity.typeActivity.iconClassName }}" *ngIf="!event.coverArtURI">
        <div class="image-overlay"></div>
    </div>
    <div class="view card-img-top {{ event.activity.typeActivity.iconClassName }}" *ngIf="event.coverArtURI" [style.backgroundImage]="'url('+ event.coverArtURI +')'">
    </div>
    <div class="agency-logos">
        <!-- If on agency view only show the opponent icon. - ELH -->
        <!-- If the event is a tournament only show the hosting entity icon. - ELH -->
        <div class="single-logo">
            <img *ngIf="getLogo()" [src]="getLogo()" alt="logo" />
            <div *ngIf="!getLogo()" class="default-logo">
                {{ agency.name | limitTo: 1 }}
            </div>
        </div>
    </div>
    <div class="card-body">
        <div style="position:relative;">
            <div class="date-time text-center" [ngStyle]="{'background-color': agency.settings?.primaryColor, 'color': agency.settings?.primaryText}">
                <div class="day">
                    {{ event.dateStart | date: 'EEE' }}
                </div>
                <div class="date">
                    {{ event.dateStart | date: 'd' }}
                </div>
                <div class="month">
                    {{ event.dateStart | date: 'MMM' }}
                </div>
                <div class="time" ng-show="includeTime">
                    {{ event.dateStart | date:'h:mm a' }}
                </div>
                <svg class="icon">
                    <use attr.xlink:href="#{{ event.activity?.typeActivity.iconClassName }}"></use>
                </svg>
            </div>
        </div>
        <h5 class="card-title" *ngIf="event.status != 0">
            <span *ngIf="event.status > 0">Postponed&nbsp;<span *ngIf="event.dateStartOriginal">(From {{ event.dateStartOriginal | date: 'MM/dd/yyyy' }})</span></span>
            <span *ngIf="event.status < 0">Cancelled</span>
        </h5>
        <h5 *ngIf="!event.isSportingEvent()" class="card-title mb-1">{{ event.title }}</h5>
        <h5 *ngIf="event.isSportingEvent()" class="card-title mb-1 text-uppercase">{{ event.title }}</h5>
        <div *ngIf="event.isSportingEvent()">
            <h5 class="card-title mb-1">{{ event.homeName }}</h5>
            <h5 class="card-title mb-1">{{ event.visitorName }}</h5>
        </div>
        <div *ngIf="!event.isSportingEvent()">
            <h5 class="card-title mb-1">{{ event.subtitle }}</h5>
        </div>
        <p class="card-text special-event"><strong>{{ event.specialEvent }}</strong></p>
        <div mat-line class="card-text location">
            <div><i class="fa fa-fw fa-map-marker mr-2"></i>{{ event.location?.address.name }}</div>
            <div><i class="fa fa-fw mr-2"></i>{{ event.location?.address.cityState }}</div>
        </div>
    </div>
    <div class="card-footer py-1 px-3" *ngIf="!event.isPassOnly()">
        <div class="text-right">
            <a class="buy">Buy Tickets <i class="fa fa-arrow-right ml-1"></i></a>
        </div>
    </div>
</div>
