import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountSettings } from '@app/data/models/settings.model';
import { MobileService } from '@app/data/services/mobile.service';
import tinycolor from "tinycolor2";

@Component({
    selector: 'ts-agency-style',
    templateUrl: './agency-style.component.html'
})
export class AgencyStyleComponent implements OnInit {
    @Input('settings') settings: AccountSettings;
    //settings: any;

    ngOnInit() {
        //this.settings = this.agency.settings;
    }

    constructor(private sanitizer: DomSanitizer, public mobileService: MobileService) { }

    lightenDarkenColor(col: string, amt: number) {

        let usePound = false;

        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }

        let num = parseInt(col, 16);

        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        let color = (g | (b << 8) | (r << 16)).toString(16);

        for(let i = 0; color.length < 6; i++) {
            color = '0' + color;
        }

        return (usePound ? '#' : '') + color;

    }


    public getStyle() {

        //Default styles for Non-Partner Schools. These will need to be added to bottom of main.scss - ELH
        let primaryColor = '#005cb9';
        let primaryText = '#FFFFFF';
        let secondaryColor = '#005CB9';
        let secondaryText = '#000000';

        //Overwrite if partner school.
        primaryColor = this.settings?.primaryColor;
        primaryText = this.settings?.primaryText;
        secondaryColor = this.settings?.secondaryColor;
        secondaryText = this.settings?.secondaryText;

        let primaryHover = this.lightenDarkenColor(primaryColor, 30);
        let primaryActive = this.lightenDarkenColor(primaryColor, -30);
        let secondaryHover = this.lightenDarkenColor(secondaryColor, 30);
        let secondaryActive = this.lightenDarkenColor(secondaryColor, -30);

        const lineColor = tinycolor(this.settings?.primaryColor);
        const colorIsTooDark = lineColor.getLuminance() < 0.1;
        const newcolor = colorIsTooDark ? lineColor.lighten(10) : lineColor.darken(10);

        let backgroundColor = '';

        if (this.mobileService.isCapacitor) {
            backgroundColor = `body, mat-sidenav-content, mat-sidenav-container, .mat-drawer-container, .mat-drawer-content {background-color: ${primaryColor} !important;}`;
        }

        let innerHTML = `<style>
            ${backgroundColor}
            .navbar, .navbar-dark .navbar-nav .nav-link, mat-sidenav {background-color: ${primaryColor} !important; color: ${primaryText} !important;}
            mat-sidenav .mat-list-base .mat-list-item {background-color: transparent !important; color: ${primaryText} !important;}
            .submenu {background-color: ${secondaryColor};color: ${secondaryText}} .agency-footer {background-color: ${secondaryActive};color: ${secondaryText};}
            .toolbar-ios-primary .toolbar-background-ios, .toolbar-md-primary .toolbar-background-md {background: ${primaryColor};color: ${primaryText};}
            .navbar .mat-badge-content, .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {background-color: ${secondaryColor} !important;color: ${secondaryText} !important;}
            .tabs-ios-primary .tabbar, .tabs-md-primary .tabbar {border-color: ${primaryColor};background-color: ${primaryColor};color: ${primaryText};}
            .button-ios, .button-md, .button-ios-primary, .button-md-primary, .button-ios.activated, .button-md.activated, .button-ios-primary.activated, .button-md-primary.activated { background-color: ${primaryColor};color: ${primaryText};}
            .segment-ios-primary .segment-button, .segment-md-primary .segment-button {color: ${primaryColor};border-color: ${primaryColor};}
            .segment-ios-primary .segment-button.activated, .segment-ios-primary .segment-button.segment-activated, .segment-md-primary .segment-button.activated, .segment-md-primary .segment-button.segment-activated {background-color: ${primaryColor};border-color: ${primaryColor};color: ${primaryText} !important;}
            .button-outline-ios-secondary, .button-outline-md-secondary {border-color: ${secondaryColor} !important;color: ${secondaryColor} !important;background-color:white !important;}
            .button-outline-ios-secondary.activated, .button-outline-md-secondary.activated {background: ${secondaryColor} !important;color: ${secondaryText} !important;}
            .button-outline-ios-secondary .button-effect, .button-outline-md-secondary .button-effect {background-color: ${secondaryColor};}
            .menu-inner > ion-content.content.menu {background-color: ${primaryColor};color; ${primaryText};}
            .menu .item.item-block {color: ${primaryText};}
            .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: ${secondaryColor}};
            page-profile .button-ios, page-profile .button-md {background-color: ${secondaryColor};border-color: ${secondaryColor};color: ${secondaryText};}
            page-profile .profileTabs button.button-ios, page-profile .profileTabs button.button-md {background-color: white;border-bottom-color: grey;color: grey;}
            page-profile .profileTabs button.button-ios.active, page-profile .profileTabs button.button-md.active {background-color: white;color: ${primaryColor};border-bottom-color: ${secondaryColor};}
            .colorOverlay {background: ${primaryColor} !important;background: -moz-linear-gradient(-45deg, ${primaryColor} 0%, ${primaryColor} 40%, ${primaryActive} 100%) !important;background: -webkit-linear-gradient(-45deg, ${primaryColor} 0%,${primaryColor} 40%, ${primaryActive} 100%) !important;background: linear-gradient(135deg, ${primaryColor} 0%,${primaryColor} 40%, ${primaryActive} 100%) !important;filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${primaryColor}', endColorstr='${primaryActive}', GradientType=0.8) !important;}
            .agencyLogo {background:transparent;background-image: url(${this.settings?.logoURI});background-position:center center;background-size:100px 100px;}
            .background-gradient {
              background: repeating-linear-gradient(135deg, transparent, transparent 5px, ${primaryColor} 5px, ${primaryColor} 10px),
              linear-gradient(${newcolor}, ${primaryColor} 85%, ${primaryColor});
            }
            .background-gradient .text-uppercase h3, .background-gradient .text-uppercase h4 {color: ${primaryText};}
            @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) {
                background-image: url(${this.settings?.logo2xURI});
            }
            .text-gradient {background: radial-gradient(${lineColor.lighten(10).toRgbString()} 0px, ${lineColor.lighten(10).setAlpha(0.4).toRgbString()} 80%, transparent);
                    color: ${primaryText};
                    padding: 1rem;
            }

            svg.ticketSpicketSVG * {color: ${primaryText} !important;fill: ${primaryText} !important;stroke: none !important;}
            ts-agency-footer .btn.btn-primary {border:2px solid ${primaryText};color: ${primaryText};}
            ts-agency-submenu .submenu {border-top:5px solid ${primaryText};}
        </style>`;

        return this.sanitizer.bypassSecurityTrustHtml(innerHTML);
    }

    public getStyleAgain() {

        //Default styles for Non-Partner Schools. These will need to be added to bottom of main.scss - ELH
        let primaryColor = '#005cb9';
        let primaryText = '#FFFFFF';
        let secondaryColor = '#005cb9';
        let secondaryText = '#000000';

        //Overwrite if partner school.
        primaryColor = this.settings?.primaryColor;
        primaryText = this.settings?.primaryText;
        secondaryColor = this.settings?.secondaryColor;
        secondaryText = this.settings?.secondaryText;

        let primaryHover = this.lightenDarkenColor(primaryColor, 30);
        let primaryActive = this.lightenDarkenColor(primaryColor, -30);
        let secondaryHover = this.lightenDarkenColor(secondaryColor, 30);
        let secondaryActive = this.lightenDarkenColor(secondaryColor, -30);

        let innerHTML = `<style>
            .btn.btn-primary {
                background: ${primaryColor};
                border:2px solid ${primaryColor};
                color: ${primaryText};
            }
            .btn.btn-primary:hover {
                border:2px solid ${primaryHover};
                background: ${primaryHover};
                color: ${primaryText};
            }
            .btn.btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
                border:2px solid ${primaryActive};
                background: ${primaryActive};
                color: ${primaryText};
            }
            .btn.btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
                box-shadow: 0 0 0 0.2rem ${primaryColor};
            }
            .btn.btn-secondary {
                background: ${secondaryColor};
                border:2px solid ${secondaryColor};
                color: ${secondaryText};
            }
            .btn.btn-secondary:hover {
                border:2px solid ${secondaryHover};
                background: ${secondaryHover};
                color: ${secondaryText};
            }
            .btn.btn-secondary:active, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
                border:2px solid ${secondaryActive};
                background: ${secondaryActive};
                color: ${secondaryText};
            }
            .btn.btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
                box-shadow: 0 0 0 0.2rem ${secondaryColor};
            }
            .btn.btn-outline-primary {
                background: transparent;
                border:2px solid ${primaryColor};
                color: ${primaryColor};
            }
            .btn.btn-outline-primary:hover {
                border:2px solid ${primaryHover};
                background: ${primaryHover};
                color: ${primaryText};
            }
            .btn.btn-outline-primary:active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
                border:2px solid ${primaryActive};
                background: ${primaryActive};
                color: ${primaryText};
            }
            .btn.btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
                box-shadow: 0 0 0 0.2rem ${primaryColor};
            }
            .btn.btn-outline-secondary {
                background: transparent;
                border:2px solid ${secondaryColor};
                color: ${secondaryColor};
            }
            .btn.btn-outline-secondary:hover {
                border:2px solid ${secondaryHover};
                background: ${secondaryHover};
                color: ${secondaryText};
            }
            .btn.btn-outline-secondary:active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
                border:2px solid ${secondaryActive};
                background: ${secondaryActive};
                color: ${secondaryText};
            }
            .btn.btn-outline-secondary:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
                box-shadow: 0 0 0 0.2rem ${secondaryColor};
            }
            app-agency .background-color {
                background: #e5e5e5;
                background: -moz-linear-gradient(top,  #e5e5e5 0%, #e5e5e5 25%, ${secondaryColor} 100%);
                background: -webkit-linear-gradient(top,  #e5e5e5 0%, #e5e5e5 25%, ${secondaryColor} 100%);
                background: linear-gradient(to bottom,  #e5e5e5 0%, #e5e5e5 25%, ${secondaryColor} 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='${secondaryColor}',GradientType=0 );
            }
            @media (max-width: 575px) {
                .dropdown-menu.show {
                    background-color: ${primaryActive} !important;
                }
            }
            @media (min-width: 576px) {
                .dropdown-menu.show {
                    background-color: ${primaryActive} !important;
                }
            }
            @media (min-width: 768px) {
                .dropdown-menu.show {
                    background-color: ${primaryActive} !important;
                }
            }
            @media (min-width: 992px) {
                .dropdown-menu.show {
                    background-color: white !important;
                }
            }
            ts-call-to-action {
                background:#000000;
                border-radius:4px;
            }
            ts-call-to-action .v1 {
                background: ${secondaryActive} !important;
                background: -moz-linear-gradient(-45deg, ${secondaryActive} 0%, ${secondaryActive} 40%, #000000 100%) !important;
                background: -webkit-linear-gradient(-45deg, ${secondaryActive} 0%,${secondaryActive} 40%, #000000 100%) !important;
                background: linear-gradient(135deg, ${secondaryActive} 0%,${secondaryActive} 40%, #000000 100%) !important;
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${secondaryActive}', endColorstr='#000000', GradientType=0.8) !important;
            }
            ts-call-to-action .v2 {
                background: ${secondaryActive} !important;
                background: -moz-linear-gradient(-45deg, ${secondaryActive} 0%, ${secondaryActive} 40%, #000000 100%) !important;
                background: -webkit-linear-gradient(-45deg, ${secondaryActive} 0%,${secondaryActive} 40%, #000000 100%) !important;
                background: linear-gradient(135deg, ${secondaryActive} 0%,${secondaryActive} 40%, #000000 100%) !important;
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${secondaryActive}', endColorstr='#000000', GradientType=0.8) !important;
            }
            ts-call-to-action .bars .bar {
                background: ${primaryColor} !important;
            }
            .modal-header-color.single {
                background: ${primaryActive} !important;
                background: -moz-linear-gradient(-45deg, ${primaryActive} 0%, ${primaryActive} 40%, #000000 100%) !important;
                background: -webkit-linear-gradient(-45deg, ${primaryActive} 0%,${primaryActive} 40%, #000000 100%) !important;
                background: linear-gradient(135deg, ${primaryActive} 0%,${primaryActive} 40%, #000000 100%) !important;
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${primaryActive}', endColorstr='#000000', GradientType=0.8) !important;
            }
            h1, h2, h3, h4, h5 {
                border-color: ${primaryColor};
            }
            ngb-modal-window .step, .mat-badge-content {
                background: ${secondaryColor};
                color: ${secondaryText};
            }
            #Layer_1 {
                fill: ${primaryHover};
                color: ${primaryHover};
                stroke: ${primaryHover};
                // opacity:0.5;
            }
            #Layer_2 {
                fill: ${primaryColor};
                color: ${primaryColor};
                stroke: ${primaryColor};
                // opacity:0.75;
            }
            #Layer_3 .top {
                fill: ${secondaryColor};
                color: ${secondaryColor};
                stroke: ${secondaryColor};
            }
            #Layer_3 .bottom {
                fill: ${primaryActive};
                color: ${primaryActive};
                stroke: ${primaryActive};
            }
            .home {
                background-color: ${secondaryColor} !important;
            }
            }
            .away {
                background-color: ${primaryColor} !important;
            }

            .mat-fab.mat-primary,
            .mat-flat-button.mat-primary,
            .mat-mini-fab.mat-primary,
            .mat-raised-button.mat-primary {
                background-color: ${secondaryColor} !important;
                color: ${secondaryText} !important;
            }
            eco-fab-speed-dial-trigger .mat-fab.mat-primary,
            eco-fab-speed-dial-trigger .mat-flat-button.mat-primary,
            eco-fab-speed-dial-trigger .mat-mini-fab.mat-primary,
            eco-fab-speed-dial-trigger .mat-raised-button.mat-primary,
            .close-button.mat-raised-button.mat-mini-fab.mat-secondary {
                background-color: ${secondaryColor} !important;
                color: ${secondaryText} !important;
            }
            app-header .logo.default {
                background-color: ${primaryColor} !important;
                color: ${primaryText} !important;
            }
            ts-section-header .h5 {
                border-color: ${primaryColor} !important;
            }
            .cdk-overlay-container {
                z-index:10000;
            }
            .seat-map-container #Layer_1 .section.reserved {
                fill:${primaryColor} !important;
                stroke:${primaryActive} !important;
            }
            .seat-map-container #Layer_1 .section.reserved:hover,
            .seat-map-container #Layer_1 .section.reserved:active,
            .seat-map-container #Layer_1 .section.reserved:focus {
                fill:${primaryHover} !important;
                stroke:${primaryColor} !important;
            }
            .seat-map-container #Layer_1 .title.reserved {
                fill:${primaryText} !important;
                stroke:${primaryText} !important;
            }
            .legend .seat-type.available .seat-color {
                background-color: ${primaryHover} !important;
                border-color:1px solid ${primaryColor} !important;
            }
            .seat-selection .seat-icon {
                background-color: ${primaryHover} !important;
                border-color:1px solid ${primaryColor} !important;
            }
            .seats-container .seats-row .seat.available {
                fill:${primaryActive} !important;
                stroke:${primaryColor} !important;
            }
            .seats-container .seats-row .seat.available:hover,
            .seats-container .seats-row .seat.available:active,
            .seats-container .seats-row .seat.available:focus {
                fill:${primaryColor} !important;
                stroke:${primaryHover} !important;
            }
            .seats-container .seats-row .seat.selected,
            .seats-container .seats-row .seat.selected:hover,
            .seats-container .seats-row .seat.selected:active,
            .seats-container .seats-row .seat.selected:focus,
            .seats-container .seats-row .seat.available.selected,
            .seats-container .seats-row .seat.available.selected:hover,
            .seats-container .seats-row .seat.available.selected:active,
            .seats-container .seats-row .seat.available.selected:focus {
                fill:${primaryHover} !important;
                stroke:${primaryColor} !important;
                transform: scale(1.2);
            }
            @media (min-width: 992px) {
                .navbar .mat-badge-content, .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
                    background-color: ${secondaryColor} !important;
                    color: ${secondaryText} !important;
                }
            }
            .letter-holder {background-color:${secondaryHover} !important; }.letter {background-color:${secondaryColor} !important; color:${secondaryText} !important; border-color:${secondaryText} !important; }
        </style>`;

        return this.sanitizer.bypassSecurityTrustHtml(innerHTML);
    }

    public getLogoStyle() {

        if (this.settings?.logoURI) {

            const innerHTML = `<style>
                @media (min-width: 992px) {
                    .agencyLogo {
                        display:inline-block !important;
                    }
                    .agencyLogo.default {
                        display:none !important;
                    }
                }
            </style>`;

            return this.sanitizer.bypassSecurityTrustHtml(innerHTML);

        } else {

            const innerHTML = `<style>
                @media (min-width: 992px) {
                    .agencyLogo {
                        display:none !important;
                    }
                    .agencyLogo.default {
                        display:inline-block !important;
                    }
                }
            </style>`;

            return this.sanitizer.bypassSecurityTrustHtml(innerHTML);

        }



    }
}
