import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Ticket } from '@app/data/models/ticket.model';
import * as _ from 'lodash';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import { ITicketService } from '@app/shared/ticket-spicket/interfaces/ticket-service.interface';
import { HttpFanwebClient } from '@app/core/http/http-nest.service';

@Injectable()
export class TicketService implements ITicketService {

    public tickets: Ticket[] = new Array<Ticket>();

    private _basePath: string = 'fans/tickets'

    constructor(
        private _http: HttpClient,
        private _httpFanweb: HttpFanwebClient
    ) { }

    public getTickets(): Observable<Ticket[]> {
        return this._http.get<Ticket[]>(this._basePath).pipe(
            map((tickets) => this.tickets = tickets.map((ticket) => new Ticket().deserialize(ticket)))
        )
    }

    /**
     * retrieves the tickets for a single event to make sure we've got the latest
     *
     */
    public getEventTickets(uuid: string): Observable<Ticket[]> {
        const url = `${this._basePath}/${uuid}`;
        return this._http.get<Ticket[]>(url).pipe(
            map((tickets) => tickets.map((ticket) => new Ticket().deserialize(ticket)))
        );

    }

    /**
     * returns a single Ticket object based on the provided event UUID
     *
     * @param eventUUID
     * @returns Observable array of ticket objects
     *
     */
    getTicket(eventUUID: string): Observable<Ticket> {
        const url = `${this._basePath}/${eventUUID}`;
        return this._http.get<Ticket>(url).pipe(
            map((ticket) => new Ticket().deserialize(ticket))
        );
    }

    /**
     * Checks in a bundle of tickets ()
     *
     */
    selfCheckIn(bundle: TicketBundle): Observable<Ticket[]> {

        const url = `${this._basePath}/checkin`;

        var ticketNumbers: string[] = bundle.tickets.map((ticket) => ticket.ticketNumber);

        var checkin: Object = {
            eventUUID: bundle.event.uuid,
            tickets: ticketNumbers
        };

        return this._http.post(url, checkin).pipe(
            switchMap(() => this.getTickets())
        );

    }

    /**
     * Calls the service API to send the ticket pdf via email
     *
     * @param uuid UUID for the Event
     */
    emailTickets(uuid: string): Observable<any> {
        const url = `${this._basePath}/${uuid}/email`;
        return this._http.post(url, {});
    }

}
