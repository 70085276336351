<div style="overflow:hidden;" style="background-color: #f5f5f5;">
    <div class="container bg-white pb-4" style="position:relative;z-index:100;">
        <div class="coverArt view" style="background-image:url('https://s3.amazonaws.com/com.ticketspicket.app/misc-images/crowd.jpeg');">
            <div class="colorOverlay"></div>
            <div class="details">
                <div class="row align-items-center">
                    <div class="col-12">
                        <h5 class="name">Renew Your 2019-20 Season Tickets Now!</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <div class="p-3 m-3 text-center">
                <div class="mx-auto pb-4">
                    <img src="https://s3.amazonaws.com/com.ticketspicket.app/misc-images/unlock.png" alt="Invalid" height="200" />
                </div>
                <h5 class="pt-4">
                    It's time again to renew your season tickets and keep your favorite seats to the big game!
                </h5>
                <p>
                    Please enter in the unique 8-digit code that was sent to you via email in order to unlock your tickets
                    and start the renewal process.
                </p>
                <div class="row">
                    <div class="col-12 offset-md-2 col-md-8">
                    <form [formGroup]="formCode" (ngSubmit)="sendCode()">
                        <formly-form [form]="formCode" [fields]="formCodeFields" [model]="code">
                        </formly-form>
                    </form>
                    </div>
                </div>
                <button class="btn btn-primary" type="button" (click)="sendCode()">
                    <i class="fa fa-lock mr-2"></i>Unlock Your Tickets
                </button>
            </div>
        </div>
    </div>
</div>