import * as moment from "moment";
import { IDeserializable } from "@app/data/models/deserializable.interface";

export class ApplicationVersion implements IDeserializable {
    name: string;
    version: string;
    dateRelease: Date;

    constructor () {}

    deserialize(input: any) {
        Object.assign(this, input);
        this.dateRelease = moment(input.dateRelease).toDate();
        return this;
    }

}