import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { of, Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { UserService } from '@app/data/services/user.service';
import { Reward } from '@app/data/models/reward.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Pagination } from '@app/data/models/pagination.model';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { MessageService } from '@app/shared/ticket-spicket/message.service';

@Component({
  selector: 'app-user-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class UserRewardsComponent implements OnInit {
  public rewards: Reward[] = new Array<Reward>();
  public noResultsMessage = 'rewards at this time';
  public reward: Reward;
  public selectAll = false;
  public isLoading: boolean = false;
  public pagination: Pagination = new Pagination(10);

  constructor(
    private _userService: UserService,
    private _message: MessageService,
    public _modal: NgbModal,
    private _sanitizer: DomSanitizer,
    public angulartics2: Angulartics2GoogleAnalytics
  ) {}

  ngOnInit() {
    this.loadRewards();
  }

  loadRewards() {
    this.isLoading = true;
    this._userService.getRewards().subscribe(rewards => {
      this.isLoading = false;
      this.selectAll = false;
      this.rewards = rewards;
      this.rewards.forEach(reward => {
        const labelString = reward.deal.sponsor.uuid + ' - ' + reward.deal.id;
        this.angulartics2.eventTrack(
          'View', {
            category: 'Digital Reward',
            label: labelString,
            value: 1
          }
        );
      })
    });
  }

  public getDealHTML() {
    if (this.reward.deal.html) {
      const innerHTML = `${this.reward.deal.html}`;
      return this._sanitizer.bypassSecurityTrustHtml(innerHTML);
    }
    return '';
  }

  public getSelectedRewards(): Observable<Reward[]> {
    return of(this.rewards.filter(reward => reward.selected));
  }

  public markSelectedAsRead() {
    this.getSelectedRewards()
      .pipe(
        flatMap(rewards => rewards),
        flatMap(reward => this._userService.setAsRead(reward.uuid))
      )
      .subscribe(() => {}, error => this._message.toast.error(error.message, 'Error!'), () => this.loadRewards());
  }

  public showReward(content: any, reward: Reward) {
    this._userService.setAsRead(reward.uuid);
    this.reward = reward;
    const labelString = this.reward.deal.sponsor.uuid + ' - ' + this.reward.deal.id;
    this.angulartics2.eventTrack(
      'Open', {
        category: 'Digital Reward',
        label: labelString,
        value: 1 }
      );
    this.loadRewards();
    this._modal.open(content, { centered: true, size: 'lg' }).result.then(
    () => {
    }, () => {});
  }

  public selectMessage(reward: Reward) {
    reward.selected = reward.selected ? false : true;
  }

  public selectAllRewards() {
    this.selectAll = this.selectAll ? false : true;
    for (let r of this.rewards) {
      r.selected = this.selectAll;
    }
  }
}
