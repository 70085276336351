<div class="event-list-item mb-2" style="position:relative;cursor:pointer;overflow:hidden;box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);">
    <div *ngIf="event.isSportingEvent() && !parentSchoolView" class="corner-tag" [ngClass]="{'home': homeIndicator(), 'away': !homeIndicator()}">
        <div>{{homeIndicator()}}</div>
    </div>
    <ts-event-header-list-item [event]="event" [logo]="getLogo()"></ts-event-header-list-item>
    <div class="row align-items-center text-muted m-0 location" *ngIf="!schoolView || (schoolView && !event.isPassOnly())">
        <div class="col-12 footer py-2">
            <div class="text-center select float-right">
                <span *ngIf="!schoolView">Claim Tickets</span>
                <span *ngIf="schoolView">Buy Tickets</span>
                <i class="fa fa-arrow-right ml-2"></i>
            </div>
        </div>
    </div>
</div>