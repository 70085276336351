import { Component, OnInit, Input } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Agency } from '@app/data/models/agency.model';

@Component({
  selector: 'app-agency-passes-list',
  templateUrl: './passes-list.component.html',
  styleUrls: ['./passes-list.component.scss']
})
export class AgencyPassesListComponent implements OnInit {

    @Input() passes: GatePass[];
    @Input() agency: Agency;
    @Input() showCount: number = -1;

    public showPassList: boolean = false;
    public noResultsMessage = 'passes at this time';

    constructor () { }

    ngOnInit() { }

    togglePassList() {
        this.showPassList = !this.showPassList;
    }

    getPasses(): GatePass[] {
        if (this.showCount > 0) {
            return this.passes.slice(0, this.showCount);
        }
        return this.passes;
    }
}
