<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency.settings" style="position:relative;z-index:100;">
      <ts-agency-cover [agency]="agency" class="fallback-gradient"></ts-agency-cover>
        <nav aria-label="breadcrumb" *ngIf="agency.parent">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a [routerLink]="['/agency', agency.parent.uuid]" >{{ agency.parent.name }}</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">{{ agency.name }}</li>
            </ol>
        </nav>
        <!-- <ts-sponsor-banner></ts-sponsor-banner> -->
        <div class="callToAction" *ngIf="getPasses() && getPasses().length > 0">
            <ts-call-to-action [version]="'v1'" [agency]="agency" routerLink="passes"></ts-call-to-action>
            <!-- <ts-call-to-action [version]="'v2'" [agency]="agency" (click)="goToRenewal()"></ts-call-to-action> -->
        </div>
        <div class="my-3 pb-3 text-center d-none d-lg-block" *ngIf="agency.hasChildren() && agency.children.length < 9">
            <div class="text-center d-inline-block child-container mx-3"  style="vertical-align:top;" *ngFor="let agency of agency.children" [routerLink]="['/agency', agency.uuid]">
                <img *ngIf="agency.settings?.logoURI" style="vertical-align:initial;" [src]="agency.settings?.logoURI" alt="agency.name" height="60" class="d-inline-block mx-auto child-logo" />
                <div *ngIf="!agency.settings?.logoURI" class="d-inline-block mx-auto default-child-logo mb-2" [ngStyle]="{'background-color': agency.settings?.primaryColor, 'color': agency.settings?.primaryText}">{{ agency.name | limitTo: 1 }}</div>
                <div class="text-uppercase mascot font-sm mb-3 d-inline-block">
                    <strong style="white-space:nowrap;" *ngIf="agency.nameMascot">{{ agency.nameMascot }}</strong>
                    <strong style="white-space:nowrap;" *ngIf="!agency.nameMascot">{{ agency.nameShort }}</strong>
                </div>
            </div>
        </div>
        <div class="my-3 pb-3 text-center d-none d-lg-block"  *ngIf="agency.hasChildren() && agency.children.length > 8">
            <div class="mx-auto">
                <button mdbRippleRadius *ngIf="agency.id === 9188" type="button" class="btn btn-primary btn-block" (click)="agencyList(agency.children)">View Sections</button>
                <button mdbRippleRadius *ngIf="agency.id != 9188" type="button" class="btn btn-primary btn-block" (click)="agencyList(agency.children)">View Schools</button>
            </div>
        </div>
        <div class="my-3 pb-3 text-center d-block d-lg-none" *ngIf="agency.hasChildren()">
            <div class="mx-auto">
                <button mdbRippleRadius *ngIf="agency.id === 9188" type="button" class="btn btn-primary btn-block" (click)="agencyList(agency.children)">View Sections</button>
                <button mdbRippleRadius *ngIf="agency.id != 9188" type="button" class="btn btn-primary btn-block" (click)="agencyList(agency.children)">View Schools</button>
            </div>
        </div>
        <div id="events" class="mt-3" style="min-height:300px;">
            <app-agency-events-list [events]="getEvents()" [agency]="agency" showCount="6"></app-agency-events-list>
            <div class="text-center mb-4" *ngIf="hasEvents()">
                <button mdbRippleRadius type="button" class="btn btn-primary btn-sm" style="min-width:150px;" routerLink="events">View All Events</button>
            </div>
        </div>
        <div id="passes" *ngIf="getPasses() && getPasses().length > 0">
            <app-agency-passes-list [passes]="getPasses()" [agency]="agency" showCount="3"></app-agency-passes-list>
            <div class="mb-4 text-center">
                <button mdbRippleRadius type="button" class="btn btn-primary btn-sm" style="min-width:150px;" routerLink="passes">View All Passes</button>
            </div>
        </div>
        <div *ngIf="agency.settings?.twitterURI && agency.settings?.facebookURI">
            <div id="latest-news" class="row">
                <div class="col-12">
                    <ts-section-header class="h5-responsive mb-3">
                        Latest News
                        <div style="clear:both;"></div>
                    </ts-section-header>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-4">
                    <ngx-twitter-timeline [data]="{sourceType: 'url', url: agency.settings?.twitterURI }" [opts]="{tweetLimit: 2, height: 200, chrome: 'noheader nofooter noborders'}" ></ngx-twitter-timeline>
                </div>
                <div class="col-12 col-sm-4">
                    <!-- <fb-page href="agency.settings?.facebookURI"></fb-page> -->
                </div>
                <div class="col-12 col-sm-4 sponsorAd">
                    <div class="text-center">
                        <img src="https://moatsearch-data.s3.amazonaws.com/creative_screens/d1/83/a0/d183a0a1523484d01af63c87951e4a22.jpg" width="100%" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="agency.sponsors">
            <ts-section-header>Sponsors</ts-section-header>
        </div>
    </div>
    <svg class="background">
        <use xlink:href="#background" x="0px" y="0px"></use>
    </svg>
</div>
