import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity } from '@app/data/models/activity.model';
import { AgencyService } from '@app/data/services/agency.service';
import * as _ from 'lodash';
import { EventFilter } from '@app/data/models/events/event-filter.model';

@Component({
  selector: 'app-agency-event-filter-modal',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class EventFilterModalComponent implements OnInit {

    // only for members tickets page
    @Input() showAgenciesFilter = false;

    @Input()
    public filter: EventFilter = new EventFilter();

    constructor (
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void  {
    }

    close() {
        this.activeModal.close(true);
    }

}
