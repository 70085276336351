import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { CartItemEventSummary } from '@app/data/models/cart/cart-item-event';
import { CartItem } from '@app/data/models/cart/cart-item.model';
import * as _ from 'lodash';

@Component({
    selector: 'ts-cart-ticket',
    templateUrl: './cart-ticket.component.html',
    styleUrls: ['./cart-ticket.component.scss']
})
export class CartTicketComponent implements OnInit {

    @Input('summary') summary: CartItemEventSummary;

    @Output() update: EventEmitter<CartItemEventSummary> = new EventEmitter<CartItemEventSummary>();

    constructor(
        private _cart: CartService,
        private _messageService: MessageService
    ) { }

    ngOnInit() {
    }

    public removeSeat(item: CartItem) {
        this._cart.removeReservedSeat(item.product, item.seat).subscribe(() => {
            this._messageService.toast.success('Your seat has been removed from the cart.');
            this.update.emit(this.summary);
        });
    }

    public updateItem(item: any) {
        this._cart.items.setProductTicketTypeQuantity(item.product, item.ticketPrice, item.quantity);
        this._messageService.toast.success('Your tickets have been updated in the cart.');
        this.update.emit(this.summary);
    }

    public get agencyRoute() {
        return this._cart.embedded ? '/embed/agency' : '/agency';
    }
}
