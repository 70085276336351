import { Component, Input } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';
import { Event } from '@app/data/models/event.model';

@Component({
    selector: 'ts-event-list-item',
    templateUrl: './event-list-item.component.html',
    styleUrls: ['./event-list-item.component.scss']
})

export class EventListItemComponent {
    @Input('event') event: Event;
    @Input('agency') agency: Agency;
    @Input('schoolView') schoolView: boolean;
    @Input('parentSchoolView') parentSchoolView: boolean;

    constructor() {
    }

    ngOnInit() {
        if (!this.agency) {
            this.schoolView = false;
            this.agency = this.event.agency;
        }
    }

    public isHomeEvent(): boolean {
        return this.event.isHome(this.agency);
    }

    public homeIndicator(): string {
        return this.isHomeEvent() ? 'H' : 'A';
    }

    public getLogo(): string {
        return this.event.getLogo(this.agency);
    }

}
