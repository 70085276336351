import { IDeserializable } from "@app/data/models/deserializable.interface";
import { ReservedSeatCategory } from "./reserved/seat.model";

export class TicketLevel implements IDeserializable {

    id: number;
    title: string;
    value: string;
    className: string;
    rank: number;
    isActive: boolean;
    description: string;
    digitalCount: number;
    gateCount: number;
    ticketColor: string;

    isReserved: boolean = false;
    reservedCategory: ReservedSeatCategory;

    constructor() {}

    deserialize(input: any) {

        if (input.reservedCategory) {
            this.reservedCategory = new ReservedSeatCategory().deserialize(input.reservedCategory);
        }

        if(input.reserved_category) {
            this.reservedCategory = new ReservedSeatCategory().deserialize(input.reserved_category);
        }

        if(input.name) {
            this.id = input.id;
            this.value = input.name; // Assuming 'value' is the UUID
            this.rank = input.rank;
            this.isActive = input.is_active;
            this.description = input.description;
            this.gateCount = input.is_gate_ticket ? 1 : 0; // Assuming `is_gate_ticket` is a boolean
            this.ticketColor = input.ticketColor;
            this.isReserved = input.reserved_category_id != null; // Assuming a null value means not reserved
        }

        // ... map other properties ...

        Object.assign(this, input);

        return this;
    }

}
