import { Component, OnInit } from '@angular/core';
import { TicketService } from '@app/data/services/ticket.service';
import { PassService } from '@app/data/services/pass.service';
import { TransferService } from '@app/data/services/transfer.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
    
    constructor (
        private _ticketService: TicketService,
        private _passService: PassService,
        private _transferService: TransferService
    ) { }

    ngOnInit() {
        //this._ticketService.getTickets().subscribe();
        //this._passService.getPasses().subscribe();
        //this._transferService.getTransfers().subscribe();
    }

    get hasIncomingTransfer(): boolean {
        return this._transferService.getIncomingTransferCount() > 0;
    }

}