<div class="background-color">
    <div class="container bg-white pb-4 pt-3">
        <ts-section-header>Rewards
            <div class="actions btn-group float-right" role="group" aria-label="Use these options to impact your rewards inbox.">
                <button type="button" mdbRippleRadius class="btn btn-outline-secondary btn-sm" title="Refresh" (click)="loadRewards()"><i class="fa fa-refresh mr-0 mr-md-2" [ngClass]="{'fa-spin': isLoading}"></i><span class="d-none d-md-inline">Refresh</span></button>
                <button type="button" mdbRippleRadius class="btn btn-outline-secondary btn-sm" title="Mark As Read" (click)="markSelectedAsRead()"><i class="fa fa-envelope-open-o mr-0 mr-md-2"></i><span class="d-none d-md-inline">Mark As Read</span></button>
            </div>
        </ts-section-header>
        <ts-no-results [message]="noResultsMessage" *ngIf="rewards.length == 0"></ts-no-results>
        <div *ngIf="rewards.length > 0">
            <mat-list class="my-1 mb-4">
                <mat-list-item class="py-0 m-0" (click)="selectAllRewards()">
                    <i mat-list-icon class="fa fa-fw mr-3" [ngClass]="{'fa-square-o' : !selectAll , 'fa-check-square-o' : selectAll }"></i>
                    <h5 mat-line><strong>SELECT ALL</strong></h5>
                    <mat-divider></mat-divider>
                </mat-list-item>
                <mat-list-item *ngFor="let r of rewards.slice(pagination.startItem(), pagination.endItem())" (click)="showReward(rewardModal, r)" class="py-0 m-0" [ngClass]="{'unread' : !r.dateRead , 'selected' : r.selected }" >
                    <i click-stop-propagation mat-list-icon class="fa fa-fw mr-3" [ngClass]="{'fa-square-o' : !r.selected , 'fa-check-square-o' : r.selected }" (click)="selectReward(r)"></i>
                    <i mat-list-icon class="fa fa-sm fa-fw" [ngClass]="{'fa-circle' : !r.dateRead}"></i>
                    <h5 mat-line><strong>{{r.deal.title}}</strong></h5>
                    <div mat-line class="font-sm"><strong>From:</strong> {{r.deal.sponsor.name}}</div>
                    <div mat-line class="font-sm"><strong>Offer expires:</strong> {{r.dateEnd | date: 'MM/dd/yyyy'}}</div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
            <ts-pagination [pagination]="pagination" [collectionSize]="rewards.length"></ts-pagination>
        </div>
    </div>
</div>
<ng-template #rewardModal let-c="close" let-d="dismiss">
  <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close"
    (click)="d()">
    <span style="font-size:3rem;font-weight:300;" aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body p-0 mb-2">
    <div *ngIf="!reward.deal.html">
      <div class="coverArt" [ngStyle]="{'background-image': 'url(' + reward.deal.featureGraphicURI + ')'}"></div>
      <div class="logo" [ngStyle]="{'background-image': 'url(' + reward.deal.sponsor.logoURI + ')'}"></div>
      <div class="row">
        <div class="col-12 mb-2">
          <div class="card-body text-center">
            <div *ngIf="reward.deal.sponsor.name">
              <div class="name">
                {{ reward.deal.sponsor.name }}
              </div>
            </div>
            <div *ngIf="reward.deal.sponsor.tagline">
              <div class="tagline">
                {{ reward.deal.sponsor.tagline }}
              </div>
            </div>
            <div *ngIf="reward.deal.sponsor.vanityURI">
              <div class="website">
                <a>{{ reward.deal.sponsor.vanityURI }}</a>
              </div>
            </div>
            <h5 class="border-0">
              {{ reward.deal.title }}
            </h5>
            <div class="mb-2">
              {{ reward.deal.subtitle }}
            </div>
            <div class="my-2">
              {{ reward.deal.description }}
            </div>
            <div *ngIf="reward.deal.promoCode">
              <div class="promo-code">
                <div class="mb-2" style="font-size:0.8rem;font-weight:bold;">Promo Code:</div>
                {{ reward.deal.promoCode }}
              </div>
            </div>
            <div class="my-2">
              {{ reward.deal.finePrint }} Offer expires: {{ reward.dateEnd | date: 'MM/dd/yyyy' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="reward.deal.html" [innerHTML]="getDealHTML()">
    </div>
  </div>
  <div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="d()">Close</button>
  </div>
</ng-template>