import { Component, OnInit, Input } from '@angular/core';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import { TicketService } from '@app/data/services/ticket.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import tinycolor from "tinycolor2";

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss']
})
export class TicketModalComponent implements OnInit {

    @Input() eventTicket: EventAdmission;

    redeemProcess: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        public _ticketService: TicketService
    ) { }

    ngOnInit() { }

    redeemProcessStarted(state: any) {
        this.redeemProcess = true;
    }

    public getCloseIconColor() {
        const lineColor = tinycolor(this.eventTicket.event.agency.settings?.primaryColor);
        const textColor = tinycolor(this.eventTicket.event.agency.settings?.primaryText);
        let newTextColor = tinycolor.isReadable(textColor, lineColor,{level:"AA",size:"small"}) ? textColor : '#fff';

        return {
            color: newTextColor,
        }
    }

    close() {
        this.activeModal.dismiss(false);
        if (this.redeemProcess) {
            this._ticketService.getTickets().subscribe();
        }
    }

}
