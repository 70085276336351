import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CartService, ICart } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import { NgxBraintreeComponent } from '@app/cart/braintree/ngx-braintree.component';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SentryTransaction } from '@app/core';


@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

    @ViewChild(NgxBraintreeComponent, { static: false }) paymentMethod: NgxBraintreeComponent;

    public code = {
        phoneNumber: ""
    }

    enablePayButton: boolean = false;
    interval: any;

    constructor(
        public cart: CartService,
        private _router: Router,
        private _messageService: MessageService,
        private activeModal: NgbActiveModal
    ) { }

    public formCode: FormGroup = new FormGroup({});
    public phoneRegex = new RegExp(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/);
    public formCodeFields: FormlyFieldConfig[] = [
        {
            className: 'col-12',
            key: 'phoneNumber',
            type: 'input',
            parsers: [(value: string) => this.formatNumber(value)],
            templateOptions: {
                type: 'tel',
                label: 'Phone Number',
                placeholder: '(555) 987-6543',
                required: true,
                appearance: 'outline',
                pattern: this.phoneRegex,
            },
            expressionProperties: {
                'model.phoneNumber': 'model.phoneNumber',
            },
            modelOptions: {
                updateOn: 'blur',
            },
        }
    ];

    public sendCode() {
        this._messageService.toast.success('success.');
    }

    public formatNumber(value: string): string {
        if (!value || value === '') {
            return value;
        }

        var cleaned = ('' + value).replace(/\D/g, '')
        var match = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

        return match;
    }

    ngOnInit() {
        this.cart.isGuestCheckout = JSON.parse(localStorage.getItem(this.cart.storageKey)).isGuestCheckout;
        this.cart.expiration.extendExpiration();
        this.interval = setInterval(() => {
            if (this.cart.countdown === "02:00") {
                this.promptForTime();
            } else if (this.cart.countdown === "00:00") {
                this.activeModal.dismiss(false);
                this._router.navigate([this.cart.cartRoute]);
            }
        }, 1000);
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    onPaymentStatus(event: any) {
        this._router.navigate(['/cart/confirmation']);
    }

    onPayButtonStatus(event: boolean) {
        this.enablePayButton = event;
    }

    checkout() {
        this.paymentMethod.pay(this.code.phoneNumber);
    }

    completePurchase() {
        this.cart.checkout(this.code).subscribe((confirmation) => {
            SentryTransaction('Checkout', 'Complete Purchase', confirmation);
            this._router.navigate(['/cart/confirmation']);
        }, (error) => {
            console.log(error);
        })
    }

    promptForTime() {
        if (this.cart.expiration.canExtend()) {
            this._messageService.ConfirmYesNo("Are you still entering your payment information? Click 'Yes' to extend your session.").then(() => {
                this.cart.expiration.extendExpiration();
            });
        }
    }

    back() {
        this.cart.setIsGuestCheckout(false);
    }
}
