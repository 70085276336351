import { Injectable } from "@angular/core";
import { HttpFanwebClient } from "@app/core/http/http-nest.service";

@Injectable()
export class PaymentMethodsService {

    constructor(private _httpFanweb: HttpFanwebClient) { }

    markAsDefault(paymentMethodNonce: string) {
        const url = `user-payment-methods/mark-as-default`;
        return this._httpFanweb.put(url, { payment_method_nonce: paymentMethodNonce });
    }
}
