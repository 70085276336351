import { IDeserializable } from "@app/data/models/deserializable.interface";

export class TransferUser implements IDeserializable {

    public email: string;
    public name: string;
    public isSelected: boolean;
    public nameFirst: string;
    public nameLast: string;

    constructor() {}

    deserialize(input: any) {

        Object.assign(this, input);

        return this;
    
    }

}