<div class="consumer-pass-list-item mb-2" (click)="openBottomSheet(consumer)">
    <div class="unconfigured-indicator" *ngIf="!consumer.holderName">
        <i class="fa fa-circle"></i>
    </div>
    <div class="text-center letter-holder d-inline-block p-2">
        <div class="letter">
            <span *ngIf="consumer.holderName">{{ consumer.holderName | limitTo: 1 }}</span>
            <span *ngIf="!consumer.holderName">U</span>
        </div>
    </div>
    <div class="d-inline-block ml-2 pt-1" style="vertical-align:top;">
        <div class="title font-weight-bold">
            <span *ngIf="consumer.holderName">{{ consumer.holderName }}</span>
            <i *ngIf="!consumer.holderName">Unassigned</i>
        </div>
        <div class="name">
            {{ consumer.level.value }}
            <span *ngIf="consumer.seatNumber" class="ml-2">
                {{ consumer.seatNumber }}
            </span>
        </div>
    </div>
    <div class="row align-items-center m-0 font-sm location">
        <div class="col-12 card-footer py-2 px-3">
            <div class="text-center float-left transferred" *ngIf="consumer.isTransferred">
                <i class="fa fa-circle mr-1"></i> Transfer Pending
            </div>
            <div class="text-center select float-right">
                <span>View Options</span>
                <i class="fa fa-arrow-right ml-2"></i>
            </div>
        </div>
    </div>
</div>