import { Component, Input } from '@angular/core';
import { AgencySearchResult } from '@app/data/models/agency-search.model';

@Component({
  selector: 'ts-agency-list-item',
  templateUrl: './agency-list-item.component.html',
  styleUrls: ['./agency-list-item.component.scss']
})

export class AgencyListItemComponent {
  @Input('agency') agency: AgencySearchResult;

  constructor() {}
}
