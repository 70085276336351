<div class="legend px-3 pb-3">
    <div class="row text-left text-sm-center">
        <div class="col-6 col-sm-3 key">
            <i class="fas fa-square mr-2 redeemed border-0"></i>
            Ready To Use
        </div>
        <div class="col-6 col-sm-3 key">
            <i class="fas fa-square mr-2 gates border-0"></i>
            Gates Not Open
        </div>
        <div class="col-6 col-sm-3 key">
            <i class="fas fa-square mr-2 claim border-0"></i>
            Ready To Claim
        </div>
        <div class="col-6 col-sm-3 key">
            <i class="fas fa-square mr-2 shared border-0"></i>
            Transferred
        </div>
    </div>
</div>
