import { ValidatorFn, AbstractControl, FormGroup, ValidationErrors, FormControl } from "@angular/forms";

export class StrongValidationModel {

    length: boolean = true;
    alpha: boolean = true;
    uppercase: boolean = false;
    lowercase: boolean = false;
    number: boolean = true;
    special: boolean = false;
    strength: number = 0;

    constructor () { }

    public setStrength() {

        this.strength = 3;

        if (this.length) this.strength--;
        if (this.alpha) this.strength--;
        if (this.uppercase) this.strength--;
        if (this.lowercase) this.strength--;
        if (this.number) this.strength--;
        if (this.special) this.strength--;

    }

    public hasErrors(): boolean {
        //return (this.length || this.uppercase || this.lowercase || this.number || this.special )
        return (this.length || this.alpha || this.number )
    }

}

export function strongPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

        const password: string = control.value;
        let errors: StrongValidationModel = new StrongValidationModel();

        // if there is no password, then it fails all tests
        if (password == null) {
            return {strong: errors};
        }

        let lengthRegEx = new RegExp("^(?=.{6,})");
        let alphaRegEx = new RegExp("^(?=.*[A-Z, a-z])");
        // let upperRegEx = new RegExp("^(?=.*[A-Z])");
        // let lowerRegEx = new RegExp("^(?=.*[a-z])");
        let numberRegEx = new RegExp("^(?=.*[0-9])");
        // let specialRegEx = new RegExp("^(?=.*[!@#\$%\^&\*])");

        // check for one uppercase
        errors.length = !lengthRegEx.test(password);
        errors.alpha = !alphaRegEx.test(password);
        // errors.uppercase = !upperRegEx.test(password);
        // errors.lowercase = !lowerRegEx.test(password);
        errors.number = !numberRegEx.test(password);
        // errors.special = !specialRegEx.test(password);

        errors.setStrength();

        return errors.hasErrors() ? {strong: errors} : null;

    };
}

export function confirmPasswordValidator (password: AbstractControl, confirm: AbstractControl): ValidatorFn{
    return (control: FormGroup): ValidationErrors | null => {
        return password && confirm && password.value === confirm.value ? null : { 'confirmPassword': true };
    }
};

export function confirmEmailValidator (email: AbstractControl, confirm: AbstractControl): ValidatorFn{
    return (control: FormGroup): ValidationErrors | null => {
        return email && confirm && email.value === confirm.value ? null : { 'confirmEmail': true };
    }
};