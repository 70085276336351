import { Injectable } from '@angular/core';
import { Event } from '@app/data/models/event.model';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import * as _ from 'lodash';
import { Observable, of, from, BehaviorSubject, Subject } from 'rxjs';
import { AdmissionSource, IAdmission } from '../models/tickets/admission.interface';
import { ClaimableEvent } from '../models/passes/claimable-events.model';
import * as moment from 'moment';
import { Ticket } from '../models/ticket.model';
import { TicketService } from './ticket.service';
import { TransferService } from './transfer.service';
import { PassService } from './pass.service';
import { switchMap, tap } from 'rxjs/operators';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';
import { Transfer } from '@app/data/models/transfer.model';
import { map } from 'lodash';

@Injectable()
export class UserAdmissionService  {

    private _admission: EventAdmission[] = new Array<EventAdmission>();
    public admission$ = new BehaviorSubject<EventAdmission[]>(new Array<EventAdmission>());

    public passes$ = new BehaviorSubject<ConsumerPass[]>(new Array<ConsumerPass>());
    public ticketBadgeCount$ = new BehaviorSubject<number>(0);

    constructor (
        private _ticketService: TicketService,
        private _transferService: TransferService,
        private _passService: PassService
    ) { }

    public clearAdmission() {
        this._admission = new Array<EventAdmission>();
    }

    public loadAdmission(): Observable<EventAdmission[]> {

        this._admission = new Array<EventAdmission>();

        return this.loadTickets().pipe(
            switchMap(() => this.loadClaimableEvents()),
            switchMap(() => this.getPasses()),
            switchMap(() => this.getTransfers()),
            tap(() => {
                this.admission$.next(this._admission),
                this.ticketBadgeCount$.next(this.getTicketBadgeCount())
            }),
            switchMap(() => {
                // return this.admission$.asObservable()
                return of(this._admission);
            })
        )

    }

    public loadTickets(): Observable<Ticket[]> {
        return this._ticketService.getTickets().pipe(
            tap((tickets) => this.setTickets(tickets))
        )
    };

    public loadClaimableEvents(): Observable<ClaimableEvent[]> {
        return this._passService.getClaimableEvents().pipe(
            tap((events) => this.setPasses(events))
        )
    };

    public getPasses(): Observable<ConsumerPass[]> {
        return this._passService.getPasses().pipe(
            tap((passes) => this.passes$.next(passes))
        )
    };

    public getTransfers(): Observable<Transfer[]> {
        return this._transferService.getTransfers()
    };

    public getEventTickets(uuid: string): Observable<Ticket[]> {
        return this._ticketService.getEventTickets(uuid).pipe(
            tap((tickets) => this.setTickets(tickets))
        )
    }

    private _groupTicketsByEvent(tickets: Ticket[]): EventAdmission[] {
        return _.chain(tickets)
                .groupBy((value) => value.event.uuid)
                .map((value) =>  {
                    return {
                        event: value[0].event,
                        tickets: value
                    }
                })
                //.orderBy(['event.dateStart']).map( (ticket: any) => {
                .map( (ticket: any) => {
                    let admission = new EventAdmission(ticket.event);
                    admission.setAdmission(ticket.tickets);
                    return admission;
                })
                .value();

    }

    public getAdmission(): EventAdmission[] {
        return this._admission;
    }

    public getAdmissionByDate(date: Date): EventAdmission[] {
        return this._admission.filter((admission) => moment(date).startOf('day').isSame(moment(admission.event.dateStart).startOf('day')))
    }

    private _findEventIndex(event: Event): number {
        return this._admission.findIndex((admission) => admission.event.uuid === event.uuid);
    }

    private _addEventAdmission(admission: EventAdmission) {

        if (this.hasEventAdmission(admission.event)) {
            // TODO: figure out how to deal with this
        } else {
            this._admission.push(admission);
        }
        // this.admission$.next(this._admission);

    }

    /**
     * Returns true if the user has tickets for a given event
     *
     * @param event
     * @returns boolean
     *
     */
    public hasEventTickets(event: Event): boolean {
        if (this.hasEventAdmission(event)) {
            return this.getEventAdmission(event).hasTickets()
        } else {
            return false;
        }
    }

    /**
     * Returns true if the user has tickets for a given event
     *
     * @param event
     * @returns boolean
     *
     */
    public hasEventPasses(event: Event): boolean {
        if (this.hasEventAdmission(event)) {
            return this.getEventAdmission(event).hasPasses()
        } else {
            return false;
        }
    }

    /**
     * Returns true if the user has tickets for a given event
     *
     * @param event
     * @returns boolean
     *
     */
    public hasEventAdmission(event: Event): boolean {
        return this._findEventIndex(event) > -1;
    }

    /**
     * Returns the EventAdmission object for the given event
     *
     * @param event
     * @returns EventAdmission
     *
     */
    public getEventAdmission(event: Event): EventAdmission {
        if (this.hasEventAdmission(event)) {
            return this._admission[this._findEventIndex(event)];
        } else {
            return new EventAdmission(event);
        }
    }

    /**
     * addEventAdmission - overloaded function that can take a single EventAdmission object,
     * or an array of EventAdmission objects
     *
     * @param ticket
     *
     */
    public addEventAdmission(admission: EventAdmission);
    public addEventAdmission(admission: EventAdmission[]);
    public addEventAdmission(admission: any) {
        if (admission instanceof Array) {
            admission.map((ticket) => this._addEventAdmission(ticket));
        } else {
            this._addEventAdmission(admission);
        }
    }

    private _addOrFindEvent(event: Event) {

        // if the event doesn't exist, add it
        if (!this.hasEventAdmission(event)) {
            this._addEventAdmission(new EventAdmission(event));
        }

        return this.getEventAdmission(event);

    }

    public getEventsToday(): EventAdmission[] {
        return this.getEventsByDate(new Date());
    }

    public getEventsByDate(date: Date): EventAdmission[] {
        return this._admission.filter((eventAdmission) => moment(eventAdmission.event.dateStart).isBetween(moment(date).startOf('day'), moment(date).endOf('day')))
    }

    private _setIAdmission(event: Event, admission: IAdmission[], source: string) {
        const ea: EventAdmission = this._addOrFindEvent(event);
        const allAdmissions = admission;
        allAdmissions.push(...ea.admission);
        const filtered: any[] = allAdmissions.filter(admission => admission.source !== source);
        if (source === AdmissionSource.pass) {
            filtered.push(..._.chain(allAdmissions.filter(admission => admission.source === source))
              .uniqBy((x: any) => x.gatePass.id).value());
        } else {
            filtered.push(..._.chain(allAdmissions.filter(admission => admission.source === source))
              .uniqBy((x: any) => x.id).value());
        }
        ea.admission = filtered;
    }

    public setTickets(tickets: Ticket[]) {

        // group the tickets by event
        let events: EventAdmission[] = this._groupTicketsByEvent(tickets);

        // loop events and and set the tickets
        events.map((event) => {
            this._setIAdmission(event.event, event.getTickets(), AdmissionSource.ticket);
        })

    }

    public setPasses(events: ClaimableEvent[]) {

        events.map((event) => {
            this._setIAdmission(event.event, event.passes, AdmissionSource.pass);
        })
    }

    public getTicketBadgeCount(): number {
        return this.getEventsToday().length; // need to add transfers?
    }

}
