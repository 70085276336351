import { Event } from "@app/data/models/event.model";
import { ConsumerPass } from "./consumer-pass.model";

export class ClaimableEvent {

    public event: Event;
    public passes: ConsumerPass[] = new Array<ConsumerPass>();

    constructor(event: Event) {
        this.event = event;
    }

}
