<mat-card class="p-0 mb-3" [ngClass]="ticket.getStatus()" (click)="$event.stopPropagation();openBottomSheet(ticket);">
    <div class="icon-container"></div>
    <i class="fa pos-ab" [ngClass]="ticket.getStatusIcon()"></i>
    <div class="ticket-action mr-1"></div>
    <ts-event-header-list-item [event]="ticket.event" [ticketView]="true" class="float-left w-100"></ts-event-header-list-item>
    <div class="clearfix"></div>
    <div>
        <div class="row align-items-center text-muted m-0 location">
            <div class="col-12 footer py-2">
                <div class="text-center select float-right">
                    <div class="d-inline">View Options</div>
                    <i class="fa fa-arrow-right ml-2"></i>
                </div>
                <div class="float-left" *ngIf="ticket.getAvailableTickets().length > 0">
                    {{ticket.getUnusedTickets().length}} of {{ticket.getAvailableTickets().length}} available
                </div>
            </div>
        </div>
    </div>
</mat-card>