import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, switchMap } from 'rxjs/operators';
import { User } from '@app/data/models/user.model';
import { UserService } from '@app/data/services/user.service';
import { CordovaService } from '@app/data/services/cordova.service';
import * as Sentry from '@sentry/angular';
import { environment } from '@env/environment';

export interface Credentials {
    username?: string;
    token?: string;
}

export interface LoginContext {
    email: string;
    password: string;
    remember?: boolean;
}

export interface ResetContext {
    email: string;
}

export interface RegistrationContext {
    email: string;
    nameFirst: string;
    nameLast: string;
    password: string;
    passwordConfirm: string;
}

export interface SignUpContext {
    email: string;
    nameFirst: string;
    nameLast: string;
    password: string;
    passwordConfirm: string;
    verification_confirmation: string;
}

export interface ChangePasswordContext {
    token: string;
    password: string;
    passwordConfirm: string;
}

const credentialsKey = 'credentials';
const jwtHelper = new JwtHelperService();

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

    public _credentials: Credentials | null;
    public _fanWebUrl: string = environment.apiUrl.fanweb;
    public httpOptions = {};

    constructor(
        private _http: HttpClient,
        private _userService: UserService,
        private _cordovaService: CordovaService,

    ) {
        const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);

        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
            // remove the token if it has expired
            if (jwtHelper.isTokenExpired(this._credentials.token)) {
                this._credentials.token = null;
            }
        }
    }

    /**
     * Authenticates the user.
     * @param {LoginContext} context The login parameters.
     * @return {Observable<Credentials>} The user credentials.
     */
    login(context: LoginContext) {
        // call the auth/login service to authenticate the user
        const url = `${this._fanWebUrl}/auth/login`;
        const contextMinusRemember = { email: context.email, password: context.password };
        return this._http.post(url, contextMinusRemember)
            .pipe(
                map((response: any) => {
                    this.setCredentials({ token: response.accessToken, username: context.email }, context.remember)
                }),
                switchMap(() => this._userService.getUser())
            );
    }

    facebook(fbResponse: any) {
        let token = {
            access_token: fbResponse.authToken ? fbResponse.authToken : fbResponse.token
        };

        // ELH - the fbResponse is formatted slightly differently with the Cordova plugin.
        if (this._cordovaService.onCordova) {
            token = { access_token: fbResponse.authResponse.accessToken };
        }

        return this._http.post('auth/facebook', token)
            .pipe(
                map((response: any) => this.setCredentials({ token: response.token, username: null }, true)),
                switchMap(() => this._userService.getUser()),
                map((user) => this.updateCredentials(user.email))
            );
    }

    /**
     * Registers a new user
     *
     * @param context
     */
    register(context: RegistrationContext): Observable<User> {
        return this._http.post('auth/register', context).pipe(
            map((response) => new User().deserialize(response))
        );
    }

    /**
     * Registers a new user
     *
     * @param context
     */
    signup(context: SignUpContext): Observable<{ success: boolean; message: string; }> {
        let url = `${this._fanWebUrl}/user/signup`;

        const body = {
            email: context.email,
            password: context.password,
            verification_confirmation: context.verification_confirmation,
            first_name: context.nameFirst,
            last_name: context.nameLast
        }
        return this._http.post(url, body).pipe(
            map((response: { success: boolean; message: string; }) => {
                return {
                    success: response.success,
                    message: response.message
                }
            })
        );
    }

    /**
     * Sends Verification Code
     *
     * @param email
     */
    generateVerificationCodeForSignup(email: string): Observable<{ success: boolean; message: string; }> {
        let url = `${this._fanWebUrl}/user/generate-verification-code/signup`;
        return this._http.post(url, { email }).pipe(
            map((response: { success: boolean; message: string; }) => {
                return {
                    success: response.success,
                    message: response.message
                }
            })
        );
    }

    /**
     * Sends Verification Code
     *
     * @param email
     */
    generateVerificationCodeForPasswordReset(email: string): Observable<{ success: boolean; message: string; }> {
        let url = `${this._fanWebUrl}/user/generate-verification-code/password-reset`;
        return this._http.post(url, { email }).pipe(
            map((response: { success: boolean; message: string; }) => {
                return {
                    success: response.success,
                    message: response.message
                }
            })
        );
    }

    /**
     * Verifies Verification Code
     *
     * @param email
     * @param code
     */
    verifyVerificationCodeForSignup(email: string, code: string): Observable<{ success: boolean; message: string; confirmation_code: string; }> {
        let url = `${this._fanWebUrl}/user/verify-verification-code/signup`;
        return this._http.post(url, { email, code }).pipe(
            map((response: { success: boolean; message: string; confirmation_code: string; }) => {
                return {
                    success: response.success,
                    message: response.message,
                    confirmation_code: response.confirmation_code
                }
            })
        );
    }

    /**
     * Verifies Verification Code
     *
     * @param email
     * @param code
     */
    verifyVerificationCodeForPasswordReset(email: string, code: string): Observable<{ success: boolean; message: string; confirmation_code: string; }> {
        let url = `${this._fanWebUrl}/user/verify-verification-code/password-reset`;
        return this._http.post(url, { email, code }).pipe(
            map((response: { success: boolean; message: string; confirmation_code: string; }) => {
                return {
                    success: response.success,
                    message: response.message,
                    confirmation_code: response.confirmation_code
                }
            })
        );
    }

    /**
     * Verifies Verification Code
     *
     * @param verification_confirmation
     * @param email
     * @param password
     */
    resetPasswordWithVerification(verification_confirmation: string, email: string, password: string): Observable<{ success: boolean; message: string; }> {
        let url = `${this._fanWebUrl}/user/reset-password`;
        return this._http.post(url, { verification_confirmation, email, password }).pipe(
            map((response: { success: boolean; message: string; }) => {
                return {
                    success: response.success,
                    message: response.message
                }
            })
        );
    }

    /**
     * Authenticates the user.
     * @param {ResetContext} context The password reset parameters.
     * @return {Observable<string>} The message.
     */
    resetPassword(context: ResetContext): Observable<{ success: boolean; message: string; }> {
        return this._http.post('auth/reset-password', context).pipe(
            map((response: { success: boolean; message: string; confirmation_code: string; }) => {
                return {
                    success: response.success,
                    message: response.message,
                }
            })
        );
    }

    /**
     * returns the authentication from the credentials object, which is used to pass to the
     */
    public getToken(): string {
        const savedCredentials: any = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            return JSON.parse(savedCredentials).token;
        }
        return null;
    }

    /**
     * Logs out the user and clear credentials.
     * @return {Observable<boolean>} True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        // Customize credentials invalidation here
        this.setCredentials();
        Sentry.configureScope(scope => scope.setUser(null));
        return of(true);
    }

    /**
     * Checks is the user is authenticated.
     * @return {boolean} True if the user is authenticated.
     */
    isAuthenticated(): boolean {
        return this.credentials !== null;
    }

    /**
     * Gets the user credentials.
     * @return {Credentials} The user credentials or null if the user is not authenticated.
     */
    get credentials(): Credentials | null {
        return this._credentials;
    }

    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * @param {Credentials=} credentials The user credentials.
     * @param {boolean=} remember True to remember credentials across sessions.
     */
    private setCredentials(credentials?: Credentials, remember?: boolean) {
        this._credentials = credentials || null;

        if (credentials) {
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(credentialsKey, JSON.stringify(credentials));
        } else {
            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);
        }
    }

    private updateCredentials(username: string) {
        const credentials = this._credentials;
        credentials.username = username;
        this.setCredentials(credentials, true);
    }

    public isTokenExpired(): boolean {
        return jwtHelper.isTokenExpired(this._credentials.token)
    }

    public changePassword(credentials: ChangePasswordContext): Observable<string> {
        return this._http.post('auth/change-password', credentials, { responseType: "text" });
    }

}
