import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Event } from '@app/data/models/event.model';
import { CartService } from '@app/data/services/cart.service';
import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservedSeat, ReservedSeatCategory, ReservedSeatObjectType } from '@app/data/models/reserved/seat.model';
import { CartItemSummary, CartItem } from '@app/data/models/cart/cart-item.model';
import { ReservedConfiguration } from '@app/data/models/reserved/configuration.model';
import { BehaviorSubject } from 'rxjs';
import { RenewalTicketHolderSeat } from '@app/data/models/renewal/ticket-holder-seat.model';
import { RenewalService } from '@app/data/services/renewal.service';
import { ReservedPricing } from '@app/data/models/reserved/pricing.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { ConsumerPassPunchModalComponent } from '@app/passes/punch/pass-punch-modal.component';

@Component({
  selector: 'app-agency-season-ticket-seatmap',
  templateUrl: './season-ticket-seatmap.component.html',
  styleUrls: ['./season-ticket-seatmap.component.scss']
})
export class AgencySeasonTicketSeatmapComponent implements OnInit {

    @Input()
    public event: Event;

    @Input()
    public gatePass: GatePass;

    public reservedSeats$ = new BehaviorSubject<CartItem[]>(new Array<CartItem>());

    public chart: any = null;
    public isLoading: boolean = true;

    public config: ReservedConfiguration;

    constructor (
        private _renewalService: RenewalService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {

        this._renewalService.getHoldToken().subscribe((token) => {

            this.config = new ReservedConfiguration(token);
            this.config.setEvent(this.event.uuid);
            this.config.objectWithoutPricingSelectable = false;
            this.config.maxSelectedObjects = this._renewalService.selectedSeats.length;
            this.isLoading = false;

            this.gatePass.getRESVTicketPrices().map((price) => {
                this.config.pricing.push(new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price));
            })

        })

    }

    ngOnDestroy() {
    }

    /**
     * Executed from the seatObjectSelected event on the seat map
     *
     * Since the seat is already selected in the seat map, it just needs to be added to the cart
     *
     * @param seat
     */
    public selectSeat(seat: any) {
        // find the price by category
        const categoryKey = seat.category.key;
        const price = this.gatePass.prices.find((price) => price.ticketType.reservedCategory.key == categoryKey);

        this._renewalService.selectExchangeSeat(seat.id, price);
    }

    /**
     * Called from the seatObjectDeselected event on the seat map
     *
     * It's removed from the seatmap, not it just needs to be removed from the cart
     *
     * @param seat
     */
    public deselectSeat(seat: any) {
        this._renewalService.deselectExchangeSeat(seat.id);
    }

    public setChart (chart: any) {
        this.chart = chart;
    }

    public close() {
        this.activeModal.dismiss(false);
    }

}
