import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferUser } from '@app/data/models/transfer-user.model';
import { TransferService } from '@app/data/services/transfer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { finalize } from 'rxjs/operators';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { Ticket } from '@app/data/models/ticket.model';
import { UserAdmissionService } from '@app/data/services/user-admission.service';

@Component({
    selector: 'app-ticket-transfer-modal',
    templateUrl: './transfer-modal.component.html'
})
export class TicketTransferModalComponent implements OnInit {

    @Input()
    admission: EventAdmission;

    selectedTickets: Ticket[] = new Array<Ticket>();

    transferForm: FormGroup;
    isLoading = false;

    public step = 1;

    public users: TransferUser[] = new Array<TransferUser>();
    public selectedUser: TransferUser = new TransferUser();

    constructor (
        public activeModal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        private _transferService: TransferService,
        private _messageService: MessageService,
        private _userAdmissionService: UserAdmissionService
    ) {
        this.createForm();
        this.transferForm.setValue({ email: null });
    }

    ngOnInit() {
        this._transferService.getTransferUsers().subscribe((users) => this.users = users);
    }

    private createForm() {
        this.transferForm = this._formBuilder.group({
            email: ['', Validators.required]
        });
    }

    updateStep(step: number) {
        this.step = step;
    }

    selectAll() {
        this.selectedTickets = this.admission.getTransferableTickets();
        this.step = 2;
    }

    toggleUser(user: TransferUser) {
        if (this.selectedUser === user) {
            this.selectedUser = new TransferUser();
            this.transferForm.setValue({"email": null});
        } else {
            this.selectedUser = user;
            this.transferForm.setValue({"email": user.email});
        }
    }

    isUserSelected(user: TransferUser): boolean {
        return user.email === this.selectedUser.email;
    }

    transferTickets() {

        this.isLoading = true;
        var ticketIds: number[] = this.selectedTickets.map((ticket: Ticket) => ticket.id);

        let email = this.transferForm.get("email")?.value;

        this._transferService
            .initiateTransfer(ticketIds, email, "ticket")
            .pipe(
                finalize(() => {
                    this.transferForm.markAsPristine();
                    this.isLoading = false;
                })
        )
        .subscribe(
            (message) => {
                this._messageService.toast.success("The Ticket Transfer has been started.");
                this._userAdmissionService.getTransfers().subscribe();
                this._userAdmissionService.loadTickets().subscribe();
                this.activeModal.close();
            },
            (error) => {
                console.log(error);
                this._messageService.toast.error(error.error.message);
            }
        );

    }

    /**
     * returns the index number for the given ticket in the selectedTickets collection
     *
     */
    private _findTicket(uuid: string): number {
        return this.selectedTickets.findIndex((ticket) => ticket.uuid === uuid);
    }

    toggleTicket(uuid: string) {

        // first, check to see if the tickets are in the selected collection
        var index = this._findTicket(uuid);

        if (index >= 0) {
            // if the ticket was found, remove it
            this.selectedTickets.splice(index, 1);
        } else {
            // Ticket was not already in the collection, so add it
            var tickets = this.admission.getTickets().find((ticket) => ticket.uuid === uuid);

            if (tickets) {
                this.selectedTickets.push(tickets);
            }
        }
    }

    isTicketSelected(uuid: string): boolean {
        return this._findTicket(uuid) >= 0;
    }

}
