<div class="p-3 mb-5">
    <ts-section-header>Pending Transfers</ts-section-header>
    <ts-loading message="Loading Transfers" [show]="isLoading"></ts-loading>
    <ts-no-results *ngIf="!transferService.hasCurrentTransfers()" [message]="noResultsMessage"></ts-no-results>
    <div class="row pt-3" *ngIf="transferService.hasCurrentTransfers()">
        <!-- incoming transfers -->
        <div class="col-sm-12 col-md-6 border-right">
            <p class="h5 text-center"><i class="fa fa-fw fa-inbox mr-2"></i><strong>Received</strong></p>
            <ts-no-results message="pending incoming transfers" *ngIf="transferService.currentTransfers.incoming.length == 0"></ts-no-results>
                <div class="mb-4 pb-2" *ngIf="transferService.currentTransfers.incoming.length > 0">
                <p class="py-2 text-center font-sm">Click on the options below each ticket to accept or decline your ticket transfer from another person.</p>
                <div class="card" *ngFor="let transfer of transferService.currentTransfers.incoming">
                    <div class="card-header py-1 font-sm pl-2">
                        You received {{transfer.tickets.length}} from {{transfer.fromAccount.login.name}}
                    </div>
                    <div class="card-body p-1">
                        <ts-event-header-list-item [event]="transfer.event"></ts-event-header-list-item>
                    </div>
                    <div class="card-footer text-muted p-0">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <button type="button" class="btn btn-block btn-default btn-left font-sm" title="Decline" (click)="declineTransfer(transfer)">
                                    <i class="fa fa-ban mr-3"></i>Decline
                                </button>
                            </div>
                            <div class="col-6 pl-0">
                                <button type="button" class="btn btn-block btn-success btn-right font-sm" title="Accept" (click)="acceptTransfer(transfer)">
                                    <i class="fa fa-check mr-3"></i>Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- outgoing transfers -->
        <div class="col-sm-12 col-md-6">
            <p class="h5 text-center"><i class="fa fa-fw fa-send mr-2"></i><strong>Sent</strong></p>
            <ts-no-results message="pending outgoing transfers" *ngIf="transferService.currentTransfers.outgoing.length == 0"></ts-no-results>
            <div *ngIf="transferService.currentTransfers.outgoing.length > 0">
                <p class="py-2 text-center font-sm">Click on the option below each ticket to cancel your ticket transfer to another person.</p>
                <div class="card" *ngFor="let transfer of transferService.currentTransfers.outgoing">
                    <div class="card-header py-1 font-sm pl-2">
                        You sent {{transfer.tickets.length}} to {{transfer.emailAddress}}
                    </div>
                    <div class="card-body p-1">
                        <ts-event-header-list-item [event]="transfer.event"></ts-event-header-list-item>
                    </div>
                    <div class="card-footer text-muted p-0">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <button type="button" class="btn btn-block btn-primary btn-left font-sm" title="Resend" (click)="resendTransfer(transfer)">
                                    <i class="fa fa-send mr-3"></i>Resend Transfer
                                </button>
                            </div>
                            <div class="col-6 pl-0">
                                <button type="button" class="btn btn-light btn-block btn-right font-sm" title="Cancel" (click)="cancelTransfer(transfer)">
                                    <i class="fa fa-close mr-3"></i>Cancel Transfer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="mb-5">
    <ts-section-header>Transfer Activity History</ts-section-header>
    <ts-no-results message="historical transfers" *ngIf="transferService.history.length == 0"></ts-no-results>
    <div *ngIf="transferService.history.length > 0">
        <div class="row mb-5">
            <div class="col-sm-12 col-md-4" *ngFor="let transfer of transferService.history.slice(pagination.startItem(), pagination.endItem())">
                <mat-card class="my-2 p-1">
                    <mat-card-header>
                        <mat-card-title class="m-1"><i class="fa fa-fw fa-lg mr-2" [ngClass]="{'fa-inbox': transfer.isReceiver, 'fa-send': transfer.isSender}"></i>{{transfer.dateStatus | date: 'MM/dd/yyyy hh:mm a'}}</mat-card-title>
                        <mat-card-subtitle class="m-1">{{transfer.getNarrative()}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="p-2 m-1">
                        <div>{{ transfer.event.school }}</div>
                        <div>{{ transfer.event.title }}</div>
                        <div>{{ transfer.event.subtitle }}</div>
                        <div>{{ transfer.event.dateStart | date: 'MM/dd/yyyy hh:mm a' }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <ts-pagination [collectionSize]="transferService.history.length" [pagination]="pagination"></ts-pagination>
    </div>
</div>