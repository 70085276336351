import { Component, Input } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';
import tinycolor from "tinycolor2";

@Component({
    selector: 'ts-ticket-header',
    templateUrl: './ticket-header.component.html',
    styleUrls: ['./ticket-header.component.scss']
})
export class TicketHeaderComponent {

    @Input('agency') agency: Agency;

    public getGradient() {
        const lineColor = tinycolor(this.agency.settings?.primaryColor);
        const colorIsTooDark = lineColor.getLuminance() < 0.1;
        const textColor = tinycolor(this.agency.settings?.primaryText);
        let newTextColor = tinycolor.isReadable(textColor, lineColor,{level:"AA",size:"small"}) ? textColor : '#fff';

        const backgroundGradient = `
            repeating-linear-gradient(
                135deg,
                transparent,
                transparent 5px,
                ${ this.agency.settings?.primaryColor } 5px,
                ${ this.agency.settings?.primaryColor } 10px
            ),
            linear-gradient(
                to bottom,
                ${ colorIsTooDark ? lineColor.lighten(10) : lineColor.darken(5) },
                ${ this.agency.settings?.primaryColor } 85%,
                ${ this.agency.settings?.primaryColor }
            )
        `;

        return {
            background: backgroundGradient,
            color: newTextColor,
        };
    };

    constructor() {}

}
