<div class="pt-4">
    <div class="border-0 p-3 text-center">
        <div class="mx-auto pt-4">
            <img src="https://s3.amazonaws.com/com.ticketspicket.app/misc-images/invalid.png" alt="Invalid" height="250" />
        </div>
        <h5 class="pt-4">
            These tickets are no longer valid.<br />Please, feel free to search for more exciting opportunities on Ticket Spicket<sup>&trade;</sup>.
        </h5>
    </div>
    <div style="background-color:#3a3a3a;">
        <div class="stub-top"></div>
        <div class="stub-bottom mb-3"></div>
    </div>
    <div class="border-0 p-3 text-center">
        <button class="btn btn-primary btn-block" routerLink="/">Back to Home</button>
    </div>
</div>