<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency.passes && agency" style="position:relative;z-index:100;">
        <ts-agency-cover [agency]="agency" class="fallback-gradient" style="margin-left: -15px; width: calc(100% + 30px);"></ts-agency-cover>
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                <a routerLink="../">{{ agency.name }}</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">All Passes</li>
            </ol>
        </nav>
        <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center my-3" routerLink="../">
            <i class="fa fa-arrow-left mr-2"></i>Back to {{ agency.name }}
        </button>
        <app-agency-passes-list [passes]="agency.passes" [agency]="agency"></app-agency-passes-list>
    </div>
    <svg class="background">
        <use xlink:href="#background" x="0px" y="0px"></use>
    </svg>
</div>
