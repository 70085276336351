import { Component, OnInit, Input } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { Event } from '@app/data/models/event.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventFilterModalComponent } from '@app/agency/events/filter.component';
import * as _ from 'lodash';
import { EventFilter } from '@app/data/models/events/event-filter.model';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-agency-events-list',
  templateUrl: './events-list.component.html'
})
export class AgencyEventsListComponent implements OnInit {

    @Input() events: Event[];
    @Input() agency: Agency;
    @Input() showCount: number = -1;

    public filter: EventFilter;

    @Input()
    public showEventList: boolean = false;
    public noResultsMessage = 'events at this time';
    public isSchoolView: boolean = true;

    constructor (
        private _modalService: NgbModal,
        private _agencyService: AgencyService,
        private _spinner: SpinnerService
    ) {
        this._spinner.setMessage("Loading Events...")
    }

    ngOnInit() {
        this.filter = this._agencyService.eventFilter;
        if (this.agency.hasChildren()) {
            this.isSchoolView = false;
        }
        this._spinner.hide();
    }

    toggleEventList() {
        this.showEventList = this.showEventList ? false : true;
    }

    hasEvents(): boolean {
        return this._agencyService.hasEvents();
    }

    getFilteredEvents() {
        var events = this._agencyService.filterEvents(this.events);
        if (this.showCount > 0) {
            events = events.slice(0, this.showCount);
        }
        return events.filter(e => e.isPrivate == false && e.areTicketsAvailable() == true);
    }

    clearFilter() {
        this._agencyService.clearEventFilter();
    }

    public filterEvents() {
        const modalRef = this._modalService.open(EventFilterModalComponent, { centered: true });
        modalRef.componentInstance.filter = this._agencyService.eventFilter;
    }

}
