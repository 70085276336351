import * as _ from 'lodash';
import { ConsumerPass } from './consumer-pass.model';

export class PassFilter {

  private _passes: Array<ConsumerPass> = new Array<ConsumerPass>();
  public agencies: Array<{ name: string, uuid: string }> = new Array<{ name: string, uuid: string }>();
  public activities: Array<string> = new Array<string>();
  public selectedActivities: Array<string> = new Array<string>();
  public selectedAgencies: Array<{ name: string, uuid: string }> = new Array<{ name: string, uuid: string }>();

  public isLoaded = false;

  constructor() {
  }

  /**
   * setPasses
   *
   * this sets the _passes array and then uses that array to extrac the unique set of activities for this
   * list of passes. By default, it selects all of the activities so that the getEvents returns all of the passes
   *
   * @param passes Array of Pass objects
   *
   */
  public setPasses(passes: ConsumerPass[]) {
    this.isLoaded = false;
    this._passes = passes;
    // sets the activities based on the activities in the passes collection
    this.activities = _.uniq(this._passes.filter(pass => pass.gatePass.activity)
      .map(pass => pass.gatePass.activity?.displayName));
    this.activities = _.orderBy(this.activities);
    this.agencies = _.uniqBy(this._passes.map((pass) => {
      return {
        name: pass.gatePass.agency.name, uuid: pass.gatePass.agency.uuid
      };
    }), 'uuid');
    this.agencies = _.orderBy(this.agencies, ['name']);
    this.isLoaded = true;
  }

  /**
   * getPasses
   *
   * @returns array of passes based on the selected activities, start date and end date specified in the filter
   *
   */
  public getPasses(): ConsumerPass[] {
    const data = this._passes
      .filter((pass) => {
        return this.hasAgencyFilter() ?
          this.selectedAgencies.find((agency) => agency.uuid === pass.gatePass.agency.uuid) : pass;
      })
      .filter((pass) => {
        return this.hasActivityFilter() ?
          this.selectedActivities.find((activity) => activity === pass.gatePass.activity?.displayName) : pass;
      });
      return data;
  }


  /**
   * _getActivityIndex
   *
   * @param selected: Activity
   * @returns number - index for the given activity in the selectedActivities collection
   *
   */
  private _getActivityIndex(selected: string): number {
    return this.selectedActivities.findIndex((activity) => activity === selected);
  }

  /**
   *
   * isActivitySelected
   *
   * @param activity
   * @returns boolean - true if the given activity has been selected
   *
   */
  public isActivitySelected(activity: string): boolean {
    return this._getActivityIndex(activity) >= 0;
  }

  /**
   * toggleActivity - adds/removes the given activity from the selectedActivities collection
   *
   * @param activity
   *
   */
  public toggleActivity(activity: string) {
    // first, check to see if the activity are in the selected collection
    const index = this._getActivityIndex(activity);
    if (index >= 0) {
      // if the activity was found, remove it
      this.selectedActivities.splice(index, 1);
    } else {
      // Activity was not already in the collection, so add it
      this.selectedActivities.push(activity);
    }
  }

  public toggleAgency(agency: { name: string, uuid: string }) {
    const index = this.selectedAgencies.findIndex(a => a.uuid === agency.uuid);
    if (index >= 0) {
      this.selectedAgencies.splice(index, 1);
    } else {
      this.selectedAgencies.push(agency);
    }
  }

  public isAgencySelected(agency: { name: string, uuid: string }): boolean {
    return this.selectedAgencies.findIndex(a => a.uuid === agency.uuid) >= 0;
  }

  /**
   * selectAllActivities()
   *
   * removes all of the selected activities
   */
  public selectAllActivities() {
    this.selectedActivities = new Array<string>();
  }

  public isAllActivitiesSelected(): boolean {
    return this.selectedActivities.length === 0;
  }

  public selectAllAgencies() {
    this.selectedAgencies = new Array<{ name: string, uuid: string }>();
  }

  public isAllAgenciesSelected(): boolean {
    return this.selectedAgencies.length === 0;
  }

  /**
   * hasActivityFilter()
   *
   * @returns true if the selected activities collection is populated
   */
  public hasActivityFilter(): boolean {
    return this.selectedActivities.length > 0;
  }

  /**
   * hasFilter()
   *
   * @returns boolean - true if any of the filter attributes have values
   *
   */
  public hasFilter(): boolean {
    return this.hasActivityFilter() && this.hasAgencyFilter();
  }

  /**
   * hasAgencyFilter()
   *
   * @returns true if the selected agencies collection is populated
   */
  public hasAgencyFilter(): boolean {
    return this.selectedAgencies.length > 0;
  }

  public clearFilters() {
    this.selectAllActivities();
    this.selectAllAgencies();
  }
}
