import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core';
import { AgencyService } from '@app/data/services/agency.service';
import { UserService } from '@app/data/services/user.service';
import { TicketService } from '@app/data/services/ticket.service';
import { PassService } from '@app/data/services/pass.service';
import { TransferService } from '@app/data/services/transfer.service';
import { InboxService } from '@app/data/services/inbox.service';
import { WeatherService } from '@app/data/services/weather.service';
import { CartService } from '@app/data/services/cart.service';
import { AgencyResolver } from '@app/data/agency.resolver';
import { MobileService } from '@app/data/services/mobile.service';
import { TicketShareService } from './services/ticket-share.service';
import { UserAdmissionService } from './services/user-admission.service';
import { PassesResolver } from './passes.resolver';
import { ReservedSeatsService } from './services/reserved-seats.service';
import { ApplicationService } from './services/application.service';
import { PaymentMethodsService } from './services/payment-methods.service';

@NgModule({
    imports: [
        CommonModule,
        CoreModule
    ],
    declarations: [],
    providers: [
        AgencyResolver,
        PassesResolver,
        ApplicationService,
        AgencyService,
        UserService,
        UserAdmissionService,
        TicketService,
        PassService,
        TransferService,
        InboxService,
        WeatherService,
        CartService,
        MobileService,
        TicketShareService,
        ReservedSeatsService,
        PaymentMethodsService
    ]
})
export class DataModule {

    constructor(@Optional() @SkipSelf() parentModule: DataModule) {
        // Import guard
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
