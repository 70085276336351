import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, AuthenticationService } from '@app/core';

const log = new Logger('ResetPassword');

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    version: string = environment.version;
    error: string;
    resetForm: FormGroup;
    isLoading = false;
    message: string;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService
    ) {
        this.createForm();
        if (authenticationService.credentials) {
            this.resetForm.setValue({ email: null });
        }
    }

    ngOnInit() { }

    resetPassword() {
        this.isLoading = true;
        this.message = null;
        this.authenticationService
            .resetPassword(this.resetForm.value)
            .pipe(
                finalize(() => {
                    this.resetForm.markAsPristine();
                    this.isLoading = false;
                })
            )
            .subscribe(
                message => {
                },
                error => {
                    this.error = 'An error occurred while trying to reset your password, please try again.';
                    log.debug(`Reset Password error: ${error}`);
                },
                () => {
                    this.message = 'If you entered a valid email address for a known account, an email has been sent to you with further instructions.';
                }
            );
    }

    private createForm() {
        this.resetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

}
