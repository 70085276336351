<div class="pass-list-item mb-3" [ngStyle]="{'border-color': pass.gatePass.agency.settings?.primaryColor}" >
    <div class="pass-item-container p-0">
        <ts-pass-header-list-item [pass]="pass.gatePass" [showIcon]="false"></ts-pass-header-list-item>
        <div class="p-2 px-3">
            <div *ngIf="!pass.gatePass.isPunchPass">
                <div *ngFor="let consumer of pass.consumers">
                    <div class="details pb-1 border-bottom" >
                        <div class="float-right">{{ consumer.level.title }}
                            <span *ngIf="consumer.seatNumber" class="ml-2">
                                Seat: {{ consumer.seatNumber }}
                            </span>
                        </div>
                        <div><i class="fa fa-fw fa-user mr-2"></i>{{ consumer.name }}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="pass.gatePass.isPunchPass">
                <div *ngFor="let level of pass.punchPass.levels">
                    <div class="details pb-1 border-bottom" >
                        <div class="float-right">{{level.remainingUses}} remaining</div>
                        <div><i class="fas fa-fw fa-ticket-alt mr-2"></i>{{ level.ticketType.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center text-muted m-0 location">
            <div class="col-12 footer py-2">
                <div class="text-center select float-right">
                    <div class="d-inline">View Events</div>
                    <i class="fa fa-arrow-right ml-2"></i>
                </div>
            </div>
        </div>
    </div>
</div>
