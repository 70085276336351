<div class="mb-5" style="min-height:calc(100% - 131px);">
    <ts-section-header>Payment History</ts-section-header>
    <ts-loading message="Loading Payment History" [show]="isLoading"></ts-loading>
    <div class="table-responsive" *ngIf="!isLoading">
        <table class="table table-hover mb-3 font-sm">
            <thead>
                <tr>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Confirmation #</th>
                    <th scope="col">Payment Amount</th>
                    <th scope="col"># Tickets</th>
                    <th scope="col"># Passes</th>
                </tr>
            </thead>
            <tbody *ngIf="payments">
                <tr *ngFor="let payment of payments.slice(pagination.startItem(), pagination.endItem())" [routerLink]="payment.uuid">
                    <td>{{payment.paymentDate | date: 'MM/dd/yyyy hh:mm a'}}</td>
                    <td>{{payment.referenceIdentifier}}</td>
                    <td>{{payment.paymentAmount | currency}}</td>
                    <td>{{payment.ticketCount}}</td>
                    <td>{{payment.passCount}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="!payments">
                <tr>
                    <td colspan="5">
                        <ts-no-results [message]="noResultsMessage"></ts-no-results>
                    </td>
                </tr>
            </tbody>
        </table>
        <ts-pagination [collectionSize]="payments.length" [pagination]="pagination"></ts-pagination>
    </div>
</div>
