import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';
import * as _ from 'lodash';
import { Agency } from "@app/data/models/agency.model";
import { Sponsor } from "@app/data/models/sponsor.model";
import { Location } from "@app/data/models/location.model";
import { Activity } from "@app/data/models/activity.model";
import { EventSchedule } from "@app/data/models/event-schedule.model";
import { TicketPrice } from "@app/data/models/ticket-price.model";
import { EventSales } from "@app/data/models/event-sales.model";
import { ITypeEvent } from "@app/data/models/domain.interface";
import { ReservedConfiguration } from "@app/data/models/reserved/configuration.model";
import { EventSchool } from "./event-school.model";
import { CartItemProduct } from "./cart/cart-item.model";
import { EventStoreChannel, EventStoreChannelCode } from "./events/event-store-channel.model";

export class Event implements IDeserializable, CartItemProduct {

    public static type_head: string = 'HEADTOHEAD';
    public static type_standard: string = 'STANDARD';
    public static type_tournament: string = 'TOURNAMENT';
    public static type_special: string = 'SPECIAL';

    id: number;
    uuid: string;
    title: string;
    private _subtitle: string;
    specialEvent: string;
    eventCode: string;
    description: string;

    agency: Agency | null;
    location: Location;
    activity: Activity;
    type?: ITypeEvent;

    dateStart: Date;
    dateEnd: Date;
    gatesMinutes: number;
    dateGates: Date;
    dateOnSale: Date;
    dateOffSale: Date;

    status: number;
    dateStartOriginal: Date;

    prices: TicketPrice[];
    promotions: any[];
    sales: EventSales;
    schedule: EventSchedule[];

    opponent: Agency;

    isPassEligible: boolean;
    convenienceFee: boolean;
    allowSelfCheckin: boolean;

    availableCount: number;
    capacity: number;
    hasProtectedChannels: boolean;

    isSponsored: boolean;
    sponsor: Sponsor;

    reservedConfiguration: ReservedConfiguration;

    version: string;

    coverArtURI: string;
    formURI: string;

    schools: EventSchool[] = new Array<EventSchool>();
    unprotectedChannel: EventStoreChannel;

    isPrivate: boolean;

    constructor() { }

    get subtitle(): string {
        if (this.isSportingEvent()) {
            if (this.isHeadToHead()) {
                return `${this.opponent.nameShort} vs. ${this.getHomeAgency().nameShort}`;
            } else {
                return `${this.agency.nameShort} ${this._subtitle}`;
            }
        } else {
            return this._subtitle;
        }
    }

    set subtitle(value: string) {
        this._subtitle = value;
    }

    get visitorName(): string {
        if (this.isHeadToHead()) {
            return `vs. ${this.opponent.nameShort}`;
        }
        return this._subtitle;
    }

    get homeName(): string {
        return this.getHomeAgency().nameShort;
    }

    deserialize(input: any) {

        Object.assign(this, input);

        if(input.index) {
            this.id = Number(input.index)
            if(input.id) {
                this.uuid = input.id;
            }
        }

        if(input.special_event) {
            this.specialEvent = input.special_event;
        }

        if(input.allow_self_checkin) {
            this.allowSelfCheckin = input.allow_self_checkin;
        }

        // using a private variable here to store the subtitle
        // the public get function will return the appropriate value for the subtitle
        this.subtitle = input.subtitle;

        // this is here because when an event is deserialized from another Event (like in the cart)
        // the subtitle doesn't exist because it's calculated.  So, use the saved private variable instead
        if (input._subtitle) {
            this.subtitle = input._subtitle;
        }

        if (_.isEmpty(input.typeEvent)) {
            this.type = {
                code: Event.type_standard
            }
        } else {
            this.type = input.typeEvent;
        }

        if(input.type_event_code) {
            this.type = new ITypeEvent();
            this.type.code = input.type_event_code;
        }

        if (input.school) {
            this.agency = new Agency().deserialize(input.school);
        }

        if(input.account)
            this.agency = new Agency().deserialize(input.account);

        if (input.location)
            this.location = new Location().deserialize(input.location);

        if (input.venue)
            this.location = new Location().deserialize(input.venue);

        if (input.activity)
            this.activity = new Activity().deserialize(input.activity);
        
        if (input.school_activity)
            this.activity = new Activity().deserialize(input.school_activity);

        if(input.event_school) {
            this.schools = _.orderBy(input.event_school, ['id']).map((school: any) => new EventSchool().deserialize(school));
        }

        if (input.sales)
            this.sales = new EventSales().deserialize(input.sales);

        if (input.sponsor)
            this.sponsor = new Sponsor().deserialize(input.sponsor);

        if (input.schedule)
            this.schedule = _.orderBy(input.schedule, ['dateStart']).map((schedule: any) => new EventSchedule().deserialize(schedule));

        if (input.prices) {
            this.prices = _.orderBy(input.prices, ['ticketType.rank', 'priceAmount'])
                .map((price: any) => {
                    const ticketPrice = new TicketPrice().deserialize(price);
                    ticketPrice.overallAvailable = input.availableCount;
                    return ticketPrice;
                });
        }

        if (input.dateStart)
            this.dateStart = moment(input.dateStart).toDate();

        if (input.start_date)
            this.dateStart = moment(input.start_date).toDate();
        
        if (input.dateEnd)
            this.dateEnd = moment(input.dateEnd).toDate();

        if (input.date_end)
            this.dateEnd = moment(input.date_end).toDate();

        if (input.dateGates)
            this.dateGates = moment(input.dateGates).toDate();

        if (input.gates_open_date)
            this.dateGates = moment(input.gates_open_date).toDate();

        if (input.gates_open_date) {
            this.gatesMinutes = (Number(input.start_date) - Number(input.gates_open_date))/60000;
        }

        if (input.dateOnSale)
            this.dateOnSale = moment(input.dateOnSale).toDate();

        if (input.date_on_sale)
            this.dateOnSale = moment(input.date_on_sale).toDate();

        if (input.dateOffSale)
            this.dateOffSale = moment(input.dateOffSale).toDate();

        if (input.date_off_sale)
            this.dateOnSale = moment(input.date_off_sale).toDate();

        if (input.schools)
            this.schools = _.orderBy(input.schools, ['id']).map((school: any) => new EventSchool().deserialize(school));

        if (input.opponent) {
            this.opponent = new Agency().deserialize(input.opponent);
        }

        if (input.unprotectedChannel) {
            this.unprotectedChannel = new EventStoreChannel().deserialize(input.unprotectedChannel);
            const code = new EventStoreChannelCode();
            code.code = this.eventCode;
            this.unprotectedChannel.channelCode = code;
        }
        return this;
    }

    public isSportingEvent(): boolean {
        let types: string[] = [Event.type_standard, Event.type_head];
        return types.includes(this.type.code);
    }

    public isHeadToHead(): boolean {
        let types: string[] = [Event.type_head];
        return types.includes(this.type.code);
    }

    /**
     * Determine if the gates for the event are currently open
     */
    public areGatesOpen(): boolean {
        return moment().isAfter(this.dateGates);
    }

    /**
     * Determine if the event is no longer available for sale
     *
     * This is determined by checking to see if the current data is after the lesser
     * of the End Date, or the Off Sale Date of the event
     *
     */
    public isEventOver(): boolean {
        let maxStartDate = moment(this.dateStart);
        if (this.schedule?.length > 0) {
            const all_start_times = this.schedule.map(s => {
                const timeStr = moment(s.dateStart).format("HH:mm");
                const date = moment(this.dateStart);
                const time = moment(timeStr, 'HH:mm');
                date.set({
                    hour: time.get('hour'),
                    minute: time.get('minute'),
                    second: time.get('second')
                });
                return date;
            });
            all_start_times.push(moment(this.dateStart));
            maxStartDate = _.max(all_start_times);
        }
        let eventEndDate: Date = this.dateEnd || moment(maxStartDate).add(3, 'hours').toDate();
        eventEndDate = _.max([eventEndDate, this.dateOffSale]);
        return moment().isAfter(moment(eventEndDate));
    }

    /**
     * Determine if tickets are on sale
     *
     * if now() is after the dateOnSale and before the dateOffSale
     *
     * *NOTE: if there is no dateOffSale, it uses midnight the day of the event
     *
     */
    public areTicketsOnSale(): boolean {
        var dateOffSale: Date = this.dateOffSale || moment(this.dateStart).startOf('day').add(1, 'days').toDate();
        return moment().isAfter(this.dateOnSale) && moment().isBefore(moment(dateOffSale));
    }

    /**
     * returns true if the event has some reserved seating configuration
     *
     */
    public hasReservedSeating(): boolean {
        return !_.isEmpty(this.reservedConfiguration);
    }

    /**
     * Check to see if the tickets are available for sale
     *
     * Tickets are available when all of these are true:
     *    - Tickets are On Sale
     *    - Event is not over
     *    - Event is not cancelled
     *    - Event is under capacity
     *    - Event has pricing
     *
     */
    public areTicketsAvailable(): boolean {
        return this.availableCount > 0
            && !this.isCancelled()
            && !this.isEventOver()
            && this.prices.length > 0
            && this.areTicketsOnSale()
    }

    public isPassOnly(): boolean {
        return this.prices.length == 0
            && this.isPassEligible;
    }

    public isScheduled(): boolean {
        return this.status === 0;
    }

    public isPostponed(): boolean {
        return this.status === 1;
    }

    public isCancelled(): boolean {
        return this.status === -1;
    }

    public isMobileOnly(): boolean {
        return this.allowSelfCheckin;
    }

    /**
     * returns true if the agency matches the event agency
     *
     * @param agency
     */
    public isHome(agency: Agency): boolean {
        return this.getHomeAgency().uuid == agency.uuid;
    }

    public showHomeIndicator(agency: Agency): boolean {
        return !agency.hasChildren() && this.isSportingEvent();
    }

    public hasOpponent(): boolean {
        return this.opponent !== null;
    }

    public getHomeAgency(): Agency {

        let index = -1;
        // if it's head to head, check for a home team
        if (this.isHeadToHead()) {
            index = this.schools.findIndex((school: EventSchool) => school.isHomeTeam == true);
        }

        if (index >= 0) {
            return this.schools[index].school;
        }
        return this.agency;
    }

    public getVisitorAgency(): Agency {
        return this.opponent;
    }

    /**
     * returns the logo of the agency for the event
     * if it's a head to head, return the opponent agency
     * if the privded agency is the home team
     *
     * @param agency return the
     */
    public getLogo(agency: Agency): string {

        var logo: string = this.agency.settings?.logoURI;

        if (this.type.code == Event.type_head) {
            if (this.isHome(agency)) {
                logo = this.opponent.settings?.logoURI;
            } else {
                logo = this.getHomeAgency().settings?.logoURI;
            }
        }

        return logo;
    }

    public getGATicketPrices(): TicketPrice[] {
        return this.prices.filter((price) => !price.isReservedPrice())
    }

    public getRESVTicketPrices(): TicketPrice[] {
        return this.prices.filter((price) => price.isReservedPrice())
    }

    /**
     * returns true if the event has a formURI associated with it
     *
     */
    public isRegistrationEvent(): boolean {
        return !_.isEmpty(this.formURI);
    }

}
