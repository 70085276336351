<div class="modal-body p-0 pb-4 mb-3">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <!-- <div class="modal-header-color single">
        <ts-ticket-selection-event-header [event]="event"></ts-ticket-selection-event-header>
    </div> -->
    <div class="modal-body pb-3" [innerHtml]="data">
    </div>
</div>
<!-- <div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Done</button>
</div> -->
