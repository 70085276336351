import { Component, Input, OnInit } from '@angular/core';
import { Pagination } from '@app/data/models/pagination.model';

@Component({
  selector: 'ts-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {
    @Input('pagination') pagination: Pagination;
    @Input('collectionSize') collectionSize: number;
    @Input('alwaysShow') alwaysShow: boolean = false;

    constructor() { }

    get show(): boolean {
        return this.alwaysShow ? true : this.collectionSize > this.pagination.pageSize;
    }
}
