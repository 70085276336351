import { Component, Input } from '@angular/core';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import { ConfigurePassBottomSheet } from '@app/shared/ticket-spicket/bottom-sheet/configure-pass-bottom-sheet/configure-pass-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
    selector: 'ts-consumer-pass-list-item',
    templateUrl: './consumer-pass-list-item.component.html',
    styleUrls: ['./consumer-pass-list-item.component.scss']
})

export class ConsumerPassListItemComponent {

    @Input('consumer') consumer: ConsumerGatePass;

    constructor(
        private bottomSheet: MatBottomSheet
    ) { }

    public openBottomSheet(consumer: ConsumerGatePass): void {
        const bottomSheetRef = this.bottomSheet.open(ConfigurePassBottomSheet, {
            data: consumer,
        });
        bottomSheetRef.afterDismissed().subscribe();
    };

}
