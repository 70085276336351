export * from './core.module';
export * from './authentication/authentication.service';
export * from './authentication/authentication.service.mock';
export * from './authentication/authentication.guard';
export * from './http/http.service';
export * from './http/http-cache.service';
export * from './http/api-prefix.interceptor';
export * from './http/cache.interceptor';
export * from './http/error-handler.interceptor';
export * from './route.service';
export * from './route-reusable-strategy';
export * from './logger.service';
export * from './sentry.util';
import { environment } from '@env/environment';

// Begin Google Analytics (GA) Implementation 
const s = document.createElement('script');
s.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ environment.googleAnalyticsPropertyId }`);
s.async = true;
document.head.appendChild(s);

window.dataLayer = window.dataLayer || [];
window.gtag = function() {window.dataLayer.push(arguments);}

window.gtag('js', new Date());
window.gtag('config', environment.googleAnalyticsPropertyId);
// End Google Analytics (GA) Implementation