import {Component, Input} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ts-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
})
export class TicketSpicketConfirmation {
    @Input() title: string = 'Are you sure?';
    @Input() content: string = 'Are you sure?';
    @Input() confirmText: string = 'Yes';
    @Input() cancelText: string = 'No'

    constructor(public activeModal: NgbActiveModal) {}
 
}
