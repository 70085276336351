import { Component } from '@angular/core';

@Component({
    selector: 'ts-ticket-legend',
    templateUrl: './ticket-legend.component.html',
    styleUrls: ['./ticket-legend.component.scss']
})

export class TicketLegendComponent {
    constructor ( ) { }
}
