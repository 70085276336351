<nav aria-label="breadcrumb">
  <ol class="breadcrumb" style="background:transparent !important;padding-top:0;">
    <li class="breadcrumb-item font-sm">
      <a routerLink="../">Payment History</a>
    </li>
    <li class="breadcrumb-item active font-sm" *ngIf="payment" aria-current="page">{{ payment.referenceIdentifier }}</li>
  </ol>
</nav>
<div class="mb-5" style="min-height:calc(100% - 131px);">
    <ts-section-header>Payment Detail
        <!-- <div class="actions">
            <button mdbRippleRadius class="btn btn-light btn-sm px-5 my-2 mr-2" [routerLink]="['..']"><i class="fa fa-arrow-left mr-2"></i>Back</button>
        </div> -->
    </ts-section-header>
    <div class="row" *ngIf="payment">
        <div class="col-md-6 col-sm-12">
            <mat-card>
                <ts-read-only label="Confirmation #" value="{{payment.referenceIdentifier}}"></ts-read-only>
                <ts-read-only label="Payment Date" value="{{payment.paymentDate | date: 'MM/dd/yyyy hh:mm a'}}"></ts-read-only>
                <ts-read-only label="Convenience Fees" value="{{payment.feeAmount | currency}}"></ts-read-only>
                <ts-read-only label="Total Payment" value="{{payment.paymentAmount | currency}}"></ts-read-only>
                <ts-read-only label="# Tickets" value="{{payment.tickets.length}}"></ts-read-only>
                <ts-read-only label="# Passes" value="{{payment.passes.length}}"></ts-read-only>
            </mat-card>
        </div>
        <div class="col-md-6 col-sm-12">
            <mat-card *ngFor="let event of events" class="p-2 mb-3">
                <ts-event-header-list-item [event]="event.event"></ts-event-header-list-item>
                <mat-divider></mat-divider>
                <mat-list>
                    <mat-list-item *ngFor="let ticket of event.tickets" class="py-0 m-0">
                        <i mat-list-icon class="fas fa-fw fa-ticket-alt"></i>
                        <div mat-line>{{ ticket.level.title }} / {{ticket.paidAmount | currency}}<span class="pull-right">#{{ticket.ticketNumber}}</span></div>
                    </mat-list-item>
                </mat-list>
            </mat-card>
            <mat-card *ngFor="let pass of payment.passes" class="p-2 mb-3">
                <ts-pass-header-list-item [pass]="pass.gatePass" [showIcon]="false"></ts-pass-header-list-item>
                <mat-divider></mat-divider>
                <mat-list>
                    <mat-list-item class="py-0 m-0" *ngFor="let consumer of pass.consumers">
                        <i mat-list-icon class="fas fa-fw fa-ticket-alt"></i>
                        <div mat-line>{{ consumer.level.title }} / {{consumer.paidAmount | currency}}</div>
                        <div mat-line>{{ consumer.holderName }}</div>
                    </mat-list-item>
                </mat-list>
            </mat-card>
        </div>
    </div>
</div>
