import { Component, Input, OnInit } from '@angular/core';
import { Event } from '@app/data/models/event.model';
import { Agency } from '@app/data/models/agency.model';

@Component({
    selector: 'ts-event-card-h2h',
    templateUrl: './event-card-h2h.component.html',
    styleUrls: ['./event-card-h2h.component.scss']
})

export class EventCardH2HComponent implements OnInit {

    @Input('event') event: Event;
    @Input('agency') agency: Agency;
    @Input('schoolView') schoolView: boolean;
    @Input('parentSchoolView') parentSchoolView: boolean;

    private colortest: string = 'blue, red';

    constructor() {
    }

    ngOnInit() {
        if (!this.agency) {
            this.schoolView = false;
            this.agency = this.event.agency;
        } 
    }

    public isHomeEvent(): boolean {
        return this.event.isHome(this.agency);
    }

    public homeIndicator(): string {
        return this.isHomeEvent() ? 'H': 'A';
    }

    public getLogo(): string {
        return this.event.getLogo(this.agency);
    }

}
