<div class="container-fluid" style="min-height:calc(100% - 131px);">
    <div class="py-3 my-0 mx-auto">
        <div class="row">
            <div class="col-12 col-sm-10 col-md-8 col-lg-8 my-0 mx-auto px-0 px-sm-3">
                <div *ngIf="user" class="card" style="position:relative;">
                    <div class="row">
                        <div class="col-10 col-md-8 text-center my-3 px-4 pt-4 mx-auto">
                            <h5>{{ user.nameFirst }} {{ user.nameLast }}</h5>
                            <div class="text-muted pb-4">
                                {{ user.email }}
                            </div>
                        </div>
                    </div>
                    <div class="toggles">
                        <div class="row text-center m-0">
                            <div class="col-6 p-0">
                                <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm btn-block border-left-0 border-right-0 border-top-0" style="border-radius:0;" [ngClass]="{'active': showProfile}" (click)="toggleProfile()"><i class="fa fa-user mr-2"></i>Profile</button>
                            </div>
                            <div class="col-6 p-0">
                                <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm btn-block border-left-0 border-right-0 border-top-0" style="border-radius:0;" [ngClass]="{'active': !showProfile}" (click)="toggleProfile()"><i class="fa fa-lock mr-2"></i>Password</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showProfile" class="card-body text-left pb-0">
                        <ts-section-header>Update Profile</ts-section-header>
                        <div class="pt-3 mt-3">
                            <form (ngSubmit)="updateProfile()" [formGroup]="profileForm" novalidate autocomplete="on">
                                <div class="form-group">
                                    <label class="d-block">
                                        <input type="text" class="form-control" formControlName="nameFirst" autocomplete="given-name" [placeholder]="'First Name'" />
                                        <span hidden >Email</span>
                                        <small [hidden]="profileForm.controls.nameFirst.valid || profileForm.controls.nameFirst.untouched" class="text-danger">
                                        First name is required
                                        </small>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="d-block">
                                        <input type="text" class="form-control" formControlName="nameLast" autocomplete="family-name" [placeholder]="'Last Name'" />
                                        <span hidden >Last Name</span>
                                        <small [hidden]="profileForm.controls.nameLast.valid || profileForm.controls.nameLast.untouched" class="text-danger">
                                        Last name is required
                                        </small>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="d-block">
                                        <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'" />
                                        <span hidden >Email</span>
                                        <small [hidden]="profileForm.controls.email.valid || profileForm.controls.email.untouched" class="text-danger">
                                        Email is required
                                        </small>
                                    </label>
                                </div>
                                <button class="btn btn-secondary w-100 m-0 mt-3" type="submit" [disabled]="profileForm.pristine || profileForm.invalid || profileIsLoading">
                                    <span [hidden]="!profileIsLoading">
                                        <i class="fa fa-cog fa-spin mr-2 d-inline-block"></i>
                                        Saving Profile...
                                    </span>
                                    <span [hidden]="profileIsLoading"><i class="fa fa-save mr-2"></i>Save Profile</span>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="!showProfile" class="card-body text-left pb-0">
                        <ts-section-header>Change Password</ts-section-header>
                        <div class="pt-3 mt-3">
                            <form (ngSubmit)="updatePassword()" [formGroup]="passwordForm" novalidate autocomplete="on">
                                <div class="row m-0">
                                    <div class="col-md-5 col-sm-12">
                                        <div class="form-group">
                                            <label class="d-block pb-2">
                                                <input type="password" class="form-control" formControlName="password" autocomplete="new-password" [placeholder]="'Password'" />
                                                <div [hidden]="passwordForm.controls.password.untouched" *ngIf="passwordForm.controls.password.invalid" class="text-danger" style="font-size: .8em">
                                                    <div *ngIf="passwordForm.controls.password.errors?.required">Password is required</div>
                                                    <div *ngIf="passwordForm.controls.password.errors.strong">Password is not strong enough</div>
                                                </div>
                                            </label>
                                            <label class="d-block">
                                                <input type="password" class="form-control" formControlName="confirmPassword" autocomplete="new-password" [placeholder]="'Confirm Password'" />
                                                <span hidden>Confirm Password</span>
                                                <div [hidden]="passwordForm.controls.confirmPassword.untouched && !passwordForm.dirty" *ngIf="passwordForm.errors?.confirmPassword" class="text-danger" style="font-size: .8em">
                                                    Passwords must match
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-12 font-sm">
                                        <p class="m-0 p-0 pb-1"><strong>In order to maintain a secure account, your password must contain the following:</strong></p>
                                            <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.controls.password.errors?.strong?.length ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least 6 characters</div>
                                            <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.controls.password.errors?.strong?.also ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one letter</div>
                                            <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.controls.password.errors?.strong?.number ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one number</div>
                                    </div>
                                    <button class="btn btn-secondary w-100 m-0 mt-3" type="submit" [disabled]="passwordForm.pristine || passwordForm.invalid || passwordIsLoading">
                                        <span [hidden]="!passwordIsLoading">
                                            <i class="fa fa-cog fa-spin mr-2 d-inline-block"></i>
                                            Changing Password...
                                        </span>
                                        <span [hidden]="passwordIsLoading"><i class="fa fa-save mr-2"></i>Change Password</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr/>
                    <div class="mb-3 mx-3">
                        <button class="btn btn-secondary btn-block" (click)="logout()">
                            <i class="fa fa-sign-out mr-2"></i>Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
