import { Component, OnInit, ViewChild, } from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Agency } from '@app/data/models/agency.model';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { RenewalService } from '@app/data/services/renewal.service';
import { PassService } from '@app/data/services/pass.service';
import { SeasonTicketExchange } from '@app/data/models/renewal/season-ticket-exchange';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgencySeasonTicketSeatmapComponent } from './seatmap/season-ticket-seatmap.component';
import { Event } from '@app/data/models/event.model';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgxBraintreeRenewalComponent } from '@app/cart/braintree/ngx-braintree-exchange.component';

@Component({
  selector: 'app-season-ticket-exchange',
  templateUrl: './season-ticket-exchange.component.html',
  styleUrls:['./season-ticket-exchange.component.scss']
})
export class SeasonTicketExchangeComponent implements OnInit {

    @ViewChild(NgxBraintreeRenewalComponent,{ static : false }) paymentMethod: NgxBraintreeRenewalComponent;

    public agency: Agency = new Agency();
    public pass: GatePass = new GatePass();
    public event: Event;

    public seats: SeasonTicketExchange[] = new Array<SeasonTicketExchange>();

    public isLoading: boolean = true;
    public gatePassUUID: string;
    public purchaseUrl: string;

    enablePayButton: boolean = false;

    constructor (
        private route: ActivatedRoute,
        private _passService: PassService,
        public _agencyService: AgencyService,
        public renewalService: RenewalService,
        private _modal: NgbModal,
        private _toastr: ToastrService,
        private _messageService: MessageService,
    ) {

    }

    ngOnInit() {

        this.agency = this._agencyService.agency;

        this.route.params.pipe (
            map((params) => this.gatePassUUID = params['pass-id']),
            switchMap((gatePassUUID) => this.renewalService.getSeasonTicketEvents(gatePassUUID)),
            map((events) => {
                return this.event = events[0];
            }),
            switchMap(() => this._passService.getPasses())
        ).subscribe((gatePassUUID) => {
            this.loadTickets();

            this.purchaseUrl = `/agency/${this.agency.uuid}/season/${this.gatePassUUID}`;

        }, (error) => {
            console.log(error);
        });

        this.isLoading = false;

    }

    public loadTickets() {
        this.renewalService.getSeasonTicketEvents(this.gatePassUUID).pipe (
            map((events) => {
                return this.event = events[0];
            }),
            switchMap(() => this._passService.getPasses())
        ).subscribe((passes) => {
            const consumerPass = passes.find((pass) => pass.gatePass.uuid == this.gatePassUUID);
            this.pass = consumerPass.gatePass;
            this.seats = _.orderBy(consumerPass.consumers.map((pass) => new SeasonTicketExchange().deserialize(pass)), "seatKey");
        }, (error) => {
            console.log(error);
        });
    }

    public showSeatMap() {
        const modalRef = this._modal.open(AgencySeasonTicketSeatmapComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.event = this.event;
        modalRef.componentInstance.gatePass = this.pass;
        modalRef.componentInstance.ticketPrices = this.pass.getRESVTicketPrices();
    }

    public toggleSeat(seat: SeasonTicketExchange) {

        if (seat.isExchanged()) {
            this._messageService.ConfirmYesNo("You have already reserved a seat to exchange for this one.  This will release the seat for other people to select.  Are you sure?", "Release Seat?").then(() => {
                this.renewalService.toggleSeat(seat);
            });
        } else {
            this.renewalService.toggleSeat(seat);
        }
    }

    onPaymentStatus (event: any) {
        this.renewalService.clearSelected();
        this.loadTickets();
        this._toastr.success("Your Seats have been exchanged!");
    }

    onPayButtonStatus (event: boolean) {
        this.enablePayButton = event;
    }


    public checkout() {

        this._messageService.ConfirmYesNo("Are you sure you want to upgrade these season tickets?  Clicking Yes will reserve your new seats and make your existing seats available for someone else to reserve!", "Upgrade Seats?").then(() => {
            this.paymentMethod.pay(this.pass.uuid);
        });

    }

    public exchangeSeats() {

        this._messageService.ConfirmYesNo("Are you sure you want to exchange these season tickets?  Clicking Yes will reserve your new seats and make your existing seats available for someone else to reserve!", "Exchange Seats?").then(() => {
            this.renewalService.exchangeSeats(this.gatePassUUID).subscribe(() => {
                this.renewalService.clearSelected();
                this.loadTickets();
                this._toastr.success("Your Seats have been exchanged!");
            }, () => {
                this.loadTickets();
                this._toastr.error("An error occurred, please try again.  If this error persiste, please contact customer support.");
            });
        });

    }

    public allowPurchase(): boolean {
        return !(this.gatePassUUID == "0f5f33fc-0f9a-40e6-a20c-d38e9c188ce2" || this.gatePassUUID == "dbe8e320-f378-454c-ae96-6bca6d2455cd");
    }

    public getTotalPaid(): number {
        return this.renewalService.getTotalPaid();
    }

    public getTotalExchange(): number {
        return this.renewalService.getTotalExchange();
    }

    public getUpgradePrice(): number {
        return this.renewalService.getUpgradePrice();
    }

    public canExchangeSeats(): boolean {
        return this.renewalService.hasExchangedSeats() && this.getUpgradePrice() <= 0;
    }

}
