import { Component, OnInit } from '@angular/core';

import { CartService } from '@app/data/services/cart.service';
import { PurchaseConfirmation } from '@app/data/models/purchase-confirmation.model';
import { User } from '@app/data/models/user.model';
import { UserService } from '@app/data/services/user.service';
import { TicketService } from '@app/data/services/ticket.service';
import { CartItemEventSummary } from '@app/data/models/cart/cart-item-event';
import { CartItemPassSummary } from '@app/data/models/cart/cart-item-pass';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import * as _ from 'lodash';

declare global {
    interface Window {
        dataLayer:any;
    }
}
@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

    public user: User;
    public confirmation: PurchaseConfirmation;
    public tickets: CartItemEventSummary[];
    public passes: CartItemPassSummary[];

    constructor(
        public cart: CartService,
        private _userService: UserService,
        private _ticketService: TicketService,
        private _router: Router
    ) { }

    ngOnInit() {
        const gaPropertyId = environment.googleAnalyticsPropertyId;
        if (this.cart.confirmation)
        {   
            const groupedItems = _.groupBy(this.cart.confirmation.items, (item) => (item.ticketPrice.id));
            const mappedItems = Object.entries(groupedItems).map(([key, values]) => {
                return {
                    item_id: key,
                    price: values[0].price,
                    qty: values.length,
                }
            })
            function gtag(a1: string, a2: string, a3?: string | object){window.dataLayer.push(arguments);}
            gtag('config', gaPropertyId);
            gtag("event", "purchase", {
                transaction_id: this.cart.purchaseId,
                value: this.cart.confirmation.totalAmount,
                currency: 'USD',
                items: mappedItems,
            });
        }
        this.confirmation = this.cart.confirmation;
        if (!this.confirmation) {
            this._router.navigate([this.cart.cartRoute], { replaceUrl: true });
        }
        this.user = this._userService.user;
        this.tickets = this.cart.getEventItems();
        this.passes = this.cart.getPassItems();
        //Added to update tickets on confirmation. - ELH
        if (!this.user) {
            this._ticketService.getTickets().subscribe();
        } else {
            return;
        }
    }

    fastLaneUrl() {
        let href = environment.fastLaneUrl + `/tickets/purchase/${this.cart.purchaseId}`
        setTimeout(() => { window.open(href) }, 1000);
    }
}
