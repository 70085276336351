<mat-nav-list>
    <a *ngIf="hasAction('transfer-cancel')" (click)="doAction('transfer-cancel')" mat-list-item  aria-label="Button that lets you view your pending ticket transfers.">
      <h5 class="text-uppercase" mat-line><i class="fa fa-send fa-fw mr-3"></i><strong>View Pending Transfer</strong></h5>
      <span mat-line>Tap here to view your pending pass transfer.</span>
    </a>
    <a *ngIf="hasAction('assign')" (click)="doAction('assign')" mat-list-item  aria-label="Button that lets you assign a passholder name to your pass.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-user fa-fw mr-3"></i><strong>Assign a Name</strong></h5>
        <span mat-line>Tap here to assign a passholder name to your pass.</span>
    </a>
    <a *ngIf="hasAction('transfer')" (click)="doAction('transfer')" mat-list-item aria-label="Button that lets you transfer your pass.">
        <h5 class="text-uppercase" mat-line><i class="fa fa-paper-plane fa-fw mr-3"></i><strong>Transfer</strong></h5>
        <span mat-line>Tap here to transfer your pass to another person.</span>
    </a>
</mat-nav-list>
