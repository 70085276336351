import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';
import { Activity } from "./activity.model";
import { Agency } from "./agency.model";

export class EventSchool implements IDeserializable {

    school: Agency;
    schoolActivity: Activity;
    isPassEligible: boolean;
    pctRevenueSplit: number;
    isHomeTeam: boolean;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        this.isPassEligible = input.is_pass_eligible;
        this.isHomeTeam = input.is_home_team;
        this.pctRevenueSplit = input.pct_revenue_split;

        if (input.school)
        {
            this.school = new Agency().deserialize(input.school);
        }
        else if (input.account)
        {
            this.school = new Agency().deserialize(input.account);
        }

        this.schoolActivity = new Activity().deserialize(input.activity);

        return this;
    }

}
