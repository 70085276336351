import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../authentication/authentication.service';
import { UserService } from '@app/data/services/user.service';
import { User } from '@app/data/models/user.model';
import { CartService } from '@app/data/services/cart.service';
import { TransferService } from '@app/data/services/transfer.service';
import { InboxService } from '@app/data/services/inbox.service';
import { AgencyService } from '@app/data/services/agency.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isAgencyPage = false;
  user: User;
  menuHidden = true;

  public ticketBadgeCount = 0;

  constructor (
      private router: Router,
      private authenticationService: AuthenticationService,
      private _userService: UserService,      
      public cart: CartService,
      private _userAdmissionService: UserAdmissionService,
      public _transferService: TransferService,
      public _inboxService: InboxService,
      public _agencyService: AgencyService  ) { 

    // Added below router check to adjust cart language based on whether on an agency page or not. - ELH
    this.router.events.subscribe(() => {
        this.isAgencyPage = false;
        ['agency', 'group'].map((page:string) => {
            if (this.router.url.indexOf(page) > -1) {
                this.isAgencyPage = true;
            }
        });

    });
  }

    ngOnInit() {
        
        this._userAdmissionService.ticketBadgeCount$.subscribe(res => {
            this.ticketBadgeCount = res;
        });
    }

    toggleMenu() {
        this.menuHidden = !this.menuHidden;
    }


    logout() {
        this.authenticationService.logout().subscribe(
            () => this.router.navigate(['/home'], { replaceUrl: true })
        );
    }

    public isAuthenticated(): boolean {
        return this.authenticationService.isAuthenticated();
    }

    public getUsername(): string {
        const user = this._userService.user;
        return user ? user.email : null;
    }

    get username(): string {
        return this._userService.user.email;

        // const credentials = this.authenticationService.credentials;
        // return credentials ? credentials.username : null;
    }

    get firstname(): string {
        const user = this._userService.user;
        return user ? user.nameFirst : null;
    }

    get unreadCount(): number {
        return this._userService.notificationBadgeCount;
    }

    get agencyName(): any {
        return this._agencyService.agencyName;
    }

    public goToAgency() {
        let agency = this._agencyService.agency;
        const uuid = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

        if (uuid.test(agency.uuid)) {
            this.router.navigate(['/agency', agency.uuid]);
        } else {
            this.router.navigate(['/group', agency.uuid]);
        }
    }

}
