import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    imports: [
        MatButtonModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatExpansionModule,
        MatCardModule,
        MatButtonToggleModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTabsModule,
        MatListModule,
        MatBottomSheetModule
    ],
    exports: [
        MatButtonModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatDialogModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatExpansionModule,
        MatCardModule,
        MatButtonToggleModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTabsModule,
        MatListModule,
        MatBottomSheetModule
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class TicketSpicketMaterialModule { }
