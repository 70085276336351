<div *ngIf="agency" class="submenu">
    <div class="container py-3 pr-3 submenu-margin">
        <div *ngIf="agency.settings?.logoURI" (click)="goToAgency()" class="pointer logo d-lg-none" [ngStyle]="{'background-image': 'url(' + agency.settings?.logoURI + ')'}"></div>
        <div *ngIf="!agency.settings?.logoURI" (click)="goToAgency()" class="pointer logo default d-lg-none text-center" [ngStyle]="{'background-color': agency.settings?.primaryColor }">{{ agency.name | limitTo: 1 }}</div>
        <span (click)="goToAgency()" class="pointer text-uppercase" style="font-size:1.2rem;"><strong>{{ agency.name }}</strong></span>
        <span (click)="goToAgency()" class="pointer" *ngIf="agency.nameMascot">
            <span class="d-none d-lg-inline">- </span>
            <br class="d-inline-block d-lg-none" />Home of the {{ agency.nameMascot }}
        </span>
        <span *ngIf="!agency.nameMascot">
            <span class="d-none d-lg-inline">- </span>
            <br class="d-inline-block d-lg-none" />
            <div class="d-inline" *ngIf="!mobileService.isCapacitor">
                <a [href]="this.agency.primaryAddress.mapString" target="_blank"  style="text-decoration:underline;" [ngStyle]="{'color': agency.settings?.secondaryText}" >
                    <i class="fa fa-map-marker mr-1"></i> {{ agency.primaryAddress?.formatted }}
                </a>
            </div>
            <div class="d-inline" *ngIf="mobileService.isCapacitor"  style="text-decoration:underline;" [ngStyle]="{'color': agency.settings?.secondaryText}" >
                <a (click)="mobileService.openURL(this.agency.primaryAddress.mapString)" target="_blank">
                    <i class="fa fa-map-marker mr-1"></i> {{ agency.primaryAddress?.formatted }}
                </a>
            </div>
        </span>
        <span *ngIf="forecast">
            <span class="d-none d-lg-inline">- </span>
            <br class="d-inline-block d-lg-none" />{{forecast.current}}&#8451;
        </span>
        <div class="ml-1 d-inline-block" style="line-height:10px;" *ngIf="agency.settings?.facebookURI || agency.settings?.twitterURI || agency.settings?.instagramURI">
            <span class="d-none d-lg-inline">- </span>
            <br class="d-inline-block d-lg-none" />
            <div class="d-inline" *ngIf="!mobileService.isCapacitor">
                <a [href]="agency.settings?.facebookURI" target="_blank" *ngIf="agency.settings?.facebookURI"><i class="fa fa-facebook ml-1 mr-2 text-white"></i></a>
                <a [href]="agency.settings?.twitterURI" target="_blank" *ngIf="agency.settings?.twitterURI"><i class="fa fa-twitter mr-2 text-white"></i></a>
                <a [href]="agency.settings?.instagramURI" target="_blank" *ngIf="agency.settings?.instagramURI"><i class="fa fa-instagram mr-2 text-white"></i></a>
            </div>
            <div class="d-inline" *ngIf="mobileService.isCapacitor">
                <a (click)="mobileService.openURL(agency.settings?.facebookURI)" *ngIf="agency.settings?.facebookURI"><i class="fa fa-facebook ml-1 mr-2 text-white"></i></a>
                <a (click)="mobileService.openURL(agency.settings?.twitterURI)" *ngIf="agency.settings?.twitterURI"><i class="fa fa-twitter mr-2 text-white"></i></a>
                <a (click)="mobileService.openURL(agency.settings?.instagramURI)" *ngIf="agency.settings?.instagramURI"><i class="fa fa-instagram mr-2 text-white"></i></a>
            </div>
        </div>
    </div>
</div>
