<form (ngSubmit)="verifyCode()" [formGroup]="verificationCodeForm" novalidate autocomplete="on">
    <div class="form-group">
        <div class="py-2">
            <p class="m-0 p-0 pb-1 text-center">
                Please enter the 6-digit verification code we sent to your email:
            </p>
            <h6>
                <strong>{{ email }}</strong>
            </h6>
        </div>
        <div class="row mx-0 my-3">
            <input type="tel" maxlength="1" class="col form-control text-center" formControlName="one" autocomplete="one-time-code" placeholder="" (keyup)="onDigitInput($event)" (paste)="verifyCode($event)"/>
            <input type="tel" maxlength="1" class="col form-control text-center" formControlName="two" autocomplete="one-time-code" placeholder="" (keyup)="onDigitInput($event)" (paste)="verifyCode($event)"/>
            <input type="tel" maxlength="1" class="col form-control text-center" formControlName="three" autocomplete="one-time-code" placeholder="" (keyup)="onDigitInput($event)" (paste)="verifyCode($event)"/>                          
            <input type="tel" maxlength="1" class="col form-control text-center" formControlName="four" autocomplete="one-time-code" placeholder="" (keyup)="onDigitInput($event)" (paste)="verifyCode($event)"/>
            <input type="tel" maxlength="1" class="col form-control text-center" formControlName="five" autocomplete="one-time-code" placeholder="" (keyup)="onDigitInput($event)" (paste)="verifyCode($event)"/>
            <input type="tel" maxlength="1" class="col form-control text-center" formControlName="six" autocomplete="one-time-code" placeholder="" (keyup)="onDigitInput($event)" (paste)="verifyCode($event)"/>
        </div>
    </div>
    <button mdbRippleRadius class="btn btn-primary w-100 mx-0 mt-3" type="submit" [disabled]="verificationCodeForm.invalid || isLoading">
        <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
        <h6 class="m-0" [hidden]="isLoading">Verify Code</h6>
    </button>
    <div class="mt-4 py-2">
        <p>Didn't receive the code? <a type="button" (click)="resendCode.emit()">Send code again.</a></p>
    </div>
    <button mdbRippleRadius class="btn btn-light w-100 mt-3 mb-4" type="button" (click)="updateSelected.emit(0)">
        <h6 class="m-0"><i class="fa fa-chevron-left mr-2" style="display:inline-block;"></i>Back</h6>
    </button>
</form>