import { Address } from "./address.model";

export class Location {

    id: number;
    name: string;
    capacity: number;

    address?: Address;

    version?: string;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if(input.address) {
            this.address = new Address().deserialize(input.address);
        }
        else if(input.location) {
            this.address = new Address().deserialize(input.location);
        }

        return this;
    }

}
