<div class="modal-body p-0 pb-4 mb-3">
  <button type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;"
    aria-label="Close" (click)="close()">
    <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body p-0">
    <div class="letter-holder">
      <div class="letter">
        <span *ngIf="consumer.holderName">{{ consumer.holderName | limitTo: 1 }}</span>
        <span *ngIf="!consumer.holderName">U</span>
      </div>
    </div>
    <div class="p-3">
      <h5 class="text-center text-uppercase font-weight-bold border-bottom">
          PASS TYPE:<br />{{ consumer.level.value }}
          <span *ngIf="consumer.seatNumber">
              Seat: {{ consumer.seatNumber }}
          </span>
      </h5>
      <div class="p-3 text-center text-uppercase">
        <strong>Pending Transfer</strong>
      </div>
      <div class="card mb-3" *ngIf="transfer">
        <div class="card-body p-0">
            <mat-list class="p-0">
                <mat-list-item class="mb-2">
                    <p class="h4" matLine>
                        <i class="fa fa-fw fa-envelope mr-3"></i>To: {{transfer.emailAddress}}
                    </p>
                </mat-list-item>
            </mat-list>
            <div class="row mx-1 mb-2">
                <div class="col-6">
                    <button type="button" class="btn btn-block btn-primary btn-left font-sm" title="Resend" (click)="resendTransfer(transfer)">
                        <i class="fa fa-send mr-3"></i>Resend Transfer
                    </button>
                </div>
                <div class="col-6">
                    <button type="button" class="btn btn-light btn-block btn-right font-sm" title="Cancel" (click)="cancelTransfer(transfer)">
                        <i class="fa fa-close mr-3"></i>Cancel Transfer
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
  </div>
</div>
<div class="text-center modal-close-button">
  <button type="button" class="btn btn-light" aria-label="Close" style="min-width:150px;" (click)="close()">Close</button>
</div>
