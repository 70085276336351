<div *ngIf="summary.ticketPrices.length > 0">
    <div *ngFor="let price of summary.ticketPrices">
        <div class="row my-2 pb-2 mx-0" style="border-bottom:1px dotted #e5e5e5;">
            <div class="col-5 p-0" style="line-height:35px;">
                {{ price.price.displayName }}
            </div>
            <div class="col-7 text-right p-0" *ngIf="!summary.product.isFamilyPass && !summary.product.isSeasonPass && !summary.product.isBundle">
                {{ price.price.priceAmount | currency }}&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;<i class="fas fa-ticket-alt mr-2"></i>
                <strong>
                    <ng-select [items]="price.numbers" [clearable]="false" (change)="updateItem(summary, price)" [dropdownPosition]="bottom" [(ngModel)]="price.quantity"></ng-select>
                </strong>
            </div>
            <div class="col-7 text-right p-0" *ngIf="summary.product.isFamilyPass">
                {{ price.price.priceAmount | currency }}&nbsp;&nbsp;
                <button class="btn btn-sm btn-danger" (click)="removeItem(summary, price)" *ngIf="summary.product.isFamilyPass">Remove <i class="fa fa-fw ml-1 fa-trash"></i></button>
            </div>
            <div class="col-7 text-right p-0" *ngIf="summary.product.isBundle">
                {{ price.price.priceAmount | currency }}
            </div>
        </div>
    </div>
</div>
