import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core';
import { User } from '@app/data/models/user.model';
import { UserService } from '@app/data/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { strongPasswordValidator, confirmPasswordValidator } from '@app/shared/ticket-spicket/validators';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Router } from '@angular/router';

const log = new Logger('ProfilePage');
@Component({
    selector: 'app-user-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    public user: User = new User();
    public profileForm: FormGroup;
    public profileIsLoading = false;
    public passwordForm: FormGroup;
    public passwordIsLoading = false;
    public showProfile = true;

    constructor(
        private formBuilder: FormBuilder,
        private _userService: UserService,
        private _messageService: MessageService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    ngOnInit() {
        this.createForm();
        if (this._userService.user) {
            this.user = this._userService.user;
            this.setFormData();
        } else {
            this._userService.user$.subscribe((user) => {
                this.user = this._userService.user;
                this.setFormData();
            });
        }
    }

    private setFormData() {
        this.profileForm.setValue({ nameFirst: this.user.nameFirst, nameLast: this.user.nameLast, email: this.user.email });
        this.passwordForm.setValue({ password: null, confirmPassword: null });
        this.passwordForm.setValidators(confirmPasswordValidator(this.passwordForm.get('password'), this.passwordForm.get('confirmPassword')));
    }

    private createForm() {
        this.profileForm = this.formBuilder.group({
            nameFirst: ['', Validators.required],
            nameLast: ['', Validators.required],
            email: ['', Validators.required]
        });

        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, strongPasswordValidator()]],
            confirmPassword: ['', Validators.required]
        });
    }

    public toggleProfile() {
        this.showProfile = this.showProfile ? false : true;
    }

    public updateProfile() {
        this.profileIsLoading = true;
        this._userService
            .updateProfile(this.profileForm.value).subscribe((response) => {
                this._messageService.toast.success("Your profile has been successfully updated");
                this.profileForm.markAsPristine();
                this.profileIsLoading = false;
            }, (error) => {
                this._messageService.toast.error(error.message);
            });
    }

    public updatePassword() {
        this.passwordIsLoading = true;
        this._userService
            .updatePassword(this.passwordForm.value).subscribe((response) => {
                if (response.success) {
                    this._messageService.toast.success("Your password has been successfully changed");
                    this.passwordForm.markAsPristine();
                    this.passwordForm.reset();
                    this.passwordIsLoading = false;
                } else {
                    log.debug(`Forgot Password error: ${response.message}`);
                    this._messageService.toast.error(response.message);
                    this.passwordIsLoading = false;
                }
            });
    }

    logout() {
        this.authenticationService.logout()
            .subscribe(() => this.router.navigate(['/home'], { replaceUrl: true }));
    }

}
