import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgxBraintreeVaultComponent } from '@app/cart/braintree/ngx-braintree-vault/ngx-braintree-vault.component';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
    selector: 'app-user-payments-methods',
    templateUrl: './payment-methods.component.html'
})
export class UserPaymentMethodsComponent implements OnInit {

    @ViewChild(NgxBraintreeVaultComponent, { static: false }) paymentMethod: NgxBraintreeVaultComponent;

    enableAddButton = false;
    canManageDefault = false;

    constructor(
        private _spinner: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this._spinner.setMessage('Loading Payment Methods');
    }

    ngOnInit() {
        this._spinner.hide();
    }

    onAddButtonStatus(event: boolean) {
        this.enableAddButton = event;
        this.changeDetectorRef.detectChanges();
    }

    onCanMarkAsDefault(event: boolean) {
        this.canManageDefault = event;
        this.changeDetectorRef.detectChanges();
    }

    add() {
        this.paymentMethod.addCard();
    }

    markAsDefault() {
        this.paymentMethod.markAsDefault();
    }
}
