import { IDeserializable } from "./deserializable.interface";
import { Address } from "@app/data/models/address.model";
import { AccountSettings } from "@app/data/models/settings.model";

export class Sponsor implements IDeserializable {

    id: number;
    uuid: string;
    name: string;
    tagline: string;
    website: string;
    vanityURI?: string;
    primaryAddress?: Address;
    settings?: AccountSettings;
    logoURI: string;
    version?: string;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if(input.index) {
            this.id = input.index;
            if(input.id) {
                this.uuid = input.id;
            }
        }

        this.settings = new AccountSettings().deserialize(input.settings);

        if(input.logo_uri) {
            this.logoURI = input.logo_uri;
        }

        if (input.primaryAddress) {
            this.primaryAddress = new Address().deserialize(input.primaryAddress);
        }

        return this;
    }

    static default() {
        let json: Object = {
            "version":null,
            "id": 0,
            "type":null,
            "uuid":null,
            "name":"Ticket Spicket",
            "website": "http://www.ticketspicket.com",
            "vanityURI":"www.ticketspicket.com",
            "logoURI":"https://s3.amazonaws.com/com.ticketspicket.app/logos/HomeTown_long_rgb.png",
            "tagline":null,
            "primaryAddress":null,
            "settings":{
               "snapchatURI":null,
               "twitterURI":null,
               "primaryText":"white",
               "timezone":"US/Eastern",
               "secondaryActive":"#004080",
               "primaryColor":"#005CB9",
               "primaryHover":"#4438ec",
               "logoURI":null,
               "secondaryText":"white",
               "primaryActive":"#005CB9",
               "coverArtURI":null,
               "instagramURI":null,
               "facebookURI":null,
               "logo2xURI":null,
               "secondaryColor":"#004080",
               "secondaryHover":"#196CC0"
            }
        }

        return new Sponsor().deserialize(json);

    }
}
