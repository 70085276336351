import { Component, OnInit, AfterContentChecked, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { CartService } from '@app/data/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PassSelectionModalComponent } from '@app/agency/passes/select-tickets.component';
import { MobileService } from '@app/data/services/mobile.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { FamilyPassSelectionModalComponent } from './select-family.component';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-agency-pass',
  templateUrl: './pass.component.html',
  styleUrls: ['./pass.component.scss']
})
export class AgencyPassComponent implements OnInit, AfterContentChecked {

  public passId: string;
  public agency: Agency;
  public pass: GatePass;
  public labelString: string = '';
  @ViewChild('sponsorImage', { static: false }) sponsorImage: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    public _agencyService: AgencyService,
    public _cartService: CartService,
    public _modal: NgbModal,
    public mobileService: MobileService,
    public angulartics2: Angulartics2GoogleAnalytics,
    private _spinner: SpinnerService,
    private _sanitizer: DomSanitizer
  ) {
    this._spinner.setMessage('Loading Pass Details');
  }

  ngOnInit() {
    this.agency = this._agencyService.agency;
    this.route.params
      .pipe(
        map((params) => this.passId = params['pass-id']),
        switchMap((id: number) => this._agencyService.getPass(this.agency.uuid, id))
      )
      .subscribe(
        (pass) => {
          this.pass = new GatePass().deserialize(pass);
          if (this.pass.isSeasonPass) {
            this._router.navigate(['/agency', this.agency.uuid, 'season', this.pass.uuid])
          }
          if (this.pass.isBundle) {
            this._router.navigate(['/agency', this.agency.uuid, 'bundle', this.pass.id])
          }
          //Track Pass Ad Load - ELH
          if (this.pass.sponsor.uuid) {
            this.labelString = this.pass.sponsor.uuid + ' - ' + 'Ad ID' + this.pass.id;
          } else {
            this.labelString = 'Default Ticket Spicket Ad - Pass Placement 1';
          }
          this.trackEvent('Load');
          this._spinner.hide();
        }
      );
  }

  ngAfterContentChecked() {
  }

  //Track Pass Ad Click - ELH
  public sponsorLinkClicked(url: string) {
    this.trackEvent('Click');
    if (this.mobileService.isCapacitor) {
      this.mobileService.openURL(url);
    } else {
      window.open(url, '_blank');
    }
  }

  public trackEvent(type: string) {
    this.angulartics2.eventTrack(
      type, {
      category: 'Digital Ad - Pass Placement 1',
      label: this.labelString,
      value: 1
    }
    );
  }

  public buyPasses() {

    if (this.pass.isFamilyPass) {

      const modalRef = this._modal.open(FamilyPassSelectionModalComponent, { centered: true, size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.product = this.pass;

    } else {

      const modalRef = this._modal.open(PassSelectionModalComponent, { centered: true, size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.product = this.pass;

    }

  }

  public hasCartItem(): boolean {
    return this._cartService.hasItem(this.pass);
  }

  public getTerms(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.pass.terms);
  }

  public hasTerms(): boolean {
    return !_.isEmpty(this.pass.terms);
  }
}
