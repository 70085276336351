import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { AgencyGeneric } from '@app/data/models/agency-generic.model';
import { Title } from '../../../node_modules/@angular/platform-browser';
import { of } from 'rxjs';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class AgencyGenericComponent implements OnInit {

    public agencyId: string;
    public agency: AgencyGeneric;
    public noResultsMessage = 'events at this time';
    public hashtag: string;

    constructor (
        private route: ActivatedRoute,
        private _agencyService: AgencyService,
        private _title: Title,
        private router: Router,
        private _spinner: SpinnerService
    ) {
      this._spinner.setMessage('Loading...');
    }

    ngOnInit() {
        this._spinner.show()
        this.route.params
            .pipe(
                map( (params) => params['nces-id']),
                switchMap( (id: string) => this._agencyService.getGenericSchool(id)),
                catchError( (err) => {
                    this.router.navigate(['/']);
                    return of (null);
                })
            )
            .subscribe((agency) => {
                this.agency = agency;
                this.hashtag = this.agency.name.split(" ")[0];
                this.agency.settings.primaryColor = '#005CB9';
                this.agency.settings.primaryHover = '#004080';
                this.agency.settings.primaryActive = '#005CB9';
                this.agency.settings.primaryText = '#FFFFFF';
                this.agency.settings.secondaryColor = '#004080';
                this.agency.settings.secondaryHover = '##003367';
                this.agency.settings.secondaryActive = '#004080';
                this.agency.settings.secondaryText = '#FFFFFF';
                this.agency.settings.logoURI = null;
                this._title.setTitle(this.agency.name + " - HomeTown");
                this._spinner.hide();
            });
    }

    get agencyName(): any {
        return this._agencyService.agencyName;
    }

}
