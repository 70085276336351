<div class="pass-list-item mb-3" [ngStyle]="{'border-color': pass.agency.settings?.primaryColor}" [ngClass]="{'dashed': configMode, 'd-none': isLinked && configMode}" style="position:relative;border-left:6px solid #004080;box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);">
    <div class="pass-item-container p-0">
        <ts-pass-header-list-item [pass]="pass" [showIcon]="schoolView" [showPrices]="schoolView"></ts-pass-header-list-item>
        <div class="row align-items-center text-muted m-0 location">
            <div class="col-12 footer py-2">
                <div class="text-center select float-right">
                    <div class="d-inline">Buy Passes</div>
                </div>
            </div>
        </div>
    </div>
</div>
