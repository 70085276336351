import { Component, OnInit } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';
import { UserService } from '@app/data/services/user.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html'
})
export class FavoritesComponent implements OnInit {
    
    public following: Agency[] = new Array<Agency>();

    constructor (
        private _userService: UserService
    ) {
        this.following = _userService.user.following;
    }

    ngOnInit() {
        this._userService.getFollowing().subscribe(following => this.following = following);
    }

}
