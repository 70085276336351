import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './cart.component';
import { CheckoutComponent } from '@app/cart/checkout.component';
import { ConfirmationComponent } from '@app/cart/confirmation.component';
import { CartSummaryComponent } from '@app/cart/summary.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxBraintreeModule } from '@app/cart/braintree/ngx-braintree.module';
import { SharedModule } from '@app/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseComponent } from './purchase/purchase.component';
import { CheckoutOptionComponent } from './checkout-option/checkout-option.component';

@NgModule({
  imports: [
    CommonModule,
    CartRoutingModule,
    NgxBraintreeModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    CartComponent,
    CartSummaryComponent,
    CheckoutComponent,
    ConfirmationComponent,
    PurchaseComponent,
    CheckoutOptionComponent
  ],
  exports: [
    CartSummaryComponent,
    CheckoutComponent,
    ConfirmationComponent,
    PurchaseComponent,
  ],
  providers: [
    NgbActiveModal
  ]
})
export class CartModule { }
