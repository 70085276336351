import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';
import { Sponsor } from "@app/data/models/sponsor.model";

export class Deal implements IDeserializable {

    public id: number;
    public title: string;
    public subtitle: string;
    public description: string;
    public finePrint: string;
    public promoCode: string;
    public dateExpiration: Date;
    public dealDays: number;
    public backgroundImageURI: string;
    public featureGraphicURI: string;
    public videoURI: string;
    public isDefault: boolean;
    public html: string;

    public sponsor: Sponsor;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        this.dateExpiration = moment(input.dateExpiration).toDate();
        this.sponsor = new Sponsor().deserialize(input.sponsor);
        return this;
    }

}
