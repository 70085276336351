import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickStopPropagation } from '@app/shared/ticket-spicket/directives/stop-propogation.directive';
import { AgencyCardComponent } from '@app/shared/ticket-spicket/agency-card/agency-card.component';
import { AgencyFooterComponent } from '@app/shared/ticket-spicket/agency-footer/agency-footer.component';
import { AgencySubmenuComponent } from '@app/shared/ticket-spicket/agency-submenu/agency-submenu.component';
import { AgencyStyleComponent } from '@app/shared/ticket-spicket/agency-style/agency-style.component';
import { AgencyListItemComponent } from '@app/shared/ticket-spicket/agency-list-item/agency-list-item.component';
import { CallToActionComponent } from '@app/shared/ticket-spicket/call-to-action/call-to-action.component';
import { CartPassComponent } from '@app/shared/ticket-spicket/cart-pass/cart-pass.component';
import { CartTicketComponent } from '@app/shared/ticket-spicket/cart-ticket/cart-ticket.component';
import { EventCardComponent } from '@app/shared/ticket-spicket/event-card/event-card.component';
import { EventCardH2HComponent } from '@app/shared/ticket-spicket/event-card-h2h/event-card-h2h.component';
import { EventListItemComponent } from '@app/shared/ticket-spicket/event-list-item/event-list-item.component';
import { ConsumerPassListItemComponent } from '@app/shared/ticket-spicket/consumer-pass-list-item/consumer-pass-list-item.component';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NoResultsComponent } from '@app/shared/ticket-spicket/no-results/no-results';
import { PassCardComponent } from '@app/shared/ticket-spicket/pass-card/pass-card.component';
import { RewardCardComponent } from '@app/shared/ticket-spicket/reward-card/reward-card.component';
import { SocialShareComponent } from '@app/shared/ticket-spicket/social-share/social-share.component';
import { SvgComponent } from '@app/shared/ticket-spicket/svg/svg.component';
import { TicketComponent } from '@app/shared/ticket-spicket/ticket/ticket.component';
import { TicketListItemComponent } from '@app/shared/ticket-spicket/ticket-list-item/ticket-list-item.component';
import { TicketSpicketConfirmation } from '@app/shared/ticket-spicket/confirm-dialog/confirm-dialog.component';
import { TruncatePipe } from '@app/shared/ticket-spicket/directives/truncate-pipe';
import { TicketHeaderComponent } from '@app/shared/ticket-spicket/ticket-header/ticket-header.component';
import { TicketEventHeaderComponent } from '@app/shared/ticket-spicket/ticket-event/ticket-event.component';
import { LoadingComponent } from '@app/shared/ticket-spicket/loading/loading.component';
import { PaginationComponent } from '@app/shared/ticket-spicket/pagination/paginiation.component';
import { TicketSpicketMaterialModule } from '@app/shared/material.module';
import { SponsorBannerAdComponent } from '@app/shared/ticket-spicket/sponsor-banner/sponsor-banner.component';
import { SectionHeaderComponent } from '@app/shared/ticket-spicket/section-header/section-header.component';
import { EventHeaderListItemComponent } from '@app/shared/ticket-spicket/event-header-list-item/event-header-list-item.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ShareModule } from '@ngx-share/core';
import { TicketHelpCTAComponent } from '@app/shared/ticket-spicket/ticket-help-cta/ticket-help-cta.component';
import { ReadOnlyComponent } from '@app/shared/ticket-spicket/read-only/read-only.component';
import { PassHeaderListItemComponent } from '@app/shared/ticket-spicket/pass-header-list-item/pass-header-list-item.component';
import { PassConsumerCardComponent } from '@app/shared/ticket-spicket/pass-consumer/pass-consumer-card.component';
import { TicketSpicketMatConfirmation } from '@app/shared/ticket-spicket/confirm-dialog/mat-confirm.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from '@app/shared/ticket-spicket/safe-pipe/safe-pipe';
import { SeatMapComponent } from '@app/shared/ticket-spicket/seat-map/seat-map.component';
import { SeatsioAngularModule } from '@seatsio/seatsio-angular';
import { TicketBottomSheet } from './bottom-sheet/ticket-bottom-sheet/ticket-bottom-sheet.component';
import { IncomingTransferBottomSheet } from './bottom-sheet/transfer-bottom-sheet/incoming-transfer-bottom-sheet.component';
import { ConfigurePassBottomSheet } from './bottom-sheet/configure-pass-bottom-sheet/configure-pass-bottom-sheet.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CartTicketTypeComponent } from './cart-ticket-types/cart-ticket-type.component';
import { CartTicketSeatsComponent } from './cart-ticket-seats/cart-ticket-seats.component';
import { TicketPriceGAComponent } from './ticket-price/ga-price.component';
import { TicketPriceGroupComponent } from './ticket-price/price-group.component';
import { TicketLegendComponent } from './ticket-legend/ticket-legend.component';
import { AgencyCoverComponent } from './agency-cover/agency-cover.component';
import { ConsumerPassListComponent } from './consumer-pass-list/consumer-pass-list.component';
import { PassListItemComponent } from './pass-list-item/pass-list-item.component';

@NgModule({
    imports: [
        CommonModule,
        TicketSpicketMaterialModule,
        QRCodeModule,
        NgbModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        ShareModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        SeatsioAngularModule,
        FormlyModule.forRoot(),
        FormlyMaterialModule
    ],
    declarations: [
        AgencyCardComponent,
        AgencyFooterComponent,
        AgencySubmenuComponent,
        AgencyStyleComponent,
        AgencyCoverComponent,
        AgencyListItemComponent,
        CallToActionComponent,
        CartTicketTypeComponent,
        CartTicketSeatsComponent,
        CartPassComponent,
        CartTicketComponent,
        ClickStopPropagation,
        EventCardComponent,
        EventCardH2HComponent,
        EventHeaderListItemComponent,
        ConsumerPassListComponent,
        ConsumerPassListItemComponent,
        EventListItemComponent,
        LoadingComponent,
        NoResultsComponent,
        PaginationComponent,
        PassCardComponent,
        PassConsumerCardComponent,
        PassHeaderListItemComponent,
        PassListItemComponent,
        ReadOnlyComponent,
        RewardCardComponent,
        SocialShareComponent,
        SvgComponent,
        TicketComponent,
        TicketEventHeaderComponent,
        TicketHeaderComponent,
        TicketHelpCTAComponent,
        TicketListItemComponent,
        TicketSpicketConfirmation,
        TicketSpicketMatConfirmation,
        TruncatePipe,
        SectionHeaderComponent,
        SponsorBannerAdComponent,
        TicketBottomSheet,
        IncomingTransferBottomSheet,
        ConfigurePassBottomSheet,
        SafePipe,
        SeatMapComponent,
        TicketPriceGAComponent,
        TicketPriceGroupComponent,
        TicketLegendComponent,
    ],
    exports: [
        AgencyCardComponent,
        AgencyFooterComponent,
        AgencySubmenuComponent,
        AgencyStyleComponent,
        AgencyCoverComponent,
        AgencyListItemComponent,
        CallToActionComponent,
        CartTicketTypeComponent,
        CartTicketSeatsComponent,
        CartPassComponent,
        CartTicketComponent,
        EventCardComponent,
        EventCardH2HComponent,
        EventHeaderListItemComponent,
        ConsumerPassListComponent,
        ConsumerPassListItemComponent,
        EventListItemComponent,
        NoResultsComponent,
        PassCardComponent,
        PassConsumerCardComponent,
        PassHeaderListItemComponent,
        PassListItemComponent,
        ReadOnlyComponent,
        RewardCardComponent,
        SocialShareComponent,
        SvgComponent,
        TicketHeaderComponent,
        TicketHelpCTAComponent,
        TicketEventHeaderComponent,
        TicketComponent,
        TicketListItemComponent,
        TicketSpicketConfirmation,
        TicketSpicketMatConfirmation,
        TruncatePipe,
        ClickStopPropagation,
        LoadingComponent,
        PaginationComponent,
        SponsorBannerAdComponent,
        SectionHeaderComponent,
        LoadingBarRouterModule,
        LoadingBarModule,
        ShareModule,
        TicketBottomSheet,
        IncomingTransferBottomSheet,
        ConfigurePassBottomSheet,
        SafePipe,
        SeatMapComponent,
        SeatsioAngularModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        TicketPriceGAComponent,
        TicketPriceGroupComponent,
        TicketLegendComponent,
    ],
    providers: [MessageService]
})
export class TicketSpicketModule {}
