import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-complete',
  templateUrl: './event-complete.component.html',
  styleUrls: ['./shared.component.scss']
})
export class EventCompleteComponent implements OnInit {
    
    constructor () {
    }

    ngOnInit() {
    }

}
