import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { TicketRenewalComponent } from './renewal/ticket-renewal.component';
import { SharedTicketsComponent } from './shared/shared-tickets.component';
import { InvalidBundleComponent } from './shared/invalid-bundle.component';
import { EventCompleteComponent } from './shared/event-complete.component';
import { TicketsModule } from '@app/tickets/tickets.module';
import { SharedComponent } from './shared/shared.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    HomeRoutingModule,
    TicketsModule    
  ],
  declarations: [
    HomeComponent,
    FavoritesComponent,
    SharedComponent,
    SharedTicketsComponent,
    InvalidBundleComponent,
    EventCompleteComponent,
    TicketRenewalComponent    
  ],
  providers: []
})
export class HomeModule {}
