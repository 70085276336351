import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ConsumerPassesComponent } from '@app/passes/passes.component';
import { ConsumerPassesListComponent } from '@app/passes/listing.component';
import { ConsumerPassesDetailComponent } from '@app/passes/detail.component';
import { PassDetailModalComponent } from '@app/passes/pass-detail.component';
import { PassAssignModalComponent } from '@app/passes/assign/pass-assign.component';
import { PassTransferModalComponent } from '@app/passes/transfer/pass-transfer.component';
import { ConsumerPassClaimModalComponent } from '@app/passes/claim/pass-claim-modal.component';

import { PassesRoutingModule } from './passes-routing.module';
import { ConsumerPassPunchModalComponent } from './punch/pass-punch-modal.component';
import { PassCancelTransferModalComponent } from './transfer/cancel-transfer-modal.component';
import { PassFilterModalComponent } from './filter.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        PassesRoutingModule,
    ],
    declarations: [
        ConsumerPassesComponent,
        ConsumerPassesListComponent,
        ConsumerPassesDetailComponent,
        PassDetailModalComponent,
        PassAssignModalComponent,
        PassTransferModalComponent,
        ConsumerPassClaimModalComponent,
        ConsumerPassPunchModalComponent,
        PassCancelTransferModalComponent,
        PassFilterModalComponent
    ],
    exports: [
        PassDetailModalComponent,
        PassAssignModalComponent,
        PassTransferModalComponent,
        PassCancelTransferModalComponent
    ]
})
export class PassesModule { }
