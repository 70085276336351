import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Observable } from 'rxjs';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})
export class NestTokenInterceptor implements HttpInterceptor {
  private authService: AuthenticationService;

  constructor(private injector: Injector) {}

  private getAuthService(): AuthenticationService {
    if (!this.authService) {
      this.authService = this.injector.get(AuthenticationService);
    }
    return this.authService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    const authService = this.getAuthService();
    const token = authService.getToken(); // Assuming this method exists on your AuthenticationService

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(request);
  }
}
