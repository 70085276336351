import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyRoutingModule } from '@app/agency/agency-routing.module';
import { SharedModule } from '@app/shared';
import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { AgencyComponent } from '@app/agency/agency.component';
import { AgencyHomeComponent } from '@app/agency/home/home.component';
import { AgencyEventsComponent } from '@app/agency/events/listing.component';
import { AgencyPassesComponent } from '@app/agency/passes/listing.component';
import { AgencyEventComponent } from '@app/agency/events/event.component';
import { ReservedTicketSelectionModalComponent } from '@app/agency/events/select-res-tickets.component';
import { AgencyPassComponent } from '@app/agency/passes/pass.component';
import { AgencyGenericComponent } from '@app/agency/generic.component';
import { EventFilterModalComponent } from '@app/agency/events/filter.component';
import { HelpModalComponent } from '@app/agency/help/help-modal.component';
import { AgencyEventsListComponent } from '@app/agency/events/events-list.component';
import { AgencyListModalComponent } from '@app/agency/home/agency-list.component';
import { AgencyTicketRenewalComponent } from '@app/agency/ticket-renewal/ticket-renewal.component';
import { TicketSelectionModalComponent } from '@app/agency/events/select-tickets.component';
import { PassSelectionModalComponent } from '@app/agency/passes/select-tickets.component';
import { TicketSelectionModalHeaderComponent } from './events/select-ticket-header.component';
import { AgencyPassesListComponent } from './passes/passes-list.component';
import { FamilyPassSelectionModalComponent } from './passes/select-family.component';
import { AgencySeasonReservedComponent } from './passes/season-reserved/season-reserved.component';
import { EventChannelComponent } from './events/channel/channel.component';
import { EventPricingComponent } from './events/pricing/pricing.component';
import { EventRegistrationModalComponent } from './events/show-registration.component';
import { AgencySeasonTicketRenewalComponent } from './season-ticket-renewal/season-ticket-renewal.component';
import { AgencySeasonTicketHeaderComponent } from './season-ticket-renewal/header/season-ticket-header.component';
import { AgencySeasonTicketSeatmapComponent } from './season-ticket-exchange/seatmap/season-ticket-seatmap.component';
import { SeasonTicketExchangeComponent } from './season-ticket-exchange/season-ticket-exchange.component';
import { AgencySeasonSeatmapComponent } from './passes/season-reserved/seatmap/seatmap.component';
import { NgxBraintreeModule } from '@app/cart/braintree/ngx-braintree.module';
import { AgencyBundleComponent } from './bundles/pass.component';
import { AgencyBundleSeatmapComponent } from './bundles/seatmap/seatmap.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AgencyRoutingModule,
        NgxTwitterTimelineModule,
        NgxBraintreeModule,
    ],
    declarations: [
        AgencyComponent,
        AgencyHomeComponent,
        AgencyEventsComponent,
        AgencyEventComponent,
        ReservedTicketSelectionModalComponent,
        AgencyPassesComponent,
        AgencyPassesListComponent,
        AgencyPassComponent,
        AgencySeasonReservedComponent,
        AgencyTicketRenewalComponent,
        AgencyGenericComponent,
        EventFilterModalComponent,
        AgencyEventsListComponent,
        AgencyListModalComponent,
        HelpModalComponent,
        TicketSelectionModalComponent,
        TicketSelectionModalHeaderComponent,
        PassSelectionModalComponent,
        FamilyPassSelectionModalComponent,
        EventChannelComponent,
        EventPricingComponent,
        EventRegistrationModalComponent,
        AgencySeasonTicketHeaderComponent,
        AgencySeasonTicketRenewalComponent,
        AgencySeasonTicketSeatmapComponent,
        SeasonTicketExchangeComponent,
        AgencySeasonSeatmapComponent,
        AgencyBundleComponent,
        AgencyBundleSeatmapComponent,
    ],
    exports: [
        AgencyEventsListComponent,
        AgencyEventComponent,
        AgencyPassesListComponent,
        AgencyPassComponent
    ],
    providers: []
})
export class AgencyModule {}
