import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-user-payments',
  templateUrl: './payments.component.html'
})
export class UserPaymentsComponent implements OnInit {

    constructor (
    ) { }

    ngOnInit() {
    }

}
