import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { CartService } from '@app/data/services/cart.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { CartItem, CartItemSummary } from '@app/data/models/cart/cart-item.model';
import { ISchool } from '@app/data/models/school.interface';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Agency } from '@app/data/models/agency.model';
import { CartItemCollection } from '@app/data/models/cart/cart-item-collection.model';
import { RenewalTicketHolder } from '@app/data/models/renewal/ticket-holder.model';
import { RenewalTicketHolderSeat } from '@app/data/models/renewal/ticket-holder-seat.model';
import * as _ from 'lodash';
import tinycolor from "tinycolor2";
import { ReservedSeat } from '@app/data/models/reserved/seat.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-agency-season-ticket-header',
  templateUrl: './season-ticket-header.component.html',
  styleUrls:['./season-ticket-header.component.scss']
})
export class AgencySeasonTicketHeaderComponent implements OnInit {

    @Input()
    @Input('agency') agency: ISchool;

    public pass: GatePass = new GatePass();

    getCoverArtContainerStyles() {
        const lineColor = tinycolor(this.agency.settings?.primaryColor);
        const colorIsTooDark = lineColor.getLuminance() < 0.1;

        const backgroundGradient = `
            repeating-linear-gradient(
                135deg,
                transparent,
                transparent 5px,
                ${ this.agency.settings?.primaryColor } 5px,
                ${ this.agency.settings?.primaryColor } 10px
            ),
            linear-gradient(
                to bottom,
                ${ colorIsTooDark ? lineColor.lighten(10) : lineColor.darken(10) },
                ${ this.agency.settings?.primaryColor } 85%,
                ${ this.agency.settings?.primaryColor }
            )
        `;

        return {
            background: backgroundGradient,
        };
    }

    constructor ( ) {}

    ngOnInit() {

    }

}
