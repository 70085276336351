<div class="p-3">
    <ts-section-header>
        Passes
    </ts-section-header>
    <ts-no-results *ngIf="(filteredPasses$ | async).length == 0 && !filter.hasFilter()" [message]="noResultsMessage"></ts-no-results>
    <div class="row">
        <div class="col-12 mb-0" *ngFor="let pass of filteredPasses$ | async; let i = index">
            <ts-consumer-pass-list [pass]="pass" [routerLink]="[i]"></ts-consumer-pass-list>
        </div>
    </div>
    <div class="text-center p-3" style="color:lightslategrey;" *ngIf="filter.hasFilter() && (filteredPasses$ | async).length == 0">
      <h4 class="h4-responsive">There are no results based on the applied filter</h4>
      <div class="mt-1">
          <button mdbRippleRadius type="button" class="btn btn-secondary mr-2 active" (click)="filter.clearFilters()">
              <span class="d-none d-sm-inline">Remove Filter</span>
              <i class="fa fa-filter ml-1"></i>
              <i class="fa fa-xs pt-1 fa-times" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasFilter()"></i>
          </button>
      </div>
    </div>
</div>
