<div *ngIf="event.isRegistrationEvent()">
    <div class="mb-4" *ngIf="getGATicketPrices().length > 0">
        <ts-section-header>
            <i class="fas fa-fw fa-ticket-alt mr-1"></i>
            <span>Available Tickets &amp; Prices</span>
        </ts-section-header>
        <ts-no-results message="tickets available" *ngIf="!event.areTicketsAvailable()"></ts-no-results>
        <div class="px-2" *ngIf="event.areTicketsAvailable()">
            <ts-ticket-price-general [price]="price" *ngFor="let price of getGATicketPrices()"></ts-ticket-price-general>
        </div>
    </div>
    <div class="row align-items-center mt-3" *ngIf="!event.isPassOnly() && getGATicketPrices().length > 0">
        <div class="col-12 mt-4 mb-2">
            <button mdbRippleRadius [disabled]="!event.areTicketsAvailable()" type="button" class="btn btn-primary w-100" (click)="buyTickets()">
                <i class="fas mr-2" [ngClass]="{'fa-ticket-alt': event.areTicketsAvailable(), 'fa-ban': !event.areTicketsAvailable()}"></i>
                Register
            </button>
        </div>
    </div>
</div>
<div *ngIf="!event.isRegistrationEvent()">
    <div class="mb-4" *ngIf="getGATicketPrices().length > 0">
        <ts-section-header>
            <i class="fas fa-fw fa-ticket-alt mr-1"></i>
            <span *ngIf="!hasReservedSeating">Available</span>
            <span *ngIf="hasReservedSeating">General</span>
            Tickets &amp; Prices
        </ts-section-header>
        <ts-no-results message="tickets available" *ngIf="!event.areTicketsAvailable()"></ts-no-results>
        <div class="px-2" *ngIf="event.areTicketsAvailable()">
            <ts-ticket-price-general [price]="price" *ngFor="let price of getGATicketPrices()"></ts-ticket-price-general>
        </div>
    </div>
    <div class="row align-items-center" [ngClass]="{'mt-3': event.areTicketsAvailable(),'mb-5': hasReservedSeating}" *ngIf="!event.isPassOnly() && getGATicketPrices().length > 0">
        <div class="col-12 mt-4 mb-2">
            <button mdbRippleRadius [disabled]="!event.areTicketsAvailable()" type="button" class="btn btn-primary w-100" (click)="buyTickets()">
                <i class="fas mr-2" [ngClass]="{'fa-ticket-alt': event.areTicketsAvailable(), 'fa-ban': !event.areTicketsAvailable()}"></i>
                Buy <span *ngIf="hasReservedSeating">General Admission</span> Tickets
            </button>
        </div>
    </div>
    <div class="mb-4" *ngIf="hasReservedSeating">
        <ts-section-header>
            <i class="fas fa-fw fa-ticket-alt mr-1"></i>Reserved Tickets &amp; Prices
        </ts-section-header>
        <ts-no-results message="tickets available" *ngIf="!event.areTicketsAvailable()"></ts-no-results>
        <div class="px-2" *ngIf="event.areTicketsAvailable()">
            <ts-ticket-price-general [price]="price" *ngFor="let price of getRESVTicketPrices()"></ts-ticket-price-general>
        </div>
    </div>
    <div class="row align-items-center" [ngClass]="{'mt-3': event.areTicketsAvailable()}"
        *ngIf="hasReservedSeating">
        <div class="col-12 mt-4 mb-2">
            <button mdbRippleRadius [disabled]="!event.areTicketsAvailable()" type="button"
                class="btn btn-primary w-100" (click)="buyReservedTickets()">
                <i class="fas mr-2" [ngClass]="{'fa-ticket-alt': event.areTicketsAvailable(), 'fa-ban': !event.areTicketsAvailable()}"></i>
                Buy Reserved Seats
            </button>
        </div>
    </div>
</div>
