import { Component, OnInit, OnDestroy } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApplicationVersion } from '@app/data/models/application-version.model';
import { switchMap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { MobileService } from '@app/data/services/mobile.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TransferService } from '@app/data/services/transfer.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { IncomingTransferBottomSheet } from '@app/shared/ticket-spicket/bottom-sheet/transfer-bottom-sheet/incoming-transfer-bottom-sheet.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UserService } from '@app/data/services/user.service';
import { InboxService } from '@app/data/services/inbox.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { ApplicationService } from '@app/data/services/application.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {

    public isAgencyPage = false;
    public version: ApplicationVersion;
    public currentVersion: string = environment.version;
    public inCart: boolean;

    private _checkVersionIntervalInSeconds: number = 60;
    private checkVersion$: Subscription;

    constructor(
        public cart: CartService,
        public router: Router,
        public mobileService: MobileService,
        private _bottomSheet: MatBottomSheet,
        private _transferService: TransferService,
        private _messageService: MessageService,
        private _authenticationService: AuthenticationService,
        private _inboxService: InboxService,
        private _userAdmissionService: UserAdmissionService,
        private _applicationService: ApplicationService,
        private _spinner: SpinnerService,
    ) {
        // Added below router check to adjust cart language based on whether on an agency page or not. - ELH
        this.router.events.subscribe(() => {
            this.inCart = this.router.url.indexOf('cart') > -1;
            if (this.router.url.indexOf('agency') > -1) {
                this.isAgencyPage = true;
            } else {
                this.isAgencyPage = false;
            }
        });

        this.mobileService.deviceCheck();
    }

    public ticketBadgeCount = 0;

    ngOnInit() {
        this.checkVersion$ = interval(this._checkVersionIntervalInSeconds * 1000).pipe(
            switchMap(() => this._applicationService.getVersion())
        ).subscribe((version) => this.version = version);

        this.mobileService.deviceCheck();

        this._userAdmissionService.ticketBadgeCount$.subscribe(res => {
            this.ticketBadgeCount = res;
        });
    }

    ngOnDestroy() {
        this.checkVersion$.unsubscribe();
    }

    hasNewVersion() {
        if (this.version) {
            return this.version.version !== environment.version;
        } else {
            return false;
        }
    }

    isIPhoneX(){
        var isIPhone = this.mobileService.deviceInfo?.model === 'iPhone' ? true : false;

        // Get the device pixel ratio
        var ratio = window.devicePixelRatio || 1;

        // Define the users device screen dimensions
        var screen = {
            width : window.screen.width * ratio,
            height : window.screen.height * ratio
        };

        // iPhone X Detection
        if (isIPhone && screen.width >= 1125 && screen.height >= 2436) {
            return true;
        }else{
            return false;
        }
    }

    /**
     * basically
     */
    public refreshApp() {
        window.history.go(0);
        //window.location.reload(true);
    }

    get hasIncomingTransfer(): boolean {
        return this._transferService.getIncomingTransferCount() > 0;
    }

    public openBottomSheet(): void {
        const bottomSheetRef = this._bottomSheet.open(IncomingTransferBottomSheet);
        bottomSheetRef.afterDismissed().subscribe((choice: string) => {
            switch (choice) {
                case 'accept':
                    this._messageService.toast.success("Your Ticket Transfer has been accepted.");
                    break;
                case 'decline':
                    this._messageService.toast.success("Your Ticket Transfer has been declined successfully.");
                    break;
                default: break;
            }
        });
    };

    public isAuthenticated(): boolean {
        return this._authenticationService.isAuthenticated();
    }

    get unreadCount(): number {
        return this._inboxService.getUnreadCount();
    }

    public logout() {
        this._spinner.show();
        this._authenticationService.logout().subscribe(
            () => {
                this.router.navigate(['/home'], { replaceUrl: true });
                this._spinner.hide();
            });
    }

}
