<div class="modal-body pb-4">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="activeModal.dismiss(false)">
        <span style="font-size:3rem;font-weight:300;" aria-hidden="true">&times;</span>
    </button>
    <div class="row">
        <div class="col-12 mb-2">
            <div class="row">
                <div class="col-12">
                    <ts-section-header class="h5-responsive mb-3">
                        Using Your Tickets
                        <div style="clear:both;"></div>
                    </ts-section-header>
                    <p style="font-size:0.8rem;">Quickly show your digital ticket <span *ngIf="!digitalOnly">or paper ticket</span> to the gate attendant at the event using your mobile device.</p>
                </div>
            </div>
            <div class="row align-items-center mb-2">
                <div class="col-2 col-md-1 text-center">
                    <div class="step mb-2 mx-auto">
                        1
                    </div>
                </div>
                <div class="col-10 col-md-11">
                    <div class="font-weight-bold my-sm-2">
                        Login to Ticket Spicket
                    </div>
                    <div class="p-0 description">
                        Now that you’ve purchased tickets to your event, simply login to your account on ticketspicket.com or within the Ticket Spicket mobile app.
                    </div>
                </div>
            </div>
            <div class="row align-items-center mb-2">
                <div class="col-2 col-md-1 text-center">
                    <div class="step mb-2 mx-auto">
                        2
                    </div>
                </div>
                <div class="col-10 col-md-11">
                    <div class="font-weight-bold my-sm-2">
                        Go to "Tickets &amp; Passes"
                    </div>
                    <div class="p-0 description">
                        Click to expand the side navigation menu and navigate to the "Tickets &amp; Passes" section.
                    </div>
                </div>
            </div>
            <div class="row align-items-center mb-2">
                <div class="col-2 col-md-1 text-center">
                    <div class="step mb-2 mx-auto">
                        3
                    </div>
                </div>
                <div class="col-10 col-md-11">
                    <div class="font-weight-bold my-sm-2">
                        Select your ticket
                    </div>
                    <div class="p-0 description">
                        Once you find the appropriate ticket that you’ve purchased for the event, simply select that ticket to show the gate attendant. <span *ngIf="!digitalOnly">Or, if you've elected to print your tickets at home simply show your printed ticket to the gate attendant. If you need to reprint your paper tickets, just tap on the Print Tickets button to download your tickets.</span>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mb-2">
                <div class="col-2 col-md-1 text-center">
                    <div class="step mb-2 mx-auto">
                        4
                    </div>
                </div>
                <div class="col-10 col-md-11">
                    <div class="font-weight-bold my-sm-2">
                        Check-in at the event
                    </div>
                    <div class="p-0 description">
                        The gate attendant will want to see you check-in on your device <span *ngIf="!digitalOnly">or by using your paper ticket</span> and verify that your ticket is valid.
                    </div>
                </div>
            </div>
            <div class="row my-2 d-none d-sm-block">
                <div class="col-12 m-t text-center">
                    <a href="https://itunes.apple.com/us/app/ticket-spicket/id1142852936?mt=8" class="appStore" target="_blank" alt="Download on the App Store"></a><br class="d-none d-sm-block" />
                    <a href="https://play.google.com/store/apps/details?id=com.ticketspicket.ticketspicket" class="googlePlay" target="_blank" alt="Get it on Google Play"></a>
                </div>
            </div>
        </div>
    </div>
<div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="activeModal.dismiss(false)">Cancel</button>
</div>
