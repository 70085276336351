import { Component, Input } from '@angular/core';
import { TicketPrice } from '@app/data/models/ticket-price.model';

@Component({
    selector: 'ts-ticket-price-general',
    templateUrl: './ga-price.component.html',
    styleUrls: ['./ga-price.component.scss']
})

export class TicketPriceGAComponent {

    @Input('price') price: TicketPrice;

    constructor () { }

}