import { Injectable, Injector, Inject, Optional } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { FanwebServicePrefixInterceptor } from './http-nest-prefix.interceptor';
import { ErrorHandlerInterceptor } from './error-handler.interceptor';
import { Observable } from 'rxjs';
import { TokenInterceptor } from './token.interceptor';
import { NestTokenInterceptor } from './nest-token.interceptor';


// From @angular/common/http/src/interceptor: allows to chain interceptors
class HttpInterceptorHandler implements HttpHandler {
    constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

    handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(request, this.next);
    }
}

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable({
    providedIn: 'root'
})
export class HttpFanwebClient extends HttpClient {

    public interceptors: HttpInterceptor[] = [];
    private _client: HttpClient;

    constructor(
        public httpHandler: HttpBackend,
        public injector: Injector
    ) {

        super(httpHandler);
        this.interceptors = [
            this.injector.get(FanwebServicePrefixInterceptor), 
            this.injector.get(NestTokenInterceptor), 
            this.injector.get(ErrorHandlerInterceptor)
        ];

        const handler = this.interceptors.reduceRight(
            (next, interceptor) => new HttpInterceptorHandler(next, interceptor), this.httpHandler
        );
        this._client = new HttpClient(handler);

    }

    // Override the original method to wire interceptors when triggering the request.
    override request(method?: any, url?: any, options?: any): any {
        const handler = this.interceptors.reduceRight(
        (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
        this.httpHandler
        );
        return new HttpClient(handler).request(method, url, options);
    }

}
