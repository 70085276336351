import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';

export class Promotion implements IDeserializable {

    public id: number;
    public name: string;
    public description: string;
    public message: string;
    public finePrint: string;
    public dateStart: Date;
    public dateEnd: Date;
    public maxUseCount: number;
    public maxUseCountLogin: number;
    public maxAmountDiscount: number;
    public isCodeRequired: boolean;

    public amountDiscount: number = 0;

    public isValid: boolean = false;
    public invalidMessage: string;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.dateStart) 
            this.dateStart = moment(input.dateStart).toDate();
        if (input.dateEnd) 
            this.dateEnd = moment(input.dateEnd).toDate();
        return this;
    }

}