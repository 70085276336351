<div class="modal-body pb-4">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close"
        (click)="activeModal.dismiss(false)">
        <span style="font-size:3rem;font-weight:300;" aria-hidden="true">&times;</span>
    </button>
    <div class="mb-2">
        <ts-section-header>Members</ts-section-header>
        <div class="row align-items-center">
            <div class="col-12 col-sm-12 col-lg-6 mb-4" *ngFor="let agency of agencies" [routerLink]="['/agency', agency.uuid]" (click)="activeModal.dismiss(false)" style="cursor:pointer;">
                <ts-agency-card [agency]="agency"></ts-agency-card>
            </div>
        </div>
    </div>
<div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="activeModal.dismiss(false)">Close</button>
</div>
