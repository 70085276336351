import { IDeserializable } from "@app/data/models/deserializable.interface";
import * as moment from 'moment';
import { Deal } from "@app/data/models/deal.model";

export class Reward implements IDeserializable {

    public id: number;
    public uuid: string;
    public dateStart: Date;
    public dateEnd: Date;
    public isValid: boolean;
    public selected: boolean;
    public dateRead: Date;

    public deal: Deal;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);
        this.dateStart = moment(input.dateStart).toDate();
        this.dateEnd = moment(input.dateEnd).toDate();
        this.dateRead = moment(input.dateRead).toDate();
        this.deal = new Deal().deserialize(input.deal);
        return this;
    }

    set isRead(val: boolean) {
    }

    get isRead(): boolean {
        return this.dateRead !== null;
    }

    set isSelected(val: boolean) {
    }

    get isSelected(): boolean {
        return this.selected !== null;
    }

}