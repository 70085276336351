import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/data/services/user.service';
import { Payment } from '@app/data/models/payment.model';
import { Pagination } from '@app/data/models/pagination.model';
import { Router } from '@angular/router';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
    selector: 'app-user-payments-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss']
})
export class UserPaymentsListingComponent implements OnInit {

    public payments: Payment[];
    public noResultsMessage = 'payments at this time';
    public pagination: Pagination = new Pagination(10);
    public isLoading: boolean = true;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _spinner: SpinnerService
    ) {
        this._spinner.setMessage('Loading Payment History');
    }

    ngOnInit() {
        //this._spinner.show();
        this.isLoading = true;
        this._userService.getPayments().subscribe((payments) => {
            this.payments = payments;
            this.isLoading = false;
            this._spinner.hide();
        });
    }

}
