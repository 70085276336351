<div *ngIf="confirmation" class="bg-white p-3">
    <div class="row justify-content-md-center text-center my-4">
        <div class="col-12 col-md-8">
            <div class="py-4 mb-4 mx-auto">
                <img src="https://s3.amazonaws.com/com.ticketspicket.app/misc-images/confirmation.png" alt="" height="250" />
            </div>
            <h3 class="h3-responsive pb-4" *ngIf="confirmation.payment.paymentAmount > 0">
                Confirmation #: {{ confirmation.payment.referenceIdentifier }}
                <div class="clearfix"></div>
            </h3>
            <h3 class="h3-responsive pb-3" *ngIf="confirmation.payment.paymentAmount === 0">
                Congratulations
                <div class="clearfix"></div>
            </h3>
            <h5 class="pb-3" *ngIf="confirmation.payment.paymentAmount === 0">
                Enjoy your digital tickets!
            </h5>
        </div>
    </div>
    <!-- <div class="row justify-content-md-center mb-3 text-center">
        <div class="col-12 col-md-8 font-sm">
            <p>Go directly to the ticket taker to have your mobile ticket scanned. Your purchases are now available under your <a routerLink="/tickets">Tickets &amp; Passes</a> page.</p>
            <p><strong>Please note:</strong> If you are checking in more than one ticket, your entire party must be present at the time of check in at the gate.</p>
        </div>
    </div> -->
    <div class="row justify-content-md-center">
        <div class="col-12 col-md-8">
            <hr />
        </div>
    </div>
    <div class="row font-sm justify-content-md-center mb-2">
        <div class="col-5 col-md-4">Total Amount Paid:</div>
        <div class="col-7 col-md-4 text-right">
            <strong *ngIf="confirmation.payment.paymentAmount > 0">{{ confirmation.payment.paymentAmount | currency }}</strong>
            <strong *ngIf="confirmation.payment.paymentAmount === 0"><span style="color:green;">FREE</span></strong>
        </div>
    </div>
    <div *ngIf="cart.phone=='' " class="row font-sm justify-content-md-center mb-2">
        <div class="col-5 col-md-4">Email Receipt:</div>
        <div class="col-7 col-md-4 text-right"><strong>{{ user.email }}</strong></div>
    </div>
    <div *ngIf="cart.phone "class="row font-sm justify-content-md-center mb-2">
        <div class="col-5 col-md-4">Phone Number:</div>
        <div class="col-7 col-md-4 text-right"><strong>{{ cart.phone }}</strong></div>
    </div>
    <div class="row font-sm justify-content-md-center mb-2" *ngIf="confirmation.payment.paymentAmount > 0">
        <div class="col-5 col-md-4">Payment Details:</div>
        <div class="col-7 col-md-4 text-right"><strong>Card ending in: {{ confirmation.method.details.lastFour }}</strong></div>
    </div>
    <div class="row justify-content-md-center mb-3 text-center">
        <div class="col-12 col-md-8">
            <!-- NEEDS WIRING TO IDENTIFY TICKETS V. PASSES - ELH -->
            <ngb-accordion #acc="ngbAccordion">
                <ngb-panel *ngIf="tickets.length > 0 || passes.length > 0">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-sort-down mr-2"></i>
                        <span>Purchase Summary</span>
                        <div class="float-right">
                            <div class="amount">
                                $1,000.00
                            </div>
                            <div class="quantity">
                                {{ cart.getTotalQty() }}
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div *ngFor="let item of tickets">
                            <!-- <ts-cart-ticket [item]="item" (change)="remove($event)" [confirmation]="true"></ts-cart-ticket> -->
                        </div>
                        <div *ngFor="let item of passes">
                            <!-- <ts-cart-pass [item]="item" (change)="remove($event)" [confirmation]="true"></ts-cart-pass> -->
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    <div *ngIf="cart.phone=='' " class="row justify-content-md-center my-5 text-center">
        <div class="col-12 col-md-8">
            <button class="btn btn-secondary btn-block px-5 my-2 mx-auto" routerLink="/tickets">
                Go To Tickets & Passes
            </button>
        </div>
    </div>
    <div *ngIf="cart.phone" class="row justify-content-md-center my-5 text-center">
        <div class="col-12 col-md-8">
            <button class="btn btn-secondary btn-block px-5 my-2 mx-auto" (click)="fastLaneUrl()">
                Go To Tickets
            </button>
        </div>
    </div>
</div>
