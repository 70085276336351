import { IDeserializable } from "@app/data/models/deserializable.interface";
import { Promotion } from "./promotion.model";
import * as _ from 'lodash';

export class PromotionCode implements IDeserializable {

    public code: string;
    public promotion: Promotion;
    public amountDiscount: number;
    public isValid: boolean = true;
    public message: string = null;

    constructor() {
        this.code = null;
        this.amountDiscount = 0;
    }

    create(code: string) {
        this.code = code;
        this.promotion = new Promotion()
        return this;
    }

    setPromotion(promotion: Promotion) {

        this.promotion = promotion;

        this.isValid = promotion.isValid;
        this.message = promotion.invalidMessage;

        if (promotion.id == null) {
            this.message = "This promo code is invalid for the items you have selected.";
        }

    }

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.promotion) {
            this.promotion = new Promotion().deserialize(input.promotion);
        }
        return this;
    }

}