import { Component, Input, OnInit } from '@angular/core';
import { ISchool } from '@app/data/models/school.interface';

@Component({
  selector: 'ts-agency-cover',
  templateUrl: './agency-cover.component.html',
  styleUrls: ['./agency-cover.component.scss']
})

export class AgencyCoverComponent implements OnInit {

    @Input('agency') agency: ISchool;

    constructor () { }

    ngOnInit() {
    }

}
