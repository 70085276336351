import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TicketsRoutingModule } from './tickets-routing.module';
import { TicketsComponent } from './tickets.component';
import { TransferListingComponent } from '@app/tickets/transfers/listing.component';
import { TicketModalComponent } from '@app/tickets/ticket/ticket-modal.component';
import { TicketsListingComponent } from '@app/tickets/ticket/listing.component';
import { TicketTransferModalComponent } from '@app/tickets/transfers/transfer-modal.component';
import { TicketCancelTransferModalComponent } from '@app/tickets/transfers/cancel-transfer-modal.component';

import { TicketRedeemComponent } from './ticket/ticket-redeem.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        TicketsRoutingModule,
    ],
    declarations: [
        TicketsComponent,
        TicketsListingComponent,
        TransferListingComponent,
        TicketRedeemComponent,
        TicketModalComponent,
        TicketTransferModalComponent,
        TicketCancelTransferModalComponent,
    ],
    exports: [
        TicketRedeemComponent
    ]
})
export class TicketsModule { }
