import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core';


@Component({
    selector: 'app-auth-user',
    templateUrl: './user.component.html'
})
export class UserComponent {

    @Input() public isLoading: boolean = false;
    
    @Output()
    public generateVerificationCode = new EventEmitter<{
        nameFirst: string,
        nameLast: string,
        email: string
    }>();
    signUpForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
    ) {
        this.createForm();
    }

    private createForm() {
        this.signUpForm = this.formBuilder.group({
            nameFirst: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
            nameLast: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
            email: ['', Validators.required],
        });
        if (this.authenticationService.credentials) {
            this.signUpForm.setValue({ email: null, nameFirst: null, nameLast: null });
        }
    }

    public postUser() {
        this.generateVerificationCode.emit(this.signUpForm.value);
    }

}
