import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { PaymentMethodsService } from '@app/data/services/payment-methods.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { LOADER_IMAGE } from '../braintree.constants';

declare var braintree: any;

@Component({
    selector: 'ngx-braintree-vault',
    templateUrl: './ngx-braintree-vault.component.html'
})
export class NgxBraintreeVaultComponent implements OnInit {

    @Output() addButtonStatus: EventEmitter<any> = new EventEmitter<any>();
    @Output() canMarkAsDefault: EventEmitter<any> = new EventEmitter<any>();

    clientToken: string;
    errorMessage: string;

    clientTokenNotReceived = false; // to show the error, "Error! Client token not received."
    interval: any;
    instance: any;
    dropinConfig: any = {};
    enableAddButton = false;
    showLoader = true;
    loaderImage = LOADER_IMAGE
    selectedPaymentMethodNonce: string;

    addingNewCard = false;

    constructor(
        private service: CartService,
        private paymentMethodsService: PaymentMethodsService,
        private changeDetectorRef: ChangeDetectorRef,
        private _messageService: MessageService
    ) { }

    ngOnInit() {
        this.generateDropInUI();
    }

    generateDropInUI() {
        this.service.getClientToken()
            .subscribe({
                next: (clientToken: string) => {
                    if (!clientToken) {
                        this.clientTokenNotReceived = true;
                        this.showLoader = false;
                    } else {
                        this.clientToken = clientToken;
                        this.clientTokenNotReceived = false;
                        this.interval = setInterval(() => {
                            this.createDropin();
                        }, 0);
                    }
                },
                error: (err: any) => {
                    this.clientTokenNotReceived = true;
                    this.showLoader = false;
                    console.error(`Client token not received. Please make sure your braintree server api is configured properly, running and accessible.`);
                }
            });
    }

    createDropin() {
        if (typeof braintree !== 'undefined') {
            this.dropinConfig.authorization = this.clientToken;
            this.dropinConfig.container = '#dropin-container';
            this.dropinConfig.vaultManager = true;

            braintree.dropin.create(this.dropinConfig, (createErr: any, instance: any) => {
                if (createErr) {
                    console.error(createErr);
                    this.errorMessage = createErr;
                    this.showLoader = false;
                    return;
                }
                this.showLoader = false;
                this.instance = instance;
                this.updateUIForVault();
                this.setInitialActiveView();
                this.instance.on('paymentMethodRequestable', (event: any) => {
                    if (this.addingNewCard) {
                        this.enableAddButton = true;
                        this.addButtonStatus.emit(this.enableAddButton);
                        this.changeDetectorRef.detectChanges();
                        const errorMessageEl = document.querySelector('.braintree-sheet__error');
                        if (errorMessageEl) {
                            errorMessageEl.classList.add('braintree-hidden');
                        }
                    } else {
                        this.requestPaymentMethod();
                    }
                });
                this.instance.on('noPaymentMethodRequestable', (event: any) => {
                    this.enableAddButton = false;
                    this.addButtonStatus.emit(this.enableAddButton);
                    this.changeDetectorRef.detectChanges();
                });

                this.instance.on('changeActiveView', (event: any) => {
                    // fires when the view changes, such as going from the
                    // credit card view to the saved payment methods view
                    this.addingNewCard = event.newViewId === 'card';
                    this.updateUIForVault();

                    this.requestPaymentMethod();
                });
            });
            clearInterval(this.interval);
        }
    }

    updateUIForVault() {
        const newViewbuttonEl = document.querySelector('.braintree-toggle');
        if (newViewbuttonEl) {
            newViewbuttonEl.innerHTML = '<span>Add another payment method</span>';
        }
    }

    setInitialActiveView() {
        const newViewbuttonEl = document.querySelector('.braintree-toggle');
        if (newViewbuttonEl && newViewbuttonEl.classList.contains('braintree-hidden')) {
            this.addingNewCard = true;
            this.canMarkAsDefault.emit(false);
        } else {
            this.requestPaymentMethod();
        }
    }

    requestPaymentMethod() {
        this.instance.requestPaymentMethod((err: string, payload: any) => {
            this.canMarkAsDefault.emit(payload?.nonce ? true : false);
            this.selectedPaymentMethodNonce = payload?.nonce;
        });
    }

    addCard(): void {
        this.enableAddButton = false;
        this.addButtonStatus.emit(this.enableAddButton);
        if (this.instance) {
            this.instance.requestPaymentMethod((err: string, payload: any) => {
                if (err) {
                    console.error(err);
                    this.errorMessage = err;
                    return;
                } else {
                    this.errorMessage = null;
                }
                this.selectedPaymentMethodNonce = payload?.nonce;
                this.updateUIForVault();
            });
        }
    }

    markAsDefault(): void {
        this.paymentMethodsService.markAsDefault(this.selectedPaymentMethodNonce)
            .subscribe(res => {
                this.clientToken = null;
                this._messageService.toast.success('Selected payment method is marked as default.');
                this.generateDropInUI();
            });
    }
}
