import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { ReservedSeat, ReservedSeatObjectType } from '../models/reserved/seat.model';
import { Event } from '../models/event.model';
import { environment } from '@env/environment';
import { UserService } from './user.service';
import { map, tap } from 'rxjs/operators';
import { ReservedHoldToken, ReservedConfiguration } from '../models/reserved/configuration.model';
import { CartItemProduct } from '../models/cart/cart-item.model';
import { GatePass } from '../models/passes/gate-pass.model';
import { expTime } from '../models/cart/cart-expiration.model';
@Injectable()
export class ReservedSeatsService {

    private _baseUrl = environment.seatsio.apiUrl;
    public encodedKey: string = environment.seatsio.encodedKey;
    public httpOptions = {};

    public configuration: ReservedConfiguration;

    constructor (
        private _http: HttpClient,
        private _user: UserService
    ) {
        this.httpOptions = {
                headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Skip-Interceptor': 'true',
                'Authorization': `Basic ${this.encodedKey}`
            })
        };
    }

    /**
     *
     */
    private _getBaseUrl(product: CartItemProduct): string {

        if (product instanceof Event) {
            return `${this._baseUrl}/events/${product.uuid}`;
        }

        if (product instanceof GatePass) {
            return `${this._baseUrl}/seasons`;
        }

        return '';
    }

    /**
     *
     * @param url
     * @param content
     */
    private _sendPost(url: string, content: any): Observable<any> {
        return this._http.post(url, content, this.httpOptions);
    }

    /**
     *
     * Generate hold token from seats.io
     *
     */
    public generateHoldToken(): Observable<ReservedHoldToken> {

        let url = `${this._baseUrl}/hold-tokens`;
        return this._sendPost(url, {}).pipe(
            map((token) => new ReservedHoldToken().deserialize(token))
        )
    }

    /**
     *
     * Extend hold token expiration time from seats.io
     *
     */
    public extendHoldToken(currentToken: string): Observable<ReservedHoldToken> {

        let url = `${this._baseUrl}/hold-tokens/${currentToken}`;
        return this._sendPost(url, { expiresInMinutes: expTime }).pipe(
            map((token) => new ReservedHoldToken().deserialize(token))
        )
    }

    /**
     * Deselect a seat from outside of the seat map
     *
     * @param product
     * @param seat
     * @param holdToken
     */
    public deselectSeat(product: CartItemProduct, seat: ReservedSeat, holdToken: string): Observable<any> {

        let url = `${this._getBaseUrl(product)}/actions/release?expand=objects`;

        let objects: any[] = new Array<String>();

        let object = {objectId: seat.key};
        objects.push(seat.key);

        let postData: any = {
            "objects": [object],
            "holdToken": holdToken
        }

        if (product instanceof GatePass) {
            postData.events = (<GatePass> product).eventKeys;
        }

        return this._sendPost(url, postData);

    }

}
