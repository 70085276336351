import { Component, OnInit, ChangeDetectorRef, ApplicationRef, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AgencyService } from '@app/data/services/agency.service';
import { Agency } from '@app/data/models/agency.model';
import { UserService } from '@app/data/services/user.service';
import { User } from '@app/data/models/user.model';
import { Title } from '@angular/platform-browser';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit, OnDestroy {

    public agencyId: string;
    public agency: Agency;
    public user: User;
    private navigationSubscription: any;

    constructor (
        private _router: Router,
        private route: ActivatedRoute,
        public agencyService: AgencyService,
        private _userService: UserService,
        private _title: Title,
        private _spinner: SpinnerService
    ) {
        this.user = this._userService.user;

        // the route has the AgencyResolver attached to it, which grabs the Agency
        // and adds it to the snapshot.data object
        this.route.data.subscribe( ({agency}) => {
            this.agency = agency;
            this._spinner.hide();
        });

        this.navigationSubscription = this._router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                // set the page title
                let mascot: string = ""
                if (this.agency.nameMascot) {
                    mascot = this.agency.nameMascot
                }

                let title: string = this.agency.nameShort ? `${this.agency.nameShort} ${mascot}` : this.agency.name;
                this._title.setTitle(title + " - Ticket Spicket");

            }
        });
    }

    ngOnInit() { }

    ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        this._title.setTitle("Ticket Spicket");
    }

}
