import { IDeserializable } from "./deserializable.interface";
import * as _ from 'lodash';
import { CartItemProduct } from "./cart/cart-item.model";
import { TicketPrice } from "./ticket-price.model";

export class Bundle implements IDeserializable {

    public id: number;
    public products: BundleProduct[] = new Array<BundleProduct>();
    public selectableProducts: BundleProduct[] = new Array<BundleProduct>();
    public includedProducts: BundleProduct[] = new Array<BundleProduct>();

    // transient properties
    public lastIndex: number = 0;
    public selectedIndex: number = 0;
    public selectedProduct: BundleProduct = new BundleProduct();
    public hasPrev: boolean = false;
    public hasNext: boolean = false;
    public canSkip: boolean = false;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        if (input.products) {
            this.products = _.orderBy(input.products, ["rank"]).map((product) => (new BundleProduct().deserialize(product)));
        }

        if (this.selectableProducts.length > 0) {
            this.lastIndex = this.selectableProducts.length - 1;
            this.selectedProduct = this.selectableProducts[0];
        }

        this._updateHasNextPrev();

        return this;
    }

    private _updateHasNextPrev() {
        if (this.selectedIndex == 0 || this.selectableProducts.length == 0) {
            this.hasPrev = false;
        } else {
            this.hasPrev = true;
        };

        if (this.selectedIndex == this.lastIndex ) {
            this.hasNext = false;
        } else {
            this.hasNext = true;
        };

        this.canSkip = false;
        if (this.selectedProduct.isOptional && this.hasNext) {
            this.canSkip = true;
        }
    }

    public selectPrev() {
        if (this.selectedIndex > 0) {
            this.selectedIndex--;
            this.selectedProduct = this.selectableProducts[this.selectedIndex];
        }
        this._updateHasNextPrev();
    }

    public selectNext() {
        if (this.selectedIndex < this.lastIndex) {
            this.selectedIndex++;
            this.selectedProduct = this.selectableProducts[this.selectedIndex];
        }
        this._updateHasNextPrev();
    }


    public getSelectableProducts() {
        return this.products.filter((product) => !product.isIncluded);
    }

    public getIncludedProducts() {
        return this.products.filter((product) => product.isIncluded);
    }

}


export class BundleProduct implements IDeserializable {

    public type: string;
    public id: number;
    public ticketTypeId: number;
    public isIncluded: boolean = false;
    public isOptional: boolean = false;
    public qtyIncluded: number;
    public qtyMax: number;
    public qtyMin: number;
    public message: string;
    public rank: number;

    public cartProduct: CartItemProduct;
    public ticketPrice: TicketPrice;

    constructor() {}

    deserialize(input: any) {
        Object.assign(this, input);

        return this;
    }

}

