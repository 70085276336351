import { Component, OnInit } from '@angular/core';
import { Payment } from '@app/data/models/payment.model';
import { UserService } from '@app/data/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { Ticket } from '@app/data/models/ticket.model';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './detail.component.html'
})
export class PaymentDetailComponent implements OnInit {

    public payment: Payment;
    public events: EventAdmission[] = new Array<EventAdmission>();

    constructor(
        private _userService: UserService,
        private _route: ActivatedRoute,
        private _spinner: SpinnerService
    ) {
        this._spinner.setMessage('Loading Payment Details');
    }

    ngOnInit() {
        this._route.params.pipe(
            map(params => params['payment-id']),
            switchMap((uuid: string) => this._userService.getPayment(uuid))
        ).subscribe((payment) => {
            this.payment = payment;
            this.events = this._groupTicketsByEvent(payment.tickets);
            this._spinner.hide();
        });
    }

    private _groupTicketsByEvent(tickets: Ticket[]): EventAdmission[] {
        return _.chain(tickets)
            .groupBy((value) => value.event.uuid)
            .map((value) => {
                return {
                    event: value[0].event,
                    tickets: value
                }
            })
            .orderBy(['event.dateStart']).map((ticket: any) => {
                let admission = new EventAdmission(ticket.event);
                admission.setAdmission(ticket.tickets);
                return admission;
            })
            .value();

    }

}
