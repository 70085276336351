<div class="modal-body">
    <ts-section-header class="h5-responsive mb-3">
        Filter Your Passes
    </ts-section-header>
    <div class="pb-3" *ngIf="filter.agencies.length > 1">
        <h6 class="font-weight-bold">Team Filters</h6>
        <ul class="list-group">
          <li style="cursor: pointer;" class="list-group-item list-group-item-action" (click)="filter.selectAllAgencies()">
              Select All Teams
              <mat-slide-toggle click-stop-propagation class="float-right" (change)="filter.selectAllAgencies()" [checked]="filter.isAllAgenciesSelected()"></mat-slide-toggle>
          </li>
        </ul>
        <ul class="list-group">
          <li style="cursor: pointer;" class="list-group-item list-group-item-action" *ngFor="let agency of filter.agencies" (click)="filter.toggleAgency(agency)">
              {{agency.name}}
              <mat-slide-toggle click-stop-propagation (change)="filter.toggleAgency(agency)" [checked]="filter.isAgencySelected(agency)" class="float-right"></mat-slide-toggle>
          </li>
        </ul>
    </div>
    <div>
        <h6 class="font-weight-bold">Activity Filters</h6>
        <ul class="list-group">
          <li style="cursor: pointer;" class="list-group-item list-group-item-action" (click)="filter.selectAllActivities()">
              Select All Activities
              <mat-slide-toggle click-stop-propagation (change)="filter.selectAllActivities()" [checked]="filter.isAllActivitiesSelected()" class="float-right"></mat-slide-toggle>
          </li>
        </ul>
        <ul class="list-group">
            <li style="cursor: pointer;" class="list-group-item list-group-item-action" *ngFor="let activity of filter.activities" (click)="filter.toggleActivity(activity)">
                {{activity}}
                <mat-slide-toggle click-stop-propagation (change)="filter.toggleActivity(activity)" [checked]="filter.isActivitySelected(activity)" class="float-right"></mat-slide-toggle>
          </li>
        </ul>
    </div>
</div>
