<mat-list class="p-2 pb-3 mb-2 border-bottom">
    <mat-list-item class="pb-2">
        <div matListAvatar class="mb-2">
            <div *ngIf="!pass.agency.settings?.logoURI" matListAvatar class="default-logo">{{ pass.agency.name | limitTo: 1 }}</div>
            <img *ngIf="pass.agency.settings?.logoURI" class="logo"matListAvatar [src]="pass.agency.settings?.logoURI" alt="Logo">
        </div>
        <h5 mat-line class="title mb-1 pt-1 text-uppercase">{{ pass.agency.name }}</h5>
        <h5 mat-line class="title mb-1 pt-1 text-uppercase">{{ pass.name }}</h5>
    </mat-list-item>
    <div class="description p-1">{{ pass.description }}</div>
    <div class="prices" *ngIf="showPrices">
        <div class="prices" class="mt-2 pb-1" style="border-bottom:1px solid #a5a5a5;" *ngFor="let price of pass.prices">
            <div>
                <div class="float-right">{{ price.priceAmount | currency }}</div>
                {{ price.ticketType.title }}
            </div>
        </div>
    </div>
</mat-list>
