<div class="modal-header">
    <span class="modal-title"><strong>{{title}}</strong></span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
        <span style="font-size:2.8rem;font-weight:300;line-height:0.4;" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{content}}</p>
</div>
<div class="modal-footer">
    <div class="w-100 d-inline-block text-center">
        <button type="button" class="btn btn-light mr-2" style="min-width:100px;" (click)="activeModal.dismiss(false)">No</button>
        <button type="button" class="btn btn-secondary ml-2" style="min-width:100px;" (click)="activeModal.close('Yes')">Yes</button>
    </div>
</div>
