import { CartItemTypes } from "./cart-item-types.enum";
import { Observable, of, BehaviorSubject } from "rxjs";
import * as _ from 'lodash';
import { CartItem, CartItemProduct, CartItemSummary } from "./cart-item.model";
import { CartItemEvent, CartItemEventSummary, CartItemEventReserved } from "./cart-item-event";
import { Event } from "../event.model";
import { CartItemPass, CartItemPassSummary, CartItemPassReserved } from "./cart-item-pass";
import { GatePass } from "../passes/gate-pass.model";
import { ReservedSeat, ReservedSeatObjectType } from "../reserved/seat.model";
import { TicketPrice } from "../ticket-price.model";
import { Promotion } from "../promotion.model";
import { ConsumerGatePassHolder } from "../passes/consumer-gate-pass.model";
import { EventStoreChannel } from "../events/event-store-channel.model";

export class CartItemCollection {

    public items: CartItem[] = new Array<CartItem>();
    public items$ = new BehaviorSubject<CartItem[]>(new Array<CartItem>());
    public hasChannel = false;

    private _getProductItemType(product: CartItemProduct): CartItemTypes {

        if (product instanceof Event) {
            return CartItemTypes.event;
        }

        if (product instanceof GatePass) {
            return CartItemTypes.pass;
        }

        return null;

    }

    private _isProductMatch(item: CartItem, product: CartItemProduct) {
        return item.itemType === this._getProductItemType(product) &&
               item.product.id == product.id
    }

    private _isTicketPriceMatch(item: CartItem, ticketPrice: TicketPrice) {
        if (!item.ticketPrice) {
            return false;
        }
        return item.ticketPrice.id === ticketPrice.id
    }

    private _isSeatMatch(item: CartItem, seat: ReservedSeat) {
        if (!item.seat) {
            return false;
        }
        return item.seat.key === seat.key
    }

    private _isChannelMatch(item: CartItem, channel: EventStoreChannel) {
        if (_.isEmpty(channel)) {
            return false;
        }
        return item.channel.id === channel.id
    }

    private _findIndexByUUID(uuid: string) {
        return this.items.findIndex((item) => item.uuid == uuid);
    }

    private _getItemByUUID(uuid: string) {
        return this.items.find((item) => item.uuid == uuid);
    }

    private _getItemsByProduct(product: CartItemProduct) {
        return this.items.filter((item) => this._isProductMatch(item, product));
    }

    private _getItemsByProductTicketPrice(product: CartItemProduct, ticketPrice: TicketPrice) {
        return this._getItemsByProduct(product).filter((item) => this._isTicketPriceMatch(item, ticketPrice));
    }

    private _getItemsByChannel(channel: EventStoreChannel) {
        return this.items.filter((item) => this._isChannelMatch(item, channel));
    }

    private _findIndexByProductTicketPrice(product: CartItemProduct, ticketPrice: TicketPrice) {
        return this.items.findIndex((item) => this._isProductMatch(item, product) && this._isTicketPriceMatch(item, ticketPrice));
    }

    private _setHasChannel() {
        let channelItems = this.items.filter((item) => !_.isEmpty(item.channel));
        this.hasChannel = channelItems.length > 0;
    }

    private _findIndexByProductSeat(product: CartItemProduct, seat: ReservedSeat) {
        return this.items.findIndex((item) => this._isProductMatch(item, product) && this._isSeatMatch(item, seat));
    }

    private _getByProductItemType(type: CartItemTypes) {
        return this.items.filter((item) => item.itemType === type);
    }

    public hasProduct(product: CartItemProduct): boolean {
        return this.items.filter((item) => {
            return item.itemType === this._getProductItemType(product) &&
            item.product.id == product.id
        }).length > 0;
    }

    public getEventItems(): CartItemEventSummary[] {
        return _.chain(this._getByProductItemType(CartItemTypes.event))
                .groupBy((value) => (<Event> value.product).uuid)
                .map((value) =>  new CartItemEventSummary(<Event> value[0].product, value))
                .orderBy(['event.dateStart'])
                .value();

    }

    public getPassItems(): CartItemPassSummary[]  {

        return _.chain(this._getByProductItemType(CartItemTypes.pass))
                .groupBy((value) => (<GatePass> value.product).id)
                .map((value) =>  new CartItemPassSummary(<GatePass> value[0].product, value))
                .orderBy(['pass.name'])
                .value();

    }

    public _addItems(items: CartItem[]) {
        this.items.push(...items);
        this.items$.next(this.items);
        this._setHasChannel();
    }

    private _removeItem(index: number) {
        this.items.splice(index, 1);
        this.items$.next(this.items);
        this._setHasChannel();
    }

    public fold(items: CartItem[]) {
        this.items = this.items.concat(items);
        this.items$.next(this.items);
        this._setHasChannel();
    }


    public addTicketItem(product: CartItemProduct, price: TicketPrice, channel?: EventStoreChannel) {
        if (product instanceof Event) {
            const item = new CartItemEvent(product, price, channel)
            this._addItems([item])
        }
    }

    public addPassItem(product: CartItemProduct, price: TicketPrice, selectedQty: number = 0, members: Array<ConsumerGatePassHolder> = new Array<ConsumerGatePassHolder>()) {
        if (product instanceof GatePass) {
            const item = new CartItemPass(product, price, selectedQty, members)
            this._addItems([item])
        }
    }

    public addItem(product: CartItemProduct, price: TicketPrice, selectedQty: number = 0, members: Array<ConsumerGatePassHolder> = new Array<ConsumerGatePassHolder>(), channel: EventStoreChannel) {

        if (product instanceof Event) {
            this.addTicketItem(product, price, channel);
        }

        if (product instanceof GatePass) {
            this.addPassItem(product, price, selectedQty, members);
        }

    }

    public addReservedItem(product: CartItemProduct, seat: ReservedSeat, price: TicketPrice, channel: EventStoreChannel) {

        seat.price = price.priceAmount;

        // check to see if the seat is already in the list
        if (seat.objectType === ReservedSeatObjectType.individualSeat && this._findIndexByProductSeat(product, seat) > -1) {
            return;
        }

        if (product instanceof Event) {
            const item = new CartItemEventReserved(product, seat, price, channel)
            this._addItems([item]);
        }

        if (product instanceof GatePass) {
            const item = new CartItemPassReserved(product, seat, price)
            this._addItems([item]);
        }

    }

    public removeItem(product: CartItemProduct, ticketPrice: TicketPrice) {
        let i = this._findIndexByProductTicketPrice(product, ticketPrice);
        if (i > -1) {
            this._removeItem(i);
        }
    }

    public removeReservedItem(product: CartItemProduct, seat: ReservedSeat) {
        let i = this._findIndexByProductSeat(product, seat);
        if (i > -1) {
            this._removeItem(i);
        }
    }

    public getProductQty(product: CartItemProduct): Observable<number> {
        return of(this._getItemsByProduct(product).length);
    }

    public getProductTicketPriceQty(product: CartItemProduct, ticketPrice: TicketPrice): Observable<number> {
        return of(this._getItemsByProductTicketPrice(product, ticketPrice).length);
    }

    public hasReachedMaxAvailableQty(product: CartItemProduct, ticketPrice: TicketPrice): Observable<boolean> {
        return of(this._getItemsByProductTicketPrice(product, ticketPrice).length === ticketPrice.calulatedAvailable);
    }

    public getChannelQty(channel: EventStoreChannel): number {
        return this._getItemsByChannel(channel).length;
    }

    public getProductTotalPrice(product: CartItemProduct): Observable<number> {
        return of(_.sumBy(this._getItemsByProduct(product), function(item) { return item.totalPrice}));
    }

    public getProductTicketPriceTotalPrice(product: CartItemProduct, ticketPrice: TicketPrice): Observable<number> {
        return of(_.sumBy(this._getItemsByProductTicketPrice(product, ticketPrice), function(item) { return item.totalPrice}));
    }

    public getTotalPrice(): number {
        return _.sumBy(this.items, function(item) { return item.totalPrice});
    }

    public getTotalFees(): number {
        return _.sumBy(this.items, function(item) { return item.fees});
    }

    public getTotalDiscount(): number {
        return _.sumBy(this.items, function(item) { return item.discount});
    }

    public getTotalQty():number {
        return this.items.length;
    }

    public isEmpty(): boolean {
        return this.items.length === 0;
    }

    public getProductItemSummary(product: CartItemProduct) {
        if (product instanceof Event) {
            return this.getEventItems().find((item) => item.product.uuid == product.uuid);
        }
        if (product instanceof GatePass) {
            return this.getPassItems().find((item) => item.product.id == product.id);
        }

        return null;
    }

    /**
     *
     * @param product
     * @param level
     * @param quantity
     */
    public setProductTicketTypeQuantity(product: CartItemProduct, ticketPrice: TicketPrice, quantity: number) {

        // first, find the items
        let items = this._getItemsByProductTicketPrice(product, ticketPrice);
        let item = this.items[this._findIndexByProductTicketPrice(product, ticketPrice)];

        // calculate the difference
        let diff = quantity - items.length;

        if (diff > 0) {
            for (let i = 1; i <= diff; i++) {
                this.addItem(item.product, item.ticketPrice, item.selectedQty, null, item.channel);
            }
        }

        if (diff < 0) {
            for (let i = diff; i < 0; i++) {
                this.removeItem(item.product, item.ticketPrice);
            }
        }

    }

    public serialize(): any[] {
        return this.items.map((item) => item.serialize());
    }

    public setCalculations(items: any[]) {
        items.map((item) => {
            let index: number = this._findIndexByUUID(item.uuid);
            if (index >= 0) {
                this.items[index].discount = item.discount;
                this.items[index].fees = item.fees;
                if (item.promotion !== null) {
                    this.items[index].promotion = new Promotion().deserialize(item.promotion);
                } else {
                    this.items[index].promotion = new Promotion();
                }
            }
        })
    }
}
