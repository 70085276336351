<div class="modal-body p-0">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="letter-holder">
            <div class="letter">
                <span *ngIf="consumer.holderName">{{ consumer.holderName | limitTo: 1 }}</span>
                <span *ngIf="!consumer.holderName">U</span>
            </div>
        </div>
        <div class="p-3">
            <h5 class="text-center text-uppercase font-weight-bold border-bottom">
                PASS TYPE:<br />{{ consumer.level.value }}
                <span *ngIf="consumer.seatNumber">
                    Seat: {{ consumer.seatNumber }}
                </span>
            </h5>
            <p class="text-muted text-center font-sm">
                Make a one time transfer to your family or close friends just by entering their email address below.
            </p>
            <p class="text-muted text-center font-sm">
                Once, they sign up with Ticket Spicket, they’ll be able to claim their tickets to the events under Tickets & Passes.
            </p>
            <form (ngSubmit)="transfer()" [formGroup]="transferForm" novalidate style="border:none;">
                <div class="form-group">
                    <label class="d-block">
                        <input type="email" class="form-control" formControlName="email" autocomplete="email" [placeholder]="'Email Address'"  aria-label="Email Address" aria-describedby="email-addon" email />
                        <span hidden >Email</span>
                        <small [hidden]="transferForm.get('email').valid || transferForm.get('email').untouched" class="text-danger" >
                        Email is required.
                        </small>
                    </label>
                </div>
                <hr />
                <button class="btn btn-sm btn-secondary btn-block text-uppercase" type="submit" [disabled]="transferForm.invalid">
                    <i class="fa fa-paper-plane mr-2"></i>Transfer Pass
                </button>
            </form>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
  <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Cancel</button>
</div>  
