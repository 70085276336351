<div class="home-container">
        <div class="container-fluid pb-3">
            <div class="text-center">
                <div class="container px-0 px-sm-3" style="z-index:4;">
                    <div class="card col-md-6 mt-3 mx-auto">
                        <div class="card-body">
                            <h4 class="card-title text-center m-0" style="border:none;">
                                <div class="row">
                                    <div class="col-10 col-offset-1 col-sm-8 col-sm-offset-2 text-center mr-auto ml-auto mt-5">
                                        <img routerLink="/home" alt="Ticket Spicket" style="cursor:pointer;" src="https://s3.amazonaws.com/com.ticketspicket.app/logos/ticket_spicket_hometown_logo_black_and_white_v1.svg" class="img-fluid flex-center mb-3">
                                        <a routerLink="/home" style="cursor:pointer;" class="font-sm d-block text-center mt-2 mb-3"><i class="fa fa-arrow-left mr-2"></i>Back to Search</a>
                                    </div>
                                </div>
                            </h4>
                            <form (ngSubmit)="changePassword()" [formGroup]="passwordForm" novalidate autocomplete="on">
                                <div class="alert alert-danger" [hidden]="!error || isLoading">
                                    {{error}}
                                </div>
                                <div class="text-center font-weight-bold h5 text-uppercase">Set Your New Password</div>
                                <div class="form-group">
                                    <label class="d-block my-3">
                                        <input type="password" class="form-control" formControlName="password" autocomplete="new-password" [placeholder]="'Password'"/>
                                        <!-- <ng2-password-strength-bar
                                            [passwordToCheck]="passwordForm.get('password').value"
                                            barLabel="Strength" class="mx-auto">
                                        </ng2-password-strength-bar> -->
                                        <span hidden>New Password</span>
                                        <div [hidden]="passwordForm.get('password').untouched" *ngIf="passwordForm.get('password').invalid" class="text-danger" style="font-size: .8em">
                                            <div *ngIf="passwordForm.get('password').errors?.required">New Password is required</div>
                                            <div *ngIf="passwordForm.get('password').errors?.strong">New Password is not strong enough</div>
                                        </div>
                                    </label>
                                    <label class="d-block">
                                        <input type="password" class="form-control" formControlName="confirmPassword" autocomplete="new-password" [placeholder]="'Confirm Password'"/>
                                        <span hidden >Confirm New Password</span>
                                        <small [hidden]="passwordForm.get('confirmPassword').valid || passwordForm.get('confirmPassword').untouched" class="text-danger">
                                            Confirm New Password is required
                                        </small>
                                        <div [hidden]="passwordForm.get('confirmPassword').untouched" *ngIf="passwordForm.get('confirmPassword').errors" class="text-danger" style="font-size: .8em">
                                            Confirm New Password does not match Password
                                        </div>
                                    </label>
                                </div>
                                <button mdbRippleRadius class="btn btn-primary w-100 m-0" type="submit" [disabled]="passwordForm.pristine || passwordForm.invalid || isLoading">
                                    <h6 class="m-0" [hidden]="!isLoading"><i class="fa fa-cog fa-spin mr-2" style="display:inline-block;"></i>Loading</h6>
                                    <h6 class="m-0" [hidden]="isLoading">Set New Password</h6>
                                </button>
                                <div class="my-3 p-3 font-sm text-left border">
                                    <p class="m-0 p-0 pb-1"><strong>Your password must contain each of the following:</strong></p>
                                    <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.get('password').errors?.['strong']?.length ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least 6 characters</div>
                                    <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.get('password').errors?.['strong']?.alpha ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one letter</div>
                                    <div><i class="fa fa-fw mr-2" [ngClass]="passwordForm.get('password').errors?.['strong']?.number ? 'fa-times text-danger' : 'text-success fa-check'"></i>at least one number</div>
                                </div>
                                <p class="text-center m-2 mt-3">
                                    <strong>Already have an account?&nbsp;</strong><a [routerLink]="['/auth', 'login']">Login</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
