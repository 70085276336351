export class Pagination {

    page: number;
    pageSize: number;
    maxSize: number;
    ellipses: boolean = false;
    boundaryLinks: boolean = true;

    constructor(pageSize: number) {
        this.pageSize = pageSize;
        this.page = 1;
        this.maxSize = 5;
    }

    public startItem(): number {
        return ((this.page - 1) * this.pageSize)
    }

    public endItem(): number {
        return (this.page * this.pageSize)
    }

}
