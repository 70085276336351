import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthComponent } from '@app/auth/auth.component';
import { SignUpComponent } from './sign-up/sign-up.component';

const routes: Routes = [
    { path: '', component: AuthComponent, data: { title: 'Auth Links'}, children: [
        { path: '', component: LoginComponent, data: { title: 'Login' } },
        { path: 'register', component: SignUpComponent, data: { title: 'Registration' } },
        { path: 'reset', component: ForgotPasswordComponent, data: { title: 'Reset Password' } },        
        { path: 'login', redirectTo: ''},
    ] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule { }
