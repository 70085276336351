<div class="container-fluid background-color">
    <div class="row menu-container" style="min-height:calc(100vh - 112px);">
        <aside class="col-12 col-lg-2 p-0 bg-dark">
            <nav class="navbar navbar-expand navbar-dark bg-dark flex-lg-column flex-row align-items-start py-2">
                <div class="collapse navbar-collapse mx-0 mx-lg-0">
                    <ul class="flex-lg-column flex-row navbar-nav w-100 justify-content-between text-uppercase">
                        <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" routerLink="."
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <i class="fa fa-user fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Profile</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Profile"
                                [matTooltipPosition]="tooltipPosition"
                                aria-label="Button that takes you to the Profile View." routerLink="."
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <i class="fa fa-user fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Profile</span>
                            </a>
                        </li>
                        <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" routerLink="invite"
                                routerLinkActive="active">
                                <i class="fa fa-share-alt fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Invites</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Invites"
                                [matTooltipPosition]="tooltipPosition"
                                aria-label="Button that takes you to the Invite View." routerLink="invite"
                                routerLinkActive="active">
                                <i class="fa fa-share-alt fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Invites</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" style="position:relative;" routerLink="messages" routerLinkActive="active">
                                <i class="fa fa-envelope fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Messages</span>
                                <span class="ml-3 badge badge-pill badge-secondary" style="position:absolute;top:10px;right:5px;" *ngIf="unreadCount > 0">{{unreadCount}}</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Messages" [matTooltipPosition]="tooltipPosition" aria-label="Button that takes you to the Messages View." style="position:relative;" routerLink="messages" routerLinkActive="active">
                                <i class="fa fa-envelope fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Messages</span>
                                <span class="ml-3 badge badge-pill badge-secondary" style="position:absolute;top:10px;right:5px;" *ngIf="unreadCount > 0">{{unreadCount}}</span>
                            </a>
                        </li> -->
                        <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" routerLink="payments"
                                routerLinkActive="active">
                                <i class="fa fa-credit-card fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Payments</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Payments"
                                [matTooltipPosition]="tooltipPosition"
                                aria-label="Button that takes you to the Payments View." routerLink="payments"
                                routerLinkActive="active">
                                <i class="fa fa-credit-card fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Payments</span>
                            </a>
                        </li>
                        <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" routerLink="payment-methods"
                                routerLinkActive="active">
                                <i class="fa fa-credit-card fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Payment Methods</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Payments Methods"
                                [matTooltipPosition]="tooltipPosition"
                                aria-label="Button that takes you to the Payment Methods." routerLink="payment-methods"
                                routerLinkActive="active">
                                <i class="fa fa-credit-card fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Payment Methods</span>
                            </a>
                        </li>
                        <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" routerLink="rewards"
                                routerLinkActive="active">
                                <i class="fa fa-gift fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Rewards</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Rewards"
                                [matTooltipPosition]="tooltipPosition"
                                aria-label="Button that takes you to the Rewards View." routerLink="rewards"
                                routerLinkActive="active">
                                <i class="fa fa-gift fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Rewards</span>
                            </a>
                        </li>
                        <li class="nav-item font-sm">
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-none d-lg-block" routerLink="transfers"
                                routerLinkActive="active">
                                <i class="fa fa-paper-plane fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Transfer History</span>
                            </a>
                            <a mdbRippleRadius class="nav-link pl-lg-0 d-block d-lg-none" matTooltip="Transfer History"
                                [matTooltipPosition]="tooltipPosition"
                                aria-label="Button that takes you to your Transfers History." routerLink="transfers"
                                routerLinkActive="active">
                                <i class="fa fa-paper-plane fa-fw mr-2"></i>
                                <span class="d-none d-lg-inline">Transfer History</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </aside>
        <main class="h-100 col bg-faded py-3">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>]
