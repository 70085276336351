<div class="card text-left" style="height:100%;cursor:pointer;">
    <div class="view card-img-top {{ event.activity.typeActivity.iconClassName }}">
        <div class="image-overlay"></div>
        <div class="header-color single" *ngIf="!event.opponent?.settings">
            <div class="agency-color" [ngStyle]="{'background-color': agency.settings?.primaryColor}"></div>
            <div class="agency-header">
                <div class="agency-logos">
                    <div class="agency-single text-center" *ngIf="!event.opponent?.settings">
                        <img *ngIf="event.getLogo()" [src]="event.getLogo()" alt="" class="float-none"/>
                        <div *ngIf="!event.getLogo()" class="float-none default-logo" [ngStyle]="{'background-color': agency.settings?.primaryColor, 'color': agency.settings?.primaryText}">
                            {{ agency.name | limitTo: 1 }}
                        </div>
                        <div class="mx-auto text-uppercase py-2 font-sm" style="color:white;"><strong>{{ event.getHomeAgency().abbreviation }}</strong></div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-color double" *ngIf="event.opponent?.settings">
            <div class="opponent-color" [ngStyle]="{'background-color': event.opponent?.settings?.primaryColor}" ></div>
            <div class="agency-color" [ngStyle]="{'background-color': agency.settings?.primaryColor}" ></div>
            <div class="agency-header">
                <div class="agency-logos text-center">
                    <div class="agency-double">
                        <div class="float-right">
                            <img *ngIf="event.getHomeAgency().settings?.logo2xURI" [src]="event.getHomeAgency().settings?.logo2xURI" alt="" class="mb-2 mx-auto d-block float-none" />
                            <div *ngIf="!event.getHomeAgency().settings?.logo2xURI" class="mb-2 mx-auto d-block float-none default-logo" [ngStyle]="{'background-color': event.getHomeAgency().settings?.primaryColor, 'color': event.getHomeAgency().settings?.primaryText}">
                                {{ event.getHomeAgency().name | limitTo: 1 }}
                            </div>
                            <div class="mx-auto text-uppercase pb-2 font-sm d-block" style="color:white;"><strong>{{ event.getHomeAgency().abbreviation }}</strong></div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="d-inline-block mx-auto float-none versus">VS</div>
                        <div class="float-left">
                            <img *ngIf="event.opponent?.settings?.logo2xURI" [src]="event.opponent?.settings?.logo2xURI" alt="" class="mb-2 mx-auto d-block float-none" />
                            <div *ngIf="!event.opponent?.settings?.logo2xURI" class="mb-2 mx-auto d-block float-none default-logo" [ngStyle]="{'background-color': event.opponent?.settings?.primaryColor, 'color': event.opponent?.settings?.primaryText}">
                                {{ event.opponent.name | limitTo: 1 }}
                            </div>
                            <div class="mx-auto text-uppercase pb-2 font-sm d-block" style="color:white;"><strong>{{ event.opponent.abbreviation }}</strong></div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-1 py-2" style="background:black;color:white;text-align:center;">
        <div class="font-sm">
            <i class="fa fa-clock mr-1"></i><strong>{{ event.dateStart | date: 'EEEE, MMMM d, y' }}</strong>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="card-body">
        <h5 class="card-title" *ngIf="event.status != 0">
            <span *ngIf="event.status > 0">Postponed&nbsp;<span *ngIf="event.dateStartOriginal">(From {{ event.dateStartOriginal | date: 'MM/dd/yyyy' }})</span></span>
            <span *ngIf="event.status < 0">Cancelled</span>
        </h5>
        <div class="row">
            <div class="col-9">
                <h5 class="card-title mb-1 text-uppercase">{{ event.title }}</h5>
                <div *ngIf="event.isSportingEvent()">
                    <h5 class="card-title mb-1">{{ event.homeName }}</h5>
                    <h5 class="card-title mb-1">{{ event.visitorName }}</h5>
                </div>
                <div *ngIf="!event.isSportingEvent()">
                    <h5 class="card-title mb-1">{{ event.subtitle }}</h5>
                </div>
            </div>
            <div class="col-3 text-right">
                <div class="activity float-right">
                    <svg class="icon">
                        <use attr.xlink:href="#{{ event.activity?.typeActivity.iconClassName }}"></use>
                    </svg>
                </div>
            </div>
        </div>
        <div class="card-text special-event pb-2">{{ event.specialEvent }}</div>
        <div class="card-text text-muted font-sm">
            <div><i class="fa fa-fw fa-map-marker mr-2"></i>{{ event.location?.address.name }}</div>
            <div><i class="fa fa-fw mr-2"></i>{{ event.location?.address.cityState }}</div>
        </div>
    </div>
    <div class="card-footer py-1 px-3" *ngIf="!event.isPassOnly()">
        <div class="text-right">
            <a class="buy">Buy Tickets <i class="fa fa-arrow-right ml-1"></i></a>
        </div>
    </div>
</div>
