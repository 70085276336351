<div *ngIf="showLoader" style="position:relative; text-align: center;">
    <div #loaderRef><ng-content select=".ngxLoader" *ngIf="!hideLoader"></ng-content></div>
    <img *ngIf="loaderRef.children.length === 0 && !hideLoader" src="{{loaderImage}}" />
</div>
<div class="alert alert-warning mb-3 p-2" *ngIf="errorMessage">
    <p>{{errorMessage}}</p>
</div>
<div *ngIf="showDropinUI && clientToken" ngxBraintreeDirective>
    <div id="dropin-container"></div>
</div>
<div *ngIf="clientTokenNotReceived">
    <div class="error p-2 mt-2">Error! Client token not received.</div>
    <div class="errorInfoDiv my-3 p-2">Make sure your clientTokenURL's JSON response is as shown below:
        <pre>{{ '{' }}"token":"braintree_client_token_generated_on_your_server"{{'}'}}</pre>
    </div>
</div>