import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, Logger, LoginContext, replacer, SentryTransaction } from '@app/core';
import { User } from '@app/data/models/user.model';
import { CartService } from '@app/data/services/cart.service';
import { confirmEmailValidator, confirmPasswordValidator, strongPasswordValidator } from '@app/shared/ticket-spicket/validators';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';

const log = new Logger('Register');

@Component({
    selector: 'app-auth-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    version: string = environment.version;
    error: string;
    registrationForm: FormGroup;
    isLoading = false;
    returnParam: string = '';

    private loginContext: LoginContext = {
        email: null,
        password: null
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private cart: CartService
    ) {
        this.createForm();
        if (authenticationService.credentials) {
            this.registrationForm.setValue({ email: null, password: null, nameFirst: null, nameLast: null, confirmPassword: null });
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => this.returnParam = params['return'] || '/home');
    }

    register() {

        this.isLoading = true;

        this.authenticationService.register(this.registrationForm.value).pipe(
            switchMap((user: User) => {
                this.loginContext.email = this.registrationForm.get("email").value;
                this.loginContext.password = this.registrationForm.get("password").value;
                return this.authenticationService.login(this.loginContext);
            }))
            .subscribe(() => {
                this.toastr.success('Your account has been successfully registered.', 'Registration Successful!');
                if (this.cart.countdown) {
                    const info = JSON.stringify(this.cart.getItemsLog(), replacer);
                    SentryTransaction('Chectout', 'Authenticated - register - redirecting to checkout', { cart: info });
                    this.router.navigateByUrl('/cart/checkout');
                } else {
                    this.router.navigateByUrl(this.returnParam);
                }
            }, (error) => {
                log.debug(`Login error: ${error}`);
                if (error.status === 400) {
                    this.error = error.error;
                } else {
                    this.error = error.message;
                }
                this.isLoading = false;
            });

    }

    private createForm() {
        this.registrationForm = this.formBuilder.group({
            nameFirst: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
            nameLast: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
            email: ['', Validators.required],
            confirmEmail: ['', Validators.required],
            password: ['', [Validators.required, strongPasswordValidator()]],
            confirmPassword: ['', Validators.required]
        });
        this.registrationForm.setValidators([
            confirmPasswordValidator(this.registrationForm.get('password'), this.registrationForm.get('confirmPassword')),
            confirmEmailValidator(this.registrationForm.get('email'), this.registrationForm.get('confirmEmail'))            
        ]);
    }

}
