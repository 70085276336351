<div class="default background-gradient" *ngIf="agency">
    <div class="text text-center col-12 text-gradient">
        <div class="row">
            <div class="col-12">
                <div class="text-uppercase d-inline-block">
                    <h3>{{ agency.name }}</h3>
                    <h4 class="mascot" *ngIf="agency.nameMascot">{{ agency.nameMascot }}</h4>
                    <h4 *ngIf="!agency.nameMascot">Events</h4>
                </div>
            </div>
        </div>
    </div>
</div>
