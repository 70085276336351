import { IDeserializable } from "@app/data/models/deserializable.interface";
import { Event } from "@app/data/models/event.model";
import { Agency } from "@app/data/models/agency.model";
import { TicketLevel } from "@app/data/models/ticket-level.model";
import * as moment from "moment";
import { IAdmission, AdmissionSource, AdmissionActions } from "./tickets/admission.interface";
import { ReservedSeat, ReservedSeatCategory, ReservedSeatObjectType } from "./reserved/seat.model";

export class Ticket implements IDeserializable, IAdmission {

    id: number;
    ticketNumber: string;
    uuid: string;
    bundleUUID: string;
    event: Event;
    level: TicketLevel;
    agency: Agency;

    seat: ReservedSeat;
    seatNumber: string;

    printable: boolean;
    transferable: boolean;
    transferred: boolean;
    shared: boolean;
    isPrinted: boolean;
    isSynchronized: boolean;

    dateRedeemed: Date;
    redemptions: any[];

    paidAmount: number;
    discountAmount: number;
    feeAmount: number;

    ticketColor?: string;

    public source: string = AdmissionSource.ticket;

    constructor() {}

    deserialize(input: any) {

        Object.assign(this, input);

        if(input.ticketColor) {
            this.ticketColor = input.ticketColor;
        }

        if(input.index) {
            this.id = input.index;
            if(input.id) {
                this.uuid = input.id;
            }
        }

        if(input.bundle_uuid) {
            this.bundleUUID = input.bundle_uuid;
        }

        if(input.is_printable) {
            this.printable = true;
        }

        if(input.is_transferred) {
            this.transferred = true;
        }

        if(input.is_transferable) {
            this.transferable = true;
        }

        if(input.is_syncronized) {
            this.isSynchronized = true;
        }

        if(input.is_shared) {
            input.shared = true
        }

        if(input.is_printed !== null) {
            this.isPrinted = input.is_printed;
        }

        if(input.ticketNumber) {
            this.ticketNumber = input.ticketNumber;
        }

        if (input.event) {
            this.event = new Event().deserialize(input.event);
        }

        if (input.school) {
            this.agency = new Agency().deserialize(input.school);
        }

        if (input.event.account) {
            this.agency = new Agency().deserialize(input.event.account);
        }

        if (input.level) {
            this.level = new TicketLevel().deserialize(input.level);
        }

        if (input.type) {
            this.level = new TicketLevel().deserialize(input.type);
        }

        if (input.seatNumber) {
            this.seat = new ReservedSeat().deserialize(input.seatNumber);
        }

        if (input.seatKey) {
            this.seat = new ReservedSeat();
            this.seat.objectType = ReservedSeatObjectType.individualSeat
            this.seat.section = input.section;
            this.seat.row = input.row;
            this.seat.number = input.seat;
            this.seat.category = new ReservedSeatCategory().deserialize(input.type.reserved_category);
            this.seatNumber = input.seatKey;
        }

        if(input.type?.code == "GA" || (!input.seatKey && !input.seatNumber)) {
            this.seat = new ReservedSeat();
            this.seat.objectType = ReservedSeatObjectType.generalAdmission
            this.seat.section = "GA";
            this.seat.row = null;
            this.seat.number = input.type?.id;
        }

        this.dateRedeemed = null;

        if (input.dateRedeemed) {
            this.dateRedeemed = moment(input.dateRedeemed).toDate();
        }

        if(input.redeemed_date) {
            this.dateRedeemed = moment(input.redeemed_date).toDate();
        }

        return this;

    }

    public getActions(): string[] {
        let actions: string[] = new Array<string>();

        actions.push(AdmissionActions.buy);

        if (!this.transferred) {
            actions.push(AdmissionActions.view);
        } else {
            actions.push(AdmissionActions.cancel);
        }

        if (this.printable) {
            actions.push(AdmissionActions.print);
        }

        if (this.transferable) {
            actions.push(AdmissionActions.transfer);
        }

        return actions;

    }

}
