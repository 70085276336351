import { Component, Input, OnInit } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { ConsumerGatePassLink } from '@app/data/models/passes/consumer-gate-pass-link.model';

@Component({
    selector: 'ts-pass-consumer-card',
    templateUrl: './pass-consumer-card.component.html',
    styleUrls: ['./pass-consumer-card.component.scss']
})

export class PassConsumerCardComponent implements OnInit {

    @Input('link') link: ConsumerGatePassLink;

    constructor() { }

    ngOnInit() { }

}