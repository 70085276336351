import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { Event } from '@app/data/models/event.model';
import { CartService } from '@app/data/services/cart.service';
import * as _ from 'lodash';
import { ReservedSeat, ReservedSeatCategory, ReservedSeatObjectType } from '@app/data/models/reserved/seat.model';
import { CartItemSummary, CartItem } from '@app/data/models/cart/cart-item.model';
import { ReservedConfiguration } from '@app/data/models/reserved/configuration.model';
import { ReservedPricing } from '@app/data/models/reserved/pricing.model';
import { BehaviorSubject } from 'rxjs';
import { EventStoreChannel } from '@app/data/models/events/event-store-channel.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { CartItemCollection } from '@app/data/models/cart/cart-item-collection.model';
import { SeatMapComponent } from '@app/shared/ticket-spicket/seat-map/seat-map.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-agency-bundle-seatmap',
  templateUrl: './seatmap.component.html',
  styleUrls: ['./seatmap.component.scss']
})
export class AgencyBundleSeatmapComponent implements OnInit {

    @Input() pass: GatePass;
    @Input() eventUUID: string;
    @Input() ticketPrices: TicketPrice[];
    @Input() maxObjects: number = 1;

    @ViewChild(SeatMapComponent, { static: false }) seatmap: SeatMapComponent;

    public reservedSeats$ = new BehaviorSubject<CartItem[]>(new Array<CartItem>());

    public seats: {};
    public chart: any = null;
    public isLoading: boolean = true;

    public config: ReservedConfiguration;
    private _token: string;

    constructor (
        public cart: CartService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.cart.getHoldToken().subscribe((token) => {
            this._token = token;
            this.setConfig();
        })
    }

    public setConfig() {

        console.log('setConfig', this.eventUUID);
        this.config = new ReservedConfiguration(this._token);
        this.config.setEvent(this.eventUUID);
        this.config.numberOfPlacesToSelect = this.maxObjects;
        this.config.pricing.push()

        // set max selected objects based on channel
        // if (this.channel) {
        //     this.config.maxSelectedObjects = this.channel.maxPerTrx
        // }

        this.ticketPrices.map((price) => {
            let rp = new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price);
            rp.price.priceAmount = 0;
            this.config.pricing.push(rp);
        });

        // // loop through the pricing and set the pricing collection
        // this.event.getRESVTicketPrices().map((price) => {
        //     this.config.pricing.push(new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price));
        // });

        this.isLoading = false;
        this._setReservedSeats();
        this.reservedSeats$.subscribe((seats) => {
            console.log(seats);
        })
        //this.seatmap.setConfig();
    }

    ngOnDestroy() {
    }

    /**
     * Executed from the seatObjectSelected event on the seat map
     *
     * Since the seat is already selected in the seat map, it just needs to be added to the cart
     *
     * @param seat
     */
    public selectSeat(seat: any) {
        this.cart.bundledItems.addReservedItem (
            this.pass,
            new ReservedSeat().deserialize(seat),
            this.config.getTicketPriceByCategoryKey(seat.category.key, seat.selectedTicketType),
            null
        );

        this._setReservedSeats();
    }

    /**
     * Called from seat listing - removes it from the list and also from the seatmap
     *
     * @param seat
     */
    public removeSeat (seat:any) {
        this.cart.bundledItems.removeReservedItem (
             this.pass,
             new ReservedSeat().deserialize(seat)
        );
        this._setReservedSeats();
    }


    private _setReservedSeats() {

        let summary: CartItemSummary = this.cart.bundledItems.getProductItemSummary(this.pass);

        if (summary) {
            this.reservedSeats$.next(summary.seats);
        } else {
            this.reservedSeats$.next(new Array<CartItem>());
        }
        console.log(this.cart.bundledItems.getPassItems());

    }

    public setChart (chart: any) {
        this.chart = chart;
    }

    public close() {
        this.activeModal.dismiss(false);
    }
}
